(function () {
  /*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
  var aa = this || self;
  function k(a, b) {
    a = a.split(".");
    var c = aa;
    a[0] in c ||
      "undefined" == typeof c.execScript ||
      c.execScript("var " + a[0]);
    for (var d; a.length && (d = a.shift()); )
      a.length || void 0 === b
        ? c[d] && c[d] !== Object.prototype[d]
          ? (c = c[d])
          : (c = c[d] = {})
        : (c[d] = b);
  }
  function m(a, b) {
    function c() {}
    c.prototype = b.prototype;
    a.ma = b.prototype;
    a.prototype = new c();
    a.prototype.constructor = a;
    a.sa = function (d, e, f) {
      for (
        var g = Array(arguments.length - 2), h = 2;
        h < arguments.length;
        h++
      )
        g[h - 2] = arguments[h];
      return b.prototype[e].apply(d, g);
    };
  }
  function ca(a) {
    const b = [];
    let c = 0;
    for (const d in a) b[c++] = a[d];
    return b;
  }
  function da(a, b) {
    this.g = a;
    this.l = !!b.aa;
    this.h = b.i;
    this.s = b.type;
    this.o = !1;
    switch (this.h) {
      case ea:
      case fa:
      case ha:
      case ia:
      case ja:
      case ka:
      case la:
        this.o = !0;
    }
    this.j = b.defaultValue;
  }
  var la = 1,
    ka = 2,
    ea = 3,
    fa = 4,
    ha = 6,
    ia = 16,
    ja = 18;
  function ma(a, b) {
    this.h = a;
    this.g = {};
    for (a = 0; a < b.length; a++) {
      var c = b[a];
      this.g[c.g] = c;
    }
  }
  function na(a) {
    a = ca(a.g);
    a.sort(function (b, c) {
      return b.g - c.g;
    });
    return a;
  }
  function n() {
    this.h = {};
    this.j = this.m().g;
    this.g = this.l = null;
  }
  n.prototype.has = function (a) {
    return p(this, a.g);
  };
  n.prototype.get = function (a, b) {
    return q(this, a.g, b);
  };
  n.prototype.set = function (a, b) {
    r(this, a.g, b);
  };
  n.prototype.add = function (a, b) {
    oa(this, a.g, b);
  };
  function pa(a, b) {
    for (var c = na(a.m()), d = 0; d < c.length; d++) {
      var e = c[d],
        f = e.g;
      if (p(b, f)) {
        a.g && delete a.g[e.g];
        var g = 11 == e.h || 10 == e.h;
        if (e.l) {
          e = t(b, f);
          for (var h = 0; h < e.length; h++) oa(a, f, g ? e[h].clone() : e[h]);
        } else
          (e = u(b, f)),
            g ? ((g = u(a, f)) ? pa(g, e) : r(a, f, e.clone())) : r(a, f, e);
      }
    }
  }
  n.prototype.clone = function () {
    var a = new this.constructor();
    a != this && ((a.h = {}), a.g && (a.g = {}), pa(a, this));
    return a;
  };
  function p(a, b) {
    return null != a.h[b];
  }
  function u(a, b) {
    var c = a.h[b];
    if (null == c) return null;
    if (a.l) {
      if (!(b in a.g)) {
        var d = a.l,
          e = a.j[b];
        if (null != c)
          if (e.l) {
            for (var f = [], g = 0; g < c.length; g++) f[g] = d.h(e, c[g]);
            c = f;
          } else c = d.h(e, c);
        return (a.g[b] = c);
      }
      return a.g[b];
    }
    return c;
  }
  function q(a, b, c) {
    var d = u(a, b);
    return a.j[b].l ? d[c || 0] : d;
  }
  function v(a, b) {
    if (p(a, b)) a = q(a, b);
    else
      a: {
        a = a.j[b];
        if (void 0 === a.j)
          if (((b = a.s), b === Boolean)) a.j = !1;
          else if (b === Number) a.j = 0;
          else if (b === String) a.j = a.o ? "0" : "";
          else {
            a = new b();
            break a;
          }
        a = a.j;
      }
    return a;
  }
  function t(a, b) {
    return u(a, b) || [];
  }
  function w(a, b) {
    return a.j[b].l ? (p(a, b) ? a.h[b].length : 0) : p(a, b) ? 1 : 0;
  }
  function r(a, b, c) {
    a.h[b] = c;
    a.g && (a.g[b] = c);
  }
  function oa(a, b, c) {
    a.h[b] || (a.h[b] = []);
    a.h[b].push(c);
    a.g && delete a.g[b];
  }
  function x(a, b) {
    var c = [],
      d;
    for (d in b) 0 != d && c.push(new da(d, b[d]));
    return new ma(a, c);
  }
  function y() {}
  y.prototype.g = function (a) {
    new a.h();
    throw Error("Unimplemented");
  };
  y.prototype.h = function (a, b) {
    if (11 == a.h || 10 == a.h)
      return b instanceof n ? b : this.g(a.s.prototype.m(), b);
    if (14 == a.h)
      return "string" === typeof b && qa.test(b) && ((a = Number(b)), 0 < a)
        ? a
        : b;
    if (!a.o) return b;
    a = a.s;
    if (a === String) {
      if ("number" === typeof b) return String(b);
    } else if (
      a === Number &&
      "string" === typeof b &&
      ("Infinity" === b || "-Infinity" === b || "NaN" === b || qa.test(b))
    )
      return Number(b);
    return b;
  };
  var qa = /^-?[0-9]+$/;
  function z() {}
  m(z, y);
  z.prototype.g = function (a, b) {
    a = new a.h();
    a.l = this;
    a.h = b;
    a.g = {};
    return a;
  };
  function A() {}
  m(A, z);
  A.prototype.h = function (a, b) {
    return 8 == a.h ? !!b : y.prototype.h.apply(this, arguments);
  };
  A.prototype.g = function (a, b) {
    return A.ma.g.call(this, a, b);
  };
  function B(a, b) {
    null != a && this.g.apply(this, arguments);
  }
  B.prototype.h = "";
  B.prototype.set = function (a) {
    this.h = "" + a;
  };
  B.prototype.g = function (a, b, c) {
    this.h += String(a);
    if (null != b)
      for (let d = 1; d < arguments.length; d++) this.h += arguments[d];
    return this;
  };
  function C(a) {
    a.h = "";
  }
  B.prototype.toString = function () {
    return this.h;
  }; /*

 Protocol Buffer 2 Copyright 2008 Google Inc.
 All other code copyright its respective owners.
 Copyright (C) 2010 The Libphonenumber Authors

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
  function E() {
    n.call(this);
  }
  m(E, n);
  var ra = null;
  function F() {
    n.call(this);
  }
  m(F, n);
  var sa = null;
  function G() {
    n.call(this);
  }
  m(G, n);
  var ta = null;
  E.prototype.m = function () {
    var a = ra;
    a ||
      (ra = a =
        x(E, {
          0: { name: "NumberFormat", ia: "i18n.phonenumbers.NumberFormat" },
          1: { name: "pattern", required: !0, i: 9, type: String },
          2: { name: "format", required: !0, i: 9, type: String },
          3: { name: "leading_digits_pattern", aa: !0, i: 9, type: String },
          4: { name: "national_prefix_formatting_rule", i: 9, type: String },
          6: {
            name: "national_prefix_optional_when_formatting",
            i: 8,
            defaultValue: !1,
            type: Boolean,
          },
          5: {
            name: "domestic_carrier_code_formatting_rule",
            i: 9,
            type: String,
          },
        }));
    return a;
  };
  E.m = E.prototype.m;
  F.prototype.m = function () {
    var a = sa;
    a ||
      (sa = a =
        x(F, {
          0: {
            name: "PhoneNumberDesc",
            ia: "i18n.phonenumbers.PhoneNumberDesc",
          },
          2: { name: "national_number_pattern", i: 9, type: String },
          9: { name: "possible_length", aa: !0, i: 5, type: Number },
          10: {
            name: "possible_length_local_only",
            aa: !0,
            i: 5,
            type: Number,
          },
          6: { name: "example_number", i: 9, type: String },
        }));
    return a;
  };
  F.m = F.prototype.m;
  G.prototype.m = function () {
    var a = ta;
    a ||
      (ta = a =
        x(G, {
          0: { name: "PhoneMetadata", ia: "i18n.phonenumbers.PhoneMetadata" },
          1: { name: "general_desc", i: 11, type: F },
          2: { name: "fixed_line", i: 11, type: F },
          3: { name: "mobile", i: 11, type: F },
          4: { name: "toll_free", i: 11, type: F },
          5: { name: "premium_rate", i: 11, type: F },
          6: { name: "shared_cost", i: 11, type: F },
          7: { name: "personal_number", i: 11, type: F },
          8: { name: "voip", i: 11, type: F },
          21: { name: "pager", i: 11, type: F },
          25: { name: "uan", i: 11, type: F },
          27: { name: "emergency", i: 11, type: F },
          28: { name: "voicemail", i: 11, type: F },
          29: { name: "short_code", i: 11, type: F },
          30: { name: "standard_rate", i: 11, type: F },
          31: { name: "carrier_specific", i: 11, type: F },
          33: { name: "sms_services", i: 11, type: F },
          24: { name: "no_international_dialling", i: 11, type: F },
          9: { name: "id", required: !0, i: 9, type: String },
          10: { name: "country_code", i: 5, type: Number },
          11: { name: "international_prefix", i: 9, type: String },
          17: { name: "preferred_international_prefix", i: 9, type: String },
          12: { name: "national_prefix", i: 9, type: String },
          13: { name: "preferred_extn_prefix", i: 9, type: String },
          15: { name: "national_prefix_for_parsing", i: 9, type: String },
          16: { name: "national_prefix_transform_rule", i: 9, type: String },
          18: {
            name: "same_mobile_and_fixed_line_pattern",
            i: 8,
            defaultValue: !1,
            type: Boolean,
          },
          19: { name: "number_format", aa: !0, i: 11, type: E },
          20: { name: "intl_number_format", aa: !0, i: 11, type: E },
          22: {
            name: "main_country_for_code",
            i: 8,
            defaultValue: !1,
            type: Boolean,
          },
          23: { name: "leading_digits", i: 9, type: String },
        }));
    return a;
  };
  G.m = G.prototype.m;
  function H() {
    n.call(this);
  }
  m(H, n);
  var ua = null,
    va = { ra: 0, qa: 1, pa: 5, oa: 10, na: 20 };
  H.prototype.m = function () {
    var a = ua;
    a ||
      (ua = a =
        x(H, {
          0: { name: "PhoneNumber", ia: "i18n.phonenumbers.PhoneNumber" },
          1: { name: "country_code", required: !0, i: 5, type: Number },
          2: { name: "national_number", required: !0, i: 4, type: Number },
          3: { name: "extension", i: 9, type: String },
          4: { name: "italian_leading_zero", i: 8, type: Boolean },
          8: {
            name: "number_of_leading_zeros",
            i: 5,
            defaultValue: 1,
            type: Number,
          },
          5: { name: "raw_input", i: 9, type: String },
          6: { name: "country_code_source", i: 14, defaultValue: 0, type: va },
          7: { name: "preferred_domestic_carrier_code", i: 9, type: String },
        }));
    return a;
  };
  H.ctor = H;
  H.ctor.m = H.prototype.m; /*

 Copyright (C) 2010 The Libphonenumber Authors

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
  var I = {
      1: "US AG AI AS BB BM BS CA DM DO GD GU JM KN KY LC MP MS PR SX TC TT VC VG VI".split(
        " "
      ),
      7: ["RU", "KZ"],
      20: ["EG"],
      27: ["ZA"],
      30: ["GR"],
      31: ["NL"],
      32: ["BE"],
      33: ["FR"],
      34: ["ES"],
      36: ["HU"],
      39: ["IT", "VA"],
      40: ["RO"],
      41: ["CH"],
      43: ["AT"],
      44: ["GB", "GG", "IM", "JE"],
      45: ["DK"],
      46: ["SE"],
      47: ["NO", "SJ"],
      48: ["PL"],
      49: ["DE"],
      51: ["PE"],
      52: ["MX"],
      53: ["CU"],
      54: ["AR"],
      55: ["BR"],
      56: ["CL"],
      57: ["CO"],
      58: ["VE"],
      60: ["MY"],
      61: ["AU", "CC", "CX"],
      62: ["ID"],
      63: ["PH"],
      64: ["NZ"],
      65: ["SG"],
      66: ["TH"],
      81: ["JP"],
      82: ["KR"],
      84: ["VN"],
      86: ["CN"],
      90: ["TR"],
      91: ["IN"],
      92: ["PK"],
      93: ["AF"],
      94: ["LK"],
      95: ["MM"],
      98: ["IR"],
      211: ["SS"],
      212: ["MA", "EH"],
      213: ["DZ"],
      216: ["TN"],
      218: ["LY"],
      220: ["GM"],
      221: ["SN"],
      222: ["MR"],
      223: ["ML"],
      224: ["GN"],
      225: ["CI"],
      226: ["BF"],
      227: ["NE"],
      228: ["TG"],
      229: ["BJ"],
      230: ["MU"],
      231: ["LR"],
      232: ["SL"],
      233: ["GH"],
      234: ["NG"],
      235: ["TD"],
      236: ["CF"],
      237: ["CM"],
      238: ["CV"],
      239: ["ST"],
      240: ["GQ"],
      241: ["GA"],
      242: ["CG"],
      243: ["CD"],
      244: ["AO"],
      245: ["GW"],
      246: ["IO"],
      247: ["AC"],
      248: ["SC"],
      249: ["SD"],
      250: ["RW"],
      251: ["ET"],
      252: ["SO"],
      253: ["DJ"],
      254: ["KE"],
      255: ["TZ"],
      256: ["UG"],
      257: ["BI"],
      258: ["MZ"],
      260: ["ZM"],
      261: ["MG"],
      262: ["RE", "YT"],
      263: ["ZW"],
      264: ["NA"],
      265: ["MW"],
      266: ["LS"],
      267: ["BW"],
      268: ["SZ"],
      269: ["KM"],
      290: ["SH", "TA"],
      291: ["ER"],
      297: ["AW"],
      298: ["FO"],
      299: ["GL"],
      350: ["GI"],
      351: ["PT"],
      352: ["LU"],
      353: ["IE"],
      354: ["IS"],
      355: ["AL"],
      356: ["MT"],
      357: ["CY"],
      358: ["FI", "AX"],
      359: ["BG"],
      370: ["LT"],
      371: ["LV"],
      372: ["EE"],
      373: ["MD"],
      374: ["AM"],
      375: ["BY"],
      376: ["AD"],
      377: ["MC"],
      378: ["SM"],
      380: ["UA"],
      381: ["RS"],
      382: ["ME"],
      383: ["XK"],
      385: ["HR"],
      386: ["SI"],
      387: ["BA"],
      389: ["MK"],
      420: ["CZ"],
      421: ["SK"],
      423: ["LI"],
      500: ["FK"],
      501: ["BZ"],
      502: ["GT"],
      503: ["SV"],
      504: ["HN"],
      505: ["NI"],
      506: ["CR"],
      507: ["PA"],
      508: ["PM"],
      509: ["HT"],
      590: ["GP", "BL", "MF"],
      591: ["BO"],
      592: ["GY"],
      593: ["EC"],
      594: ["GF"],
      595: ["PY"],
      596: ["MQ"],
      597: ["SR"],
      598: ["UY"],
      599: ["CW", "BQ"],
      670: ["TL"],
      672: ["NF"],
      673: ["BN"],
      674: ["NR"],
      675: ["PG"],
      676: ["TO"],
      677: ["SB"],
      678: ["VU"],
      679: ["FJ"],
      680: ["PW"],
      681: ["WF"],
      682: ["CK"],
      683: ["NU"],
      685: ["WS"],
      686: ["KI"],
      687: ["NC"],
      688: ["TV"],
      689: ["PF"],
      690: ["TK"],
      691: ["FM"],
      692: ["MH"],
      800: ["001"],
      808: ["001"],
      850: ["KP"],
      852: ["HK"],
      853: ["MO"],
      855: ["KH"],
      856: ["LA"],
      870: ["001"],
      878: ["001"],
      880: ["BD"],
      881: ["001"],
      882: ["001"],
      883: ["001"],
      886: ["TW"],
      888: ["001"],
      960: ["MV"],
      961: ["LB"],
      962: ["JO"],
      963: ["SY"],
      964: ["IQ"],
      965: ["KW"],
      966: ["SA"],
      967: ["YE"],
      968: ["OM"],
      970: ["PS"],
      971: ["AE"],
      972: ["IL"],
      973: ["BH"],
      974: ["QA"],
      975: ["BT"],
      976: ["MN"],
      977: ["NP"],
      979: ["001"],
      992: ["TJ"],
      993: ["TM"],
      994: ["AZ"],
      995: ["GE"],
      996: ["KG"],
      998: ["UZ"],
    },
    wa = {
      AC: [
        ,
        [, , "(?:[01589]\\d|[46])\\d{4}", , , , , , , [5, 6]],
        [, , "6[2-467]\\d{3}", , , , "62889", , , [5]],
        [, , "4\\d{4}", , , , "40123", , , [5]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "AC",
        247,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "(?:0[1-9]|[1589]\\d)\\d{4}", , , , "542011", , , [6]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AD: [
        ,
        [, , "(?:1|6\\d)\\d{7}|[135-9]\\d{5}", , , , , , , [6, 8, 9]],
        [, , "[78]\\d{5}", , , , "712345", , , [6]],
        [, , "690\\d{6}|[356]\\d{5}", , , , "312345", , , [6, 9]],
        [, , "180[02]\\d{4}", , , , "18001234", , , [8]],
        [, , "[19]\\d{5}", , , , "912345", , , [6]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "AD",
        376,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})", "$1 $2", ["[135-9]"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["1"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["6"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "1800\\d{4}", , , , , , , [8]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AE: [
        ,
        [
          ,
          ,
          "(?:[4-7]\\d|9[0-689])\\d{7}|800\\d{2,9}|[2-4679]\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 8, 9, 10, 11, 12],
        ],
        [, , "[2-4679][2-8]\\d{6}", , , , "22345678", , , [8], [7]],
        [, , "5[024-68]\\d{7}", , , , "501234567", , , [9]],
        [, , "400\\d{6}|800\\d{2,9}", , , , "800123456"],
        [, , "900[02]\\d{5}", , , , "900234567", , , [9]],
        [, , "700[05]\\d{5}", , , , "700012345", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "AE",
        971,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{2,9})", "$1 $2", ["60|8"]],
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["[236]|[479][2-8]"], "0$1"],
          [, "(\\d{3})(\\d)(\\d{5})", "$1 $2 $3", ["[479]"]],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["5"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "600[25]\\d{5}", , , , "600212345", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AF: [
        ,
        [, , "[2-7]\\d{8}", , , , , , , [9], [7]],
        [
          ,
          ,
          "(?:[25][0-8]|[34][0-4]|6[0-5])[2-9]\\d{6}",
          ,
          ,
          ,
          "234567890",
          ,
          ,
          ,
          [7],
        ],
        [, , "7\\d{8}", , , , "701234567", , , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "AF",
        93,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[1-9]"]],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[2-7]"], "0$1"],
        ],
        [[, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[2-7]"], "0$1"]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AG: [
        ,
        [, , "(?:268|[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "268(?:4(?:6[0-38]|84)|56[0-2])\\d{4}",
          ,
          ,
          ,
          "2684601234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "268(?:464|7(?:1[3-9]|[28]\\d|3[0246]|64|7[0-689]))\\d{4}",
          ,
          ,
          ,
          "2684641234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , "26848[01]\\d{4}", , , , "2684801234", , , , [7]],
        "AG",
        1,
        "011",
        "1",
        ,
        ,
        "([457]\\d{6})$|1",
        "268$1",
        ,
        ,
        ,
        ,
        [, , "26840[69]\\d{4}", , , , "2684061234", , , , [7]],
        ,
        "268",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AI: [
        ,
        [, , "(?:264|[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "264(?:292|4(?:6[12]|9[78]))\\d{4}",
          ,
          ,
          ,
          "2644612345",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "264(?:235|4(?:69|76)|5(?:3[6-9]|8[1-4])|7(?:29|72))\\d{4}",
          ,
          ,
          ,
          "2642351234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "AI",
        1,
        "011",
        "1",
        ,
        ,
        "([2457]\\d{6})$|1",
        "264$1",
        ,
        ,
        ,
        ,
        [, , "264724\\d{4}", , , , "2647241234", , , , [7]],
        ,
        "264",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AL: [
        ,
        [
          ,
          ,
          "(?:700\\d\\d|900)\\d{3}|8\\d{5,7}|(?:[2-5]|6\\d)\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9],
          [5],
        ],
        [
          ,
          ,
          "4505[0-2]\\d{3}|(?:[2358][16-9]\\d[2-9]|4410)\\d{4}|(?:[2358][2-5][2-9]|4(?:[2-57-9][2-9]|6\\d))\\d{5}",
          ,
          ,
          ,
          "22345678",
          ,
          ,
          [8],
          [5, 6, 7],
        ],
        [, , "6(?:[78][2-9]|9\\d)\\d{6}", , , , "672123456", , , [9]],
        [, , "800\\d{4}", , , , "8001234", , , [7]],
        [, , "900[1-9]\\d\\d", , , , "900123", , , [6]],
        [, , "808[1-9]\\d\\d", , , , "808123", , , [6]],
        [, , "700[2-9]\\d{4}", , , , "70021234", , , [8]],
        [, , , , , , , , , [-1]],
        "AL",
        355,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3,4})", "$1 $2", ["80|9"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["4[2-6]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["[2358][2-5]|4"], "0$1"],
          [, "(\\d{3})(\\d{5})", "$1 $2", ["[23578]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["6"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AM: [
        ,
        [, , "(?:[1-489]\\d|55|60|77)\\d{6}", , , , , , , [8], [5, 6]],
        [
          ,
          ,
          "(?:(?:1[0-25]|47)\\d|2(?:2[2-46]|3[1-8]|4[2-69]|5[2-7]|6[1-9]|8[1-7])|3[12]2)\\d{5}",
          ,
          ,
          ,
          "10123456",
          ,
          ,
          ,
          [5, 6],
        ],
        [, , "(?:33|4[1349]|55|77|88|9[13-9])\\d{6}", , , , "77123456"],
        [, , "800\\d{5}", , , , "80012345"],
        [, , "90[016]\\d{5}", , , , "90012345"],
        [, , "80[1-4]\\d{5}", , , , "80112345"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "60(?:2[78]|3[5-9]|4[02-9]|5[0-46-9]|[6-8]\\d|9[0-2])\\d{4}",
          ,
          ,
          ,
          "60271234",
        ],
        "AM",
        374,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3", ["[89]0"], "0 $1"],
          [, "(\\d{3})(\\d{5})", "$1 $2", ["2|3[12]"], "(0$1)"],
          [, "(\\d{2})(\\d{6})", "$1 $2", ["1|47"], "(0$1)"],
          [, "(\\d{2})(\\d{6})", "$1 $2", ["[3-9]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AO: [
        ,
        [, , "[29]\\d{8}", , , , , , , [9]],
        [, , "2\\d(?:[0134][25-9]|[25-9]\\d)\\d{5}", , , , "222123456"],
        [, , "9[1-59]\\d{7}", , , , "923123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "AO",
        244,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[29]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AR: [
        ,
        [
          ,
          ,
          "(?:11|[89]\\d\\d)\\d{8}|[2368]\\d{9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [10, 11],
          [6, 7, 8],
        ],
        [
          ,
          ,
          "3888[013-9]\\d{5}|3(?:7(?:1[15]|81)|8(?:21|4[16]|69|9[12]))[46]\\d{5}|(?:2(?:657|9(?:54|66))|3(?:7(?:55|77)|865))[2-8]\\d{5}|(?:2(?:2(?:2[59]|44|52)|3(?:26|44)|473|9(?:[07]2|2[26]|34|46))|3327)[45]\\d{5}|(?:2(?:284|3(?:02|23)|920)|3(?:4(?:46|8[27]|92)|541|878))[2-7]\\d{5}|(?:2(?:(?:26|62)2|320|477|9(?:42|83))|3(?:329|4(?:62|76|89)|564))[2-6]\\d{5}|(?:(?:11[1-8]|670)\\d|2(?:2(?:0[45]|1[2-6]|3[3-6])|3(?:[06]4|7[45])|494|6(?:04|1[2-8]|[36][45]|4[3-6])|80[45]|9(?:[17][4-6]|[48][45]|9[3-6]))|3(?:364|4(?:1[2-8]|[235][4-6]|84)|5(?:1[2-9]|[38][4-6])|6(?:2[45]|44)|7[069][45]|8(?:0[45]|[17][2-6]|3[4-6]|[58][3-6])))\\d{6}|2(?:2(?:21|4[23]|6[145]|7[1-4]|8[356]|9[267])|3(?:16|3[13-8]|43|5[346-8]|9[3-5])|475|6(?:2[46]|4[78]|5[1568])|9(?:03|2[1457-9]|3[1356]|4[08]|[56][23]|82))4\\d{5}|(?:2(?:2(?:57|81)|3(?:24|46|92)|9(?:01|23|64))|3(?:4(?:42|71)|5(?:25|37|4[347]|71)|7(?:18|5[17])))[3-6]\\d{5}|(?:2(?:2(?:02|2[3467]|4[156]|5[45]|6[6-8]|91)|3(?:1[47]|25|[45][25]|96)|47[48]|625|932)|3(?:38[2578]|4(?:0[0-24-9]|3[78]|4[457]|58|6[03-9]|72|83|9[136-8])|5(?:2[124]|[368][23]|4[2689]|7[2-6])|7(?:16|2[15]|3[145]|4[13]|5[468]|7[2-5]|8[26])|8(?:2[5-7]|3[278]|4[3-5]|5[78]|6[1-378]|[78]7|94)))[4-6]\\d{5}",
          ,
          ,
          ,
          "1123456789",
          ,
          ,
          [10],
          [6, 7, 8],
        ],
        [
          ,
          ,
          "93(?:7(?:1[15]|81)[46]|8(?:(?:21|4[16]|69|9[12])[46]|88[013-9]))\\d{5}|9(?:2(?:657|9(?:54|66))|3(?:7(?:55|77)|865))[2-8]\\d{5}|9(?:2(?:2(?:2[59]|44|52)|3(?:26|44)|473|9(?:[07]2|2[26]|34|46))|3327)[45]\\d{5}|9(?:2(?:284|3(?:02|23)|920)|3(?:4(?:46|8[27]|92)|541|878))[2-7]\\d{5}|9(?:2(?:(?:26|62)2|320|477|9(?:42|83))|3(?:329|4(?:62|76|89)|564))[2-6]\\d{5}|(?:675\\d|9(?:11[1-8]\\d|2(?:2(?:0[45]|1[2-6]|3[3-6])|3(?:[06]4|7[45])|494|6(?:04|1[2-8]|[36][45]|4[3-6])|80[45]|9(?:[17][4-6]|[48][45]|9[3-6]))|3(?:364|4(?:1[2-8]|[235][4-6]|84)|5(?:1[2-9]|[38][4-6])|6(?:2[45]|44)|7[069][45]|8(?:0[45]|[17][2-6]|3[4-6]|[58][3-6]))))\\d{6}|92(?:2(?:21|4[23]|6[145]|7[1-4]|8[356]|9[267])|3(?:16|3[13-8]|43|5[346-8]|9[3-5])|475|6(?:2[46]|4[78]|5[1568])|9(?:03|2[1457-9]|3[1356]|4[08]|[56][23]|82))4\\d{5}|9(?:2(?:2(?:57|81)|3(?:24|46|92)|9(?:01|23|64))|3(?:4(?:42|71)|5(?:25|37|4[347]|71)|7(?:18|5[17])))[3-6]\\d{5}|9(?:2(?:2(?:02|2[3467]|4[156]|5[45]|6[6-8]|91)|3(?:1[47]|25|[45][25]|96)|47[48]|625|932)|3(?:38[2578]|4(?:0[0-24-9]|3[78]|4[457]|58|6[03-9]|72|83|9[136-8])|5(?:2[124]|[368][23]|4[2689]|7[2-6])|7(?:16|2[15]|3[145]|4[13]|5[468]|7[2-5]|8[26])|8(?:2[5-7]|3[278]|4[3-5]|5[78]|6[1-378]|[78]7|94)))[4-6]\\d{5}",
          ,
          ,
          ,
          "91123456789",
          ,
          ,
          ,
          [6, 7, 8],
        ],
        [, , "800\\d{7,8}", , , , "8001234567"],
        [, , "60[04579]\\d{7}", , , , "6001234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "AR",
        54,
        "00",
        "0",
        ,
        ,
        "0?(?:(11|2(?:2(?:02?|[13]|2[13-79]|4[1-6]|5[2457]|6[124-8]|7[1-4]|8[13-6]|9[1267])|3(?:02?|1[467]|2[03-6]|3[13-8]|[49][2-6]|5[2-8]|[67])|4(?:7[3-578]|9)|6(?:[0136]|2[24-6]|4[6-8]?|5[15-8])|80|9(?:0[1-3]|[19]|2\\d|3[1-6]|4[02568]?|5[2-4]|6[2-46]|72?|8[23]?))|3(?:3(?:2[79]|6|8[2578])|4(?:0[0-24-9]|[12]|3[5-8]?|4[24-7]|5[4-68]?|6[02-9]|7[126]|8[2379]?|9[1-36-8])|5(?:1|2[1245]|3[237]?|4[1-46-9]|6[2-4]|7[1-6]|8[2-5]?)|6[24]|7(?:[069]|1[1568]|2[15]|3[145]|4[13]|5[14-8]|7[2-57]|8[126])|8(?:[01]|2[15-7]|3[2578]?|4[13-6]|5[4-8]?|6[1-357-9]|7[36-8]?|8[5-8]?|9[124])))15)?",
        "9$1",
        ,
        ,
        [
          [
            ,
            "(\\d{3})",
            "$1",
            ["0|1(?:0[0-35-7]|1[02-5]|2[015]|3[47]|4[478])|911"],
          ],
          [, "(\\d{2})(\\d{4})", "$1-$2", ["[1-9]"]],
          [, "(\\d{3})(\\d{4})", "$1-$2", ["[2-9]"]],
          [, "(\\d{4})(\\d{4})", "$1-$2", ["[1-8]"]],
          [
            ,
            "(\\d{4})(\\d{2})(\\d{4})",
            "$1 $2-$3",
            [
              "2(?:2[024-9]|3[0-59]|47|6[245]|9[02-8])|3(?:3[28]|4[03-9]|5[2-46-8]|7[1-578]|8[2-9])",
              "2(?:[23]02|6(?:[25]|4[6-8])|9(?:[02356]|4[02568]|72|8[23]))|3(?:3[28]|4(?:[04679]|3[5-8]|5[4-68]|8[2379])|5(?:[2467]|3[237]|8[2-5])|7[1-578]|8(?:[2469]|3[2578]|5[4-8]|7[36-8]|8[5-8]))|2(?:2[24-9]|3[1-59]|47)",
              "2(?:[23]02|6(?:[25]|4(?:64|[78]))|9(?:[02356]|4(?:[0268]|5[2-6])|72|8[23]))|3(?:3[28]|4(?:[04679]|3[78]|5(?:4[46]|8)|8[2379])|5(?:[2467]|3[237]|8[23])|7[1-578]|8(?:[2469]|3[278]|5[56][46]|86[3-6]))|2(?:2[24-9]|3[1-59]|47)|38(?:[58][78]|7[378])|3(?:4[35][56]|58[45]|8(?:[38]5|54|76))[4-6]",
              "2(?:[23]02|6(?:[25]|4(?:64|[78]))|9(?:[02356]|4(?:[0268]|5[2-6])|72|8[23]))|3(?:3[28]|4(?:[04679]|3(?:5(?:4[0-25689]|[56])|[78])|58|8[2379])|5(?:[2467]|3[237]|8(?:[23]|4(?:[45]|60)|5(?:4[0-39]|5|64)))|7[1-578]|8(?:[2469]|3[278]|54(?:4|5[13-7]|6[89])|86[3-6]))|2(?:2[24-9]|3[1-59]|47)|38(?:[58][78]|7[378])|3(?:454|85[56])[46]|3(?:4(?:36|5[56])|8(?:[38]5|76))[4-6]",
            ],
            "0$1",
            ,
            1,
          ],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2-$3", ["1"], "0$1", , 1],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1-$2-$3", ["[68]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2-$3", ["[23]"], "0$1", , 1],
          [
            ,
            "(\\d)(\\d{4})(\\d{2})(\\d{4})",
            "$2 15-$3-$4",
            [
              "9(?:2[2-469]|3[3-578])",
              "9(?:2(?:2[024-9]|3[0-59]|47|6[245]|9[02-8])|3(?:3[28]|4[03-9]|5[2-46-8]|7[1-578]|8[2-9]))",
              "9(?:2(?:[23]02|6(?:[25]|4[6-8])|9(?:[02356]|4[02568]|72|8[23]))|3(?:3[28]|4(?:[04679]|3[5-8]|5[4-68]|8[2379])|5(?:[2467]|3[237]|8[2-5])|7[1-578]|8(?:[2469]|3[2578]|5[4-8]|7[36-8]|8[5-8])))|92(?:2[24-9]|3[1-59]|47)",
              "9(?:2(?:[23]02|6(?:[25]|4(?:64|[78]))|9(?:[02356]|4(?:[0268]|5[2-6])|72|8[23]))|3(?:3[28]|4(?:[04679]|3[78]|5(?:4[46]|8)|8[2379])|5(?:[2467]|3[237]|8[23])|7[1-578]|8(?:[2469]|3[278]|5(?:[56][46]|[78])|7[378]|8(?:6[3-6]|[78]))))|92(?:2[24-9]|3[1-59]|47)|93(?:4[35][56]|58[45]|8(?:[38]5|54|76))[4-6]",
              "9(?:2(?:[23]02|6(?:[25]|4(?:64|[78]))|9(?:[02356]|4(?:[0268]|5[2-6])|72|8[23]))|3(?:3[28]|4(?:[04679]|3(?:5(?:4[0-25689]|[56])|[78])|5(?:4[46]|8)|8[2379])|5(?:[2467]|3[237]|8(?:[23]|4(?:[45]|60)|5(?:4[0-39]|5|64)))|7[1-578]|8(?:[2469]|3[278]|5(?:4(?:4|5[13-7]|6[89])|[56][46]|[78])|7[378]|8(?:6[3-6]|[78]))))|92(?:2[24-9]|3[1-59]|47)|93(?:4(?:36|5[56])|8(?:[38]5|76))[4-6]",
            ],
            "0$1",
          ],
          [, "(\\d)(\\d{2})(\\d{4})(\\d{4})", "$2 15-$3-$4", ["91"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{5})", "$1-$2-$3", ["8"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{4})", "$2 15-$3-$4", ["9"], "0$1"],
        ],
        [
          [
            ,
            "(\\d{4})(\\d{2})(\\d{4})",
            "$1 $2-$3",
            [
              "2(?:2[024-9]|3[0-59]|47|6[245]|9[02-8])|3(?:3[28]|4[03-9]|5[2-46-8]|7[1-578]|8[2-9])",
              "2(?:[23]02|6(?:[25]|4[6-8])|9(?:[02356]|4[02568]|72|8[23]))|3(?:3[28]|4(?:[04679]|3[5-8]|5[4-68]|8[2379])|5(?:[2467]|3[237]|8[2-5])|7[1-578]|8(?:[2469]|3[2578]|5[4-8]|7[36-8]|8[5-8]))|2(?:2[24-9]|3[1-59]|47)",
              "2(?:[23]02|6(?:[25]|4(?:64|[78]))|9(?:[02356]|4(?:[0268]|5[2-6])|72|8[23]))|3(?:3[28]|4(?:[04679]|3[78]|5(?:4[46]|8)|8[2379])|5(?:[2467]|3[237]|8[23])|7[1-578]|8(?:[2469]|3[278]|5[56][46]|86[3-6]))|2(?:2[24-9]|3[1-59]|47)|38(?:[58][78]|7[378])|3(?:4[35][56]|58[45]|8(?:[38]5|54|76))[4-6]",
              "2(?:[23]02|6(?:[25]|4(?:64|[78]))|9(?:[02356]|4(?:[0268]|5[2-6])|72|8[23]))|3(?:3[28]|4(?:[04679]|3(?:5(?:4[0-25689]|[56])|[78])|58|8[2379])|5(?:[2467]|3[237]|8(?:[23]|4(?:[45]|60)|5(?:4[0-39]|5|64)))|7[1-578]|8(?:[2469]|3[278]|54(?:4|5[13-7]|6[89])|86[3-6]))|2(?:2[24-9]|3[1-59]|47)|38(?:[58][78]|7[378])|3(?:454|85[56])[46]|3(?:4(?:36|5[56])|8(?:[38]5|76))[4-6]",
            ],
            "0$1",
            ,
            1,
          ],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2-$3", ["1"], "0$1", , 1],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1-$2-$3", ["[68]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2-$3", ["[23]"], "0$1", , 1],
          [
            ,
            "(\\d)(\\d{4})(\\d{2})(\\d{4})",
            "$1 $2 $3-$4",
            [
              "9(?:2[2-469]|3[3-578])",
              "9(?:2(?:2[024-9]|3[0-59]|47|6[245]|9[02-8])|3(?:3[28]|4[03-9]|5[2-46-8]|7[1-578]|8[2-9]))",
              "9(?:2(?:[23]02|6(?:[25]|4[6-8])|9(?:[02356]|4[02568]|72|8[23]))|3(?:3[28]|4(?:[04679]|3[5-8]|5[4-68]|8[2379])|5(?:[2467]|3[237]|8[2-5])|7[1-578]|8(?:[2469]|3[2578]|5[4-8]|7[36-8]|8[5-8])))|92(?:2[24-9]|3[1-59]|47)",
              "9(?:2(?:[23]02|6(?:[25]|4(?:64|[78]))|9(?:[02356]|4(?:[0268]|5[2-6])|72|8[23]))|3(?:3[28]|4(?:[04679]|3[78]|5(?:4[46]|8)|8[2379])|5(?:[2467]|3[237]|8[23])|7[1-578]|8(?:[2469]|3[278]|5(?:[56][46]|[78])|7[378]|8(?:6[3-6]|[78]))))|92(?:2[24-9]|3[1-59]|47)|93(?:4[35][56]|58[45]|8(?:[38]5|54|76))[4-6]",
              "9(?:2(?:[23]02|6(?:[25]|4(?:64|[78]))|9(?:[02356]|4(?:[0268]|5[2-6])|72|8[23]))|3(?:3[28]|4(?:[04679]|3(?:5(?:4[0-25689]|[56])|[78])|5(?:4[46]|8)|8[2379])|5(?:[2467]|3[237]|8(?:[23]|4(?:[45]|60)|5(?:4[0-39]|5|64)))|7[1-578]|8(?:[2469]|3[278]|5(?:4(?:4|5[13-7]|6[89])|[56][46]|[78])|7[378]|8(?:6[3-6]|[78]))))|92(?:2[24-9]|3[1-59]|47)|93(?:4(?:36|5[56])|8(?:[38]5|76))[4-6]",
            ],
          ],
          [, "(\\d)(\\d{2})(\\d{4})(\\d{4})", "$1 $2 $3-$4", ["91"]],
          [, "(\\d{3})(\\d{3})(\\d{5})", "$1-$2-$3", ["8"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3-$4", ["9"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "810\\d{7}", , , , , , , [10]],
        [, , "810\\d{7}", , , , "8101234567", , , [10]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AS: [
        ,
        [, , "(?:[58]\\d\\d|684|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "6846(?:22|33|44|55|77|88|9[19])\\d{4}",
          ,
          ,
          ,
          "6846221234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "684(?:2(?:48|5[2468]|7[26])|7(?:3[13]|70|82))\\d{4}",
          ,
          ,
          ,
          "6847331234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "AS",
        1,
        "011",
        "1",
        ,
        ,
        "([267]\\d{6})$|1",
        "684$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "684",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AT: [
        ,
        [
          ,
          ,
          "1\\d{3,12}|2\\d{6,12}|43(?:(?:0\\d|5[02-9])\\d{3,9}|2\\d{4,5}|[3467]\\d{4}|8\\d{4,6}|9\\d{4,7})|5\\d{4,12}|8\\d{7,12}|9\\d{8,12}|(?:[367]\\d|4[0-24-9])\\d{4,11}",
          ,
          ,
          ,
          ,
          ,
          ,
          [4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          [3],
        ],
        [
          ,
          ,
          "1(?:11\\d|[2-9]\\d{3,11})|(?:316|463|(?:51|66|73)2)\\d{3,10}|(?:2(?:1[467]|2[13-8]|5[2357]|6[1-46-8]|7[1-8]|8[124-7]|9[1458])|3(?:1[1-578]|3[23568]|4[5-7]|5[1378]|6[1-38]|8[3-68])|4(?:2[1-8]|35|7[1368]|8[2457])|5(?:2[1-8]|3[357]|4[147]|5[12578]|6[37])|6(?:13|2[1-47]|4[135-8]|5[468])|7(?:2[1-8]|35|4[13478]|5[68]|6[16-8]|7[1-6]|9[45]))\\d{4,10}",
          ,
          ,
          ,
          "1234567890",
          ,
          ,
          ,
          [3],
        ],
        [
          ,
          ,
          "6(?:5[0-3579]|6[013-9]|[7-9]\\d)\\d{4,10}",
          ,
          ,
          ,
          "664123456",
          ,
          ,
          [7, 8, 9, 10, 11, 12, 13],
        ],
        [, , "800\\d{6,10}", , , , "800123456", , , [9, 10, 11, 12, 13]],
        [
          ,
          ,
          "(?:8[69][2-68]|9(?:0[01]|3[019]))\\d{6,10}",
          ,
          ,
          ,
          "900123456",
          ,
          ,
          [9, 10, 11, 12, 13],
        ],
        [
          ,
          ,
          "8(?:10|2[018])\\d{6,10}|828\\d{5}",
          ,
          ,
          ,
          "810123456",
          ,
          ,
          [8, 9, 10, 11, 12, 13],
        ],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "5(?:0[1-9]|17|[79]\\d)\\d{2,10}|7[28]0\\d{6,10}",
          ,
          ,
          ,
          "780123456",
          ,
          ,
          [5, 6, 7, 8, 9, 10, 11, 12, 13],
        ],
        "AT",
        43,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{4})", "$1", ["14"]],
          [, "(\\d)(\\d{3,12})", "$1 $2", ["1(?:11|[2-9])"], "0$1"],
          [, "(\\d{3})(\\d{2})", "$1 $2", ["517"], "0$1"],
          [, "(\\d{2})(\\d{3,5})", "$1 $2", ["5[079]"], "0$1"],
          [, "(\\d{6})", "$1", ["[18]"]],
          [
            ,
            "(\\d{3})(\\d{3,10})",
            "$1 $2",
            ["(?:31|4)6|51|6(?:5[0-3579]|[6-9])|7(?:20|32|8)|[89]"],
            "0$1",
          ],
          [, "(\\d{4})(\\d{3,9})", "$1 $2", ["[2-467]|5[2-6]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["5"], "0$1"],
          [, "(\\d{2})(\\d{4})(\\d{4,7})", "$1 $2 $3", ["5"], "0$1"],
        ],
        [
          [, "(\\d)(\\d{3,12})", "$1 $2", ["1(?:11|[2-9])"], "0$1"],
          [, "(\\d{3})(\\d{2})", "$1 $2", ["517"], "0$1"],
          [, "(\\d{2})(\\d{3,5})", "$1 $2", ["5[079]"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{3,10})",
            "$1 $2",
            ["(?:31|4)6|51|6(?:5[0-3579]|[6-9])|7(?:20|32|8)|[89]"],
            "0$1",
          ],
          [, "(\\d{4})(\\d{3,9})", "$1 $2", ["[2-467]|5[2-6]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["5"], "0$1"],
          [, "(\\d{2})(\\d{4})(\\d{4,7})", "$1 $2 $3", ["5"], "0$1"],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AU: [
        ,
        [
          ,
          ,
          "1(?:[0-79]\\d{7}(?:\\d(?:\\d{2})?)?|8[0-24-9]\\d{7})|[2-478]\\d{8}|1\\d{4,7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 8, 9, 10, 12],
        ],
        [
          ,
          ,
          "(?:(?:(?:2(?:[0-26-9]\\d|3[0-8]|4[02-9]|5[0135-9])|7(?:[013-57-9]\\d|2[0-8]))\\d|3(?:(?:[0-3589]\\d|6[1-9]|7[0-35-9])\\d|4(?:[0-578]\\d|90)))\\d\\d|8(?:51(?:0(?:0[03-9]|[12479]\\d|3[2-9]|5[0-8]|6[1-9]|8[0-7])|1(?:[0235689]\\d|1[0-69]|4[0-589]|7[0-47-9])|2(?:0[0-79]|[18][13579]|2[14-9]|3[0-46-9]|[4-6]\\d|7[89]|9[0-4])|3\\d\\d)|(?:6[0-8]|[78]\\d)\\d{3}|9(?:[02-9]\\d{3}|1(?:(?:[0-58]\\d|6[0135-9])\\d|7(?:0[0-24-9]|[1-9]\\d)|9(?:[0-46-9]\\d|5[0-79])))))\\d{3}",
          ,
          ,
          ,
          "212345678",
          ,
          ,
          [9],
          [8],
        ],
        [
          ,
          ,
          "4(?:(?:79|94)[01]|83[0-389])\\d{5}|4(?:[0-3]\\d|4[047-9]|5[0-25-9]|6[0-36-9]|7[02-8]|8[0-24-9]|9[0-37-9])\\d{6}",
          ,
          ,
          ,
          "412345678",
          ,
          ,
          [9],
        ],
        [, , "180(?:0\\d{3}|2)\\d{3}", , , , "1800123456", , , [7, 10]],
        [, , "190[0-26]\\d{6}", , , , "1900123456", , , [10]],
        [
          ,
          ,
          "13(?:00\\d{6}(?:\\d{2})?|45[0-4]\\d{3})|13\\d{4}",
          ,
          ,
          ,
          "1300123456",
          ,
          ,
          [6, 8, 10, 12],
        ],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "14(?:5(?:1[0458]|[23][458])|71\\d)\\d{4}",
          ,
          ,
          ,
          "147101234",
          ,
          ,
          [9],
        ],
        "AU",
        61,
        "001[14-689]|14(?:1[14]|34|4[17]|[56]6|7[47]|88)0011",
        "0",
        ,
        ,
        "(183[12])|0",
        ,
        "0011",
        ,
        [
          [, "(\\d{2})(\\d{3,4})", "$1 $2", ["16"], "0$1"],
          [, "(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3", ["13"]],
          [, "(\\d{3})(\\d{3})", "$1 $2", ["19"]],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["180", "1802"]],
          [, "(\\d{4})(\\d{3,4})", "$1 $2", ["19"]],
          [, "(\\d{2})(\\d{3})(\\d{2,4})", "$1 $2 $3", ["16"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["14|4"], "0$1"],
          [
            ,
            "(\\d)(\\d{4})(\\d{4})",
            "$1 $2 $3",
            ["[2378]"],
            "(0$1)",
            "$CC ($1)",
          ],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1 $2 $3", ["1(?:30|[89])"]],
          [, "(\\d{4})(\\d{4})(\\d{4})", "$1 $2 $3", ["130"]],
        ],
        [
          [, "(\\d{2})(\\d{3,4})", "$1 $2", ["16"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{2,4})", "$1 $2 $3", ["16"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["14|4"], "0$1"],
          [
            ,
            "(\\d)(\\d{4})(\\d{4})",
            "$1 $2 $3",
            ["[2378]"],
            "(0$1)",
            "$CC ($1)",
          ],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1 $2 $3", ["1(?:30|[89])"]],
        ],
        [, , "163\\d{2,6}", , , , "1631234", , , [5, 6, 7, 8, 9]],
        1,
        ,
        [
          ,
          ,
          "1(?:3(?:00\\d{5}|45[0-4])|802)\\d{3}|1[38]00\\d{6}|13\\d{4}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 10, 12],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AW: [
        ,
        [, , "(?:[25-79]\\d\\d|800)\\d{4}", , , , , , , [7]],
        [, , "5(?:2\\d|8[1-9])\\d{4}", , , , "5212345"],
        [
          ,
          ,
          "(?:290|5[69]\\d|6(?:[03]0|22|4[0-2]|[69]\\d)|7(?:[34]\\d|7[07])|9(?:6[45]|9[4-8]))\\d{4}",
          ,
          ,
          ,
          "5601234",
        ],
        [, , "800\\d{4}", , , , "8001234"],
        [, , "900\\d{4}", , , , "9001234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:28\\d|501)\\d{4}", , , , "5011234"],
        "AW",
        297,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[25-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AX: [
        ,
        [
          ,
          ,
          "2\\d{4,9}|35\\d{4,5}|(?:60\\d\\d|800)\\d{4,6}|7\\d{5,11}|(?:[14]\\d|3[0-46-9]|50)\\d{4,8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 8, 9, 10, 11, 12],
        ],
        [, , "18[1-8]\\d{3,6}", , , , "181234567", , , [6, 7, 8, 9]],
        [
          ,
          ,
          "4946\\d{2,6}|(?:4[0-8]|50)\\d{4,8}",
          ,
          ,
          ,
          "412345678",
          ,
          ,
          [6, 7, 8, 9, 10],
        ],
        [, , "800\\d{4,6}", , , , "800123456", , , [7, 8, 9]],
        [, , "[67]00\\d{5,6}", , , , "600123456", , , [8, 9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "AX",
        358,
        "00|99(?:[01469]|5(?:[14]1|3[23]|5[59]|77|88|9[09]))",
        "0",
        ,
        ,
        "0",
        ,
        "00",
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "18",
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "20\\d{4,8}|60[12]\\d{5,6}|7(?:099\\d{4,5}|5[03-9]\\d{3,7})|20[2-59]\\d\\d|(?:606|7(?:0[78]|1|3\\d))\\d{7}|(?:10|29|3[09]|70[1-5]\\d)\\d{4,8}",
          ,
          ,
          ,
          "10112345",
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      AZ: [
        ,
        [, , "365\\d{6}|(?:[124579]\\d|60|88)\\d{7}", , , , , , , [9], [7]],
        [
          ,
          ,
          "(?:2[12]428|3655[02])\\d{4}|(?:2(?:22[0-79]|63[0-28])|3654)\\d{5}|(?:(?:1[28]|46)\\d|2(?:[014-6]2|[23]3))\\d{6}",
          ,
          ,
          ,
          "123123456",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "36554\\d{4}|(?:[16]0|4[04]|5[015]|7[07]|99)\\d{7}",
          ,
          ,
          ,
          "401234567",
        ],
        [, , "88\\d{7}", , , , "881234567"],
        [, , "900200\\d{3}", , , , "900200123"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "AZ",
        994,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{2})(\\d{2})", "$1 $2 $3", ["[1-9]"]],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["90"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            [
              "1[28]|2|365|46",
              "1[28]|2|365[45]|46",
              "1[28]|2|365(?:4|5[02])|46",
            ],
            "(0$1)",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[13-9]"],
            "0$1",
          ],
        ],
        [
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["90"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            [
              "1[28]|2|365|46",
              "1[28]|2|365[45]|46",
              "1[28]|2|365(?:4|5[02])|46",
            ],
            "(0$1)",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[13-9]"],
            "0$1",
          ],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BA: [
        ,
        [, , "6\\d{8}|(?:[35689]\\d|49|70)\\d{6}", , , , , , , [8, 9], [6]],
        [
          ,
          ,
          "(?:3(?:[05-79][2-9]|1[4579]|[23][24-9]|4[2-4689]|8[2457-9])|49[2-579]|5(?:0[2-49]|[13][2-9]|[268][2-4679]|4[4689]|5[2-79]|7[2-69]|9[2-4689]))\\d{5}",
          ,
          ,
          ,
          "30212345",
          ,
          ,
          [8],
          [6],
        ],
        [, , "6040\\d{5}|6(?:03|[1-356]|44|7\\d)\\d{6}", , , , "61123456"],
        [, , "8[08]\\d{6}", , , , "80123456", , , [8]],
        [, , "9[0246]\\d{6}", , , , "90123456", , , [8]],
        [, , "8[12]\\d{6}", , , , "82123456", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BA",
        387,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})", "$1-$2", ["[2-9]"]],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["6[1-3]|[7-9]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2-$3", ["[3-5]|6[56]"], "0$1"],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{3})", "$1 $2 $3 $4", ["6"], "0$1"],
        ],
        [
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["6[1-3]|[7-9]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2-$3", ["[3-5]|6[56]"], "0$1"],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{3})", "$1 $2 $3 $4", ["6"], "0$1"],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "703[235]0\\d{3}|70(?:2[0-5]|3[0146]|[56]0)\\d{4}",
          ,
          ,
          ,
          "70341234",
          ,
          ,
          [8],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BB: [
        ,
        [, , "(?:246|[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "246521[0369]\\d{3}|246(?:2(?:2[78]|7[0-4])|4(?:1[024-6]|2\\d|3[2-9])|5(?:20|[34]\\d|54|7[1-3])|6(?:2\\d|38)|7[35]7|9(?:1[89]|63))\\d{4}",
          ,
          ,
          ,
          "2464123456",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "246(?:(?:2(?:[3568]\\d|4[0-57-9])|3(?:5[2-9]|6[0-6])|4(?:46|5\\d)|69[5-7]|8(?:[2-5]\\d|83))\\d|52(?:1[147]|20))\\d{3}",
          ,
          ,
          ,
          "2462501234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "(?:246976|900[2-9]\\d\\d)\\d{4}", , , , "9002123456", , , , [7]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , "24631\\d{5}", , , , "2463101234", , , , [7]],
        "BB",
        1,
        "011",
        "1",
        ,
        ,
        "([2-9]\\d{6})$|1",
        "246$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "246",
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "246(?:292|367|4(?:1[7-9]|3[01]|4[47-9]|67)|7(?:1[2-9]|2\\d|3[016]|53))\\d{4}",
          ,
          ,
          ,
          "2464301234",
          ,
          ,
          ,
          [7],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BD: [
        ,
        [
          ,
          ,
          "[1-469]\\d{9}|8[0-79]\\d{7,8}|[2-79]\\d{8}|[2-9]\\d{7}|[3-9]\\d{6}|[57-9]\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10],
        ],
        [
          ,
          ,
          "(?:4(?:31\\d\\d|423)|5222)\\d{3}(?:\\d{2})?|8332[6-9]\\d\\d|(?:3(?:03[56]|224)|4(?:22[25]|653))\\d{3,4}|(?:3(?:42[47]|529|823)|4(?:027|525|65(?:28|8))|562|6257|7(?:1(?:5[3-5]|6[12]|7[156]|89)|22[589]56|32|42675|52(?:[25689](?:56|8)|[347]8)|71(?:6[1267]|75|89)|92374)|82(?:2[59]|32)56|9(?:03[23]56|23(?:256|373)|31|5(?:1|2[4589]56)))\\d{3}|(?:3(?:02[348]|22[35]|324|422)|4(?:22[67]|32[236-9]|6(?:2[46]|5[57])|953)|5526|6(?:024|6655)|81)\\d{4,5}|(?:2(?:7(?:1[0-267]|2[0-289]|3[0-29]|4[01]|5[1-3]|6[013]|7[0178]|91)|8(?:0[125]|1[1-6]|2[0157-9]|3[1-69]|41|6[1-35]|7[1-5]|8[1-8]|9[0-6])|9(?:0[0-2]|1[0-4]|2[568]|3[3-6]|5[5-7]|6[0136-9]|7[0-7]|8[014-9]))|3(?:0(?:2[025-79]|3[2-4])|181|22[12]|32[2356]|824)|4(?:02[09]|22[348]|32[045]|523|6(?:27|54))|666(?:22|53)|7(?:22[57-9]|42[56]|82[35])8|8(?:0[124-9]|2(?:181|2[02-4679]8)|4[12]|[5-7]2)|9(?:[04]2|2(?:2|328)|81))\\d{4}|(?:2(?:222|[45]\\d)\\d|3(?:1(?:2[5-7]|[5-7])|425|822)|4(?:033|1\\d|[257]1|332|4(?:2[246]|5[25])|6(?:2[35]|56|62)|8(?:23|54)|92[2-5])|5(?:02[03489]|22[457]|32[35-79]|42[46]|6(?:[18]|53)|724|826)|6(?:023|2(?:2[2-5]|5[3-5]|8)|32[3478]|42[34]|52[47]|6(?:[18]|6(?:2[34]|5[24]))|[78]2[2-5]|92[2-6])|7(?:02|21\\d|[3-589]1|6[12]|72[24])|8(?:217|3[12]|[5-7]1)|9[24]1)\\d{5}|(?:(?:3[2-8]|5[2-57-9]|6[03-589])1|4[4689][18])\\d{5}|[59]1\\d{5}",
          ,
          ,
          ,
          "27111234",
        ],
        [
          ,
          ,
          "(?:1[13-9]\\d|644)\\d{7}|(?:3[78]|44|66)[02-9]\\d{7}",
          ,
          ,
          ,
          "1812345678",
          ,
          ,
          [10],
        ],
        [, , "80[03]\\d{7}", , , , "8001234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "96(?:0[469]|1[0-47]|3[389]|43|6[69]|7[78])\\d{6}",
          ,
          ,
          ,
          "9604123456",
          ,
          ,
          [10],
        ],
        "BD",
        880,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{4,6})", "$1-$2", ["31[5-8]|[459]1"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{3,7})",
            "$1-$2",
            [
              "3(?:[67]|8[013-9])|4(?:6[168]|7|[89][18])|5(?:6[128]|9)|6(?:[15]|28|4[14])|7[2-589]|8(?:0[014-9]|[12])|9[358]|(?:3[2-5]|4[235]|5[2-578]|6[0389]|76|8[3-7]|9[24])1|(?:44|66)[01346-9]",
            ],
            "0$1",
          ],
          [, "(\\d{4})(\\d{3,6})", "$1-$2", ["[13-9]|22"], "0$1"],
          [, "(\\d)(\\d{7,8})", "$1-$2", ["2"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BE: [
        ,
        [, , "4\\d{8}|[1-9]\\d{7}", , , , , , , [8, 9]],
        [
          ,
          ,
          "80[2-8]\\d{5}|(?:1[0-69]|[23][2-8]|4[23]|5\\d|6[013-57-9]|71|8[1-79]|9[2-4])\\d{6}",
          ,
          ,
          ,
          "12345678",
          ,
          ,
          [8],
        ],
        [, , "4[5-9]\\d{7}", , , , "470123456", , , [9]],
        [, , "800[1-9]\\d{4}", , , , "80012345", , , [8]],
        [
          ,
          ,
          "(?:70(?:2[0-57]|3[04-7]|44|6[4-69]|7[0579])|90\\d\\d)\\d{4}",
          ,
          ,
          ,
          "90012345",
          ,
          ,
          [8],
        ],
        [, , "7879\\d{4}", , , , "78791234", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BE",
        32,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3", ["(?:80|9)0"], "0$1"],
          [
            ,
            "(\\d)(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[239]|4[23]"],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[15-8]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["4"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "78(?:0[57]|1[014-8]|2[25]|3[15-8]|48|[56]0|7[06-8]|9\\d)\\d{4}",
          ,
          ,
          ,
          "78102345",
          ,
          ,
          [8],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BF: [
        ,
        [, , "[025-7]\\d{7}", , , , , , , [8]],
        [
          ,
          ,
          "2(?:0(?:49|5[23]|6[5-7]|9[016-9])|4(?:4[569]|5[4-6]|6[5-7]|7[0179])|5(?:[34]\\d|50|6[5-7]))\\d{4}",
          ,
          ,
          ,
          "20491234",
        ],
        [, , "(?:0[1-35-7]|5[0-8]|[67]\\d)\\d{6}", , , , "70123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BF",
        226,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[025-7]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BG: [
        ,
        [
          ,
          ,
          "00800\\d{7}|[2-7]\\d{6,7}|[89]\\d{6,8}|2\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 12],
          [4, 5],
        ],
        [
          ,
          ,
          "2\\d{5,7}|(?:43[1-6]|70[1-9])\\d{4,5}|(?:[36]\\d|4[124-7]|[57][1-9]|8[1-6]|9[1-7])\\d{5,6}",
          ,
          ,
          ,
          "2123456",
          ,
          ,
          [6, 7, 8],
          [4, 5],
        ],
        [
          ,
          ,
          "(?:43[07-9]|99[69]\\d)\\d{5}|(?:8[7-9]|98)\\d{7}",
          ,
          ,
          ,
          "43012345",
          ,
          ,
          [8, 9],
        ],
        [, , "(?:00800\\d\\d|800)\\d{5}", , , , "80012345", , , [8, 12]],
        [, , "90\\d{6}", , , , "90123456", , , [8]],
        [, , "700\\d{5}", , , , "70012345", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BG",
        359,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{6})", "$1", ["1"]],
          [, "(\\d)(\\d)(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["2"], "0$1"],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["43[1-6]|70[1-9]"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{3,4})", "$1 $2 $3", ["2"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2,3})",
            "$1 $2 $3",
            ["[356]|4[124-7]|7[1-9]|8[1-6]|9[1-7]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3", ["(?:70|8)0"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{2})", "$1 $2 $3", ["43[1-7]|7"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[48]|9[08]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["9"], "0$1"],
        ],
        [
          [, "(\\d)(\\d)(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["2"], "0$1"],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["43[1-6]|70[1-9]"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{3,4})", "$1 $2 $3", ["2"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2,3})",
            "$1 $2 $3",
            ["[356]|4[124-7]|7[1-9]|8[1-6]|9[1-7]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3", ["(?:70|8)0"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{2})", "$1 $2 $3", ["43[1-7]|7"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[48]|9[08]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["9"], "0$1"],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BH: [
        ,
        [, , "[136-9]\\d{7}", , , , , , , [8]],
        [
          ,
          ,
          "(?:1(?:3[1356]|6[0156]|7\\d)\\d|6(?:1[16]\\d|500|6(?:0\\d|3[12]|44|55|7[7-9]|88)|9[69][69])|7(?:[07]\\d\\d|1(?:11|78)))\\d{4}",
          ,
          ,
          ,
          "17001234",
        ],
        [
          ,
          ,
          "(?:3(?:[0-79]\\d|8[0-57-9])\\d|6(?:3(?:00|33|6[16])|441|6(?:3[03-9]|[69]\\d|7[0-689])))\\d{4}",
          ,
          ,
          ,
          "36001234",
        ],
        [, , "8[02369]\\d{6}", , , , "80123456"],
        [, , "(?:87|9[0-8])\\d{6}", , , , "90123456"],
        [, , "84\\d{6}", , , , "84123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BH",
        973,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{4})(\\d{4})", "$1 $2", ["[13679]|8[02-4679]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BI: [
        ,
        [, , "(?:[267]\\d|31)\\d{6}", , , , , , , [8]],
        [, , "(?:22|31)\\d{6}", , , , "22201234"],
        [, , "(?:29|[67][125-9])\\d{6}", , , , "79561234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BI",
        257,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[2367]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BJ: [
        ,
        [, , "[24-689]\\d{7}", , , , , , , [8]],
        [, , "2(?:02|1[037]|2[45]|3[68]|4\\d)\\d{5}", , , , "20211234"],
        [, , "(?:4[0-7]|[56]\\d|9[013-9])\\d{6}", , , , "90011234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "857[58]\\d{4}", , , , "85751234"],
        "BJ",
        229,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[24-689]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "81\\d{6}", , , , "81123456"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BL: [
        ,
        [, , "590\\d{6}|(?:69|80|9\\d)\\d{7}", , , , , , , [9]],
        [, , "590(?:2[7-9]|3[3-7]|5[12]|87)\\d{4}", , , , "590271234"],
        [
          ,
          ,
          "69(?:0\\d\\d|1(?:2[2-9]|3[0-5])|4(?:0[89]|1[2-6]|9\\d)|6(?:1[016-9]|5[0-4]|[67]\\d))\\d{4}",
          ,
          ,
          ,
          "690001234",
        ],
        [, , "80[0-5]\\d{6}", , , , "800012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "9(?:(?:39[5-7]|76[018])\\d|475[0-5])\\d{4}", , , , "976012345"],
        "BL",
        590,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BM: [
        ,
        [, , "(?:441|[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "441(?:[46]\\d\\d|5(?:4\\d|60|89))\\d{4}",
          ,
          ,
          ,
          "4414123456",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "441(?:[2378]\\d|5[0-39]|9[02])\\d{5}",
          ,
          ,
          ,
          "4413701234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "BM",
        1,
        "011",
        "1",
        ,
        ,
        "([2-9]\\d{6})$|1",
        "441$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "441",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BN: [
        ,
        [, , "[2-578]\\d{6}", , , , , , , [7]],
        [
          ,
          ,
          "22[0-7]\\d{4}|(?:2[013-9]|[34]\\d|5[0-25-9])\\d{5}",
          ,
          ,
          ,
          "2345678",
        ],
        [, , "(?:22[89]|[78]\\d\\d)\\d{4}", , , , "7123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "5[34]\\d{5}", , , , "5345678"],
        "BN",
        673,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[2-578]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BO: [
        ,
        [, , "(?:[2-467]\\d\\d|8001)\\d{5}", , , , , , , [8, 9], [7]],
        [
          ,
          ,
          "(?:2(?:2\\d\\d|5(?:11|[258]\\d|9[67])|6(?:12|2\\d|9[34])|8(?:2[34]|39|62))|3(?:3\\d\\d|4(?:6\\d|8[24])|8(?:25|42|5[257]|86|9[25])|9(?:[27]\\d|3[2-4]|4[248]|5[24]|6[2-6]))|4(?:4\\d\\d|6(?:11|[24689]\\d|72)))\\d{4}",
          ,
          ,
          ,
          "22123456",
          ,
          ,
          [8],
          [7],
        ],
        [, , "[67]\\d{7}", , , , "71234567", , , [8]],
        [, , "8001[07]\\d{4}", , , , "800171234", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BO",
        591,
        "00(?:1\\d)?",
        "0",
        ,
        ,
        "0(1\\d)?",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{7})", "$1 $2", ["[23]|4[46]"], , "0$CC $1"],
          [, "(\\d{8})", "$1", ["[67]"], , "0$CC $1"],
          [, "(\\d{3})(\\d{2})(\\d{4})", "$1 $2 $3", ["8"], , "0$CC $1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "8001[07]\\d{4}", , , , , , , [9]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BQ: [
        ,
        [, , "(?:[34]1|7\\d)\\d{5}", , , , , , , [7]],
        [
          ,
          ,
          "(?:318[023]|41(?:6[023]|70)|7(?:1[578]|2[05]|50)\\d)\\d{3}",
          ,
          ,
          ,
          "7151234",
        ],
        [
          ,
          ,
          "(?:31(?:8[14-8]|9[14578])|416[14-9]|7(?:0[01]|7[07]|8\\d|9[056])\\d)\\d{3}",
          ,
          ,
          ,
          "3181234",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BQ",
        599,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "[347]",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BR: [
        ,
        [
          ,
          ,
          "(?:[1-46-9]\\d\\d|5(?:[0-46-9]\\d|5[0-46-9]))\\d{8}|[1-9]\\d{9}|[3589]\\d{8}|[34]\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10, 11],
        ],
        [
          ,
          ,
          "(?:[14689][1-9]|2[12478]|3[1-578]|5[13-5]|7[13-579])[2-5]\\d{7}",
          ,
          ,
          ,
          "1123456789",
          ,
          ,
          [10],
          [8],
        ],
        [
          ,
          ,
          "(?:[14689][1-9]|2[12478]|3[1-578]|5[13-5]|7[13-579])(?:7|9\\d)\\d{7}",
          ,
          ,
          ,
          "11961234567",
          ,
          ,
          [10, 11],
          [8, 9],
        ],
        [, , "800\\d{6,7}", , , , "800123456", , , [9, 10]],
        [, , "300\\d{6}|[59]00\\d{6,7}", , , , "300123456", , , [9, 10]],
        [
          ,
          ,
          "(?:30[03]\\d{3}|4(?:0(?:0\\d|20)|370))\\d{4}|300\\d{5}",
          ,
          ,
          ,
          "40041234",
          ,
          ,
          [8, 10],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BR",
        55,
        "00(?:1[245]|2[1-35]|31|4[13]|[56]5|99)",
        "0",
        ,
        ,
        "(?:0|90)(?:(1[245]|2[1-35]|31|4[13]|[56]5|99)(\\d{10,11}))?",
        "$2",
        ,
        ,
        [
          [
            ,
            "(\\d{3,6})",
            "$1",
            [
              "1(?:1[25-8]|2[357-9]|3[02-68]|4[12568]|5|6[0-8]|8[015]|9[0-47-9])|321|610",
            ],
          ],
          [
            ,
            "(\\d{4})(\\d{4})",
            "$1-$2",
            ["300|4(?:0[02]|37)", "4(?:02|37)0|[34]00"],
          ],
          [
            ,
            "(\\d{4})(\\d{4})",
            "$1-$2",
            ["[2-57]", "[2357]|4(?:[0-24-9]|3(?:[0-689]|7[1-9]))"],
          ],
          [
            ,
            "(\\d{3})(\\d{2,3})(\\d{4})",
            "$1 $2 $3",
            ["(?:[358]|90)0"],
            "0$1",
          ],
          [, "(\\d{5})(\\d{4})", "$1-$2", ["9"]],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1 $2-$3",
            ["(?:[14689][1-9]|2[12478]|3[1-578]|5[13-5]|7[13-579])[2-57]"],
            "($1)",
            "0 $CC ($1)",
          ],
          [
            ,
            "(\\d{2})(\\d{5})(\\d{4})",
            "$1 $2-$3",
            ["[16][1-9]|[2-57-9]"],
            "($1)",
            "0 $CC ($1)",
          ],
        ],
        [
          [
            ,
            "(\\d{4})(\\d{4})",
            "$1-$2",
            ["300|4(?:0[02]|37)", "4(?:02|37)0|[34]00"],
          ],
          [
            ,
            "(\\d{3})(\\d{2,3})(\\d{4})",
            "$1 $2 $3",
            ["(?:[358]|90)0"],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1 $2-$3",
            ["(?:[14689][1-9]|2[12478]|3[1-578]|5[13-5]|7[13-579])[2-57]"],
            "($1)",
            "0 $CC ($1)",
          ],
          [
            ,
            "(\\d{2})(\\d{5})(\\d{4})",
            "$1 $2-$3",
            ["[16][1-9]|[2-57-9]"],
            "($1)",
            "0 $CC ($1)",
          ],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [
          ,
          ,
          "30(?:0\\d{5,7}|3\\d{7})|40(?:0\\d|20)\\d{4}|800\\d{6,7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BS: [
        ,
        [, , "(?:242|[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "242(?:3(?:02|[236][1-9]|4[0-24-9]|5[0-68]|7[347]|8[0-4]|9[2-467])|461|502|6(?:0[1-5]|12|2[013]|[45]0|7[67]|8[78]|9[89])|7(?:02|88))\\d{4}",
          ,
          ,
          ,
          "2423456789",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "242(?:3(?:5[79]|7[56]|95)|4(?:[23][1-9]|4[1-35-9]|5[1-8]|6[2-8]|7\\d|81)|5(?:2[45]|3[35]|44|5[1-46-9]|65|77)|6[34]6|7(?:27|38)|8(?:0[1-9]|1[02-9]|2\\d|3[0-4]|[89]9))\\d{4}",
          ,
          ,
          ,
          "2423591234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "242300\\d{4}|8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "8002123456",
          ,
          ,
          ,
          [7],
        ],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "BS",
        1,
        "011",
        "1",
        ,
        ,
        "([3-8]\\d{6})$|1",
        "242$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "242",
        [, , , , , , , , , [-1]],
        [, , "242225\\d{4}", , , , "2422250123"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BT: [
        ,
        [, , "[17]\\d{7}|[2-8]\\d{6}", , , , , , , [7, 8], [6]],
        [
          ,
          ,
          "(?:2[3-6]|[34][5-7]|5[236]|6[2-46]|7[246]|8[2-4])\\d{5}",
          ,
          ,
          ,
          "2345678",
          ,
          ,
          [7],
          [6],
        ],
        [, , "(?:1[67]|77)\\d{6}", , , , "17123456", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BT",
        975,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})", "$1 $2", ["[2-7]"]],
          [, "(\\d)(\\d{3})(\\d{3})", "$1 $2 $3", ["[2-68]|7[246]"]],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["1[67]|7"]],
        ],
        [
          [, "(\\d)(\\d{3})(\\d{3})", "$1 $2 $3", ["[2-68]|7[246]"]],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["1[67]|7"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BW: [
        ,
        [
          ,
          ,
          "(?:0800|(?:[37]|800)\\d)\\d{6}|(?:[2-6]\\d|90)\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 10],
        ],
        [
          ,
          ,
          "(?:2(?:4[0-48]|6[0-24]|9[0578])|3(?:1[0-35-9]|55|[69]\\d|7[013]|81)|4(?:6[03]|7[1267]|9[0-5])|5(?:3[03489]|4[0489]|7[1-47]|88|9[0-49])|6(?:2[1-35]|5[149]|8[067]))\\d{4}",
          ,
          ,
          ,
          "2401234",
          ,
          ,
          [7],
        ],
        [, , "(?:321|7[1-8]\\d)\\d{5}", , , , "71123456", , , [8]],
        [, , "(?:0800|800\\d)\\d{6}", , , , "0800012345", , , [10]],
        [, , "90\\d{5}", , , , "9012345", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "79(?:1(?:[01]\\d|2[0-8])|2[0-7]\\d)\\d{3}",
          ,
          ,
          ,
          "79101234",
          ,
          ,
          [8],
        ],
        "BW",
        267,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{5})", "$1 $2", ["90"]],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[24-6]|3[15-9]"]],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["[37]"]],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1 $2 $3", ["0"]],
          [, "(\\d{3})(\\d{4})(\\d{3})", "$1 $2 $3", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BY: [
        ,
        [
          ,
          ,
          "(?:[12]\\d|33|44|902)\\d{7}|8(?:0[0-79]\\d{5,7}|[1-7]\\d{9})|8(?:1[0-489]|[5-79]\\d)\\d{7}|8[1-79]\\d{6,7}|8[0-79]\\d{5}|8\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 11],
          [5],
        ],
        [
          ,
          ,
          "(?:1(?:5(?:1[1-5]|[24]\\d|6[2-4]|9[1-7])|6(?:[235]\\d|4[1-7])|7\\d\\d)|2(?:1(?:[246]\\d|3[0-35-9]|5[1-9])|2(?:[235]\\d|4[0-8])|3(?:[26]\\d|3[02-79]|4[024-7]|5[03-7])))\\d{5}",
          ,
          ,
          ,
          "152450911",
          ,
          ,
          [9],
          [5, 6, 7],
        ],
        [
          ,
          ,
          "(?:2(?:5[5-79]|9[1-9])|(?:33|44)\\d)\\d{6}",
          ,
          ,
          ,
          "294911911",
          ,
          ,
          [9],
        ],
        [, , "800\\d{3,7}|8(?:0[13]|20\\d)\\d{7}", , , , "8011234567"],
        [, , "(?:810|902)\\d{7}", , , , "9021234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "249\\d{6}", , , , "249123456", , , [9]],
        "BY",
        375,
        "810",
        "8",
        ,
        ,
        "0|80?",
        ,
        "8~10",
        ,
        [
          [, "(\\d{3})(\\d{3})", "$1 $2", ["800"], "8 $1"],
          [, "(\\d{3})(\\d{2})(\\d{2,4})", "$1 $2 $3", ["800"], "8 $1"],
          [
            ,
            "(\\d{4})(\\d{2})(\\d{3})",
            "$1 $2-$3",
            [
              "1(?:5[169]|6[3-5]|7[179])|2(?:1[35]|2[34]|3[3-5])",
              "1(?:5[169]|6(?:3[1-3]|4|5[125])|7(?:1[3-9]|7[0-24-6]|9[2-7]))|2(?:1[35]|2[34]|3[3-5])",
            ],
            "8 0$1",
          ],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2-$3-$4",
            ["1(?:[56]|7[467])|2[1-3]"],
            "8 0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2-$3-$4",
            ["[1-4]"],
            "8 0$1",
          ],
          [, "(\\d{3})(\\d{3,4})(\\d{4})", "$1 $2 $3", ["[89]"], "8 $1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "800\\d{3,7}|(?:8(?:0[13]|10|20\\d)|902)\\d{7}"],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      BZ: [
        ,
        [, , "(?:0800\\d|[2-8])\\d{6}", , , , , , , [7, 11]],
        [
          ,
          ,
          "(?:2(?:[02]\\d|36|[68]0)|[3-58](?:[02]\\d|[68]0)|7(?:[02]\\d|32|[68]0))\\d{4}",
          ,
          ,
          ,
          "2221234",
          ,
          ,
          [7],
        ],
        [, , "6[0-35-7]\\d{5}", , , , "6221234", , , [7]],
        [, , "0800\\d{7}", , , , "08001234123", , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "BZ",
        501,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1-$2", ["[2-8]"]],
          [, "(\\d)(\\d{3})(\\d{4})(\\d{3})", "$1-$2-$3-$4", ["0"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CA: [
        ,
        [, , "(?:[2-8]\\d|90)\\d{8}|3\\d{6}", , , , , , , [7, 10]],
        [
          ,
          ,
          "(?:2(?:04|[23]6|[48]9|50|63)|3(?:06|43|54|6[578]|82)|4(?:03|1[68]|[26]8|3[178]|50|74)|5(?:06|1[49]|48|79|8[147])|6(?:04|[18]3|39|47|72)|7(?:0[59]|42|53|78|8[02])|8(?:[06]7|19|25|73)|90[25])[2-9]\\d{6}",
          ,
          ,
          ,
          "5062345678",
          ,
          ,
          [10],
          [7],
        ],
        [
          ,
          ,
          "(?:2(?:04|[23]6|[48]9|50|63)|3(?:06|43|54|6[578]|82)|4(?:03|1[68]|[26]8|3[178]|50|74)|5(?:06|1[49]|48|79|8[147])|6(?:04|[18]3|39|47|72)|7(?:0[59]|42|53|78|8[02])|8(?:[06]7|19|25|73)|90[25])[2-9]\\d{6}",
          ,
          ,
          ,
          "5062345678",
          ,
          ,
          [10],
          [7],
        ],
        [
          ,
          ,
          "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "8002123456",
          ,
          ,
          [10],
        ],
        [, , "900[2-9]\\d{6}", , , , "9002123456", , , [10]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|(?:5(?:00|2[125-9]|33|44|66|77|88)|622)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
          ,
          ,
          [10],
        ],
        [, , "600[2-9]\\d{6}", , , , "6002012345", , , [10]],
        "CA",
        1,
        "011",
        "1",
        ,
        ,
        "1",
        ,
        ,
        1,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "310\\d{4}", , , , "3101234", , , [7]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CC: [
        ,
        [
          ,
          ,
          "1(?:[0-79]\\d{8}(?:\\d{2})?|8[0-24-9]\\d{7})|[148]\\d{8}|1\\d{5,7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 12],
        ],
        [
          ,
          ,
          "8(?:51(?:0(?:02|31|60|89)|1(?:18|76)|223)|91(?:0(?:1[0-2]|29)|1(?:[28]2|50|79)|2(?:10|64)|3(?:[06]8|22)|4[29]8|62\\d|70[23]|959))\\d{3}",
          ,
          ,
          ,
          "891621234",
          ,
          ,
          [9],
          [8],
        ],
        [
          ,
          ,
          "4(?:(?:79|94)[01]|83[0-389])\\d{5}|4(?:[0-3]\\d|4[047-9]|5[0-25-9]|6[0-36-9]|7[02-8]|8[0-24-9]|9[0-37-9])\\d{6}",
          ,
          ,
          ,
          "412345678",
          ,
          ,
          [9],
        ],
        [, , "180(?:0\\d{3}|2)\\d{3}", , , , "1800123456", , , [7, 10]],
        [, , "190[0-26]\\d{6}", , , , "1900123456", , , [10]],
        [
          ,
          ,
          "13(?:00\\d{6}(?:\\d{2})?|45[0-4]\\d{3})|13\\d{4}",
          ,
          ,
          ,
          "1300123456",
          ,
          ,
          [6, 8, 10, 12],
        ],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "14(?:5(?:1[0458]|[23][458])|71\\d)\\d{4}",
          ,
          ,
          ,
          "147101234",
          ,
          ,
          [9],
        ],
        "CC",
        61,
        "001[14-689]|14(?:1[14]|34|4[17]|[56]6|7[47]|88)0011",
        "0",
        ,
        ,
        "([59]\\d{7})$|0",
        "8$1",
        "0011",
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CD: [
        ,
        [, , "[189]\\d{8}|[1-68]\\d{6}", , , , , , , [7, 9]],
        [, , "12\\d{7}|[1-6]\\d{6}", , , , "1234567"],
        [, , "88\\d{5}|(?:8[0-59]|9[017-9])\\d{7}", , , , "991234567"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CD",
        243,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{3})", "$1 $2 $3", ["88"], "0$1"],
          [, "(\\d{2})(\\d{5})", "$1 $2", ["[1-6]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[89]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CF: [
        ,
        [, , "(?:[27]\\d{3}|8776)\\d{4}", , , , , , , [8]],
        [, , "2[12]\\d{6}", , , , "21612345"],
        [, , "7[024-7]\\d{6}", , , , "70012345"],
        [, , , , , , , , , [-1]],
        [, , "8776\\d{4}", , , , "87761234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CF",
        236,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[278]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CG: [
        ,
        [, , "222\\d{6}|(?:0\\d|80)\\d{7}", , , , , , , [9]],
        [, , "222[1-589]\\d{5}", , , , "222123456"],
        [
          ,
          ,
          "026(?:1[0-5]|6[6-9])\\d{4}|0(?:[14-6]\\d\\d|2(?:40|5[5-8]|6[07-9]))\\d{5}",
          ,
          ,
          ,
          "061234567",
        ],
        [, , , , , , , , , [-1]],
        [, , "80[0-2]\\d{6}", , , , "800123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CG",
        242,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["8"]],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[02]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CH: [
        ,
        [, , "8\\d{11}|[2-9]\\d{8}", , , , , , , [9, 12]],
        [
          ,
          ,
          "(?:2[12467]|3[1-4]|4[134]|5[256]|6[12]|[7-9]1)\\d{7}",
          ,
          ,
          ,
          "212345678",
          ,
          ,
          [9],
        ],
        [, , "7[35-9]\\d{7}", , , , "781234567", , , [9]],
        [, , "800\\d{6}", , , , "800123456", , , [9]],
        [, , "90[016]\\d{6}", , , , "900123456", , , [9]],
        [, , "84[0248]\\d{6}", , , , "840123456", , , [9]],
        [, , "878\\d{6}", , , , "878123456", , , [9]],
        [, , , , , , , , , [-1]],
        "CH",
        41,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["8[047]|90"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[2-79]|81"],
            "0$1",
          ],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4 $5",
            ["8"],
            "0$1",
          ],
        ],
        ,
        [, , "74[0248]\\d{6}", , , , "740123456", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "5[18]\\d{7}", , , , "581234567", , , [9]],
        ,
        ,
        [, , "860\\d{9}", , , , "860123456789", , , [12]],
      ],
      CI: [
        ,
        [, , "[02]\\d{9}", , , , , , , [10]],
        [
          ,
          ,
          "2(?:[15]\\d{3}|7(?:2(?:0[23]|1[2357]|2[245]|3[45]|4[3-5])|3(?:06|1[69]|[2-6]7)))\\d{5}",
          ,
          ,
          ,
          "2123456789",
        ],
        [, , "0[157]\\d{8}", , , , "0123456789"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CI",
        225,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d)(\\d{5})", "$1 $2 $3 $4", ["2"]],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{4})", "$1 $2 $3 $4", ["0"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CK: [
        ,
        [, , "[2-578]\\d{4}", , , , , , , [5]],
        [, , "(?:2\\d|3[13-7]|4[1-5])\\d{3}", , , , "21234"],
        [, , "[578]\\d{4}", , , , "71234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CK",
        682,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{3})", "$1 $2", ["[2-578]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CL: [
        ,
        [, , "12300\\d{6}|6\\d{9,10}|[2-9]\\d{8}", , , , , , , [9, 10, 11]],
        [
          ,
          ,
          "2(?:1982[0-6]|3314[05-9])\\d{3}|(?:2(?:1(?:160|962)|3(?:2\\d\\d|3(?:[03467]\\d|1[0-35-9]|2[1-9]|5[0-24-9]|8[0-3])|600)|646[59])|80[1-9]\\d\\d|9(?:3(?:[0-57-9]\\d\\d|6(?:0[02-9]|[1-9]\\d))|6(?:[0-8]\\d\\d|9(?:[02-79]\\d|1[05-9]))|7[1-9]\\d\\d|9(?:[03-9]\\d\\d|1(?:[0235-9]\\d|4[0-24-9])|2(?:[0-79]\\d|8[0-46-9]))))\\d{4}|(?:22|3[2-5]|[47][1-35]|5[1-3578]|6[13-57]|8[1-9]|9[2458])\\d{7}",
          ,
          ,
          ,
          "221234567",
          ,
          ,
          [9],
        ],
        [
          ,
          ,
          "2(?:1982[0-6]|3314[05-9])\\d{3}|(?:2(?:1(?:160|962)|3(?:2\\d\\d|3(?:[03467]\\d|1[0-35-9]|2[1-9]|5[0-24-9]|8[0-3])|600)|646[59])|80[1-9]\\d\\d|9(?:3(?:[0-57-9]\\d\\d|6(?:0[02-9]|[1-9]\\d))|6(?:[0-8]\\d\\d|9(?:[02-79]\\d|1[05-9]))|7[1-9]\\d\\d|9(?:[03-9]\\d\\d|1(?:[0235-9]\\d|4[0-24-9])|2(?:[0-79]\\d|8[0-46-9]))))\\d{4}|(?:22|3[2-5]|[47][1-35]|5[1-3578]|6[13-57]|8[1-9]|9[2458])\\d{7}",
          ,
          ,
          ,
          "221234567",
          ,
          ,
          [9],
        ],
        [, , "(?:123|8)00\\d{6}", , , , "800123456", , , [9, 11]],
        [, , , , , , , , , [-1]],
        [, , "600\\d{7,8}", , , , "6001234567", , , [10, 11]],
        [, , , , , , , , , [-1]],
        [, , "44\\d{7}", , , , "441234567", , , [9]],
        "CL",
        56,
        "(?:0|1(?:1[0-69]|2[02-5]|5[13-58]|69|7[0167]|8[018]))0",
        ,
        ,
        ,
        ,
        ,
        ,
        1,
        [
          [, "(\\d{4})", "$1", ["1(?:[03-589]|21)|[29]0|78"]],
          [, "(\\d{5})(\\d{4})", "$1 $2", ["219", "2196"], "($1)"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["44"]],
          [, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["2[1-36]"], "($1)"],
          [, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["9[2-9]"]],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["3[2-5]|[47]|5[1-3578]|6[13-57]|8(?:0[1-9]|[1-9])"],
            "($1)",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["60|8"]],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"]],
          [, "(\\d{3})(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3 $4", ["60"]],
        ],
        [
          [, "(\\d{5})(\\d{4})", "$1 $2", ["219", "2196"], "($1)"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["44"]],
          [, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["2[1-36]"], "($1)"],
          [, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["9[2-9]"]],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["3[2-5]|[47]|5[1-3578]|6[13-57]|8(?:0[1-9]|[1-9])"],
            "($1)",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["60|8"]],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"]],
          [, "(\\d{3})(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3 $4", ["60"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "600\\d{7,8}", , , , , , , [10, 11]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CM: [
        ,
        [, , "[26]\\d{8}|88\\d{6,7}", , , , , , , [8, 9]],
        [, , "2(?:22|33)\\d{6}", , , , "222123456", , , [9]],
        [, , "(?:24[23]|6[25-9]\\d)\\d{6}", , , , "671234567", , , [9]],
        [, , "88\\d{6,7}", , , , "88012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CM",
        237,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["88"]],
          [
            ,
            "(\\d)(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4 $5",
            ["[26]|88"],
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CN: [
        ,
        [
          ,
          ,
          "1[127]\\d{8,9}|2\\d{9}(?:\\d{2})?|[12]\\d{6,7}|86\\d{6}|(?:1[03-689]\\d|6)\\d{7,9}|(?:[3-579]\\d|8[0-57-9])\\d{6,9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 10, 11, 12],
          [5, 6],
        ],
        [
          ,
          ,
          "(?:10(?:[02-79]\\d\\d|[18](?:0[1-9]|[1-9]\\d))|21(?:[18](?:0[1-9]|[1-9]\\d)|[2-79]\\d\\d))\\d{5}|(?:43[35]|754)\\d{7,8}|8(?:078\\d{7}|51\\d{7,8})|(?:10|(?:2|85)1|43[35]|754)(?:100\\d\\d|95\\d{3,4})|(?:2[02-57-9]|3(?:11|7[179])|4(?:[15]1|3[12])|5(?:1\\d|2[37]|3[12]|51|7[13-79]|9[15])|7(?:[39]1|5[57]|6[09])|8(?:71|98))(?:[02-8]\\d{7}|1(?:0(?:0\\d\\d(?:\\d{3})?|[1-9]\\d{5})|[1-9]\\d{6})|9(?:[0-46-9]\\d{6}|5\\d{3}(?:\\d(?:\\d{2})?)?))|(?:3(?:1[02-9]|35|49|5\\d|7[02-68]|9[1-68])|4(?:1[02-9]|2[179]|3[46-9]|5[2-9]|6[47-9]|7\\d|8[23])|5(?:3[03-9]|4[36]|5[02-9]|6[1-46]|7[028]|80|9[2-46-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[17]\\d|2[248]|3[04-9]|4[3-6]|5[0-3689]|6[2368]|9[02-9])|8(?:1[236-8]|2[5-7]|3\\d|5[2-9]|7[02-9]|8[36-8]|9[1-7])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))(?:[02-8]\\d{6}|1(?:0(?:0\\d\\d(?:\\d{2})?|[1-9]\\d{4})|[1-9]\\d{5})|9(?:[0-46-9]\\d{5}|5\\d{3,5}))",
          ,
          ,
          ,
          "1012345678",
          ,
          ,
          [7, 8, 9, 10, 11],
          [5, 6],
        ],
        [
          ,
          ,
          "1740[0-5]\\d{6}|1(?:[38]\\d|4[57]|[59][0-35-9]|6[25-7]|7[0-35-8])\\d{8}",
          ,
          ,
          ,
          "13123456789",
          ,
          ,
          [11],
        ],
        [, , "(?:(?:10|21)8|8)00\\d{7}", , , , "8001234567", , , [10, 12]],
        [, , "16[08]\\d{5}", , , , "16812345", , , [8]],
        [
          ,
          ,
          "10(?:10\\d{4}|96\\d{3,4})|400\\d{7}|950\\d{7,8}|(?:2[0-57-9]|3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[14-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))96\\d{3,4}",
          ,
          ,
          ,
          "4001234567",
          ,
          ,
          [7, 8, 9, 10, 11],
          [5, 6],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CN",
        86,
        "00|1(?:[12]\\d|79)\\d\\d00",
        "0",
        ,
        ,
        "(1(?:[12]\\d|79)\\d\\d)|0",
        ,
        "00",
        ,
        [
          [
            ,
            "(\\d{5,6})",
            "$1",
            [
              "1(?:00|2[13])|95",
              "1(?:00|2(?:11|39))|95",
              "1(?:00|2(?:110|395))|95",
            ],
          ],
          [, "(\\d{5,6})", "$1", ["10|96"]],
          [
            ,
            "(\\d{2})(\\d{5,6})",
            "$1 $2",
            [
              "(?:10|2[0-57-9])[19]",
              "(?:10|2[0-57-9])(?:10|9[56])",
              "10(?:10|9[56])|2[0-57-9](?:100|9[56])",
            ],
            "0$1",
            "$CC $1",
          ],
          [
            ,
            "(\\d{3})(\\d{4})",
            "$1 $2",
            [
              "[1-9]",
              "1[1-9]|26|[3-9]|(?:10|2[0-57-9])(?:[0-8]|9[0-47-9])",
              "1(?:0(?:[0-8]|9[0-47-9])|[1-9])|2(?:[0-57-9](?:[02-8]|1(?:0[1-9]|[1-9])|9[0-47-9])|6)|[3-9]",
            ],
          ],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["16[08]"]],
          [
            ,
            "(\\d{3})(\\d{5,6})",
            "$1 $2",
            [
              "3(?:[157]|35|49|9[1-68])|4(?:[17]|2[179]|6[47-9]|8[23])|5(?:[1357]|2[37]|4[36]|6[1-46]|80)|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]|4[13]|5[1-5])|(?:4[35]|59|85)[1-9]",
              "(?:3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[1-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))[19]",
              "85[23](?:10|95)|(?:3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[14-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))(?:10|9[56])",
              "85[23](?:100|95)|(?:3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[14-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))(?:100|9[56])",
            ],
            "0$1",
            "$CC $1",
          ],
          [
            ,
            "(\\d{4})(\\d{4})",
            "$1 $2",
            [
              "[1-9]",
              "1(?:0(?:[02-8]|1[1-9]|9[0-47-9])|[1-9])|2(?:[0-57-9](?:[0-8]|9[0-47-9])|6)|[3-9]",
              "1(?:0(?:[02-8]|1[1-9]|9[0-47-9])|[1-9])|26|3(?:[0268]|4[0-8]|9[079])|4(?:[049]|2[02-68]|[35]0|6[0-356]|8[014-9])|5(?:0|2[0-24-689]|4[0-2457-9]|6[057-9]|8[1-9]|90)|6(?:[0-24578]|3[06-9]|6[14-79]|9[03-9])|7(?:0[02-9]|2[0135-79]|3[23]|4[0-27-9]|6[1457]|8)|8(?:[046]|1[01459]|2[0-489]|5(?:0|[23][0-8])|8[0-2459]|9[09])|9(?:0[0457]|1[08]|[268]|4[024-9]|5[06-9])|(?:33|85[23]9)[0-46-9]|(?:2[0-57-9]|3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[14-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))(?:[0-8]|9[0-47-9])",
              "1(?:0[02-8]|[1-9])|2(?:[0-57-9][0-8]|6)|3(?:[0268]|3[0-46-9]|4[0-8]|9[079])|4(?:[049]|2[02-68]|[35]0|6[0-356]|8[014-9])|5(?:0|2[0-24-689]|4[0-2457-9]|6[057-9]|90)|6(?:[0-24578]|3[06-9]|6[14-79]|9[03-9])|7(?:0[02-9]|2[0135-79]|3[23]|4[0-27-9]|6[1457]|8)|8(?:[046]|1[01459]|2[0-489]|5(?:0|[23](?:[02-8]|1[1-9]|9[0-46-9]))|8[0-2459]|9[09])|9(?:0[0457]|1[08]|[268]|4[024-9]|5[06-9])|(?:10|2[0-57-9])9[0-47-9]|(?:101|58|85[23]10)[1-9]|(?:3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[14-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))(?:[02-8]|1(?:0[1-9]|[1-9])|9[0-47-9])",
            ],
          ],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["(?:4|80)0"]],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1 $2 $3",
            [
              "10|2(?:[02-57-9]|1[1-9])",
              "10|2(?:[02-57-9]|1[1-9])",
              "10[0-79]|2(?:[02-57-9]|1[1-79])|(?:10|21)8(?:0[1-9]|[1-9])",
            ],
            "0$1",
            "$CC $1",
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            [
              "3(?:[3-59]|7[02-68])|4(?:[26-8]|3[3-9]|5[2-9])|5(?:3[03-9]|[468]|7[028]|9[2-46-9])|6|7(?:[0-247]|3[04-9]|5[0-4689]|6[2368])|8(?:[1-358]|9[1-7])|9(?:[013479]|5[1-5])|(?:[34]1|55|79|87)[02-9]",
            ],
            "0$1",
            "$CC $1",
            1,
          ],
          [, "(\\d{3})(\\d{7,8})", "$1 $2", ["9"]],
          [
            ,
            "(\\d{4})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["80"],
            "0$1",
            "$CC $1",
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{4})(\\d{4})",
            "$1 $2 $3",
            ["[3-578]"],
            "0$1",
            "$CC $1",
            1,
          ],
          [, "(\\d{3})(\\d{4})(\\d{4})", "$1 $2 $3", ["1[3-9]"], , "$CC $1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3})(\\d{4})",
            "$1 $2 $3 $4",
            ["[12]"],
            "0$1",
            ,
            1,
          ],
        ],
        [
          [
            ,
            "(\\d{2})(\\d{5,6})",
            "$1 $2",
            [
              "(?:10|2[0-57-9])[19]",
              "(?:10|2[0-57-9])(?:10|9[56])",
              "10(?:10|9[56])|2[0-57-9](?:100|9[56])",
            ],
            "0$1",
            "$CC $1",
          ],
          [
            ,
            "(\\d{3})(\\d{5,6})",
            "$1 $2",
            [
              "3(?:[157]|35|49|9[1-68])|4(?:[17]|2[179]|6[47-9]|8[23])|5(?:[1357]|2[37]|4[36]|6[1-46]|80)|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]|4[13]|5[1-5])|(?:4[35]|59|85)[1-9]",
              "(?:3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[1-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))[19]",
              "85[23](?:10|95)|(?:3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[14-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))(?:10|9[56])",
              "85[23](?:100|95)|(?:3(?:[157]\\d|35|49|9[1-68])|4(?:[17]\\d|2[179]|[35][1-9]|6[47-9]|8[23])|5(?:[1357]\\d|2[37]|4[36]|6[1-46]|80|9[1-9])|6(?:3[1-5]|6[0238]|9[12])|7(?:01|[1579]\\d|2[248]|3[014-9]|4[3-6]|6[023689])|8(?:1[236-8]|2[5-7]|[37]\\d|5[14-9]|8[36-8]|9[1-8])|9(?:0[1-3689]|1[1-79]|[379]\\d|4[13]|5[1-5]))(?:100|9[56])",
            ],
            "0$1",
            "$CC $1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["(?:4|80)0"]],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1 $2 $3",
            [
              "10|2(?:[02-57-9]|1[1-9])",
              "10|2(?:[02-57-9]|1[1-9])",
              "10[0-79]|2(?:[02-57-9]|1[1-79])|(?:10|21)8(?:0[1-9]|[1-9])",
            ],
            "0$1",
            "$CC $1",
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            [
              "3(?:[3-59]|7[02-68])|4(?:[26-8]|3[3-9]|5[2-9])|5(?:3[03-9]|[468]|7[028]|9[2-46-9])|6|7(?:[0-247]|3[04-9]|5[0-4689]|6[2368])|8(?:[1-358]|9[1-7])|9(?:[013479]|5[1-5])|(?:[34]1|55|79|87)[02-9]",
            ],
            "0$1",
            "$CC $1",
            1,
          ],
          [, "(\\d{3})(\\d{7,8})", "$1 $2", ["9"]],
          [
            ,
            "(\\d{4})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["80"],
            "0$1",
            "$CC $1",
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{4})(\\d{4})",
            "$1 $2 $3",
            ["[3-578]"],
            "0$1",
            "$CC $1",
            1,
          ],
          [, "(\\d{3})(\\d{4})(\\d{4})", "$1 $2 $3", ["1[3-9]"], , "$CC $1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3})(\\d{4})",
            "$1 $2 $3 $4",
            ["[12]"],
            "0$1",
            ,
            1,
          ],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [
          ,
          ,
          "(?:(?:10|21)8|[48])00\\d{7}|950\\d{7,8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [10, 11, 12],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CO: [
        ,
        [
          ,
          ,
          "(?:60\\d\\d|9101)\\d{6}|(?:1\\d|3)\\d{9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [10, 11],
          [7],
        ],
        [
          ,
          ,
          "601055(?:[0-4]\\d|50)\\d\\d|6010(?:[0-4]\\d|5[0-4])\\d{4}|60(?:[124-7][2-9]|8[1-9])\\d{6}",
          ,
          ,
          ,
          "6012345678",
          ,
          ,
          [10],
          [7],
        ],
        [
          ,
          ,
          "333301[0-5]\\d{3}|3333(?:00|2[5-9]|[3-9]\\d)\\d{4}|(?:3(?:24[1-9]|3(?:00|3[0-24-9]))|9101)\\d{6}|3(?:0[0-5]|1\\d|2[0-3]|5[01]|70)\\d{7}",
          ,
          ,
          ,
          "3211234567",
          ,
          ,
          [10],
        ],
        [, , "1800\\d{7}", , , , "18001234567", , , [11]],
        [, , "19(?:0[01]|4[78])\\d{7}", , , , "19001234567", , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CO",
        57,
        "00(?:4(?:[14]4|56)|[579])",
        "0",
        ,
        ,
        "0([3579]|4(?:[14]4|56))?",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{7})", "$1 $2", ["6"], "($1)", "0$CC $1"],
          [, "(\\d{3})(\\d{7})", "$1 $2", ["3[0-357]|91"], , "0$CC $1"],
          [, "(\\d)(\\d{3})(\\d{7})", "$1-$2-$3", ["1"], "0$1"],
        ],
        [
          [, "(\\d{3})(\\d{7})", "$1 $2", ["6"], "($1)", "0$CC $1"],
          [, "(\\d{3})(\\d{7})", "$1 $2", ["3[0-357]|91"], , "0$CC $1"],
          [, "(\\d)(\\d{3})(\\d{7})", "$1 $2 $3", ["1"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CR: [
        ,
        [
          ,
          ,
          "(?:8\\d|90)\\d{8}|(?:[24-8]\\d{3}|3005)\\d{4}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 10],
        ],
        [
          ,
          ,
          "210[7-9]\\d{4}|2(?:[024-7]\\d|1[1-9])\\d{5}",
          ,
          ,
          ,
          "22123456",
          ,
          ,
          [8],
        ],
        [
          ,
          ,
          "(?:3005\\d|6500[01])\\d{3}|(?:5[07]|6[0-4]|7[0-3]|8[3-9])\\d{6}",
          ,
          ,
          ,
          "83123456",
          ,
          ,
          [8],
        ],
        [, , "800\\d{7}", , , , "8001234567", , , [10]],
        [, , "90[059]\\d{7}", , , , "9001234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:210[0-6]|4\\d{3}|5100)\\d{4}", , , , "40001234", , , [8]],
        "CR",
        506,
        "00",
        ,
        ,
        ,
        "(19(?:0[0-2468]|1[09]|20|66|77|99))",
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[2-7]|8[3-9]"], , "$CC $1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1-$2-$3", ["[89]"], , "$CC $1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CU: [
        ,
        [
          ,
          ,
          "[27]\\d{6,7}|[34]\\d{5,7}|63\\d{6}|(?:5|8\\d\\d)\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 10],
          [4, 5],
        ],
        [
          ,
          ,
          "(?:3[23]|4[89])\\d{4,6}|(?:31|4[36]|8(?:0[25]|78)\\d)\\d{6}|(?:2[1-4]|4[1257]|7\\d)\\d{5,6}",
          ,
          ,
          ,
          "71234567",
          ,
          ,
          ,
          [4, 5],
        ],
        [, , "(?:5\\d|63)\\d{6}", , , , "51234567", , , [8]],
        [, , "800\\d{7}", , , , "8001234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , "807\\d{7}", , , , "8071234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CU",
        53,
        "119",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{4,6})", "$1 $2", ["2[1-4]|[34]"], "(0$1)"],
          [, "(\\d)(\\d{6,7})", "$1 $2", ["7"], "(0$1)"],
          [, "(\\d)(\\d{7})", "$1 $2", ["[56]"], "0$1"],
          [, "(\\d{3})(\\d{7})", "$1 $2", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CV: [
        ,
        [, , "(?:[2-59]\\d\\d|800)\\d{4}", , , , , , , [7]],
        [
          ,
          ,
          "2(?:2[1-7]|3[0-8]|4[12]|5[1256]|6\\d|7[1-3]|8[1-5])\\d{4}",
          ,
          ,
          ,
          "2211234",
        ],
        [, , "(?:36|5[1-389]|9\\d)\\d{5}", , , , "9911234"],
        [, , "800\\d{4}", , , , "8001234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:3[3-5]|4[356])\\d{5}", , , , "3401234"],
        "CV",
        238,
        "0",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{2})(\\d{2})", "$1 $2 $3", ["[2-589]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CW: [
        ,
        [, , "(?:[34]1|60|(?:7|9\\d)\\d)\\d{5}", , , , , , , [7, 8]],
        [
          ,
          ,
          "9(?:4(?:3[0-5]|4[14]|6\\d)|50\\d|7(?:2[014]|3[02-9]|4[4-9]|6[357]|77|8[7-9])|8(?:3[39]|[46]\\d|7[01]|8[57-9]))\\d{4}",
          ,
          ,
          ,
          "94351234",
        ],
        [, , "953[01]\\d{4}|9(?:5[12467]|6[5-9])\\d{5}", , , , "95181234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "60[0-2]\\d{4}", , , , "6001234", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "CW",
        599,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[3467]"]],
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["9[4-8]"]],
        ],
        ,
        [, , "955\\d{5}", , , , "95581234", , , [8]],
        1,
        "[69]",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CX: [
        ,
        [
          ,
          ,
          "1(?:[0-79]\\d{8}(?:\\d{2})?|8[0-24-9]\\d{7})|[148]\\d{8}|1\\d{5,7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 12],
        ],
        [
          ,
          ,
          "8(?:51(?:0(?:01|30|59|88)|1(?:17|46|75)|2(?:22|35))|91(?:00[6-9]|1(?:[28]1|49|78)|2(?:09|63)|3(?:12|26|75)|4(?:56|97)|64\\d|7(?:0[01]|1[0-2])|958))\\d{3}",
          ,
          ,
          ,
          "891641234",
          ,
          ,
          [9],
          [8],
        ],
        [
          ,
          ,
          "4(?:(?:79|94)[01]|83[0-389])\\d{5}|4(?:[0-3]\\d|4[047-9]|5[0-25-9]|6[0-36-9]|7[02-8]|8[0-24-9]|9[0-37-9])\\d{6}",
          ,
          ,
          ,
          "412345678",
          ,
          ,
          [9],
        ],
        [, , "180(?:0\\d{3}|2)\\d{3}", , , , "1800123456", , , [7, 10]],
        [, , "190[0-26]\\d{6}", , , , "1900123456", , , [10]],
        [
          ,
          ,
          "13(?:00\\d{6}(?:\\d{2})?|45[0-4]\\d{3})|13\\d{4}",
          ,
          ,
          ,
          "1300123456",
          ,
          ,
          [6, 8, 10, 12],
        ],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "14(?:5(?:1[0458]|[23][458])|71\\d)\\d{4}",
          ,
          ,
          ,
          "147101234",
          ,
          ,
          [9],
        ],
        "CX",
        61,
        "001[14-689]|14(?:1[14]|34|4[17]|[56]6|7[47]|88)0011",
        "0",
        ,
        ,
        "([59]\\d{7})$|0",
        "8$1",
        "0011",
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CY: [
        ,
        [, , "(?:[279]\\d|[58]0)\\d{6}", , , , , , , [8]],
        [, , "2[2-6]\\d{6}", , , , "22345678"],
        [, , "9(?:10|[4-79]\\d)\\d{5}", , , , "96123456"],
        [, , "800\\d{5}", , , , "80001234"],
        [, , "90[09]\\d{5}", , , , "90012345"],
        [, , "80[1-9]\\d{5}", , , , "80112345"],
        [, , "700\\d{5}", , , , "70012345"],
        [, , , , , , , , , [-1]],
        "CY",
        357,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{6})", "$1 $2", ["[257-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "(?:50|77)\\d{6}", , , , "77123456"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      CZ: [
        ,
        [, , "(?:[2-578]\\d|60)\\d{7}|9\\d{8,11}", , , , , , , [9, 10, 11, 12]],
        [
          ,
          ,
          "(?:2\\d|3[1257-9]|4[16-9]|5[13-9])\\d{7}",
          ,
          ,
          ,
          "212345678",
          ,
          ,
          [9],
        ],
        [
          ,
          ,
          "(?:60[1-8]\\d|7(?:0(?:[2-5]\\d|60)|190|[2379]\\d\\d))\\d{5}",
          ,
          ,
          ,
          "601123456",
          ,
          ,
          [9],
        ],
        [, , "800\\d{6}", , , , "800123456", , , [9]],
        [, , "9(?:0[05689]|76)\\d{6}", , , , "900123456", , , [9]],
        [, , "8[134]\\d{7}", , , , "811234567", , , [9]],
        [, , "70[01]\\d{6}", , , , "700123456", , , [9]],
        [, , "9[17]0\\d{6}", , , , "910123456", , , [9]],
        "CZ",
        420,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[2-8]|9[015-7]"]],
          [, "(\\d{2})(\\d{3})(\\d{3})(\\d{2})", "$1 $2 $3 $4", ["96"]],
          [, "(\\d{2})(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["9"]],
          [, "(\\d{3})(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["9"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "9(?:5\\d|7[2-4])\\d{6}", , , , "972123456", , , [9]],
        ,
        ,
        [, , "9(?:3\\d{9}|6\\d{7,10})", , , , "93123456789"],
      ],
      DE: [
        ,
        [
          ,
          ,
          "[2579]\\d{5,14}|49(?:[34]0|69|8\\d)\\d\\d?|49(?:37|49|60|7[089]|9\\d)\\d{1,3}|49(?:2[024-9]|3[2-689]|7[1-7])\\d{1,8}|(?:1|[368]\\d|4[0-8])\\d{3,13}|49(?:[015]\\d|2[13]|31|[46][1-8])\\d{1,9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
          [2, 3],
        ],
        [
          ,
          ,
          "32\\d{9,11}|49[1-6]\\d{10}|322\\d{6}|49[0-7]\\d{3,9}|(?:[34]0|[68]9)\\d{3,13}|(?:2(?:0[1-689]|[1-3569]\\d|4[0-8]|7[1-7]|8[0-7])|3(?:[3569]\\d|4[0-79]|7[1-7]|8[1-8])|4(?:1[02-9]|[2-48]\\d|5[0-6]|6[0-8]|7[0-79])|5(?:0[2-8]|[124-6]\\d|[38][0-8]|[79][0-7])|6(?:0[02-9]|[1-358]\\d|[47][0-8]|6[1-9])|7(?:0[2-8]|1[1-9]|[27][0-7]|3\\d|[4-6][0-8]|8[0-5]|9[013-7])|8(?:0[2-9]|1[0-79]|2\\d|3[0-46-9]|4[0-6]|5[013-9]|6[1-8]|7[0-8]|8[0-24-6])|9(?:0[6-9]|[1-4]\\d|[589][0-7]|6[0-8]|7[0-467]))\\d{3,12}",
          ,
          ,
          ,
          "30123456",
          ,
          ,
          [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
          [2, 3, 4],
        ],
        [
          ,
          ,
          "15(?:[0-25-9]\\d\\d|310)\\d{6}|1(?:6[023]|7\\d)\\d{7,8}",
          ,
          ,
          ,
          "15123456789",
          ,
          ,
          [10, 11],
        ],
        [
          ,
          ,
          "800\\d{7,12}",
          ,
          ,
          ,
          "8001234567890",
          ,
          ,
          [10, 11, 12, 13, 14, 15],
        ],
        [
          ,
          ,
          "(?:137[7-9]|900(?:[135]|9\\d))\\d{6}",
          ,
          ,
          ,
          "9001234567",
          ,
          ,
          [10, 11],
        ],
        [
          ,
          ,
          "180\\d{5,11}|13(?:7[1-6]\\d\\d|8)\\d{4}",
          ,
          ,
          ,
          "18012345",
          ,
          ,
          [7, 8, 9, 10, 11, 12, 13, 14],
        ],
        [, , "700\\d{8}", , , , "70012345678", , , [11]],
        [, , , , , , , , , [-1]],
        "DE",
        49,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3,13})", "$1 $2", ["3[02]|40|[68]9"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{3,12})",
            "$1 $2",
            [
              "2(?:0[1-389]|1[124]|2[18]|3[14])|3(?:[35-9][15]|4[015])|906|(?:2[4-9]|4[2-9]|[579][1-9]|[68][1-8])1",
              "2(?:0[1-389]|12[0-8])|3(?:[35-9][15]|4[015])|906|2(?:[13][14]|2[18])|(?:2[4-9]|4[2-9]|[579][1-9]|[68][1-8])1",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{4})(\\d{2,11})",
            "$1 $2",
            [
              "[24-6]|3(?:[3569][02-46-9]|4[2-4679]|7[2-467]|8[2-46-8])|70[2-8]|8(?:0[2-9]|[1-8])|90[7-9]|[79][1-9]",
              "[24-6]|3(?:3(?:0[1-467]|2[127-9]|3[124578]|7[1257-9]|8[1256]|9[145])|4(?:2[135]|4[13578]|9[1346])|5(?:0[14]|2[1-3589]|6[1-4]|7[13468]|8[13568])|6(?:2[1-489]|3[124-6]|6[13]|7[12579]|8[1-356]|9[135])|7(?:2[1-7]|4[145]|6[1-5]|7[1-4])|8(?:21|3[1468]|6|7[1467]|8[136])|9(?:0[12479]|2[1358]|4[134679]|6[1-9]|7[136]|8[147]|9[1468]))|70[2-8]|8(?:0[2-9]|[1-8])|90[7-9]|[79][1-9]|3[68]4[1347]|3(?:47|60)[1356]|3(?:3[46]|46|5[49])[1246]|3[4579]3[1357]",
            ],
            "0$1",
          ],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["138"], "0$1"],
          [, "(\\d{5})(\\d{2,10})", "$1 $2", ["3"], "0$1"],
          [, "(\\d{3})(\\d{5,11})", "$1 $2", ["181"], "0$1"],
          [, "(\\d{3})(\\d)(\\d{4,10})", "$1 $2 $3", ["1(?:3|80)|9"], "0$1"],
          [, "(\\d{3})(\\d{7,8})", "$1 $2", ["1[67]"], "0$1"],
          [, "(\\d{3})(\\d{7,12})", "$1 $2", ["8"], "0$1"],
          [, "(\\d{5})(\\d{6})", "$1 $2", ["185", "1850", "18500"], "0$1"],
          [, "(\\d{3})(\\d{4})(\\d{4})", "$1 $2 $3", ["7"], "0$1"],
          [, "(\\d{4})(\\d{7})", "$1 $2", ["18[68]"], "0$1"],
          [, "(\\d{4})(\\d{7})", "$1 $2", ["15[1279]"], "0$1"],
          [
            ,
            "(\\d{5})(\\d{6})",
            "$1 $2",
            ["15[03568]", "15(?:[0568]|31)"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{8})", "$1 $2", ["18"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{7,8})",
            "$1 $2 $3",
            ["1(?:6[023]|7)"],
            "0$1",
          ],
          [, "(\\d{4})(\\d{2})(\\d{7})", "$1 $2 $3", ["15[279]"], "0$1"],
          [, "(\\d{3})(\\d{2})(\\d{8})", "$1 $2 $3", ["15"], "0$1"],
        ],
        ,
        [
          ,
          ,
          "16(?:4\\d{1,10}|[89]\\d{1,11})",
          ,
          ,
          ,
          "16412345",
          ,
          ,
          [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "18(?:1\\d{5,11}|[2-9]\\d{8})",
          ,
          ,
          ,
          "18500123456",
          ,
          ,
          [8, 9, 10, 11, 12, 13, 14],
        ],
        ,
        ,
        [
          ,
          ,
          "1(?:6(?:013|255|399)|7(?:(?:[015]1|[69]3)3|[2-4]55|[78]99))\\d{7,8}|15(?:(?:[03-68]00|113)\\d|2\\d55|7\\d99|9\\d33)\\d{7}",
          ,
          ,
          ,
          "177991234567",
          ,
          ,
          [12, 13],
        ],
      ],
      DJ: [
        ,
        [, , "(?:2\\d|77)\\d{6}", , , , , , , [8]],
        [, , "2(?:1[2-5]|7[45])\\d{5}", , , , "21360003"],
        [, , "77\\d{6}", , , , "77831001"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "DJ",
        253,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[27]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      DK: [
        ,
        [, , "[2-9]\\d{7}", , , , , , , [8]],
        [, , "(?:[2-7]\\d|8[126-9]|9[1-46-9])\\d{6}", , , , "32123456"],
        [, , "(?:[2-7]\\d|8[126-9]|9[1-46-9])\\d{6}", , , , "32123456"],
        [, , "80\\d{6}", , , , "80123456"],
        [, , "90\\d{6}", , , , "90123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "DK",
        45,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        1,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[2-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      DM: [
        ,
        [, , "(?:[58]\\d\\d|767|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "767(?:2(?:55|66)|4(?:2[01]|4[0-25-9])|50[0-4])\\d{4}",
          ,
          ,
          ,
          "7674201234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "767(?:2(?:[2-4689]5|7[5-7])|31[5-7]|61[1-8]|70[1-6])\\d{4}",
          ,
          ,
          ,
          "7672251234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "DM",
        1,
        "011",
        "1",
        ,
        ,
        "([2-7]\\d{6})$|1",
        "767$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "767",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      DO: [
        ,
        [, , "(?:[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "8(?:[04]9[2-9]\\d\\d|29(?:2(?:[0-59]\\d|6[04-9]|7[0-27]|8[0237-9])|3(?:[0-35-9]\\d|4[7-9])|[45]\\d\\d|6(?:[0-27-9]\\d|[3-5][1-9]|6[0135-8])|7(?:0[013-9]|[1-37]\\d|4[1-35689]|5[1-4689]|6[1-57-9]|8[1-79]|9[1-8])|8(?:0[146-9]|1[0-48]|[248]\\d|3[1-79]|5[01589]|6[013-68]|7[124-8]|9[0-8])|9(?:[0-24]\\d|3[02-46-9]|5[0-79]|60|7[0169]|8[57-9]|9[02-9])))\\d{4}",
          ,
          ,
          ,
          "8092345678",
          ,
          ,
          ,
          [7],
        ],
        [, , "8[024]9[2-9]\\d{6}", , , , "8092345678", , , , [7]],
        [
          ,
          ,
          "8(?:00(?:14|[2-9]\\d)|(?:33|44|55|66|77|88)[2-9]\\d)\\d{5}",
          ,
          ,
          ,
          "8002123456",
        ],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "DO",
        1,
        "011",
        "1",
        ,
        ,
        "1",
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "8001|8[024]9",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      DZ: [
        ,
        [, , "(?:[1-4]|[5-79]\\d|80)\\d{7}", , , , , , , [8, 9]],
        [
          ,
          ,
          "9619\\d{5}|(?:1\\d|2[013-79]|3[0-8]|4[013-689])\\d{6}",
          ,
          ,
          ,
          "12345678",
        ],
        [
          ,
          ,
          "(?:5(?:4[0-29]|5\\d|6[0-2])|6(?:[569]\\d|7[0-6])|7[7-9]\\d)\\d{6}",
          ,
          ,
          ,
          "551234567",
          ,
          ,
          [9],
        ],
        [, , "800\\d{6}", , , , "800123456", , , [9]],
        [, , "80[3-689]1\\d{5}", , , , "808123456", , , [9]],
        [, , "80[12]1\\d{5}", , , , "801123456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , "98[23]\\d{6}", , , , "983123456", , , [9]],
        "DZ",
        213,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[1-4]"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{3})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["9"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[5-8]"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      EC: [
        ,
        [
          ,
          ,
          "1\\d{9,10}|(?:[2-7]|9\\d)\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10, 11],
          [7],
        ],
        [, , "[2-7][2-7]\\d{6}", , , , "22123456", , , [8], [7]],
        [
          ,
          ,
          "964[0-2]\\d{5}|9(?:39|[57][89]|6[0-36-9]|[89]\\d)\\d{6}",
          ,
          ,
          ,
          "991234567",
          ,
          ,
          [9],
        ],
        [, , "1800\\d{7}|1[78]00\\d{6}", , , , "18001234567", , , [10, 11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "[2-7]890\\d{4}", , , , "28901234", , , [8]],
        "EC",
        593,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1-$2", ["[2-7]"]],
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2-$3", ["[2-7]"], "(0$1)"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["9"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["1"]],
        ],
        [
          [, "(\\d)(\\d{3})(\\d{4})", "$1-$2-$3", ["[2-7]"]],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["9"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["1"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      EE: [
        ,
        [
          ,
          ,
          "8\\d{9}|[4578]\\d{7}|(?:[3-8]\\d|90)\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 10],
        ],
        [
          ,
          ,
          "(?:3[23589]|4[3-8]|6\\d|7[1-9]|88)\\d{5}",
          ,
          ,
          ,
          "3212345",
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "(?:5\\d{5}|8(?:1(?:0(?:0(?:00|[178]\\d)|[3-9]\\d\\d)|(?:1(?:0[236]|1\\d)|(?:2[0-59]|[3-79]\\d)\\d)\\d)|2(?:0(?:0(?:00|4\\d)|(?:19|[2-7]\\d)\\d)|(?:(?:[124-6]\\d|3[5-9])\\d|7(?:[0-79]\\d|8[13-9])|8(?:[2-6]\\d|7[01]))\\d)|[349]\\d{4}))\\d\\d|5(?:(?:[02]\\d|5[0-478])\\d|1(?:[0-8]\\d|95)|6(?:4[0-4]|5[1-589]))\\d{3}",
          ,
          ,
          ,
          "51234567",
          ,
          ,
          [7, 8],
        ],
        [, , "800(?:(?:0\\d\\d|1)\\d|[2-9])\\d{3}", , , , "80012345"],
        [, , "(?:40\\d\\d|900)\\d{4}", , , , "9001234", , , [7, 8]],
        [, , , , , , , , , [-1]],
        [, , "70[0-2]\\d{5}", , , , "70012345", , , [8]],
        [, , , , , , , , , [-1]],
        "EE",
        372,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{4})",
            "$1 $2",
            [
              "[369]|4[3-8]|5(?:[0-2]|5[0-478]|6[45])|7[1-9]|88",
              "[369]|4[3-8]|5(?:[02]|1(?:[0-8]|95)|5[0-478]|6(?:4[0-4]|5[1-589]))|7[1-9]|88",
            ],
          ],
          [
            ,
            "(\\d{4})(\\d{3,4})",
            "$1 $2",
            ["[45]|8(?:00|[1-49])", "[45]|8(?:00[1-9]|[1-49])"],
          ],
          [, "(\\d{2})(\\d{2})(\\d{4})", "$1 $2 $3", ["7"]],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1 $2 $3", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "800[2-9]\\d{3}", , , , , , , [7]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      EG: [
        ,
        [
          ,
          ,
          "[189]\\d{8,9}|[24-6]\\d{8}|[135]\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10],
          [6, 7],
        ],
        [
          ,
          ,
          "13[23]\\d{6}|(?:15|57)\\d{6,7}|(?:2[2-4]|3|4[05-8]|5[05]|6[24-689]|8[2468]|9[235-7])\\d{7}",
          ,
          ,
          ,
          "234567890",
          ,
          ,
          [8, 9],
          [6, 7],
        ],
        [, , "1[0-25]\\d{8}", , , , "1001234567", , , [10]],
        [, , "800\\d{7}", , , , "8001234567", , , [10]],
        [, , "900\\d{7}", , , , "9001234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "EG",
        20,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{7,8})", "$1 $2", ["[23]"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{6,7})",
            "$1 $2",
            ["1[35]|[4-6]|8[2468]|9[235-7]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["[89]"], "0$1"],
          [, "(\\d{2})(\\d{8})", "$1 $2", ["1"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      EH: [
        ,
        [, , "[5-8]\\d{8}", , , , , , , [9]],
        [, , "528[89]\\d{5}", , , , "528812345"],
        [
          ,
          ,
          "(?:6(?:[0-79]\\d|8[0-247-9])|7(?:[0167]\\d|2[0-4]|5[01]|8[0-3]))\\d{6}",
          ,
          ,
          ,
          "650123456",
        ],
        [, , "80[0-7]\\d{6}", , , , "801234567"],
        [, , "89\\d{7}", , , , "891234567"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:592(?:4[0-2]|93)|80[89]\\d\\d)\\d{4}", , , , "592401234"],
        "EH",
        212,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "528[89]",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ER: [
        ,
        [, , "[178]\\d{6}", , , , , , , [7], [6]],
        [
          ,
          ,
          "(?:1(?:1[12568]|[24]0|55|6[146])|8\\d\\d)\\d{4}",
          ,
          ,
          ,
          "8370362",
          ,
          ,
          ,
          [6],
        ],
        [, , "(?:17[1-3]|7\\d\\d)\\d{4}", , , , "7123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "ER",
        291,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [[, "(\\d)(\\d{3})(\\d{3})", "$1 $2 $3", ["[178]"], "0$1"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ES: [
        ,
        [, , "[5-9]\\d{8}", , , , , , , [9]],
        [
          ,
          ,
          "96906(?:0[0-8]|1[1-9]|[2-9]\\d)\\d\\d|9(?:69(?:0[0-57-9]|[1-9]\\d)|73(?:[0-8]\\d|9[1-9]))\\d{4}|(?:8(?:[1356]\\d|[28][0-8]|[47][1-9])|9(?:[135]\\d|[268][0-8]|4[1-9]|7[124-9]))\\d{6}",
          ,
          ,
          ,
          "810123456",
        ],
        [
          ,
          ,
          "(?:590[16]00\\d|9(?:6906(?:09|10)|7390\\d\\d))\\d\\d|(?:6\\d|7[1-48])\\d{7}",
          ,
          ,
          ,
          "612345678",
        ],
        [, , "[89]00\\d{6}", , , , "800123456"],
        [, , "80[367]\\d{6}", , , , "803123456"],
        [, , "90[12]\\d{6}", , , , "901123456"],
        [, , "70\\d{7}", , , , "701234567"],
        [, , , , , , , , , [-1]],
        "ES",
        34,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{4})", "$1", ["905"]],
          [, "(\\d{6})", "$1", ["[79]9"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[89]00"]],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[5-9]"]],
        ],
        [
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[89]00"]],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[5-9]"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "51\\d{7}", , , , "511234567"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ET: [
        ,
        [, , "(?:11|[2-579]\\d)\\d{7}", , , , , , , [9], [7]],
        [
          ,
          ,
          "11667[01]\\d{3}|(?:11(?:1(?:1[124]|2[2-7]|3[1-5]|5[5-8]|8[6-8])|2(?:13|3[6-8]|5[89]|7[05-9]|8[2-6])|3(?:2[01]|3[0-289]|4[1289]|7[1-4]|87)|4(?:1[69]|3[2-49]|4[0-3]|6[5-8])|5(?:1[578]|44|5[0-4])|6(?:1[578]|2[69]|39|4[5-7]|5[0-5]|6[0-59]|8[015-8]))|2(?:2(?:11[1-9]|22[0-7]|33\\d|44[1467]|66[1-68])|5(?:11[124-6]|33[2-8]|44[1467]|55[14]|66[1-3679]|77[124-79]|880))|3(?:3(?:11[0-46-8]|(?:22|55)[0-6]|33[0134689]|44[04]|66[01467])|4(?:44[0-8]|55[0-69]|66[0-3]|77[1-5]))|4(?:6(?:119|22[0-24-7]|33[1-5]|44[13-69]|55[14-689]|660|88[1-4])|7(?:(?:11|22)[1-9]|33[13-7]|44[13-6]|55[1-689]))|5(?:7(?:227|55[05]|(?:66|77)[14-8])|8(?:11[149]|22[013-79]|33[0-68]|44[013-8]|550|66[1-5]|77\\d)))\\d{4}",
          ,
          ,
          ,
          "111112345",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "700[1-9]\\d{5}|(?:7(?:0[1-9]|1[0-8]|22|77|86|99)|9\\d\\d)\\d{6}",
          ,
          ,
          ,
          "911234567",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "ET",
        251,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[1-579]"], "0$1"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      FI: [
        ,
        [
          ,
          ,
          "[1-35689]\\d{4}|7\\d{10,11}|(?:[124-7]\\d|3[0-46-9])\\d{8}|[1-9]\\d{5,8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 8, 9, 10, 11, 12],
        ],
        [
          ,
          ,
          "(?:1[3-79][1-8]|[23568][1-8]\\d|9(?:00|[1-8]\\d))\\d{2,6}",
          ,
          ,
          ,
          "131234567",
          ,
          ,
          [5, 6, 7, 8, 9],
        ],
        [
          ,
          ,
          "4946\\d{2,6}|(?:4[0-8]|50)\\d{4,8}",
          ,
          ,
          ,
          "412345678",
          ,
          ,
          [6, 7, 8, 9, 10],
        ],
        [, , "800\\d{4,6}", , , , "800123456", , , [7, 8, 9]],
        [, , "[67]00\\d{5,6}", , , , "600123456", , , [8, 9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "FI",
        358,
        "00|99(?:[01469]|5(?:[14]1|3[23]|5[59]|77|88|9[09]))",
        "0",
        ,
        ,
        "0",
        ,
        "00",
        ,
        [
          [, "(\\d{5})", "$1", ["75[12]"], "0$1"],
          [, "(\\d{5})", "$1", ["20[2-59]"], "0$1"],
          [, "(\\d{6})", "$1", ["11"]],
          [
            ,
            "(\\d{3})(\\d{3,7})",
            "$1 $2",
            ["(?:[1-3]0|[68])0|70[07-9]"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{4,8})", "$1 $2", ["[14]|2[09]|50|7[135]"], "0$1"],
          [, "(\\d{2})(\\d{6,10})", "$1 $2", ["7"], "0$1"],
          [
            ,
            "(\\d)(\\d{4,9})",
            "$1 $2",
            ["(?:1[3-79]|[2568])[1-8]|3(?:0[1-9]|[1-9])|9"],
            "0$1",
          ],
        ],
        [
          [, "(\\d{5})", "$1", ["20[2-59]"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{3,7})",
            "$1 $2",
            ["(?:[1-3]0|[68])0|70[07-9]"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{4,8})", "$1 $2", ["[14]|2[09]|50|7[135]"], "0$1"],
          [, "(\\d{2})(\\d{6,10})", "$1 $2", ["7"], "0$1"],
          [
            ,
            "(\\d)(\\d{4,9})",
            "$1 $2",
            ["(?:1[3-79]|[2568])[1-8]|3(?:0[1-9]|[1-9])|9"],
            "0$1",
          ],
        ],
        [, , , , , , , , , [-1]],
        1,
        "1[03-79]|[2-9]",
        [
          ,
          ,
          "20(?:2[023]|9[89])\\d{1,6}|(?:60[12]\\d|7099)\\d{4,5}|(?:606|7(?:0[78]|1|3\\d))\\d{7}|(?:[1-3]00|7(?:0[1-5]\\d\\d|5[03-9]))\\d{3,7}",
        ],
        [
          ,
          ,
          "20\\d{4,8}|60[12]\\d{5,6}|7(?:099\\d{4,5}|5[03-9]\\d{3,7})|20[2-59]\\d\\d|(?:606|7(?:0[78]|1|3\\d))\\d{7}|(?:10|29|3[09]|70[1-5]\\d)\\d{4,8}",
          ,
          ,
          ,
          "10112345",
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      FJ: [
        ,
        [, , "45\\d{5}|(?:0800\\d|[235-9])\\d{6}", , , , , , , [7, 11]],
        [
          ,
          ,
          "603\\d{4}|(?:3[0-5]|6[25-7]|8[58])\\d{5}",
          ,
          ,
          ,
          "3212345",
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "(?:[279]\\d|45|5[01568]|8[034679])\\d{5}",
          ,
          ,
          ,
          "7012345",
          ,
          ,
          [7],
        ],
        [, , "0800\\d{7}", , , , "08001234567", , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "FJ",
        679,
        "0(?:0|52)",
        ,
        ,
        ,
        ,
        ,
        "00",
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[235-9]|45"]],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["0"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      FK: [
        ,
        [, , "[2-7]\\d{4}", , , , , , , [5]],
        [, , "[2-47]\\d{4}", , , , "31234"],
        [, , "[56]\\d{4}", , , , "51234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "FK",
        500,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      FM: [
        ,
        [, , "(?:[39]\\d\\d|820)\\d{4}", , , , , , , [7]],
        [
          ,
          ,
          "31(?:00[67]|208|309)\\d\\d|(?:3(?:[2357]0[1-9]|602|804|905)|(?:820|9[2-6]\\d)\\d)\\d{3}",
          ,
          ,
          ,
          "3201234",
        ],
        [
          ,
          ,
          "31(?:00[67]|208|309)\\d\\d|(?:3(?:[2357]0[1-9]|602|804|905)|(?:820|9[2-7]\\d)\\d)\\d{3}",
          ,
          ,
          ,
          "3501234",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "FM",
        691,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[389]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      FO: [
        ,
        [, , "[2-9]\\d{5}", , , , , , , [6]],
        [, , "(?:20|[34]\\d|8[19])\\d{4}", , , , "201234"],
        [, , "(?:[27][1-9]|5\\d|9[16])\\d{4}", , , , "211234"],
        [, , "80[257-9]\\d{3}", , , , "802123"],
        [, , "90(?:[13-5][15-7]|2[125-7]|9\\d)\\d\\d", , , , "901123"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:6[0-36]|88)\\d{4}", , , , "601234"],
        "FO",
        298,
        "00",
        ,
        ,
        ,
        "(10(?:01|[12]0|88))",
        ,
        ,
        ,
        [[, "(\\d{6})", "$1", ["[2-9]"], , "$CC $1"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      FR: [
        ,
        [, , "[1-9]\\d{8}", , , , , , , [9]],
        [
          ,
          ,
          "(?:26[013-9]|59[1-35-9])\\d{6}|(?:[13]\\d|2[0-57-9]|4[1-9]|5[0-8])\\d{7}",
          ,
          ,
          ,
          "123456789",
        ],
        [
          ,
          ,
          "(?:6(?:[0-24-8]\\d|3[0-8]|9[589])|7[3-9]\\d)\\d{6}",
          ,
          ,
          ,
          "612345678",
        ],
        [, , "80[0-5]\\d{6}", , , , "801234567"],
        [
          ,
          ,
          "836(?:0[0-36-9]|[1-9]\\d)\\d{4}|8(?:1[2-9]|2[2-47-9]|3[0-57-9]|[569]\\d|8[0-35-9])\\d{6}",
          ,
          ,
          ,
          "891123456",
        ],
        [, , "8(?:1[01]|2[0156]|4[02]|84)\\d{6}", , , , "884012345"],
        [, , , , , , , , , [-1]],
        [, , "9\\d{8}", , , , "912345678"],
        "FR",
        33,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{4})", "$1", ["10"]],
          [, "(\\d{3})(\\d{3})", "$1 $2", ["1"]],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"], "0 $1"],
          [
            ,
            "(\\d)(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4 $5",
            ["[1-79]"],
            "0$1",
          ],
        ],
        [
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"], "0 $1"],
          [
            ,
            "(\\d)(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4 $5",
            ["[1-79]"],
            "0$1",
          ],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "80[6-9]\\d{6}", , , , "806123456"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GA: [
        ,
        [, , "(?:[067]\\d|11)\\d{6}|[2-7]\\d{6}", , , , , , , [7, 8]],
        [, , "[01]1\\d{6}", , , , "01441234", , , [8]],
        [
          ,
          ,
          "(?:(?:0[2-7]|7[467])\\d|6(?:0[0-4]|10|[256]\\d))\\d{5}|[2-7]\\d{6}",
          ,
          ,
          ,
          "06031234",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "GA",
        241,
        "00",
        ,
        ,
        ,
        "0(11\\d{6}|60\\d{6}|61\\d{6}|6[256]\\d{6}|7[467]\\d{6})",
        "$1",
        ,
        ,
        [
          [, "(\\d)(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[2-7]"], "0$1"],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["0"]],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["11|[67]"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GB: [
        ,
        [
          ,
          ,
          "[1-357-9]\\d{9}|[18]\\d{8}|8\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 9, 10],
          [4, 5, 6, 8],
        ],
        [
          ,
          ,
          "(?:1(?:1(?:3(?:[0-58]\\d\\d|73[0235])|4(?:(?:[0-5]\\d|70)\\d|69[7-9])|(?:(?:5[0-26-9]|[78][0-49])\\d|6(?:[0-4]\\d|50))\\d)|(?:2(?:(?:0[024-9]|2[3-9]|3[3-79]|4[1-689]|[58][02-9]|6[0-47-9]|7[013-9]|9\\d)\\d|1(?:[0-7]\\d|8[0-2]))|(?:3(?:0\\d|1[0-8]|[25][02-9]|3[02-579]|[468][0-46-9]|7[1-35-79]|9[2-578])|4(?:0[03-9]|[137]\\d|[28][02-57-9]|4[02-69]|5[0-8]|[69][0-79])|5(?:0[1-35-9]|[16]\\d|2[024-9]|3[015689]|4[02-9]|5[03-9]|7[0-35-9]|8[0-468]|9[0-57-9])|6(?:0[034689]|1\\d|2[0-35689]|[38][013-9]|4[1-467]|5[0-69]|6[13-9]|7[0-8]|9[0-24578])|7(?:0[0246-9]|2\\d|3[0236-8]|4[03-9]|5[0-46-9]|6[013-9]|7[0-35-9]|8[024-9]|9[02-9])|8(?:0[35-9]|2[1-57-9]|3[02-578]|4[0-578]|5[124-9]|6[2-69]|7\\d|8[02-9]|9[02569])|9(?:0[02-589]|[18]\\d|2[02-689]|3[1-57-9]|4[2-9]|5[0-579]|6[2-47-9]|7[0-24578]|9[2-57]))\\d)\\d)|2(?:0[013478]|3[0189]|4[017]|8[0-46-9]|9[0-2])\\d{3})\\d{4}|1(?:2(?:0(?:46[1-4]|87[2-9])|545[1-79]|76(?:2\\d|3[1-8]|6[1-6])|9(?:7(?:2[0-4]|3[2-5])|8(?:2[2-8]|7[0-47-9]|8[3-5])))|3(?:6(?:38[2-5]|47[23])|8(?:47[04-9]|64[0157-9]))|4(?:044[1-7]|20(?:2[23]|8\\d)|6(?:0(?:30|5[2-57]|6[1-8]|7[2-8])|140)|8(?:052|87[1-3]))|5(?:2(?:4(?:3[2-79]|6\\d)|76\\d)|6(?:26[06-9]|686))|6(?:06(?:4\\d|7[4-79])|295[5-7]|35[34]\\d|47(?:24|61)|59(?:5[08]|6[67]|74)|9(?:55[0-4]|77[23]))|7(?:26(?:6[13-9]|7[0-7])|(?:442|688)\\d|50(?:2[0-3]|[3-68]2|76))|8(?:27[56]\\d|37(?:5[2-5]|8[239])|843[2-58])|9(?:0(?:0(?:6[1-8]|85)|52\\d)|3583|4(?:66[1-8]|9(?:2[01]|81))|63(?:23|3[1-4])|9561))\\d{3}",
          ,
          ,
          ,
          "1212345678",
          ,
          ,
          [9, 10],
          [4, 5, 6, 7, 8],
        ],
        [
          ,
          ,
          "7(?:457[0-57-9]|700[01]|911[028])\\d{5}|7(?:[1-3]\\d\\d|4(?:[0-46-9]\\d|5[0-689])|5(?:0[0-8]|[13-9]\\d|2[0-35-9])|7(?:0[1-9]|[1-7]\\d|8[02-9]|9[0-689])|8(?:[014-9]\\d|[23][0-8])|9(?:[024-9]\\d|1[02-9]|3[0-689]))\\d{6}",
          ,
          ,
          ,
          "7400123456",
          ,
          ,
          [10],
        ],
        [, , "80[08]\\d{7}|800\\d{6}|8001111", , , , "8001234567"],
        [
          ,
          ,
          "(?:8(?:4[2-5]|7[0-3])|9(?:[01]\\d|8[2-49]))\\d{7}|845464\\d",
          ,
          ,
          ,
          "9012345678",
          ,
          ,
          [7, 10],
        ],
        [, , , , , , , , , [-1]],
        [, , "70\\d{8}", , , , "7012345678", , , [10]],
        [, , "56\\d{8}", , , , "5612345678", , , [10]],
        "GB",
        44,
        "00",
        "0",
        " x",
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{4})",
            "$1 $2",
            ["800", "8001", "80011", "800111", "8001111"],
            "0$1",
          ],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3",
            ["845", "8454", "84546", "845464"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{6})", "$1 $2", ["800"], "0$1"],
          [
            ,
            "(\\d{5})(\\d{4,5})",
            "$1 $2",
            [
              "1(?:38|5[23]|69|76|94)",
              "1(?:(?:38|69)7|5(?:24|39)|768|946)",
              "1(?:3873|5(?:242|39[4-6])|(?:697|768)[347]|9467)",
            ],
            "0$1",
          ],
          [, "(\\d{4})(\\d{5,6})", "$1 $2", ["1(?:[2-69][02-9]|[78])"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1 $2 $3",
            ["[25]|7(?:0|6[02-9])", "[25]|7(?:0|6(?:[03-9]|2[356]))"],
            "0$1",
          ],
          [, "(\\d{4})(\\d{6})", "$1 $2", ["7"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["[1389]"], "0$1"],
        ],
        ,
        [
          ,
          ,
          "76(?:464|652)\\d{5}|76(?:0[0-28]|2[356]|34|4[01347]|5[49]|6[0-369]|77|8[14]|9[139])\\d{6}",
          ,
          ,
          ,
          "7640123456",
          ,
          ,
          [10],
        ],
        1,
        ,
        [, , , , , , , , , [-1]],
        [, , "(?:3[0347]|55)\\d{8}", , , , "5512345678", , , [10]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GD: [
        ,
        [, , "(?:473|[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "473(?:2(?:3[0-2]|69)|3(?:2[89]|86)|4(?:[06]8|3[5-9]|4[0-49]|5[5-79]|73|90)|63[68]|7(?:58|84)|800|938)\\d{4}",
          ,
          ,
          ,
          "4732691234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "473(?:4(?:0[2-79]|1[04-9]|2[0-5]|58)|5(?:2[01]|3[3-8])|901)\\d{4}",
          ,
          ,
          ,
          "4734031234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "GD",
        1,
        "011",
        "1",
        ,
        ,
        "([2-9]\\d{6})$|1",
        "473$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "473",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GE: [
        ,
        [, , "(?:[3-57]\\d\\d|800)\\d{6}", , , , , , , [9], [6, 7]],
        [
          ,
          ,
          "(?:3(?:[256]\\d|4[124-9]|7[0-4])|4(?:1\\d|2[2-7]|3[1-79]|4[2-8]|7[239]|9[1-7]))\\d{6}",
          ,
          ,
          ,
          "322123456",
          ,
          ,
          ,
          [6, 7],
        ],
        [
          ,
          ,
          "5(?:(?:(?:0555|1(?:[17]77|555))[5-9]|757(?:7[7-9]|8[01]))\\d|22252[0-4])\\d\\d|(?:5(?:00(?:0\\d|11|22|33|44|5[05]|77|88|9[09])|1(?:1(?:00|[124]\\d|3[01])|4\\d\\d)|(?:44|68)\\d\\d|5(?:[0157-9]\\d\\d|200)|7(?:[0147-9]\\d\\d|5(?:00|[57]5))|8(?:0(?:[018]\\d|2[0-4])|58[89]|8(?:55|88))|9(?:090|[1-35-9]\\d\\d))|790\\d\\d)\\d{4}|5(?:0(?:0[17]0|505)|1(?:0[01]0|1(?:07|33|51))|2(?:0[02]0|2[25]2)|3(?:0[03]0|3[35]3)|(?:40[04]|900)0|5222)[0-4]\\d{3}",
          ,
          ,
          ,
          "555123456",
        ],
        [, , "800\\d{6}", , , , "800123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "70[67]\\d{6}", , , , "706123456"],
        "GE",
        995,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["70"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["32"], "0$1"],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[57]"]],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[348]"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "70[67]\\d{6}"],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GF: [
        ,
        [, , "[56]94\\d{6}|(?:80|9\\d)\\d{7}", , , , , , , [9]],
        [
          ,
          ,
          "594(?:[02-49]\\d|1[0-5]|5[6-9]|6[0-3]|80)\\d{4}",
          ,
          ,
          ,
          "594101234",
        ],
        [, , "694(?:[0-249]\\d|3[0-8])\\d{4}", , , , "694201234"],
        [, , "80[0-5]\\d{6}", , , , "800012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "9(?:(?:396|76\\d)\\d|476[0-5])\\d{4}", , , , "976012345"],
        "GF",
        594,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[56]|9[47]"],
            "0$1",
          ],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[89]"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GG: [
        ,
        [
          ,
          ,
          "(?:1481|[357-9]\\d{3})\\d{6}|8\\d{6}(?:\\d{2})?",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 9, 10],
          [6],
        ],
        [, , "1481[25-9]\\d{5}", , , , "1481256789", , , [10], [6]],
        [, , "7(?:(?:781|839)\\d|911[17])\\d{5}", , , , "7781123456", , , [10]],
        [, , "80[08]\\d{7}|800\\d{6}|8001111", , , , "8001234567"],
        [
          ,
          ,
          "(?:8(?:4[2-5]|7[0-3])|9(?:[01]\\d|8[0-3]))\\d{7}|845464\\d",
          ,
          ,
          ,
          "9012345678",
          ,
          ,
          [7, 10],
        ],
        [, , , , , , , , , [-1]],
        [, , "70\\d{8}", , , , "7012345678", , , [10]],
        [, , "56\\d{8}", , , , "5612345678", , , [10]],
        "GG",
        44,
        "00",
        "0",
        ,
        ,
        "([25-9]\\d{5})$|0",
        "1481$1",
        ,
        ,
        ,
        ,
        [
          ,
          ,
          "76(?:464|652)\\d{5}|76(?:0[0-28]|2[356]|34|4[01347]|5[49]|6[0-369]|77|8[14]|9[139])\\d{6}",
          ,
          ,
          ,
          "7640123456",
          ,
          ,
          [10],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "(?:3[0347]|55)\\d{8}", , , , "5512345678", , , [10]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GH: [
        ,
        [, , "(?:[235]\\d{3}|800)\\d{5}", , , , , , , [8, 9], [7]],
        [
          ,
          ,
          "3082[0-5]\\d{4}|3(?:0(?:[237]\\d|8[01])|[167](?:2[0-6]|7\\d|80)|2(?:2[0-5]|7\\d|80)|3(?:2[0-3]|7\\d|80)|4(?:2[013-9]|3[01]|7\\d|80)|5(?:2[0-7]|7\\d|80)|8(?:2[0-2]|7\\d|80)|9(?:[28]0|7\\d))\\d{5}",
          ,
          ,
          ,
          "302345678",
          ,
          ,
          [9],
          [7],
        ],
        [
          ,
          ,
          "(?:2(?:[0346-9]\\d|5[67])|5(?:[03-7]\\d|9[1-9]))\\d{6}",
          ,
          ,
          ,
          "231234567",
          ,
          ,
          [9],
        ],
        [, , "800\\d{5}", , , , "80012345", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "GH",
        233,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[237]|8[0-2]"]],
          [, "(\\d{3})(\\d{5})", "$1 $2", ["8"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[235]"], "0$1"],
        ],
        [
          [, "(\\d{3})(\\d{5})", "$1 $2", ["8"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[235]"], "0$1"],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "800\\d{5}", , , , , , , [8]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GI: [
        ,
        [, , "(?:[25]\\d|60)\\d{6}", , , , , , , [8]],
        [
          ,
          ,
          "2190[0-2]\\d{3}|2(?:0(?:[02]\\d|3[01])|16[24-9]|2[2-5]\\d)\\d{4}",
          ,
          ,
          ,
          "20012345",
        ],
        [
          ,
          ,
          "5251[0-4]\\d{3}|(?:5(?:[146-8]\\d\\d|250)|60(?:1[01]|6\\d))\\d{4}",
          ,
          ,
          ,
          "57123456",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "GI",
        350,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{5})", "$1 $2", ["2"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GL: [
        ,
        [, , "(?:19|[2-689]\\d|70)\\d{4}", , , , , , , [6]],
        [, , "(?:19|3[1-7]|[68][1-9]|70|9\\d)\\d{4}", , , , "321000"],
        [, , "[245]\\d{5}", , , , "221234"],
        [, , "80\\d{4}", , , , "801234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "3[89]\\d{4}", , , , "381234"],
        "GL",
        299,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3", ["19|[2-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GM: [
        ,
        [, , "[2-9]\\d{6}", , , , , , , [7]],
        [
          ,
          ,
          "(?:4(?:[23]\\d\\d|4(?:1[024679]|[6-9]\\d))|5(?:5(?:3\\d|4[0-7])|6[67]\\d|7(?:1[04]|2[035]|3[58]|48))|8\\d{3})\\d{3}",
          ,
          ,
          ,
          "5661234",
        ],
        [, , "(?:[23679]\\d|5[0-489])\\d{5}", , , , "3012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "GM",
        220,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[2-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GN: [
        ,
        [, , "722\\d{6}|(?:3|6\\d)\\d{7}", , , , , , , [8, 9]],
        [
          ,
          ,
          "3(?:0(?:24|3[12]|4[1-35-7]|5[13]|6[189]|[78]1|9[1478])|1\\d\\d)\\d{4}",
          ,
          ,
          ,
          "30241234",
          ,
          ,
          [8],
        ],
        [, , "6[0-356]\\d{7}", , , , "601123456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "722\\d{6}", , , , "722123456", , , [9]],
        "GN",
        224,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["3"]],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[67]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GP: [
        ,
        [, , "590\\d{6}|(?:69|80|9\\d)\\d{7}", , , , , , , [9]],
        [
          ,
          ,
          "590(?:0[1-68]|[14][0-24-9]|2[0-68]|3[1-9]|5[3-579]|[68][0-689]|7[08]|9\\d)\\d{4}",
          ,
          ,
          ,
          "590201234",
        ],
        [
          ,
          ,
          "69(?:0\\d\\d|1(?:2[2-9]|3[0-5])|4(?:0[89]|1[2-6]|9\\d)|6(?:1[016-9]|5[0-4]|[67]\\d))\\d{4}",
          ,
          ,
          ,
          "690001234",
        ],
        [, , "80[0-5]\\d{6}", , , , "800012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "9(?:(?:39[5-7]|76[018])\\d|475[0-5])\\d{4}", , , , "976012345"],
        "GP",
        590,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[569]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        1,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GQ: [
        ,
        [, , "222\\d{6}|(?:3\\d|55|[89]0)\\d{7}", , , , , , , [9]],
        [
          ,
          ,
          "33[0-24-9]\\d[46]\\d{4}|3(?:33|5\\d)\\d[7-9]\\d{4}",
          ,
          ,
          ,
          "333091234",
        ],
        [, , "(?:222|55\\d)\\d{6}", , , , "222123456"],
        [, , "80\\d[1-9]\\d{5}", , , , "800123456"],
        [, , "90\\d[1-9]\\d{5}", , , , "900123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "GQ",
        240,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[235]"]],
          [, "(\\d{3})(\\d{6})", "$1 $2", ["[89]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GR: [
        ,
        [
          ,
          ,
          "5005000\\d{3}|8\\d{9,11}|(?:[269]\\d|70)\\d{8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [10, 11, 12],
        ],
        [
          ,
          ,
          "2(?:1\\d\\d|2(?:2[1-46-9]|[36][1-8]|4[1-7]|5[1-4]|7[1-5]|[89][1-9])|3(?:1\\d|2[1-57]|[35][1-3]|4[13]|7[1-7]|8[124-6]|9[1-79])|4(?:1\\d|2[1-8]|3[1-4]|4[13-5]|6[1-578]|9[1-5])|5(?:1\\d|[29][1-4]|3[1-5]|4[124]|5[1-6])|6(?:1\\d|[269][1-6]|3[1245]|4[1-7]|5[13-9]|7[14]|8[1-5])|7(?:1\\d|2[1-5]|3[1-6]|4[1-7]|5[1-57]|6[135]|9[125-7])|8(?:1\\d|2[1-5]|[34][1-4]|9[1-57]))\\d{6}",
          ,
          ,
          ,
          "2123456789",
          ,
          ,
          [10],
        ],
        [, , "68[57-9]\\d{7}|(?:69|94)\\d{8}", , , , "6912345678", , , [10]],
        [, , "800\\d{7,9}", , , , "8001234567"],
        [, , "90[19]\\d{7}", , , , "9091234567", , , [10]],
        [, , "8(?:0[16]|12|[27]5|50)\\d{7}", , , , "8011234567", , , [10]],
        [, , "70\\d{8}", , , , "7012345678", , , [10]],
        [, , , , , , , , , [-1]],
        "GR",
        30,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2 $3", ["21|7"]],
          [
            ,
            "(\\d{4})(\\d{6})",
            "$1 $2",
            ["2(?:2|3[2-57-9]|4[2-469]|5[2-59]|6[2-9]|7[2-69]|8[2-49])|5"],
          ],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["[2689]"]],
          [, "(\\d{3})(\\d{3,4})(\\d{5})", "$1 $2 $3", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "5005000\\d{3}", , , , "5005000123", , , [10]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GT: [
        ,
        [, , "80\\d{6}|(?:1\\d{3}|[2-7])\\d{7}", , , , , , , [8, 11]],
        [, , "[267][2-9]\\d{6}", , , , "22456789", , , [8]],
        [, , "(?:[3-5]\\d\\d|80[0-4])\\d{5}", , , , "51234567", , , [8]],
        [, , "18[01]\\d{8}", , , , "18001112222", , , [11]],
        [, , "19\\d{9}", , , , "19001112222", , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "GT",
        502,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[2-8]"]],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GU: [
        ,
        [, , "(?:[58]\\d\\d|671|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "671(?:2\\d\\d|3(?:00|3[39]|4[349]|55|6[26])|4(?:00|56|7[1-9]|8[02-46-9])|5(?:55|6[2-5]|88)|6(?:3[2-578]|4[24-9]|5[34]|78|8[235-9])|7(?:[0479]7|2[0167]|3[45]|8[7-9])|8(?:[2-57-9]8|6[48])|9(?:2[29]|6[79]|7[1279]|8[7-9]|9[78]))\\d{4}",
          ,
          ,
          ,
          "6713001234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "671(?:2\\d\\d|3(?:00|3[39]|4[349]|55|6[26])|4(?:00|56|7[1-9]|8[02-46-9])|5(?:55|6[2-5]|88)|6(?:3[2-578]|4[24-9]|5[34]|78|8[235-9])|7(?:[0479]7|2[0167]|3[45]|8[7-9])|8(?:[2-57-9]8|6[48])|9(?:2[29]|6[79]|7[1279]|8[7-9]|9[78]))\\d{4}",
          ,
          ,
          ,
          "6713001234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "GU",
        1,
        "011",
        "1",
        ,
        ,
        "([2-9]\\d{6})$|1",
        "671$1",
        ,
        1,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "671",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GW: [
        ,
        [, , "[49]\\d{8}|4\\d{6}", , , , , , , [7, 9]],
        [, , "443\\d{6}", , , , "443201234", , , [9]],
        [, , "9(?:5\\d|6[569]|77)\\d{6}", , , , "955012345", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "40\\d{5}", , , , "4012345", , , [7]],
        "GW",
        245,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["40"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[49]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      GY: [
        ,
        [, , "(?:[2-8]\\d{3}|9008)\\d{3}", , , , , , , [7]],
        [
          ,
          ,
          "(?:2(?:1[6-9]|2[0-35-9]|3[1-4]|5[3-9]|6\\d|7[0-79])|3(?:2[25-9]|3\\d)|4(?:4[0-24]|5[56])|50[0-6]|77[1-57])\\d{4}",
          ,
          ,
          ,
          "2201234",
        ],
        [
          ,
          ,
          "(?:510|6\\d\\d|7(?:[01]\\d|2[156]|31|49))\\d{4}",
          ,
          ,
          ,
          "6091234",
        ],
        [, , "(?:289|8(?:00|6[28]|88|99))\\d{4}", , , , "2891234"],
        [, , "9008\\d{3}", , , , "9008123"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "515\\d{4}", , , , "5151234"],
        "GY",
        592,
        "001",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[2-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      HK: [
        ,
        [
          ,
          ,
          "8[0-46-9]\\d{6,7}|9\\d{4,7}|(?:[2-7]|9\\d{3})\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 8, 9, 11],
        ],
        [
          ,
          ,
          "(?:2(?:[13-9]\\d|2[013-9])\\d|3(?:(?:[1569][0-24-9]|4[0-246-9]|7[0-24-69])\\d|8(?:4[0-8]|[59]\\d|6[01]))|58(?:0[1-9]|1[2-9]))\\d{4}",
          ,
          ,
          ,
          "21234567",
          ,
          ,
          [8],
        ],
        [
          ,
          ,
          "(?:4(?:44[0-25-9]|6(?:1[0-7]|4[0-57-9]|6[0-4]))|5(?:73[0-6]|95[0-8])|6(?:26[013-8]|66[0-3])|70(?:7[1-8]|8[0-4])|848[0-35-9]|9(?:29[013-9]|39[01]|59[0-4]|899))\\d{4}|(?:4(?:4[0-35-8]|6[02357-9])|5(?:[1-59][0-46-9]|6[0-4689]|7[0-246-9])|6(?:0[1-9]|[13-59]\\d|[268][0-57-9]|7[0-79])|70[129]|84[0-29]|9(?:0[1-9]|1[02-9]|[2358][0-8]|[467]\\d))\\d{5}",
          ,
          ,
          ,
          "51234567",
          ,
          ,
          [8],
        ],
        [, , "800\\d{6}", , , , "800123456", , , [9]],
        [
          ,
          ,
          "900(?:[0-24-9]\\d{7}|3\\d{1,4})",
          ,
          ,
          ,
          "90012345678",
          ,
          ,
          [5, 6, 7, 8, 11],
        ],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "8(?:1[0-4679]\\d|2(?:[0-36]\\d|7[0-4])|3(?:[034]\\d|2[09]|70))\\d{4}",
          ,
          ,
          ,
          "81123456",
          ,
          ,
          [8],
        ],
        [, , , , , , , , , [-1]],
        "HK",
        852,
        "00(?:30|5[09]|[126-9]?)",
        ,
        ,
        ,
        ,
        ,
        "00",
        ,
        [
          [, "(\\d{3})(\\d{2,5})", "$1 $2", ["900", "9003"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[2-7]|8[1-4]|9(?:0[1-9]|[1-8])"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["8"]],
          [, "(\\d{3})(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["9"]],
        ],
        ,
        [
          ,
          ,
          "7(?:1(?:0[0-38]|1[0-3679]|3[013]|69|9[0136])|2(?:[02389]\\d|1[18]|7[27-9])|3(?:[0-38]\\d|7[0-369]|9[2357-9])|47\\d|5(?:[178]\\d|5[0-5])|6(?:0[0-7]|2[236-9]|[35]\\d)|7(?:[27]\\d|8[7-9])|8(?:[23689]\\d|7[1-9])|9(?:[025]\\d|6[0-246-8]|7[0-36-9]|8[238]))\\d{4}",
          ,
          ,
          ,
          "71123456",
          ,
          ,
          [8],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "30(?:0[1-9]|[15-7]\\d|2[047]|89)\\d{4}",
          ,
          ,
          ,
          "30161234",
          ,
          ,
          [8],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      HN: [
        ,
        [, , "8\\d{10}|[237-9]\\d{7}", , , , , , , [8, 11]],
        [
          ,
          ,
          "2(?:2(?:0[0-59]|1[1-9]|[23]\\d|4[02-6]|5[57]|6[245]|7[0135689]|8[01346-9]|9[0-2])|4(?:0[578]|2[3-59]|3[13-9]|4[0-68]|5[1-3589])|5(?:0[2357-9]|1[1-356]|4[03-5]|5\\d|6[014-69]|7[04]|80)|6(?:[056]\\d|17|2[067]|3[047]|4[0-378]|[78][0-8]|9[01])|7(?:0[5-79]|6[46-9]|7[02-9]|8[034]|91)|8(?:79|8[0-357-9]|9[1-57-9]))\\d{4}",
          ,
          ,
          ,
          "22123456",
          ,
          ,
          [8],
        ],
        [, , "[37-9]\\d{7}", , , , "91234567", , , [8]],
        [, , "8002\\d{7}", , , , "80021234567", , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "HN",
        504,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{4})", "$1-$2", ["[237-9]"]],
          [, "(\\d{3})(\\d{4})(\\d{4})", "$1 $2 $3", ["8"]],
        ],
        [[, "(\\d{4})(\\d{4})", "$1-$2", ["[237-9]"]]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "8002\\d{7}", , , , , , , [11]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      HR: [
        ,
        [
          ,
          ,
          "(?:[24-69]\\d|3[0-79])\\d{7}|80\\d{5,7}|[1-79]\\d{7}|6\\d{5,6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9],
        ],
        [
          ,
          ,
          "1\\d{7}|(?:2[0-3]|3[1-5]|4[02-47-9]|5[1-3])\\d{6,7}",
          ,
          ,
          ,
          "12345678",
          ,
          ,
          [8, 9],
          [6, 7],
        ],
        [
          ,
          ,
          "9(?:(?:0[1-9]|[12589]\\d)\\d\\d|7(?:[0679]\\d\\d|5(?:[01]\\d|44|55|77|9[5-7])))\\d{4}|98\\d{6}",
          ,
          ,
          ,
          "921234567",
          ,
          ,
          [8, 9],
        ],
        [, , "80\\d{5,7}", , , , "800123456", , , [7, 8, 9]],
        [, , "6[01459]\\d{6}|6[01]\\d{4,5}", , , , "611234", , , [6, 7, 8]],
        [, , , , , , , , , [-1]],
        [, , "7[45]\\d{6}", , , , "74123456", , , [8]],
        [, , , , , , , , , [-1]],
        "HR",
        385,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{2,3})", "$1 $2 $3", ["6[01]"], "0$1"],
          [, "(\\d{3})(\\d{2})(\\d{2,3})", "$1 $2 $3", ["8"], "0$1"],
          [, "(\\d)(\\d{4})(\\d{3})", "$1 $2 $3", ["1"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["6|7[245]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["9"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[2-57]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "62\\d{6,7}|72\\d{6}", , , , "62123456", , , [8, 9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      HT: [
        ,
        [, , "(?:[2-489]\\d|55)\\d{6}", , , , , , , [8]],
        [, , "2(?:2\\d|5[1-5]|81|9[149])\\d{5}", , , , "22453300"],
        [, , "(?:[34]\\d|55)\\d{6}", , , , "34101234"],
        [, , "8\\d{7}", , , , "80012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "9(?:[67][0-4]|8[0-3589]|9\\d)\\d{5}", , , , "98901234"],
        "HT",
        509,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{4})", "$1 $2 $3", ["[2-589]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      HU: [
        ,
        [, , "[235-7]\\d{8}|[1-9]\\d{7}", , , , , , , [8, 9], [6, 7]],
        [
          ,
          ,
          "(?:1\\d|[27][2-9]|3[2-7]|4[24-9]|5[2-79]|6[23689]|8[2-57-9]|9[2-69])\\d{6}",
          ,
          ,
          ,
          "12345678",
          ,
          ,
          [8],
          [6, 7],
        ],
        [, , "(?:[257]0|3[01])\\d{7}", , , , "201234567", , , [9]],
        [, , "(?:[48]0\\d|680[29])\\d{5}", , , , "80123456"],
        [, , "9[01]\\d{6}", , , , "90123456", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "21\\d{7}", , , , "211234567", , , [9]],
        "HU",
        36,
        "00",
        "06",
        ,
        ,
        "06",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["1"], "(06 $1)"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            ["[27][2-9]|3[2-7]|4[24-9]|5[2-79]|6|8[2-57-9]|9[2-69]"],
            "(06 $1)",
          ],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[2-9]"], "06 $1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "(?:[48]0\\d|680[29])\\d{5}"],
        [, , "38\\d{7}", , , , "381234567", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ID: [
        ,
        [
          ,
          ,
          "(?:(?:00[1-9]|8\\d)\\d{4}|[1-36])\\d{6}|00\\d{10}|[1-9]\\d{8,10}|[2-9]\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 10, 11, 12, 13],
          [5, 6],
        ],
        [
          ,
          ,
          "2[124]\\d{7,8}|619\\d{8}|2(?:1(?:14|500)|2\\d{3})\\d{3}|61\\d{5,8}|(?:2(?:[35][1-4]|6[0-8]|7[1-6]|8\\d|9[1-8])|3(?:1|[25][1-8]|3[1-68]|4[1-3]|6[1-3568]|7[0-469]|8\\d)|4(?:0[1-589]|1[01347-9]|2[0-36-8]|3[0-24-68]|43|5[1-378]|6[1-5]|7[134]|8[1245])|5(?:1[1-35-9]|2[25-8]|3[124-9]|4[1-3589]|5[1-46]|6[1-8])|6(?:[25]\\d|3[1-69]|4[1-6])|7(?:02|[125][1-9]|[36]\\d|4[1-8]|7[0-36-9])|9(?:0[12]|1[013-8]|2[0-479]|5[125-8]|6[23679]|7[159]|8[01346]))\\d{5,8}",
          ,
          ,
          ,
          "218350123",
          ,
          ,
          [7, 8, 9, 10, 11],
          [5, 6],
        ],
        [, , "8[1-35-9]\\d{7,10}", , , , "812345678", , , [9, 10, 11, 12]],
        [
          ,
          ,
          "00[17]803\\d{7}|(?:177\\d|800)\\d{5,7}|001803\\d{6}",
          ,
          ,
          ,
          "8001234567",
          ,
          ,
          [8, 9, 10, 11, 12, 13],
        ],
        [, , "809\\d{7}", , , , "8091234567", , , [10]],
        [, , "804\\d{7}", , , , "8041234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "ID",
        62,
        "00[89]",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{3})", "$1 $2 $3", ["15"]],
          [, "(\\d{2})(\\d{5,9})", "$1 $2", ["2[124]|[36]1"], "(0$1)"],
          [, "(\\d{3})(\\d{5,7})", "$1 $2", ["800"], "0$1"],
          [, "(\\d{3})(\\d{5,8})", "$1 $2", ["[2-79]"], "(0$1)"],
          [, "(\\d{3})(\\d{3,4})(\\d{3})", "$1-$2-$3", ["8[1-35-9]"], "0$1"],
          [, "(\\d{3})(\\d{6,8})", "$1 $2", ["1"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["804"], "0$1"],
          [, "(\\d{3})(\\d)(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["80"], "0$1"],
          [, "(\\d{3})(\\d{4})(\\d{4,5})", "$1-$2-$3", ["8"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["001"]],
          [, "(\\d{2})(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3 $4", ["0"]],
        ],
        [
          [, "(\\d)(\\d{3})(\\d{3})", "$1 $2 $3", ["15"]],
          [, "(\\d{2})(\\d{5,9})", "$1 $2", ["2[124]|[36]1"], "(0$1)"],
          [, "(\\d{3})(\\d{5,7})", "$1 $2", ["800"], "0$1"],
          [, "(\\d{3})(\\d{5,8})", "$1 $2", ["[2-79]"], "(0$1)"],
          [, "(\\d{3})(\\d{3,4})(\\d{3})", "$1-$2-$3", ["8[1-35-9]"], "0$1"],
          [, "(\\d{3})(\\d{6,8})", "$1 $2", ["1"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["804"], "0$1"],
          [, "(\\d{3})(\\d)(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["80"], "0$1"],
          [, "(\\d{3})(\\d{4})(\\d{4,5})", "$1-$2-$3", ["8"], "0$1"],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [
          ,
          ,
          "001803\\d{6,7}|(?:007803\\d|8071)\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [10, 12, 13],
        ],
        [, , "(?:1500|8071\\d{3})\\d{3}", , , , "8071123456", , , [7, 10]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      IE: [
        ,
        [
          ,
          ,
          "(?:1\\d|[2569])\\d{6,8}|4\\d{6,9}|7\\d{8}|8\\d{8,9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 10],
          [5, 6],
        ],
        [
          ,
          ,
          "(?:1\\d|21)\\d{6,7}|(?:2[24-9]|4(?:0[24]|5\\d|7)|5(?:0[45]|1\\d|8)|6(?:1\\d|[237-9])|9(?:1\\d|[35-9]))\\d{5}|(?:23|4(?:[1-469]|8\\d)|5[23679]|6[4-6]|7[14]|9[04])\\d{7}",
          ,
          ,
          ,
          "2212345",
          ,
          ,
          ,
          [5, 6],
        ],
        [, , "8(?:22|[35-9]\\d)\\d{6}", , , , "850123456", , , [9]],
        [, , "1800\\d{6}", , , , "1800123456", , , [10]],
        [, , "15(?:1[2-8]|[2-8]0|9[089])\\d{6}", , , , "1520123456", , , [10]],
        [, , "18[59]0\\d{6}", , , , "1850123456", , , [10]],
        [, , "700\\d{6}", , , , "700123456", , , [9]],
        [, , "76\\d{7}", , , , "761234567", , , [9]],
        "IE",
        353,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{2})(\\d{5})",
            "$1 $2",
            ["2[24-9]|47|58|6[237-9]|9[35-9]"],
            "(0$1)",
          ],
          [, "(\\d{3})(\\d{5})", "$1 $2", ["[45]0"], "(0$1)"],
          [, "(\\d)(\\d{3,4})(\\d{4})", "$1 $2 $3", ["1"], "(0$1)"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["[2569]|4[1-69]|7[14]"],
            "(0$1)",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["70"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["81"], "(0$1)"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[78]"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1 $2 $3", ["1"]],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2 $3", ["4"], "(0$1)"],
          [, "(\\d{2})(\\d)(\\d{3})(\\d{4})", "$1 $2 $3 $4", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "18[59]0\\d{6}", , , , , , , [10]],
        [, , "818\\d{6}", , , , "818123456", , , [9]],
        ,
        ,
        [
          ,
          ,
          "88210[1-9]\\d{4}|8(?:[35-79]5\\d\\d|8(?:[013-9]\\d\\d|2(?:[01][1-9]|[2-9]\\d)))\\d{5}",
          ,
          ,
          ,
          "8551234567",
          ,
          ,
          [10],
        ],
      ],
      IL: [
        ,
        [
          ,
          ,
          "1\\d{6}(?:\\d{3,5})?|[57]\\d{8}|[1-489]\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 10, 11, 12],
        ],
        [
          ,
          ,
          "153\\d{8,9}|29[1-9]\\d{5}|(?:2[0-8]|[3489]\\d)\\d{6}",
          ,
          ,
          ,
          "21234567",
          ,
          ,
          [8, 11, 12],
          [7],
        ],
        [
          ,
          ,
          "55410\\d{4}|5(?:(?:[02][02-9]|[149][2-9]|[36]\\d|8[3-7])\\d|5(?:01|2\\d|3[0-3]|4[34]|5[0-25689]|6[6-8]|7[0-267]|8[7-9]|9[1-9]))\\d{5}",
          ,
          ,
          ,
          "502345678",
          ,
          ,
          [9],
        ],
        [, , "1(?:255|80[019]\\d{3})\\d{3}", , , , "1800123456", , , [7, 10]],
        [
          ,
          ,
          "1212\\d{4}|1(?:200|9(?:0[0-2]|19))\\d{6}",
          ,
          ,
          ,
          "1919123456",
          ,
          ,
          [8, 10],
        ],
        [, , "1700\\d{6}", , , , "1700123456", , , [10]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "7(?:38(?:0\\d|5[09]|88)|8(?:33|55|77|81)\\d)\\d{4}|7(?:18|2[23]|3[237]|47|6[258]|7\\d|82|9[2-9])\\d{6}",
          ,
          ,
          ,
          "771234567",
          ,
          ,
          [9],
        ],
        "IL",
        972,
        "0(?:0|1[2-9])",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{3})", "$1-$2", ["125"]],
          [, "(\\d{4})(\\d{2})(\\d{2})", "$1-$2-$3", ["121"]],
          [, "(\\d)(\\d{3})(\\d{4})", "$1-$2-$3", ["[2-489]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1-$2-$3", ["[57]"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1-$2-$3", ["12"]],
          [, "(\\d{4})(\\d{6})", "$1-$2", ["159"]],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{3})", "$1-$2-$3-$4", ["1[7-9]"]],
          [, "(\\d{3})(\\d{1,2})(\\d{3})(\\d{4})", "$1-$2 $3-$4", ["15"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "1700\\d{6}", , , , , , , [10]],
        [, , "1599\\d{6}", , , , "1599123456", , , [10]],
        ,
        ,
        [, , "151\\d{8,9}", , , , "15112340000", , , [11, 12]],
      ],
      IM: [
        ,
        [, , "1624\\d{6}|(?:[3578]\\d|90)\\d{8}", , , , , , , [10], [6]],
        [, , "1624(?:230|[5-8]\\d\\d)\\d{3}", , , , "1624756789", , , , [6]],
        [
          ,
          ,
          "76245[06]\\d{4}|7(?:4576|[59]24\\d|624[0-4689])\\d{5}",
          ,
          ,
          ,
          "7924123456",
        ],
        [, , "808162\\d{4}", , , , "8081624567"],
        [
          ,
          ,
          "8(?:440[49]06|72299\\d)\\d{3}|(?:8(?:45|70)|90[0167])624\\d{4}",
          ,
          ,
          ,
          "9016247890",
        ],
        [, , , , , , , , , [-1]],
        [, , "70\\d{8}", , , , "7012345678"],
        [, , "56\\d{8}", , , , "5612345678"],
        "IM",
        44,
        "00",
        "0",
        ,
        ,
        "([25-8]\\d{5})$|0",
        "1624$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "74576|(?:16|7[56])24",
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "3440[49]06\\d{3}|(?:3(?:08162|3\\d{4}|45624|7(?:0624|2299))|55\\d{4})\\d{4}",
          ,
          ,
          ,
          "5512345678",
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      IN: [
        ,
        [
          ,
          ,
          "(?:000800|[2-9]\\d\\d)\\d{7}|1\\d{7,12}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10, 11, 12, 13],
          [6, 7],
        ],
        [
          ,
          ,
          "2717(?:[2-7]\\d|95)\\d{4}|(?:271[0-689]|782[0-6])[2-7]\\d{5}|(?:170[24]|2(?:(?:[02][2-79]|90)\\d|80[13468])|(?:3(?:23|80)|683|79[1-7])\\d|4(?:20[24]|72[2-8])|552[1-7])\\d{6}|(?:11|33|4[04]|80)[2-7]\\d{7}|(?:342|674|788)(?:[0189][2-7]|[2-7]\\d)\\d{5}|(?:1(?:2[0-249]|3[0-25]|4[145]|[59][14]|6[014]|7[1257]|8[01346])|2(?:1[257]|3[013]|4[01]|5[0137]|6[0158]|78|8[1568]|9[14])|3(?:26|4[13]|5[34]|6[01489]|7[02-46]|8[159])|4(?:1[36]|2[1-47]|3[15]|5[12]|6[0-26-9]|7[014-9]|8[013-57]|9[014-7])|5(?:1[025]|22|[36][25]|4[28]|[578]1|9[15])|6(?:12|[2-47]1|5[17]|6[13]|80)|7(?:12|2[14]|3[134]|4[47]|5[15]|[67]1)|8(?:16|2[014]|3[126]|6[136]|7[078]|8[34]|91))[2-7]\\d{6}|(?:1(?:2[35-8]|3[346-9]|4[236-9]|[59][0235-9]|6[235-9]|7[34689]|8[257-9])|2(?:1[134689]|3[24-8]|4[2-8]|5[25689]|6[2-4679]|7[3-79]|8[2-479]|9[235-9])|3(?:01|1[79]|2[1245]|4[5-8]|5[125689]|6[235-7]|7[157-9]|8[2-46-8])|4(?:1[14578]|2[5689]|3[2-467]|5[4-7]|6[35]|73|8[2689]|9[2389])|5(?:[16][146-9]|2[14-8]|3[1346]|4[14-69]|5[46]|7[2-4]|8[2-8]|9[246])|6(?:1[1358]|2[2457]|3[2-4]|4[235-7]|5[2-689]|6[24578]|7[235689]|8[124-6])|7(?:1[013-9]|2[0235-9]|3[2679]|4[1-35689]|5[2-46-9]|[67][02-9]|8[013-7]|9[089])|8(?:1[1357-9]|2[235-8]|3[03-57-9]|4[0-24-9]|5\\d|6[2457-9]|7[1-6]|8[1256]|9[2-4]))\\d[2-7]\\d{5}",
          ,
          ,
          ,
          "7410410123",
          ,
          ,
          [10],
          [6, 7, 8],
        ],
        [
          ,
          ,
          "(?:61279|7(?:887[02-9]|9(?:313|79[07-9]))|8(?:079[04-9]|(?:84|91)7[02-8]))\\d{5}|(?:6(?:12|[2-47]1|5[17]|6[13]|80)[0189]|7(?:1(?:2[0189]|9[0-5])|2(?:[14][017-9]|8[0-59])|3(?:2[5-8]|[34][017-9]|9[016-9])|4(?:1[015-9]|[29][89]|39|8[389])|5(?:[15][017-9]|2[04-9]|9[7-9])|6(?:0[0-47]|1[0-257-9]|2[0-4]|3[19]|5[4589])|70[0289]|88[089]|97[02-8])|8(?:0(?:6[67]|7[02-8])|70[017-9]|84[01489]|91[0-289]))\\d{6}|(?:7(?:31|4[47])|8(?:16|2[014]|3[126]|6[136]|7[78]|83))(?:[0189]\\d|7[02-8])\\d{5}|(?:6(?:[09]\\d|1[04679]|2[03689]|3[05-9]|4[0489]|50|6[069]|7[07]|8[7-9])|7(?:0\\d|2[0235-79]|3[05-8]|40|5[0346-8]|6[6-9]|7[1-9]|8[0-79]|9[089])|8(?:0[01589]|1[0-57-9]|2[235-9]|3[03-57-9]|[45]\\d|6[02457-9]|7[1-69]|8[0-25-9]|9[02-9])|9\\d\\d)\\d{7}|(?:6(?:(?:1[1358]|2[2457]|3[2-4]|4[235-7]|5[2-689]|6[24578]|8[124-6])\\d|7(?:[235689]\\d|4[0189]))|7(?:1(?:[013-8]\\d|9[6-9])|28[6-8]|3(?:2[0-49]|9[2-5])|4(?:1[2-4]|[29][0-7]|3[0-8]|[56]\\d|8[0-24-7])|5(?:2[1-3]|9[0-6])|6(?:0[5689]|2[5-9]|3[02-8]|4\\d|5[0-367])|70[13-7]|881))[0189]\\d{5}",
          ,
          ,
          ,
          "8123456789",
          ,
          ,
          [10],
        ],
        [
          ,
          ,
          "000800\\d{7}|1(?:600\\d{6}|80(?:0\\d{4,9}|3\\d{9}))",
          ,
          ,
          ,
          "1800123456",
        ],
        [, , "186[12]\\d{9}", , , , "1861123456789", , , [13]],
        [, , "1860\\d{7}", , , , "18603451234", , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "IN",
        91,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{7})", "$1", ["575"]],
          [
            ,
            "(\\d{8})",
            "$1",
            [
              "5(?:0|2[23]|3[03]|[67]1|88)",
              "5(?:0|2(?:21|3)|3(?:0|3[23])|616|717|888)",
              "5(?:0|2(?:21|3)|3(?:0|3[23])|616|717|8888)",
            ],
            ,
            ,
            1,
          ],
          [, "(\\d{4})(\\d{4,5})", "$1 $2", ["180", "1800"], , , 1],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["140"], , , 1],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1 $2 $3",
            [
              "11|2[02]|33|4[04]|79[1-7]|80[2-46]",
              "11|2[02]|33|4[04]|79(?:[1-6]|7[19])|80(?:[2-4]|6[0-589])",
              "11|2[02]|33|4[04]|79(?:[124-6]|3(?:[02-9]|1[0-24-9])|7(?:1|9[1-6]))|80(?:[2-4]|6[0-589])",
            ],
            "0$1",
            ,
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            [
              "1(?:2[0-249]|3[0-25]|4[145]|[68]|7[1257])|2(?:1[257]|3[013]|4[01]|5[0137]|6[0158]|78|8[1568])|3(?:26|4[1-3]|5[34]|6[01489]|7[02-46]|8[159])|4(?:1[36]|2[1-47]|5[12]|6[0-26-9]|7[0-24-9]|8[013-57]|9[014-7])|5(?:1[025]|22|[36][25]|4[28]|5[12]|[78]1)|6(?:12|[2-4]1|5[17]|6[13]|80)|7(?:12|3[134]|4[47]|61|88)|8(?:16|2[014]|3[126]|6[136]|7[078]|8[34]|91)|(?:43|59|75)[15]|(?:1[59]|29|67|72)[14]",
              "1(?:2[0-24]|3[0-25]|4[145]|[59][14]|6[1-9]|7[1257]|8[1-57-9])|2(?:1[257]|3[013]|4[01]|5[0137]|6[058]|78|8[1568]|9[14])|3(?:26|4[1-3]|5[34]|6[01489]|7[02-46]|8[159])|4(?:1[36]|2[1-47]|3[15]|5[12]|6[0-26-9]|7[0-24-9]|8[013-57]|9[014-7])|5(?:1[025]|22|[36][25]|4[28]|[578]1|9[15])|674|7(?:(?:2[14]|3[34]|5[15])[2-6]|61[346]|88[0-8])|8(?:70[2-6]|84[235-7]|91[3-7])|(?:1(?:29|60|8[06])|261|552|6(?:12|[2-47]1|5[17]|6[13]|80)|7(?:12|31|4[47])|8(?:16|2[014]|3[126]|6[136]|7[78]|83))[2-7]",
              "1(?:2[0-24]|3[0-25]|4[145]|[59][14]|6[1-9]|7[1257]|8[1-57-9])|2(?:1[257]|3[013]|4[01]|5[0137]|6[058]|78|8[1568]|9[14])|3(?:26|4[1-3]|5[34]|6[01489]|7[02-46]|8[159])|4(?:1[36]|2[1-47]|3[15]|5[12]|6[0-26-9]|7[0-24-9]|8[013-57]|9[014-7])|5(?:1[025]|22|[36][25]|4[28]|[578]1|9[15])|6(?:12(?:[2-6]|7[0-8])|74[2-7])|7(?:(?:2[14]|5[15])[2-6]|3171|61[346]|88(?:[2-7]|82))|8(?:70[2-6]|84(?:[2356]|7[19])|91(?:[3-6]|7[19]))|73[134][2-6]|(?:74[47]|8(?:16|2[014]|3[126]|6[136]|7[78]|83))(?:[2-6]|7[19])|(?:1(?:29|60|8[06])|261|552|6(?:[2-4]1|5[17]|6[13]|7(?:1|4[0189])|80)|7(?:12|88[01]))[2-7]",
            ],
            "0$1",
            ,
            1,
          ],
          [
            ,
            "(\\d{4})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            [
              "1(?:[2-479]|5[0235-9])|[2-5]|6(?:1[1358]|2[2457-9]|3[2-5]|4[235-7]|5[2-689]|6[24578]|7[235689]|8[1-6])|7(?:1[013-9]|28|3[129]|4[1-35689]|5[29]|6[02-5]|70)|807",
              "1(?:[2-479]|5[0235-9])|[2-5]|6(?:1[1358]|2(?:[2457]|84|95)|3(?:[2-4]|55)|4[235-7]|5[2-689]|6[24578]|7[235689]|8[1-6])|7(?:1(?:[013-8]|9[6-9])|28[6-8]|3(?:17|2[0-49]|9[2-57])|4(?:1[2-4]|[29][0-7]|3[0-8]|[56]|8[0-24-7])|5(?:2[1-3]|9[0-6])|6(?:0[5689]|2[5-9]|3[02-8]|4|5[0-367])|70[13-7])|807[19]",
              "1(?:[2-479]|5(?:[0236-9]|5[013-9]))|[2-5]|6(?:2(?:84|95)|355|83)|73179|807(?:1|9[1-3])|(?:1552|6(?:1[1358]|2[2457]|3[2-4]|4[235-7]|5[2-689]|6[24578]|7[235689]|8[124-6])\\d|7(?:1(?:[013-8]\\d|9[6-9])|28[6-8]|3(?:2[0-49]|9[2-57])|4(?:1[2-4]|[29][0-7]|3[0-8]|[56]\\d|8[0-24-7])|5(?:2[1-3]|9[0-6])|6(?:0[5689]|2[5-9]|3[02-8]|4\\d|5[0-367])|70[13-7]))[2-7]",
            ],
            "0$1",
            ,
            1,
          ],
          [, "(\\d{5})(\\d{5})", "$1 $2", ["[6-9]"], "0$1", , 1],
          [
            ,
            "(\\d{4})(\\d{2,4})(\\d{4})",
            "$1 $2 $3",
            ["1(?:6|8[06])", "1(?:6|8[06]0)"],
            ,
            ,
            1,
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3 $4", ["0"]],
          [, "(\\d{4})(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["18"], , , 1],
        ],
        [
          [
            ,
            "(\\d{8})",
            "$1",
            [
              "5(?:0|2[23]|3[03]|[67]1|88)",
              "5(?:0|2(?:21|3)|3(?:0|3[23])|616|717|888)",
              "5(?:0|2(?:21|3)|3(?:0|3[23])|616|717|8888)",
            ],
            ,
            ,
            1,
          ],
          [, "(\\d{4})(\\d{4,5})", "$1 $2", ["180", "1800"], , , 1],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["140"], , , 1],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1 $2 $3",
            [
              "11|2[02]|33|4[04]|79[1-7]|80[2-46]",
              "11|2[02]|33|4[04]|79(?:[1-6]|7[19])|80(?:[2-4]|6[0-589])",
              "11|2[02]|33|4[04]|79(?:[124-6]|3(?:[02-9]|1[0-24-9])|7(?:1|9[1-6]))|80(?:[2-4]|6[0-589])",
            ],
            "0$1",
            ,
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            [
              "1(?:2[0-249]|3[0-25]|4[145]|[68]|7[1257])|2(?:1[257]|3[013]|4[01]|5[0137]|6[0158]|78|8[1568])|3(?:26|4[1-3]|5[34]|6[01489]|7[02-46]|8[159])|4(?:1[36]|2[1-47]|5[12]|6[0-26-9]|7[0-24-9]|8[013-57]|9[014-7])|5(?:1[025]|22|[36][25]|4[28]|5[12]|[78]1)|6(?:12|[2-4]1|5[17]|6[13]|80)|7(?:12|3[134]|4[47]|61|88)|8(?:16|2[014]|3[126]|6[136]|7[078]|8[34]|91)|(?:43|59|75)[15]|(?:1[59]|29|67|72)[14]",
              "1(?:2[0-24]|3[0-25]|4[145]|[59][14]|6[1-9]|7[1257]|8[1-57-9])|2(?:1[257]|3[013]|4[01]|5[0137]|6[058]|78|8[1568]|9[14])|3(?:26|4[1-3]|5[34]|6[01489]|7[02-46]|8[159])|4(?:1[36]|2[1-47]|3[15]|5[12]|6[0-26-9]|7[0-24-9]|8[013-57]|9[014-7])|5(?:1[025]|22|[36][25]|4[28]|[578]1|9[15])|674|7(?:(?:2[14]|3[34]|5[15])[2-6]|61[346]|88[0-8])|8(?:70[2-6]|84[235-7]|91[3-7])|(?:1(?:29|60|8[06])|261|552|6(?:12|[2-47]1|5[17]|6[13]|80)|7(?:12|31|4[47])|8(?:16|2[014]|3[126]|6[136]|7[78]|83))[2-7]",
              "1(?:2[0-24]|3[0-25]|4[145]|[59][14]|6[1-9]|7[1257]|8[1-57-9])|2(?:1[257]|3[013]|4[01]|5[0137]|6[058]|78|8[1568]|9[14])|3(?:26|4[1-3]|5[34]|6[01489]|7[02-46]|8[159])|4(?:1[36]|2[1-47]|3[15]|5[12]|6[0-26-9]|7[0-24-9]|8[013-57]|9[014-7])|5(?:1[025]|22|[36][25]|4[28]|[578]1|9[15])|6(?:12(?:[2-6]|7[0-8])|74[2-7])|7(?:(?:2[14]|5[15])[2-6]|3171|61[346]|88(?:[2-7]|82))|8(?:70[2-6]|84(?:[2356]|7[19])|91(?:[3-6]|7[19]))|73[134][2-6]|(?:74[47]|8(?:16|2[014]|3[126]|6[136]|7[78]|83))(?:[2-6]|7[19])|(?:1(?:29|60|8[06])|261|552|6(?:[2-4]1|5[17]|6[13]|7(?:1|4[0189])|80)|7(?:12|88[01]))[2-7]",
            ],
            "0$1",
            ,
            1,
          ],
          [
            ,
            "(\\d{4})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            [
              "1(?:[2-479]|5[0235-9])|[2-5]|6(?:1[1358]|2[2457-9]|3[2-5]|4[235-7]|5[2-689]|6[24578]|7[235689]|8[1-6])|7(?:1[013-9]|28|3[129]|4[1-35689]|5[29]|6[02-5]|70)|807",
              "1(?:[2-479]|5[0235-9])|[2-5]|6(?:1[1358]|2(?:[2457]|84|95)|3(?:[2-4]|55)|4[235-7]|5[2-689]|6[24578]|7[235689]|8[1-6])|7(?:1(?:[013-8]|9[6-9])|28[6-8]|3(?:17|2[0-49]|9[2-57])|4(?:1[2-4]|[29][0-7]|3[0-8]|[56]|8[0-24-7])|5(?:2[1-3]|9[0-6])|6(?:0[5689]|2[5-9]|3[02-8]|4|5[0-367])|70[13-7])|807[19]",
              "1(?:[2-479]|5(?:[0236-9]|5[013-9]))|[2-5]|6(?:2(?:84|95)|355|83)|73179|807(?:1|9[1-3])|(?:1552|6(?:1[1358]|2[2457]|3[2-4]|4[235-7]|5[2-689]|6[24578]|7[235689]|8[124-6])\\d|7(?:1(?:[013-8]\\d|9[6-9])|28[6-8]|3(?:2[0-49]|9[2-57])|4(?:1[2-4]|[29][0-7]|3[0-8]|[56]\\d|8[0-24-7])|5(?:2[1-3]|9[0-6])|6(?:0[5689]|2[5-9]|3[02-8]|4\\d|5[0-367])|70[13-7]))[2-7]",
            ],
            "0$1",
            ,
            1,
          ],
          [, "(\\d{5})(\\d{5})", "$1 $2", ["[6-9]"], "0$1", , 1],
          [
            ,
            "(\\d{4})(\\d{2,4})(\\d{4})",
            "$1 $2 $3",
            ["1(?:6|8[06])", "1(?:6|8[06]0)"],
            ,
            ,
            1,
          ],
          [, "(\\d{4})(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["18"], , , 1],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [
          ,
          ,
          "1(?:600\\d{6}|800\\d{4,9})|(?:000800|18(?:03\\d\\d|6(?:0|[12]\\d\\d)))\\d{7}",
        ],
        [, , "140\\d{7}", , , , "1409305260", , , [10]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      IO: [
        ,
        [, , "3\\d{6}", , , , , , , [7]],
        [, , "37\\d{5}", , , , "3709100"],
        [, , "38\\d{5}", , , , "3801234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "IO",
        246,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["3"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      IQ: [
        ,
        [
          ,
          ,
          "(?:1|7\\d\\d)\\d{7}|[2-6]\\d{7,8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10],
          [6, 7],
        ],
        [
          ,
          ,
          "1\\d{7}|(?:2[13-5]|3[02367]|4[023]|5[03]|6[026])\\d{6,7}",
          ,
          ,
          ,
          "12345678",
          ,
          ,
          [8, 9],
          [6, 7],
        ],
        [, , "7[3-9]\\d{8}", , , , "7912345678", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "IQ",
        964,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["1"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[2-6]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["7"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      IR: [
        ,
        [
          ,
          ,
          "[1-9]\\d{9}|(?:[1-8]\\d\\d|9)\\d{3,4}",
          ,
          ,
          ,
          ,
          ,
          ,
          [4, 5, 6, 7, 10],
          [8],
        ],
        [
          ,
          ,
          "(?:1[137]|2[13-68]|3[1458]|4[145]|5[1468]|6[16]|7[1467]|8[13467])(?:[03-57]\\d{7}|[16]\\d{3}(?:\\d{4})?|[289]\\d{3}(?:\\d(?:\\d{3})?)?)|94(?:000[09]|(?:12\\d|30[0-2])\\d|2(?:121|[2689]0\\d)|4(?:111|40\\d))\\d{4}",
          ,
          ,
          ,
          "2123456789",
          ,
          ,
          [6, 7, 10],
          [4, 5, 8],
        ],
        [
          ,
          ,
          "9(?:(?:0(?:[0-35]\\d|4[4-6])|(?:[13]\\d|2[0-3])\\d)\\d|9(?:[0-46]\\d\\d|5[15]0|8(?:[12]\\d|88)|9(?:0[0-3]|[19]\\d|21|69|77|8[7-9])))\\d{5}",
          ,
          ,
          ,
          "9123456789",
          ,
          ,
          [10],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "IR",
        98,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{4,5})", "$1", ["96"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{4,5})",
            "$1 $2",
            [
              "(?:1[137]|2[13-68]|3[1458]|4[145]|5[1468]|6[16]|7[1467]|8[13467])[12689]",
            ],
            "0$1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["9"], "0$1"],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2 $3", ["[1-8]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [
          ,
          ,
          "9(?:4440\\d{5}|6(?:0[12]|2[16-8]|3(?:08|[14]5|[23]|66)|4(?:0|80)|5[01]|6[89]|86|9[19]))",
          ,
          ,
          ,
          ,
          ,
          ,
          [4, 5, 10],
        ],
        [
          ,
          ,
          "96(?:0[12]|2[16-8]|3(?:08|[14]5|[23]|66)|4(?:0|80)|5[01]|6[89]|86|9[19])",
          ,
          ,
          ,
          "9601",
          ,
          ,
          [4, 5],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      IS: [
        ,
        [, , "(?:38\\d|[4-9])\\d{6}", , , , , , , [7, 9]],
        [
          ,
          ,
          "(?:4(?:1[0-24-69]|2[0-7]|[37][0-8]|4[0-24589]|5[0-68]|6\\d|8[0-36-8])|5(?:05|[156]\\d|2[02578]|3[0-579]|4[03-7]|7[0-2578]|8[0-35-9]|9[013-689])|872)\\d{4}",
          ,
          ,
          ,
          "4101234",
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "(?:38[589]\\d\\d|6(?:1[1-8]|2[0-6]|3[026-9]|4[014679]|5[0159]|6[0-69]|70|8[06-8]|9\\d)|7(?:5[057]|[6-9]\\d)|8(?:2[0-59]|[3-69]\\d|8[238]))\\d{4}",
          ,
          ,
          ,
          "6111234",
        ],
        [, , "80[0-8]\\d{4}", , , , "8001234", , , [7]],
        [
          ,
          ,
          "90(?:0\\d|1[5-79]|2[015-79]|3[135-79]|4[125-7]|5[25-79]|7[1-37]|8[0-35-7])\\d{3}",
          ,
          ,
          ,
          "9001234",
          ,
          ,
          [7],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "49[0-24-79]\\d{4}", , , , "4921234", , , [7]],
        "IS",
        354,
        "00|1(?:0(?:01|[12]0)|100)",
        ,
        ,
        ,
        ,
        ,
        "00",
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[4-9]"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["3"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "809\\d{4}", , , , "8091234", , , [7]],
        ,
        ,
        [, , "(?:689|8(?:7[18]|80)|95[48])\\d{4}", , , , "6891234", , , [7]],
      ],
      IT: [
        ,
        [
          ,
          ,
          "0\\d{5,10}|1\\d{8,10}|3(?:[0-8]\\d{7,10}|9\\d{7,8})|(?:43|55|70)\\d{8}|8\\d{5}(?:\\d{2,4})?",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 11, 12],
        ],
        [
          ,
          ,
          "0669[0-79]\\d{1,6}|0(?:1(?:[0159]\\d|[27][1-5]|31|4[1-4]|6[1356]|8[2-57])|2\\d\\d|3(?:[0159]\\d|2[1-4]|3[12]|[48][1-6]|6[2-59]|7[1-7])|4(?:[0159]\\d|[23][1-9]|4[245]|6[1-5]|7[1-4]|81)|5(?:[0159]\\d|2[1-5]|3[2-6]|4[1-79]|6[4-6]|7[1-578]|8[3-8])|6(?:[0-57-9]\\d|6[0-8])|7(?:[0159]\\d|2[12]|3[1-7]|4[2-46]|6[13569]|7[13-6]|8[1-59])|8(?:[0159]\\d|2[3-578]|3[1-356]|[6-8][1-5])|9(?:[0159]\\d|[238][1-5]|4[12]|6[1-8]|7[1-6]))\\d{2,7}",
          ,
          ,
          ,
          "0212345678",
          ,
          ,
          [6, 7, 8, 9, 10, 11],
        ],
        [, , "3[2-9]\\d{7,8}|(?:31|43)\\d{8}", , , , "3123456789", , , [9, 10]],
        [, , "80(?:0\\d{3}|3)\\d{3}", , , , "800123456", , , [6, 9]],
        [
          ,
          ,
          "(?:0878\\d{3}|89(?:2\\d|3[04]|4(?:[0-4]|[5-9]\\d\\d)|5[0-4]))\\d\\d|(?:1(?:44|6[346])|89(?:38|5[5-9]|9))\\d{6}",
          ,
          ,
          ,
          "899123456",
          ,
          ,
          [6, 8, 9, 10],
        ],
        [, , "84(?:[08]\\d{3}|[17])\\d{3}", , , , "848123456", , , [6, 9]],
        [, , "1(?:78\\d|99)\\d{6}", , , , "1781234567", , , [9, 10]],
        [, , "55\\d{8}", , , , "5512345678", , , [10]],
        "IT",
        39,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{4,5})", "$1", ["1(?:0|9[246])", "1(?:0|9(?:2[2-9]|[46]))"]],
          [, "(\\d{6})", "$1", ["1(?:1|92)"]],
          [, "(\\d{2})(\\d{4,6})", "$1 $2", ["0[26]"]],
          [
            ,
            "(\\d{3})(\\d{3,6})",
            "$1 $2",
            [
              "0[13-57-9][0159]|8(?:03|4[17]|9[2-5])",
              "0[13-57-9][0159]|8(?:03|4[17]|9(?:2|3[04]|[45][0-4]))",
            ],
          ],
          [, "(\\d{4})(\\d{2,6})", "$1 $2", ["0(?:[13-579][2-46-8]|8[236-8])"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["894"]],
          [, "(\\d{2})(\\d{3,4})(\\d{4})", "$1 $2 $3", ["0[26]|5"]],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["1(?:44|[679])|[378]|43"],
          ],
          [, "(\\d{3})(\\d{3,4})(\\d{4})", "$1 $2 $3", ["0[13-57-9][0159]|14"]],
          [, "(\\d{2})(\\d{4})(\\d{5})", "$1 $2 $3", ["0[26]"]],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["0"]],
          [, "(\\d{3})(\\d{4})(\\d{4,5})", "$1 $2 $3", ["3"]],
        ],
        [
          [, "(\\d{2})(\\d{4,6})", "$1 $2", ["0[26]"]],
          [
            ,
            "(\\d{3})(\\d{3,6})",
            "$1 $2",
            [
              "0[13-57-9][0159]|8(?:03|4[17]|9[2-5])",
              "0[13-57-9][0159]|8(?:03|4[17]|9(?:2|3[04]|[45][0-4]))",
            ],
          ],
          [, "(\\d{4})(\\d{2,6})", "$1 $2", ["0(?:[13-579][2-46-8]|8[236-8])"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["894"]],
          [, "(\\d{2})(\\d{3,4})(\\d{4})", "$1 $2 $3", ["0[26]|5"]],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["1(?:44|[679])|[378]|43"],
          ],
          [, "(\\d{3})(\\d{3,4})(\\d{4})", "$1 $2 $3", ["0[13-57-9][0159]|14"]],
          [, "(\\d{2})(\\d{4})(\\d{5})", "$1 $2 $3", ["0[26]"]],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["0"]],
          [, "(\\d{3})(\\d{4})(\\d{4,5})", "$1 $2 $3", ["3"]],
        ],
        [, , , , , , , , , [-1]],
        1,
        ,
        [, , "848\\d{6}", , , , , , , [9]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "3[2-8]\\d{9,10}", , , , "33101234501", , , [11, 12]],
      ],
      JE: [
        ,
        [, , "1534\\d{6}|(?:[3578]\\d|90)\\d{8}", , , , , , , [10], [6]],
        [, , "1534[0-24-8]\\d{5}", , , , "1534456789", , , , [6]],
        [
          ,
          ,
          "7(?:(?:(?:50|82)9|937)\\d|7(?:00[378]|97\\d))\\d{5}",
          ,
          ,
          ,
          "7797712345",
        ],
        [, , "80(?:07(?:35|81)|8901)\\d{4}", , , , "8007354567"],
        [
          ,
          ,
          "(?:8(?:4(?:4(?:4(?:05|42|69)|703)|5(?:041|800))|7(?:0002|1206))|90(?:066[59]|1810|71(?:07|55)))\\d{4}",
          ,
          ,
          ,
          "9018105678",
        ],
        [, , , , , , , , , [-1]],
        [, , "701511\\d{4}", , , , "7015115678"],
        [, , "56\\d{8}", , , , "5612345678"],
        "JE",
        44,
        "00",
        "0",
        ,
        ,
        "([0-24-8]\\d{5})$|0",
        "1534$1",
        ,
        ,
        ,
        ,
        [
          ,
          ,
          "76(?:464|652)\\d{5}|76(?:0[0-28]|2[356]|34|4[01347]|5[49]|6[0-369]|77|8[14]|9[139])\\d{6}",
          ,
          ,
          ,
          "7640123456",
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "(?:3(?:0(?:07(?:35|81)|8901)|3\\d{4}|4(?:4(?:4(?:05|42|69)|703)|5(?:041|800))|7(?:0002|1206))|55\\d{4})\\d{4}",
          ,
          ,
          ,
          "5512345678",
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      JM: [
        ,
        [, , "(?:[58]\\d\\d|658|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "8766060\\d{3}|(?:658(?:2(?:[0-8]\\d|9[0-46-9])|[3-9]\\d\\d)|876(?:52[35]|6(?:0[1-3579]|1[0235-9]|[23]\\d|40|5[06]|6[2-589]|7[0-25-9]|8[04]|9[4-9])|7(?:0[2-689]|[1-6]\\d|8[056]|9[45])|9(?:0[1-8]|1[02378]|[2-8]\\d|9[2-468])))\\d{4}",
          ,
          ,
          ,
          "8765230123",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "(?:658295|876(?:2(?:0[1-9]|[13-9]\\d|2[013-9])|[348]\\d\\d|5(?:0[1-9]|[1-9]\\d)|6(?:4[89]|6[67])|7(?:0[07]|7\\d|8[1-47-9]|9[0-36-9])|9(?:[01]9|9[0579])))\\d{4}",
          ,
          ,
          ,
          "8762101234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "JM",
        1,
        "011",
        "1",
        ,
        ,
        "1",
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "658|876",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      JO: [
        ,
        [, , "(?:(?:[2689]|7\\d)\\d|32|53)\\d{6}", , , , , , , [8, 9]],
        [
          ,
          ,
          "87(?:000|90[01])\\d{3}|(?:2(?:6(?:2[0-35-9]|3[0-578]|4[24-7]|5[0-24-8]|[6-8][023]|9[0-3])|7(?:0[1-79]|10|2[014-7]|3[0-689]|4[019]|5[0-3578]))|32(?:0[1-69]|1[1-35-7]|2[024-7]|3\\d|4[0-3]|[5-7][023])|53(?:0[0-3]|[13][023]|2[0-59]|49|5[0-35-9]|6[15]|7[45]|8[1-6]|9[0-36-9])|6(?:2(?:[05]0|22)|3(?:00|33)|4(?:0[0-25]|1[2-7]|2[0569]|[38][07-9]|4[025689]|6[0-589]|7\\d|9[0-2])|5(?:[01][056]|2[034]|3[0-57-9]|4[178]|5[0-69]|6[0-35-9]|7[1-379]|8[0-68]|9[0239]))|87(?:20|7[078]|99))\\d{4}",
          ,
          ,
          ,
          "62001234",
          ,
          ,
          [8],
        ],
        [, , "7(?:[78][0-25-9]|9\\d)\\d{6}", , , , "790123456", , , [9]],
        [, , "80\\d{6}", , , , "80012345", , , [8]],
        [, , "9\\d{7}", , , , "90012345", , , [8]],
        [, , "85\\d{6}", , , , "85012345", , , [8]],
        [, , "70\\d{7}", , , , "700123456", , , [9]],
        [, , , , , , , , , [-1]],
        "JO",
        962,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["[2356]|87"], "(0$1)"],
          [, "(\\d{3})(\\d{5,6})", "$1 $2", ["[89]"], "0$1"],
          [, "(\\d{2})(\\d{7})", "$1 $2", ["70"], "0$1"],
          [, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["7"], "0$1"],
        ],
        ,
        [, , "74(?:66|77)\\d{5}", , , , "746612345", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "8(?:10|8\\d)\\d{5}", , , , "88101234", , , [8]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      JP: [
        ,
        [
          ,
          ,
          "00[1-9]\\d{6,14}|[257-9]\\d{9}|(?:00|[1-9]\\d\\d)\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        ],
        [
          ,
          ,
          "(?:1(?:1[235-8]|2[3-6]|3[3-9]|4[2-6]|[58][2-8]|6[2-7]|7[2-9]|9[1-9])|(?:2[2-9]|[36][1-9])\\d|4(?:[2-578]\\d|6[02-8]|9[2-59])|5(?:[2-589]\\d|6[1-9]|7[2-8])|7(?:[25-9]\\d|3[4-9]|4[02-9])|8(?:[2679]\\d|3[2-9]|4[5-9]|5[1-9]|8[03-9])|9(?:[2-58]\\d|[679][1-9]))\\d{6}",
          ,
          ,
          ,
          "312345678",
          ,
          ,
          [9],
        ],
        [, , "[7-9]0[1-9]\\d{7}", , , , "9012345678", , , [10]],
        [
          ,
          ,
          "00777(?:[01]|5\\d)\\d\\d|(?:00(?:7778|882[1245])|(?:120|800\\d)\\d\\d)\\d{4}|00(?:37|66|78)\\d{6,13}",
          ,
          ,
          ,
          "120123456",
        ],
        [, , "990\\d{6}", , , , "990123456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , "60\\d{7}", , , , "601234567", , , [9]],
        [, , "50[1-9]\\d{7}", , , , "5012345678", , , [10]],
        "JP",
        81,
        "010",
        "0",
        ,
        ,
        "(000[259]\\d{6})$|(?:(?:003768)0?)|0",
        "$1",
        ,
        ,
        [
          [
            ,
            "(\\d{4})(\\d{4})",
            "$1-$2",
            ["007", "0077", "00777", "00777[01]"],
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1-$2-$3", ["(?:12|57|99)0"], "0$1"],
          [
            ,
            "(\\d{4})(\\d)(\\d{4})",
            "$1-$2-$3",
            [
              "1(?:26|3[79]|4[56]|5[4-68]|6[3-5])|499|5(?:76|97)|746|8(?:3[89]|47|51)|9(?:80|9[16])",
              "1(?:267|3(?:7[247]|9[278])|466|5(?:47|58|64)|6(?:3[245]|48|5[4-68]))|499[2468]|5(?:76|97)9|7468|8(?:3(?:8[7-9]|96)|477|51[2-9])|9(?:802|9(?:1[23]|69))|1(?:45|58)[67]",
              "1(?:267|3(?:7[247]|9[278])|466|5(?:47|58|64)|6(?:3[245]|48|5[4-68]))|499[2468]|5(?:769|979[2-69])|7468|8(?:3(?:8[7-9]|96[2457-9])|477|51[2-9])|9(?:802|9(?:1[23]|69))|1(?:45|58)[67]",
            ],
            "0$1",
          ],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1-$2-$3", ["60"], "0$1"],
          [
            ,
            "(\\d)(\\d{4})(\\d{4})",
            "$1-$2-$3",
            [
              "[36]|4(?:2[09]|7[01])",
              "[36]|4(?:2(?:0|9[02-69])|7(?:0[019]|1))",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{4})",
            "$1-$2-$3",
            [
              "1(?:1|5[45]|77|88|9[69])|2(?:2[1-37]|3[0-269]|4[59]|5|6[24]|7[1-358]|8[1369]|9[0-38])|4(?:[28][1-9]|3[0-57]|[45]|6[248]|7[2-579]|9[29])|5(?:2|3[0459]|4[0-369]|5[29]|8[02389]|9[0-389])|7(?:2[02-46-9]|34|[58]|6[0249]|7[57]|9[2-6])|8(?:2[124589]|3[26-9]|49|51|6|7[0-468]|8[68]|9[019])|9(?:[23][1-9]|4[15]|5[138]|6[1-3]|7[156]|8[189]|9[1-489])",
              "1(?:1|5(?:4[018]|5[017])|77|88|9[69])|2(?:2(?:[127]|3[014-9])|3[0-269]|4[59]|5(?:[1-3]|5[0-69]|9[19])|62|7(?:[1-35]|8[0189])|8(?:[16]|3[0134]|9[0-5])|9(?:[028]|17))|4(?:2(?:[13-79]|8[014-6])|3[0-57]|[45]|6[248]|7[2-47]|8[1-9]|9[29])|5(?:2|3(?:[045]|9[0-8])|4[0-369]|5[29]|8[02389]|9[0-3])|7(?:2[02-46-9]|34|[58]|6[0249]|7[57]|9(?:[23]|4[0-59]|5[01569]|6[0167]))|8(?:2(?:[1258]|4[0-39]|9[0-2469])|3(?:[29]|60)|49|51|6(?:[0-24]|36|5[0-3589]|7[23]|9[01459])|7[0-468]|8[68])|9(?:[23][1-9]|4[15]|5[138]|6[1-3]|7[156]|8[189]|9(?:[1289]|3[34]|4[0178]))|(?:264|837)[016-9]|2(?:57|93)[015-9]|(?:25[0468]|422|838)[01]|(?:47[59]|59[89]|8(?:6[68]|9))[019]",
              "1(?:1|5(?:4[018]|5[017])|77|88|9[69])|2(?:2[127]|3[0-269]|4[59]|5(?:[1-3]|5[0-69]|9(?:17|99))|6(?:2|4[016-9])|7(?:[1-35]|8[0189])|8(?:[16]|3[0134]|9[0-5])|9(?:[028]|17))|4(?:2(?:[13-79]|8[014-6])|3[0-57]|[45]|6[248]|7[2-47]|9[29])|5(?:2|3(?:[045]|9(?:[0-58]|6[4-9]|7[0-35689]))|4[0-369]|5[29]|8[02389]|9[0-3])|7(?:2[02-46-9]|34|[58]|6[0249]|7[57]|9(?:[23]|4[0-59]|5[01569]|6[0167]))|8(?:2(?:[1258]|4[0-39]|9[0169])|3(?:[29]|60|7(?:[017-9]|6[6-8]))|49|51|6(?:[0-24]|36[2-57-9]|5(?:[0-389]|5[23])|6(?:[01]|9[178])|7(?:2[2-468]|3[78])|9[0145])|7[0-468]|8[68])|9(?:4[15]|5[138]|7[156]|8[189]|9(?:[1289]|3(?:31|4[357])|4[0178]))|(?:8294|96)[1-3]|2(?:57|93)[015-9]|(?:223|8699)[014-9]|(?:25[0468]|422|838)[01]|(?:48|8292|9[23])[1-9]|(?:47[59]|59[89]|8(?:68|9))[019]",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{4})",
            "$1-$2-$3",
            ["[14]|[289][2-9]|5[3-9]|7[2-4679]"],
            "0$1",
          ],
          [, "(\\d{4})(\\d{2})(\\d{3,4})", "$1-$2-$3", ["007", "0077"]],
          [, "(\\d{4})(\\d{2})(\\d{4})", "$1-$2-$3", ["008"]],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1-$2-$3", ["800"], "0$1"],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1-$2-$3", ["[257-9]"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{3,4})", "$1-$2-$3", ["0"]],
          [, "(\\d{4})(\\d{4})(\\d{4,5})", "$1-$2-$3", ["0"]],
          [, "(\\d{4})(\\d{5})(\\d{5,6})", "$1-$2-$3", ["0"]],
          [, "(\\d{4})(\\d{6})(\\d{6,7})", "$1-$2-$3", ["0"]],
        ],
        [
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1-$2-$3", ["(?:12|57|99)0"], "0$1"],
          [
            ,
            "(\\d{4})(\\d)(\\d{4})",
            "$1-$2-$3",
            [
              "1(?:26|3[79]|4[56]|5[4-68]|6[3-5])|499|5(?:76|97)|746|8(?:3[89]|47|51)|9(?:80|9[16])",
              "1(?:267|3(?:7[247]|9[278])|466|5(?:47|58|64)|6(?:3[245]|48|5[4-68]))|499[2468]|5(?:76|97)9|7468|8(?:3(?:8[7-9]|96)|477|51[2-9])|9(?:802|9(?:1[23]|69))|1(?:45|58)[67]",
              "1(?:267|3(?:7[247]|9[278])|466|5(?:47|58|64)|6(?:3[245]|48|5[4-68]))|499[2468]|5(?:769|979[2-69])|7468|8(?:3(?:8[7-9]|96[2457-9])|477|51[2-9])|9(?:802|9(?:1[23]|69))|1(?:45|58)[67]",
            ],
            "0$1",
          ],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1-$2-$3", ["60"], "0$1"],
          [
            ,
            "(\\d)(\\d{4})(\\d{4})",
            "$1-$2-$3",
            [
              "[36]|4(?:2[09]|7[01])",
              "[36]|4(?:2(?:0|9[02-69])|7(?:0[019]|1))",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{4})",
            "$1-$2-$3",
            [
              "1(?:1|5[45]|77|88|9[69])|2(?:2[1-37]|3[0-269]|4[59]|5|6[24]|7[1-358]|8[1369]|9[0-38])|4(?:[28][1-9]|3[0-57]|[45]|6[248]|7[2-579]|9[29])|5(?:2|3[0459]|4[0-369]|5[29]|8[02389]|9[0-389])|7(?:2[02-46-9]|34|[58]|6[0249]|7[57]|9[2-6])|8(?:2[124589]|3[26-9]|49|51|6|7[0-468]|8[68]|9[019])|9(?:[23][1-9]|4[15]|5[138]|6[1-3]|7[156]|8[189]|9[1-489])",
              "1(?:1|5(?:4[018]|5[017])|77|88|9[69])|2(?:2(?:[127]|3[014-9])|3[0-269]|4[59]|5(?:[1-3]|5[0-69]|9[19])|62|7(?:[1-35]|8[0189])|8(?:[16]|3[0134]|9[0-5])|9(?:[028]|17))|4(?:2(?:[13-79]|8[014-6])|3[0-57]|[45]|6[248]|7[2-47]|8[1-9]|9[29])|5(?:2|3(?:[045]|9[0-8])|4[0-369]|5[29]|8[02389]|9[0-3])|7(?:2[02-46-9]|34|[58]|6[0249]|7[57]|9(?:[23]|4[0-59]|5[01569]|6[0167]))|8(?:2(?:[1258]|4[0-39]|9[0-2469])|3(?:[29]|60)|49|51|6(?:[0-24]|36|5[0-3589]|7[23]|9[01459])|7[0-468]|8[68])|9(?:[23][1-9]|4[15]|5[138]|6[1-3]|7[156]|8[189]|9(?:[1289]|3[34]|4[0178]))|(?:264|837)[016-9]|2(?:57|93)[015-9]|(?:25[0468]|422|838)[01]|(?:47[59]|59[89]|8(?:6[68]|9))[019]",
              "1(?:1|5(?:4[018]|5[017])|77|88|9[69])|2(?:2[127]|3[0-269]|4[59]|5(?:[1-3]|5[0-69]|9(?:17|99))|6(?:2|4[016-9])|7(?:[1-35]|8[0189])|8(?:[16]|3[0134]|9[0-5])|9(?:[028]|17))|4(?:2(?:[13-79]|8[014-6])|3[0-57]|[45]|6[248]|7[2-47]|9[29])|5(?:2|3(?:[045]|9(?:[0-58]|6[4-9]|7[0-35689]))|4[0-369]|5[29]|8[02389]|9[0-3])|7(?:2[02-46-9]|34|[58]|6[0249]|7[57]|9(?:[23]|4[0-59]|5[01569]|6[0167]))|8(?:2(?:[1258]|4[0-39]|9[0169])|3(?:[29]|60|7(?:[017-9]|6[6-8]))|49|51|6(?:[0-24]|36[2-57-9]|5(?:[0-389]|5[23])|6(?:[01]|9[178])|7(?:2[2-468]|3[78])|9[0145])|7[0-468]|8[68])|9(?:4[15]|5[138]|7[156]|8[189]|9(?:[1289]|3(?:31|4[357])|4[0178]))|(?:8294|96)[1-3]|2(?:57|93)[015-9]|(?:223|8699)[014-9]|(?:25[0468]|422|838)[01]|(?:48|8292|9[23])[1-9]|(?:47[59]|59[89]|8(?:68|9))[019]",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{4})",
            "$1-$2-$3",
            ["[14]|[289][2-9]|5[3-9]|7[2-4679]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1-$2-$3", ["800"], "0$1"],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1-$2-$3", ["[257-9]"], "0$1"],
        ],
        [, , "20\\d{8}", , , , "2012345678", , , [10]],
        ,
        ,
        [
          ,
          ,
          "00(?:777(?:[01]|(?:5|8\\d)\\d)|882[1245]\\d\\d)\\d\\d|00(?:37|66|78)\\d{6,13}",
        ],
        [, , "570\\d{6}", , , , "570123456", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KE: [
        ,
        [
          ,
          ,
          "(?:[17]\\d\\d|900)\\d{6}|(?:2|80)0\\d{6,7}|[4-6]\\d{6,8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 10],
        ],
        [
          ,
          ,
          "(?:4[245]|5[1-79]|6[01457-9])\\d{5,7}|(?:4[136]|5[08]|62)\\d{7}|(?:[24]0|66)\\d{6,7}",
          ,
          ,
          ,
          "202012345",
          ,
          ,
          [7, 8, 9],
        ],
        [
          ,
          ,
          "(?:1(?:0[0-6]|1[0-5]|2[014]|30)|7\\d\\d)\\d{6}",
          ,
          ,
          ,
          "712123456",
          ,
          ,
          [9],
        ],
        [, , "800[02-8]\\d{5,6}", , , , "800223456", , , [9, 10]],
        [, , "900[02-9]\\d{5}", , , , "900223456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "KE",
        254,
        "000",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{5,7})", "$1 $2", ["[24-6]"], "0$1"],
          [, "(\\d{3})(\\d{6})", "$1 $2", ["[17]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[89]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KG: [
        ,
        [, , "8\\d{9}|[235-9]\\d{8}", , , , , , , [9, 10], [5, 6]],
        [
          ,
          ,
          "312(?:5[0-79]\\d|9(?:[0-689]\\d|7[0-24-9]))\\d{3}|(?:3(?:1(?:2[0-46-8]|3[1-9]|47|[56]\\d)|2(?:22|3[0-479]|6[0-7])|4(?:22|5[6-9]|6\\d)|5(?:22|3[4-7]|59|6\\d)|6(?:22|5[35-7]|6\\d)|7(?:22|3[468]|4[1-9]|59|[67]\\d)|9(?:22|4[1-8]|6\\d))|6(?:09|12|2[2-4])\\d)\\d{5}",
          ,
          ,
          ,
          "312123456",
          ,
          ,
          [9],
          [5, 6],
        ],
        [
          ,
          ,
          "312(?:58\\d|973)\\d{3}|(?:2(?:0[0-35]|2\\d)|5[0-24-7]\\d|600|7(?:[07]\\d|55)|88[08]|9(?:12|9[05-9]))\\d{6}",
          ,
          ,
          ,
          "700123456",
          ,
          ,
          [9],
        ],
        [, , "800\\d{6,7}", , , , "800123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "KG",
        996,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{5})", "$1 $2", ["3(?:1[346]|[24-79])"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[235-79]|88"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d)(\\d{2,3})", "$1 $2 $3 $4", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KH: [
        ,
        [, , "1\\d{9}|[1-9]\\d{7,8}", , , , , , , [8, 9, 10], [6, 7]],
        [
          ,
          ,
          "23(?:4(?:[2-4]|[56]\\d)|[568]\\d\\d)\\d{4}|23[236-9]\\d{5}|(?:2[4-6]|3[2-6]|4[2-4]|[5-7][2-5])(?:(?:[237-9]|4[56]|5\\d)\\d{5}|6\\d{5,6})",
          ,
          ,
          ,
          "23756789",
          ,
          ,
          [8, 9],
          [6, 7],
        ],
        [
          ,
          ,
          "(?:(?:1[28]|3[18]|9[67])\\d|6[016-9]|7(?:[07-9]|[16]\\d)|8(?:[013-79]|8\\d))\\d{6}|(?:1\\d|9[0-57-9])\\d{6}|(?:2[3-6]|3[2-6]|4[2-4]|[5-7][2-5])48\\d{5}",
          ,
          ,
          ,
          "91234567",
          ,
          ,
          [8, 9],
        ],
        [, , "1800(?:1\\d|2[019])\\d{4}", , , , "1800123456", , , [10]],
        [, , "1900(?:1\\d|2[09])\\d{4}", , , , "1900123456", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "KH",
        855,
        "00[14-9]",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[1-9]"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1 $2 $3", ["1"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KI: [
        ,
        [
          ,
          ,
          "(?:[37]\\d|6[0-79])\\d{6}|(?:[2-48]\\d|50)\\d{3}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 8],
        ],
        [
          ,
          ,
          "(?:[24]\\d|3[1-9]|50|65(?:02[12]|12[56]|22[89]|[3-5]00)|7(?:27\\d\\d|3100|5(?:02[12]|12[56]|22[89]|[34](?:00|81)|500))|8[0-5])\\d{3}",
          ,
          ,
          ,
          "31234",
        ],
        [
          ,
          ,
          "(?:6200[01]|7(?:310[1-9]|5(?:02[03-9]|12[0-47-9]|22[0-7]|[34](?:0[1-9]|8[02-9])|50[1-9])))\\d{3}|(?:63\\d\\d|7(?:(?:[0146-9]\\d|2[0-689])\\d|3(?:[02-9]\\d|1[1-9])|5(?:[0-2][013-9]|[34][1-79]|5[1-9]|[6-9]\\d)))\\d{4}",
          ,
          ,
          ,
          "72001234",
          ,
          ,
          [8],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "30(?:0[01]\\d\\d|12(?:11|20))\\d\\d", , , , "30010000", , , [8]],
        "KI",
        686,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KM: [
        ,
        [, , "[3478]\\d{6}", , , , , , , [7], [4]],
        [, , "7[4-7]\\d{5}", , , , "7712345", , , , [4]],
        [, , "[34]\\d{6}", , , , "3212345"],
        [, , , , , , , , , [-1]],
        [, , "8\\d{6}", , , , "8001234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "KM",
        269,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{2})(\\d{2})", "$1 $2 $3", ["[3478]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KN: [
        ,
        [, , "(?:[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "869(?:2(?:29|36)|302|4(?:6[015-9]|70)|56[5-7])\\d{4}",
          ,
          ,
          ,
          "8692361234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "869(?:48[89]|55[6-8]|66\\d|76[02-7])\\d{4}",
          ,
          ,
          ,
          "8697652917",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "KN",
        1,
        "011",
        "1",
        ,
        ,
        "([2-7]\\d{6})$|1",
        "869$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "869",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KP: [
        ,
        [, , "85\\d{6}|(?:19\\d|[2-7])\\d{7}", , , , , , , [8, 10], [6, 7]],
        [
          ,
          ,
          "(?:(?:195|2)\\d|3[19]|4[159]|5[37]|6[17]|7[39]|85)\\d{6}",
          ,
          ,
          ,
          "21234567",
          ,
          ,
          ,
          [6, 7],
        ],
        [, , "19[1-3]\\d{7}", , , , "1921234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "KP",
        850,
        "00|99",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["8"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["[2-7]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "238[02-9]\\d{4}|2(?:[0-24-9]\\d|3[0-79])\\d{5}", , , , , , , [8]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KR: [
        ,
        [
          ,
          ,
          "00[1-9]\\d{8,11}|(?:[12]|5\\d{3})\\d{7}|[13-6]\\d{9}|(?:[1-6]\\d|80)\\d{7}|[3-6]\\d{4,5}|(?:00|7)0\\d{8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 8, 9, 10, 11, 12, 13, 14],
          [3, 4, 7],
        ],
        [
          ,
          ,
          "(?:2|3[1-3]|[46][1-4]|5[1-5])[1-9]\\d{6,7}|(?:3[1-3]|[46][1-4]|5[1-5])1\\d{2,3}",
          ,
          ,
          ,
          "22123456",
          ,
          ,
          [5, 6, 8, 9, 10],
          [3, 4, 7],
        ],
        [
          ,
          ,
          "1(?:05(?:[0-8]\\d|9[0-6])|22[13]\\d)\\d{4,5}|1(?:0[0-46-9]|[16-9]\\d|2[013-9])\\d{6,7}",
          ,
          ,
          ,
          "1020000000",
          ,
          ,
          [9, 10],
        ],
        [
          ,
          ,
          "00(?:308\\d{6,7}|798\\d{7,9})|(?:00368|80)\\d{7}",
          ,
          ,
          ,
          "801234567",
          ,
          ,
          [9, 11, 12, 13, 14],
        ],
        [, , "60[2-9]\\d{6}", , , , "602345678", , , [9]],
        [, , , , , , , , , [-1]],
        [, , "50\\d{8,9}", , , , "5012345678", , , [10, 11]],
        [, , "70\\d{8}", , , , "7012345678", , , [10]],
        "KR",
        82,
        "00(?:[125689]|3(?:[46]5|91)|7(?:00|27|3|55|6[126]))",
        "0",
        ,
        ,
        "0(8(?:[1-46-8]|5\\d\\d))?",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{5})",
            "$1",
            ["1[016-9]1", "1[016-9]11", "1[016-9]114"],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3,4})",
            "$1-$2",
            ["(?:3[1-3]|[46][1-4]|5[1-5])1"],
            "0$1",
            "0$CC-$1",
          ],
          [, "(\\d{4})(\\d{4})", "$1-$2", ["1"]],
          [, "(\\d)(\\d{3,4})(\\d{4})", "$1-$2-$3", ["2"], "0$1", "0$CC-$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{4})",
            "$1-$2-$3",
            ["60|8"],
            "0$1",
            "0$CC-$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3,4})(\\d{4})",
            "$1-$2-$3",
            ["[1346]|5[1-5]"],
            "0$1",
            "0$CC-$1",
          ],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1-$2-$3",
            ["[57]"],
            "0$1",
            "0$CC-$1",
          ],
          [, "(\\d{5})(\\d{3})(\\d{3})", "$1 $2 $3", ["003", "0030"]],
          [, "(\\d{2})(\\d{5})(\\d{4})", "$1-$2-$3", ["5"], "0$1", "0$CC-$1"],
          [, "(\\d{5})(\\d{3,4})(\\d{4})", "$1 $2 $3", ["0"]],
          [, "(\\d{5})(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3 $4", ["0"]],
        ],
        [
          [
            ,
            "(\\d{2})(\\d{3,4})",
            "$1-$2",
            ["(?:3[1-3]|[46][1-4]|5[1-5])1"],
            "0$1",
            "0$CC-$1",
          ],
          [, "(\\d{4})(\\d{4})", "$1-$2", ["1"]],
          [, "(\\d)(\\d{3,4})(\\d{4})", "$1-$2-$3", ["2"], "0$1", "0$CC-$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{4})",
            "$1-$2-$3",
            ["60|8"],
            "0$1",
            "0$CC-$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3,4})(\\d{4})",
            "$1-$2-$3",
            ["[1346]|5[1-5]"],
            "0$1",
            "0$CC-$1",
          ],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1-$2-$3",
            ["[57]"],
            "0$1",
            "0$CC-$1",
          ],
          [, "(\\d{2})(\\d{5})(\\d{4})", "$1-$2-$3", ["5"], "0$1", "0$CC-$1"],
        ],
        [, , "15\\d{7,8}", , , , "1523456789", , , [9, 10]],
        ,
        ,
        [
          ,
          ,
          "00(?:3(?:08\\d{6,7}|68\\d{7})|798\\d{7,9})",
          ,
          ,
          ,
          ,
          ,
          ,
          [11, 12, 13, 14],
        ],
        [
          ,
          ,
          "1(?:5(?:22|33|44|66|77|88|99)|6(?:[07]0|44|6[0168]|88)|8(?:00|33|55|77|99))\\d{4}",
          ,
          ,
          ,
          "15441234",
          ,
          ,
          [8],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KW: [
        ,
        [, , "18\\d{5}|(?:[2569]\\d|41)\\d{6}", , , , , , , [7, 8]],
        [
          ,
          ,
          "2(?:[23]\\d\\d|4(?:[1-35-9]\\d|44)|5(?:0[034]|[2-46]\\d|5[1-3]|7[1-7]))\\d{4}",
          ,
          ,
          ,
          "22345678",
          ,
          ,
          [8],
        ],
        [
          ,
          ,
          "(?:41\\d\\d|5(?:(?:[05]\\d|1[0-7]|6[56])\\d|2(?:22|5[25])|7(?:55|77)|88[58])|6(?:(?:0[034679]|5[015-9]|6\\d)\\d|1(?:00|11|6[16])|2[26]2|3[36]3|4[46]4|7(?:0[013-9]|[67]\\d)|8[68]8|9(?:[069]\\d|3[039]))|9(?:(?:[04679]\\d|8[057-9])\\d|1(?:1[01]|99)|2(?:00|2\\d)|3(?:00|3[03])|5(?:00|5\\d)))\\d{4}",
          ,
          ,
          ,
          "50012345",
          ,
          ,
          [8],
        ],
        [, , "18\\d{5}", , , , "1801234", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "KW",
        965,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{3,4})", "$1 $2", ["[169]|2(?:[235]|4[1-35-9])|52"]],
          [, "(\\d{3})(\\d{5})", "$1 $2", ["[245]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KY: [
        ,
        [, , "(?:345|[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "345(?:2(?:22|3[23]|44|66)|333|444|6(?:23|38|40)|7(?:30|4[35-79]|6[6-9]|77)|8(?:00|1[45]|[48]8)|9(?:14|4[035-9]))\\d{4}",
          ,
          ,
          ,
          "3452221234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "345(?:32[1-9]|42[0-4]|5(?:1[67]|2[5-79]|4[6-9]|50|76)|649|82[56]|9(?:1[679]|2[2-9]|3[06-9]|90))\\d{4}",
          ,
          ,
          ,
          "3453231234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002345678"],
        [, , "(?:345976|900[2-9]\\d\\d)\\d{4}", , , , "9002345678"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "KY",
        1,
        "011",
        "1",
        ,
        ,
        "([2-9]\\d{6})$|1",
        "345$1",
        ,
        ,
        ,
        ,
        [, , "345849\\d{4}", , , , "3458491234"],
        ,
        "345",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      KZ: [
        ,
        [
          ,
          ,
          "(?:33622|8\\d{8})\\d{5}|[78]\\d{9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [10, 14],
          [5, 6, 7],
        ],
        [
          ,
          ,
          "(?:33622|7(?:1(?:0(?:[23]\\d|4[0-3]|59|63)|1(?:[23]\\d|4[0-79]|59)|2(?:[23]\\d|59)|3(?:2\\d|3[0-79]|4[0-35-9]|59)|4(?:[24]\\d|3[013-9]|5[1-9]|97)|5(?:2\\d|3[1-9]|4[0-7]|59)|6(?:[2-4]\\d|5[19]|61)|72\\d|8(?:[27]\\d|3[1-46-9]|4[0-5]|59))|2(?:1(?:[23]\\d|4[46-9]|5[3469])|2(?:2\\d|3[0679]|46|5[12679])|3(?:[2-4]\\d|5[139])|4(?:2\\d|3[1-35-9]|59)|5(?:[23]\\d|4[0-8]|59|61)|6(?:2\\d|3[1-9]|4[0-4]|59)|7(?:[2379]\\d|40|5[279])|8(?:[23]\\d|4[0-3]|59)|9(?:2\\d|3[124578]|59))))\\d{5}",
          ,
          ,
          ,
          "7123456789",
          ,
          ,
          [10],
          [5, 6, 7],
        ],
        [
          ,
          ,
          "7(?:0[0-25-8]|47|6[0-4]|7[15-8]|85)\\d{7}",
          ,
          ,
          ,
          "7710009998",
          ,
          ,
          [10],
        ],
        [, , "8(?:00|108\\d{3})\\d{7}", , , , "8001234567"],
        [, , "809\\d{7}", , , , "8091234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , "808\\d{7}", , , , "8081234567", , , [10]],
        [, , "751\\d{7}", , , , "7511234567", , , [10]],
        "KZ",
        7,
        "810",
        "8",
        ,
        ,
        "8",
        ,
        "8~10",
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "33|7",
        [, , "751\\d{7}", , , , , , , [10]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LA: [
        ,
        [
          ,
          ,
          "[23]\\d{9}|3\\d{8}|(?:[235-8]\\d|41)\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10],
          [6],
        ],
        [
          ,
          ,
          "(?:2[13]|[35-7][14]|41|8[1468])\\d{6}",
          ,
          ,
          ,
          "21212862",
          ,
          ,
          [8],
          [6],
        ],
        [
          ,
          ,
          "(?:20(?:[2359]\\d|7[6-8]|88)|302\\d)\\d{6}",
          ,
          ,
          ,
          "2023123456",
          ,
          ,
          [10],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "LA",
        856,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            ["2[13]|3[14]|[4-8]"],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{3})",
            "$1 $2 $3 $4",
            ["30[013-9]"],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{3})(\\d{3})",
            "$1 $2 $3 $4",
            ["[23]"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "30[013-9]\\d{6}", , , , "301234567", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LB: [
        ,
        [, , "[27-9]\\d{7}|[13-9]\\d{6}", , , , , , , [7, 8]],
        [
          ,
          ,
          "7(?:62|8[0-7]|9[04-9])\\d{4}|(?:[14-69]\\d|2(?:[14-69]\\d|[78][1-9])|7[2-57]|8[02-9])\\d{5}",
          ,
          ,
          ,
          "1123456",
        ],
        [
          ,
          ,
          "793(?:[01]\\d|2[0-4])\\d{3}|(?:(?:3|81)\\d|7(?:[01]\\d|6[013-9]|8[89]|9[12]))\\d{5}",
          ,
          ,
          ,
          "71123456",
        ],
        [, , , , , , , , , [-1]],
        [, , "9[01]\\d{6}", , , , "90123456", , , [8]],
        [, , "80\\d{6}", , , , "80123456", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "LB",
        961,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d)(\\d{3})(\\d{3})",
            "$1 $2 $3",
            ["[13-69]|7(?:[2-57]|62|8[0-7]|9[04-9])|8[02-9]"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["[27-9]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LC: [
        ,
        [, , "(?:[58]\\d\\d|758|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "758(?:234|4(?:30|5\\d|6[2-9]|8[0-2])|57[0-2]|(?:63|75)8)\\d{4}",
          ,
          ,
          ,
          "7584305678",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "758(?:28[4-7]|384|4(?:6[01]|8[4-9])|5(?:1[89]|20|84)|7(?:1[2-9]|2\\d|3[0-3])|812)\\d{4}",
          ,
          ,
          ,
          "7582845678",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "LC",
        1,
        "011",
        "1",
        ,
        ,
        "([2-8]\\d{6})$|1",
        "758$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "758",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LI: [
        ,
        [, , "[68]\\d{8}|(?:[2378]\\d|90)\\d{5}", , , , , , , [7, 9]],
        [
          ,
          ,
          "(?:2(?:01|1[27]|2[02]|3\\d|6[02-578]|96)|3(?:[24]0|33|7[0135-7]|8[048]|9[0269]))\\d{4}",
          ,
          ,
          ,
          "2345678",
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "(?:6(?:(?:4[5-9]|5[0-469])\\d|6(?:[024-6]\\d|[17]0|3[7-9]))\\d|7(?:[37-9]\\d|42|56))\\d{4}",
          ,
          ,
          ,
          "660234567",
        ],
        [, , "8002[28]\\d\\d|80(?:05\\d|9)\\d{4}", , , , "8002222"],
        [
          ,
          ,
          "90(?:02[258]|1(?:23|3[14])|66[136])\\d\\d",
          ,
          ,
          ,
          "9002222",
          ,
          ,
          [7],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "LI",
        423,
        "00",
        "0",
        ,
        ,
        "(1001)|0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3",
            ["[2379]|8(?:0[09]|7)", "[2379]|8(?:0(?:02|9)|7)"],
            ,
            "$CC $1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["8"]],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["69"], , "$CC $1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["6"], , "$CC $1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "870(?:28|87)\\d\\d", , , , "8702812", , , [7]],
        ,
        ,
        [, , "697(?:42|56|[78]\\d)\\d{4}", , , , "697861234", , , [9]],
      ],
      LK: [
        ,
        [, , "[1-9]\\d{8}", , , , , , , [9], [7]],
        [
          ,
          ,
          "(?:12[2-9]|602|8[12]\\d|9(?:1\\d|22|9[245]))\\d{6}|(?:11|2[13-7]|3[1-8]|4[157]|5[12457]|6[35-7])[2-57]\\d{6}",
          ,
          ,
          ,
          "112345678",
          ,
          ,
          ,
          [7],
        ],
        [, , "7(?:[0-25-8]\\d|4[0-4])\\d{6}", , , , "712345678"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "LK",
        94,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["7"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[1-689]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "1973\\d{5}", , , , "197312345"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LR: [
        ,
        [
          ,
          ,
          "(?:[245]\\d|33|77|88)\\d{7}|(?:2\\d|[4-6])\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9],
        ],
        [, , "2\\d{7}", , , , "21234567", , , [8]],
        [
          ,
          ,
          "(?:(?:(?:22|33)0|555|(?:77|88)\\d)\\d|4(?:240|[67]))\\d{5}|[56]\\d{6}",
          ,
          ,
          ,
          "770123456",
          ,
          ,
          [7, 9],
        ],
        [, , , , , , , , , [-1]],
        [, , "332(?:02|[34]\\d)\\d{4}", , , , "332021234", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "LR",
        231,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{3})", "$1 $2 $3", ["4[67]|[56]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["2"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[2-578]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LS: [
        ,
        [, , "(?:[256]\\d\\d|800)\\d{5}", , , , , , , [8]],
        [, , "2\\d{7}", , , , "22123456"],
        [, , "[56]\\d{7}", , , , "50123456"],
        [, , "800[1256]\\d{4}", , , , "80021234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "LS",
        266,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{4})(\\d{4})", "$1 $2", ["[2568]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LT: [
        ,
        [, , "(?:[3469]\\d|52|[78]0)\\d{6}", , , , , , , [8]],
        [, , "(?:3[1478]|4[124-6]|52)\\d{6}", , , , "31234567"],
        [, , "6\\d{7}", , , , "61234567"],
        [, , "80[02]\\d{5}", , , , "80012345"],
        [, , "9(?:0[0239]|10)\\d{5}", , , , "90012345"],
        [, , "808\\d{5}", , , , "80812345"],
        [, , "70[05]\\d{5}", , , , "70012345"],
        [, , "[89]01\\d{5}", , , , "80123456"],
        "LT",
        370,
        "00",
        "8",
        ,
        ,
        "[08]",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["52[0-7]"], "(8-$1)", , 1],
          [, "(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3", ["[7-9]"], "8 $1", , 1],
          [
            ,
            "(\\d{2})(\\d{6})",
            "$1 $2",
            ["37|4(?:[15]|6[1-8])"],
            "(8-$1)",
            ,
            1,
          ],
          [, "(\\d{3})(\\d{5})", "$1 $2", ["[3-6]"], "(8-$1)", , 1],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "70[67]\\d{5}", , , , "70712345"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LU: [
        ,
        [
          ,
          ,
          "35[013-9]\\d{4,8}|6\\d{8}|35\\d{2,4}|(?:[2457-9]\\d|3[0-46-9])\\d{2,9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [4, 5, 6, 7, 8, 9, 10, 11],
        ],
        [
          ,
          ,
          "(?:35[013-9]|80[2-9]|90[89])\\d{1,8}|(?:2[2-9]|3[0-46-9]|[457]\\d|8[13-9]|9[2-579])\\d{2,9}",
          ,
          ,
          ,
          "27123456",
        ],
        [
          ,
          ,
          "6(?:[269][18]|5[1568]|7[189]|81)\\d{6}",
          ,
          ,
          ,
          "628123456",
          ,
          ,
          [9],
        ],
        [, , "800\\d{5}", , , , "80012345", , , [8]],
        [, , "90[015]\\d{5}", , , , "90012345", , , [8]],
        [, , "801\\d{5}", , , , "80112345", , , [8]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "20(?:1\\d{5}|[2-689]\\d{1,7})",
          ,
          ,
          ,
          "20201234",
          ,
          ,
          [4, 5, 6, 7, 8, 9, 10],
        ],
        "LU",
        352,
        "00",
        ,
        ,
        ,
        "(15(?:0[06]|1[12]|[35]5|4[04]|6[26]|77|88|99)\\d)",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{2})(\\d{3})",
            "$1 $2",
            [
              "2(?:0[2-689]|[2-9])|[3-57]|8(?:0[2-9]|[13-9])|9(?:0[89]|[2-579])",
            ],
            ,
            "$CC $1",
          ],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3",
            [
              "2(?:0[2-689]|[2-9])|[3-57]|8(?:0[2-9]|[13-9])|9(?:0[89]|[2-579])",
            ],
            ,
            "$CC $1",
          ],
          [, "(\\d{2})(\\d{2})(\\d{3})", "$1 $2 $3", ["20[2-689]"], , "$CC $1"],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{1,2})",
            "$1 $2 $3 $4",
            ["2(?:[0367]|4[3-8])"],
            ,
            "$CC $1",
          ],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{3})",
            "$1 $2 $3",
            ["80[01]|90[015]"],
            ,
            "$CC $1",
          ],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{3})",
            "$1 $2 $3 $4",
            ["20"],
            ,
            "$CC $1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["6"], , "$CC $1"],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{2})(\\d{1,2})",
            "$1 $2 $3 $4 $5",
            ["2(?:[0367]|4[3-8])"],
            ,
            "$CC $1",
          ],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{1,5})",
            "$1 $2 $3 $4",
            ["[3-57]|8[13-9]|9(?:0[89]|[2-579])|(?:2|80)[2-9]"],
            ,
            "$CC $1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LV: [
        ,
        [, , "(?:[268]\\d|90)\\d{6}", , , , , , , [8]],
        [, , "6\\d{7}", , , , "63123456"],
        [
          ,
          ,
          "23(?:23[0-57-9]|33[0238])\\d{3}|2(?:[0-24-9]\\d\\d|3(?:0[07]|[14-9]\\d|2[024-9]|3[0-24-9]))\\d{4}",
          ,
          ,
          ,
          "21234567",
        ],
        [, , "80\\d{6}", , , , "80123456"],
        [, , "90\\d{6}", , , , "90123456"],
        [, , "81\\d{6}", , , , "81123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "LV",
        371,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["[269]|8[01]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      LY: [
        ,
        [, , "[2-9]\\d{8}", , , , , , , [9], [7]],
        [
          ,
          ,
          "(?:2(?:0[56]|[1-6]\\d|7[124579]|8[124])|3(?:1\\d|2[2356])|4(?:[17]\\d|2[1-357]|5[2-4]|8[124])|5(?:[1347]\\d|2[1-469]|5[13-5]|8[1-4])|6(?:[1-479]\\d|5[2-57]|8[1-5])|7(?:[13]\\d|2[13-79])|8(?:[124]\\d|5[124]|84))\\d{6}",
          ,
          ,
          ,
          "212345678",
          ,
          ,
          ,
          [7],
        ],
        [, , "9[1-6]\\d{7}", , , , "912345678"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "LY",
        218,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{7})", "$1-$2", ["[2-9]"], "0$1"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MA: [
        ,
        [, , "[5-8]\\d{8}", , , , , , , [9]],
        [
          ,
          ,
          "5(?:2(?:[0-25-79]\\d|3[1-578]|4[02-46-8]|8[0235-7])|3(?:[0-47]\\d|5[02-9]|6[02-8]|8[014-9]|9[3-9])|(?:4[067]|5[03])\\d)\\d{5}",
          ,
          ,
          ,
          "520123456",
        ],
        [
          ,
          ,
          "(?:6(?:[0-79]\\d|8[0-247-9])|7(?:[0167]\\d|2[0-4]|5[01]|8[0-3]))\\d{6}",
          ,
          ,
          ,
          "650123456",
        ],
        [, , "80[0-7]\\d{6}", , , , "801234567"],
        [, , "89\\d{7}", , , , "891234567"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:592(?:4[0-2]|93)|80[89]\\d\\d)\\d{4}", , , , "592401234"],
        "MA",
        212,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["5[45]"],
            "0$1",
          ],
          [
            ,
            "(\\d{4})(\\d{5})",
            "$1-$2",
            ["5(?:2[2-46-9]|3[3-9]|9)|8(?:0[89]|92)"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{7})", "$1-$2", ["8"], "0$1"],
          [, "(\\d{3})(\\d{6})", "$1-$2", ["[5-7]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        1,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MC: [
        ,
        [, , "(?:[3489]|6\\d)\\d{7}", , , , , , , [8, 9]],
        [, , "(?:870|9[2-47-9]\\d)\\d{5}", , , , "99123456", , , [8]],
        [, , "4(?:[469]\\d|5[1-9])\\d{5}|(?:3|6\\d)\\d{7}", , , , "612345678"],
        [, , "(?:800|90\\d)\\d{5}", , , , "90123456", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "MC",
        377,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})(\\d{2})", "$1 $2 $3", ["87"]],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["4"], "0$1"],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[389]"]],
          [
            ,
            "(\\d)(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4 $5",
            ["6"],
            "0$1",
          ],
        ],
        [
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["4"], "0$1"],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[389]"]],
          [
            ,
            "(\\d)(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4 $5",
            ["6"],
            "0$1",
          ],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "8[07]0\\d{5}", , , , , , , [8]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MD: [
        ,
        [, , "(?:[235-7]\\d|[89]0)\\d{6}", , , , , , , [8]],
        [
          ,
          ,
          "(?:(?:2[1-9]|3[1-79])\\d|5(?:33|5[257]))\\d{5}",
          ,
          ,
          ,
          "22212345",
        ],
        [, , "562\\d{5}|(?:6\\d|7[16-9])\\d{6}", , , , "62112345"],
        [, , "800\\d{5}", , , , "80012345"],
        [, , "90[056]\\d{5}", , , , "90012345"],
        [, , "808\\d{5}", , , , "80812345"],
        [, , , , , , , , , [-1]],
        [, , "3[08]\\d{6}", , , , "30123456"],
        "MD",
        373,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{5})", "$1 $2", ["[89]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["22|3"], "0$1"],
          [, "(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3", ["[25-7]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "803\\d{5}", , , , "80312345"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ME: [
        ,
        [, , "(?:20|[3-79]\\d)\\d{6}|80\\d{6,7}", , , , , , , [8, 9], [6]],
        [
          ,
          ,
          "(?:20[2-8]|3(?:[0-2][2-7]|3[24-7])|4(?:0[2-467]|1[2467])|5(?:0[2467]|1[24-7]|2[2-467]))\\d{5}",
          ,
          ,
          ,
          "30234567",
          ,
          ,
          [8],
          [6],
        ],
        [, , "6(?:[07-9]\\d|3[024]|6[0-25])\\d{5}", , , , "67622901", , , [8]],
        [, , "80(?:[0-2578]|9\\d)\\d{5}", , , , "80080002"],
        [, , "9(?:4[1568]|5[178])\\d{5}", , , , "94515151", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "78[1-49]\\d{5}", , , , "78108780", , , [8]],
        "ME",
        382,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[2-9]"], "0$1"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "77[1-9]\\d{5}", , , , "77273012", , , [8]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MF: [
        ,
        [, , "590\\d{6}|(?:69|80|9\\d)\\d{7}", , , , , , , [9]],
        [
          ,
          ,
          "590(?:0[079]|[14]3|[27][79]|3[03-7]|5[0-268]|87)\\d{4}",
          ,
          ,
          ,
          "590271234",
        ],
        [
          ,
          ,
          "69(?:0\\d\\d|1(?:2[2-9]|3[0-5])|4(?:0[89]|1[2-6]|9\\d)|6(?:1[016-9]|5[0-4]|[67]\\d))\\d{4}",
          ,
          ,
          ,
          "690001234",
        ],
        [, , "80[0-5]\\d{6}", , , , "800012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "9(?:(?:39[5-7]|76[018])\\d|475[0-5])\\d{4}", , , , "976012345"],
        "MF",
        590,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MG: [
        ,
        [, , "[23]\\d{8}", , , , , , , [9], [7]],
        [
          ,
          ,
          "2072[29]\\d{4}|20(?:2\\d|4[47]|5[3467]|6[279]|7[35]|8[268]|9[245])\\d{5}",
          ,
          ,
          ,
          "202123456",
          ,
          ,
          ,
          [7],
        ],
        [, , "3[2-47-9]\\d{7}", , , , "321234567"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "22\\d{7}", , , , "221234567"],
        "MG",
        261,
        "00",
        "0",
        ,
        ,
        "([24-9]\\d{6})$|0",
        "20$1",
        ,
        ,
        [
          [
            ,
            "(\\d{2})(\\d{2})(\\d{3})(\\d{2})",
            "$1 $2 $3 $4",
            ["[23]"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MH: [
        ,
        [, , "329\\d{4}|(?:[256]\\d|45)\\d{5}", , , , , , , [7]],
        [, , "(?:247|528|625)\\d{4}", , , , "2471234"],
        [, , "(?:(?:23|54)5|329|45[35-8])\\d{4}", , , , "2351234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "635\\d{4}", , , , "6351234"],
        "MH",
        692,
        "011",
        "1",
        ,
        ,
        "1",
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1-$2", ["[2-6]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MK: [
        ,
        [, , "[2-578]\\d{7}", , , , , , , [8], [6, 7]],
        [
          ,
          ,
          "(?:(?:2(?:62|77)0|3444)\\d|4[56]440)\\d{3}|(?:34|4[357])700\\d{3}|(?:2(?:[0-3]\\d|5[0-578]|6[01]|82)|3(?:1[3-68]|[23][2-68]|4[23568])|4(?:[23][2-68]|4[3-68]|5[2568]|6[25-8]|7[24-68]|8[4-68]))\\d{5}",
          ,
          ,
          ,
          "22012345",
          ,
          ,
          ,
          [6, 7],
        ],
        [
          ,
          ,
          "7(?:3555|(?:474|9[019]7)7)\\d{3}|7(?:[0-25-8]\\d\\d|3(?:[1-48]\\d|6[01]|7[01578])|4(?:2\\d|60|7[01578])|9(?:[2-4]\\d|5[01]|7[015]))\\d{4}",
          ,
          ,
          ,
          "72345678",
        ],
        [, , "800\\d{5}", , , , "80012345"],
        [, , "5\\d{7}", , , , "50012345"],
        [, , "8(?:0[1-9]|[1-9]\\d)\\d{5}", , , , "80123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "MK",
        389,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d)(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["2|34[47]|4(?:[37]7|5[47]|64)"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["[347]"], "0$1"],
          [, "(\\d{3})(\\d)(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[58]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ML: [
        ,
        [, , "[24-9]\\d{7}", , , , , , , [8]],
        [
          ,
          ,
          "2(?:07[0-8]|12[67])\\d{4}|(?:2(?:02|1[4-689])|4(?:0[0-4]|4[1-39]))\\d{5}",
          ,
          ,
          ,
          "20212345",
        ],
        [
          ,
          ,
          "2(?:0(?:01|79)|17\\d)\\d{4}|(?:5[01]|[679]\\d|8[2-49])\\d{6}",
          ,
          ,
          ,
          "65012345",
        ],
        [, , "80\\d{6}", , , , "80012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "ML",
        223,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{4})",
            "$1",
            [
              "67[057-9]|74[045]",
              "67(?:0[09]|[59]9|77|8[89])|74(?:0[02]|44|55)",
            ],
          ],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[24-9]"]],
        ],
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[24-9]"]]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "80\\d{6}"],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MM: [
        ,
        [
          ,
          ,
          "1\\d{5,7}|95\\d{6}|(?:[4-7]|9[0-46-9])\\d{6,8}|(?:2|8\\d)\\d{5,8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10],
          [5],
        ],
        [
          ,
          ,
          "(?:1(?:(?:2\\d|3[56]|[89][0-6])\\d|4(?:2[29]|62|7[0-2]|83)|6)|2(?:2(?:00|8[34])|4(?:0\\d|[26]2|7[0-2]|83)|51\\d\\d)|4(?:2(?:2\\d\\d|48[013])|3(?:20\\d|4(?:70|83)|56)|420\\d|5470)|6(?:0(?:[23]|88\\d)|(?:124|[56]2\\d)\\d|2472|3(?:20\\d|470)|4(?:2[04]\\d|472)|7(?:(?:3\\d|8[01459])\\d|4[67]0)))\\d{4}|5(?:2(?:2\\d{5,6}|47[02]\\d{4})|(?:3472|4(?:2(?:1|86)|470)|522\\d|6(?:20\\d|483)|7(?:20\\d|48[01])|8(?:20\\d|47[02])|9(?:20\\d|470))\\d{4})|7(?:(?:0470|4(?:25\\d|470)|5(?:202|470|96\\d))\\d{4}|1(?:20\\d{4,5}|4(?:70|83)\\d{4}))|8(?:1(?:2\\d{5,6}|4(?:10|7[01]\\d)\\d{3})|2(?:2\\d{5,6}|(?:320|490\\d)\\d{3})|(?:3(?:2\\d\\d|470)|4[24-7]|5(?:(?:2\\d|51)\\d|4(?:[1-35-9]\\d|4[0-57-9]))|6[23])\\d{4})|(?:1[2-6]\\d|4(?:2[24-8]|3[2-7]|[46][2-6]|5[3-5])|5(?:[27][2-8]|3[2-68]|4[24-8]|5[23]|6[2-4]|8[24-7]|9[2-7])|6(?:[19]20|42[03-6]|(?:52|7[45])\\d)|7(?:[04][24-8]|[15][2-7]|22|3[2-4])|8(?:1[2-689]|2[2-8]|[35]2\\d))\\d{4}|25\\d{5,6}|(?:2[2-9]|6(?:1[2356]|[24][2-6]|3[24-6]|5[2-4]|6[2-8]|7[235-7]|8[245]|9[24])|8(?:3[24]|5[245]))\\d{4}",
          ,
          ,
          ,
          "1234567",
          ,
          ,
          [6, 7, 8, 9],
          [5],
        ],
        [
          ,
          ,
          "(?:17[01]|9(?:2(?:[0-4]|[56]\\d\\d)|(?:3(?:[0-36]|4\\d)|(?:6\\d|8[89]|9[4-8])\\d|7(?:3|40|[5-9]\\d))\\d|4(?:(?:[0245]\\d|[1379])\\d|88)|5[0-6])\\d)\\d{4}|9[69]1\\d{6}|9(?:[68]\\d|9[089])\\d{5}",
          ,
          ,
          ,
          "92123456",
          ,
          ,
          [7, 8, 9, 10],
        ],
        [, , "80080(?:0[1-9]|2\\d)\\d{3}", , , , "8008001234", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "1333\\d{4}|[12]468\\d{4}", , , , "13331234", , , [8]],
        "MM",
        95,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{2})(\\d{3})", "$1 $2 $3", ["16|2"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{3})",
            "$1 $2 $3",
            ["[45]|6(?:0[23]|[1-689]|7[235-7])|7(?:[0-4]|5[2-7])|8[1-6]"],
            "0$1",
          ],
          [, "(\\d)(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[12]"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["[4-7]|8[1-35]"],
            "0$1",
          ],
          [
            ,
            "(\\d)(\\d{3})(\\d{4,6})",
            "$1 $2 $3",
            ["9(?:2[0-4]|[35-9]|4[137-9])"],
            "0$1",
          ],
          [, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["2"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["8"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["92"], "0$1"],
          [, "(\\d)(\\d{5})(\\d{4})", "$1 $2 $3", ["9"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MN: [
        ,
        [, , "[12]\\d{7,9}|[5-9]\\d{7}", , , , , , , [8, 9, 10], [4, 5, 6]],
        [
          ,
          ,
          "[12]2[1-3]\\d{5,6}|(?:(?:[12](?:1|27)|5[368])\\d\\d|7(?:0(?:[0-5]\\d|7[078]|80)|128))\\d{4}|[12](?:3[2-8]|4[2-68]|5[1-4689])\\d{6,7}",
          ,
          ,
          ,
          "53123456",
          ,
          ,
          ,
          [4, 5, 6],
        ],
        [
          ,
          ,
          "(?:83[01]|92[039])\\d{5}|(?:5[05]|6[069]|8[015689]|9[013-9])\\d{6}",
          ,
          ,
          ,
          "88123456",
          ,
          ,
          [8],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "712[0-79]\\d{4}|7(?:1[013-9]|[25-9]\\d)\\d{5}",
          ,
          ,
          ,
          "75123456",
          ,
          ,
          [8],
        ],
        "MN",
        976,
        "001",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{4})", "$1 $2 $3", ["[12]1"], "0$1"],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[5-9]"]],
          [, "(\\d{3})(\\d{5,6})", "$1 $2", ["[12]2[1-3]"], "0$1"],
          [
            ,
            "(\\d{4})(\\d{5,6})",
            "$1 $2",
            [
              "[12](?:27|3[2-8]|4[2-68]|5[1-4689])",
              "[12](?:27|3[2-8]|4[2-68]|5[1-4689])[0-3]",
            ],
            "0$1",
          ],
          [, "(\\d{5})(\\d{4,5})", "$1 $2", ["[12]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MO: [
        ,
        [, , "0800\\d{3}|(?:28|[68]\\d)\\d{6}", , , , , , , [7, 8]],
        [
          ,
          ,
          "(?:28[2-9]|8(?:11|[2-57-9]\\d))\\d{5}",
          ,
          ,
          ,
          "28212345",
          ,
          ,
          [8],
        ],
        [
          ,
          ,
          "6800[0-79]\\d{3}|6(?:[235]\\d\\d|6(?:0[0-5]|[1-9]\\d)|8(?:0[1-9]|[14-8]\\d|2[5-9]|[39][0-4]))\\d{4}",
          ,
          ,
          ,
          "66123456",
          ,
          ,
          [8],
        ],
        [, , "0800\\d{3}", , , , "0800501", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "MO",
        853,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{3})", "$1 $2", ["0"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[268]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MP: [
        ,
        [, , "[58]\\d{9}|(?:67|90)0\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "670(?:2(?:3[3-7]|56|8[4-8])|32[1-38]|4(?:33|8[348])|5(?:32|55|88)|6(?:64|70|82)|78[3589]|8[3-9]8|989)\\d{4}",
          ,
          ,
          ,
          "6702345678",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "670(?:2(?:3[3-7]|56|8[4-8])|32[1-38]|4(?:33|8[348])|5(?:32|55|88)|6(?:64|70|82)|78[3589]|8[3-9]8|989)\\d{4}",
          ,
          ,
          ,
          "6702345678",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "MP",
        1,
        "011",
        "1",
        ,
        ,
        "([2-9]\\d{6})$|1",
        "670$1",
        ,
        1,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "670",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MQ: [
        ,
        [, , "596\\d{6}|(?:69|80|9\\d)\\d{7}", , , , , , , [9]],
        [
          ,
          ,
          "596(?:[03-7]\\d|1[05]|2[7-9]|8[0-39]|9[04-9])\\d{4}",
          ,
          ,
          ,
          "596301234",
        ],
        [, , "69(?:6(?:[0-46-9]\\d|5[0-6])|727)\\d{4}", , , , "696201234"],
        [, , "80[0-5]\\d{6}", , , , "800012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "9(?:397[0-3]|477[0-5]|76(?:6\\d|7[0-367]))\\d{4}",
          ,
          ,
          ,
          "976612345",
        ],
        "MQ",
        596,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[569]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MR: [
        ,
        [, , "(?:[2-4]\\d\\d|800)\\d{5}", , , , , , , [8]],
        [, , "(?:25[08]|35\\d|45[1-7])\\d{5}", , , , "35123456"],
        [, , "[2-4][0-46-9]\\d{6}", , , , "22123456"],
        [, , "800\\d{5}", , , , "80012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "MR",
        222,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[2-48]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MS: [
        ,
        [, , "(?:[58]\\d\\d|664|900)\\d{7}", , , , , , , [10], [7]],
        [, , "6644(?:1[0-3]|91)\\d{4}", , , , "6644912345", , , , [7]],
        [
          ,
          ,
          "664(?:3(?:49|9[1-6])|49[2-6])\\d{4}",
          ,
          ,
          ,
          "6644923456",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "MS",
        1,
        "011",
        "1",
        ,
        ,
        "([34]\\d{6})$|1",
        "664$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "664",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MT: [
        ,
        [, , "3550\\d{4}|(?:[2579]\\d\\d|800)\\d{5}", , , , , , , [8]],
        [
          ,
          ,
          "20(?:3[1-4]|6[059])\\d{4}|2(?:0[19]|[1-357]\\d|60)\\d{5}",
          ,
          ,
          ,
          "21001234",
        ],
        [
          ,
          ,
          "(?:7(?:210|[79]\\d\\d)|9(?:[29]\\d\\d|69[67]|8(?:1[1-3]|89|97)))\\d{4}",
          ,
          ,
          ,
          "96961234",
        ],
        [, , "800(?:02|[3467]\\d)\\d{3}", , , , "80071234"],
        [
          ,
          ,
          "5(?:0(?:0(?:37|43)|(?:6\\d|70|9[0168])\\d)|[12]\\d0[1-5])\\d{3}",
          ,
          ,
          ,
          "50037123",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "3550\\d{4}", , , , "35501234"],
        "MT",
        356,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{4})(\\d{4})", "$1 $2", ["[2357-9]"]]],
        ,
        [, , "7117\\d{4}", , , , "71171234"],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "501\\d{5}", , , , "50112345"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MU: [
        ,
        [, , "(?:[57]|8\\d\\d)\\d{7}|[2-468]\\d{6}", , , , , , , [7, 8, 10]],
        [
          ,
          ,
          "(?:2(?:[0346-8]\\d|1[0-7])|4(?:[013568]\\d|2[4-8])|54(?:[3-5]\\d|71)|6\\d\\d|8(?:14|3[129]))\\d{4}",
          ,
          ,
          ,
          "54480123",
          ,
          ,
          [7, 8],
        ],
        [
          ,
          ,
          "5(?:4(?:2[1-389]|7[1-9])|87[15-8])\\d{4}|(?:5(?:2[5-9]|4[3-689]|[57]\\d|8[0-689]|9[0-8])|7(?:0[0-3]|3[013]))\\d{5}",
          ,
          ,
          ,
          "52512345",
          ,
          ,
          [8],
        ],
        [, , "802\\d{7}|80[0-2]\\d{4}", , , , "8001234", , , [7, 10]],
        [, , "30\\d{5}", , , , "3012345", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "3(?:20|9\\d)\\d{4}", , , , "3201234", , , [7]],
        "MU",
        230,
        "0(?:0|[24-7]0|3[03])",
        ,
        ,
        ,
        ,
        ,
        "020",
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[2-46]|8[013]"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[57]"]],
          [, "(\\d{5})(\\d{5})", "$1 $2", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MV: [
        ,
        [, , "(?:800|9[0-57-9]\\d)\\d{7}|[34679]\\d{6}", , , , , , , [7, 10]],
        [
          ,
          ,
          "(?:3(?:0[0-3]|3[0-59])|6(?:[58][024689]|6[024-68]|7[02468]))\\d{4}",
          ,
          ,
          ,
          "6701234",
          ,
          ,
          [7],
        ],
        [, , "(?:46[46]|[79]\\d\\d)\\d{4}", , , , "7712345", , , [7]],
        [, , "800\\d{7}", , , , "8001234567", , , [10]],
        [, , "900\\d{7}", , , , "9001234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "MV",
        960,
        "0(?:0|19)",
        ,
        ,
        ,
        ,
        ,
        "00",
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1-$2", ["[34679]"]],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["[89]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "4(?:0[01]|50)\\d{4}", , , , "4001234", , , [7]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MW: [
        ,
        [, , "(?:[1289]\\d|31|77)\\d{7}|1\\d{6}", , , , , , , [7, 9]],
        [, , "(?:1[2-9]|2[12]\\d\\d)\\d{5}", , , , "1234567"],
        [, , "111\\d{6}|(?:31|77|[89][89])\\d{7}", , , , "991234567", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "MW",
        265,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{3})", "$1 $2 $3", ["1[2-9]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["2"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[137-9]"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MX: [
        ,
        [
          ,
          ,
          "1(?:(?:22|44|7[27]|87|99)[1-9]|65[0-689])\\d{7}|(?:1(?:[01]\\d|2[13-9]|[35][1-9]|4[0-35-9]|6[0-46-9]|7[013-689]|8[1-69]|9[1-8])|[2-9]\\d)\\d{8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [10, 11],
          [7, 8],
        ],
        [
          ,
          ,
          "657[12]\\d{6}|(?:2(?:0[01]|2\\d|3[1-35-8]|4[13-9]|7[1-689]|8[1-578]|9[467])|3(?:1[1-79]|[2458][1-9]|3\\d|7[1-8]|9[1-5])|4(?:1[1-57-9]|[25-7][1-9]|3[1-8]|4\\d|8[1-35-9]|9[2-689])|5(?:[56]\\d|88|9[1-79])|6(?:1[2-68]|[2-4][1-9]|5[1-3689]|6[1-57-9]|7[1-7]|8[67]|9[4-8])|7(?:[1346][1-9]|[27]\\d|5[13-9]|8[1-69]|9[17])|8(?:1\\d|2[13-689]|3[1-6]|4[124-6]|6[1246-9]|7[0-378]|9[12479])|9(?:1[346-9]|2[1-4]|3[2-46-8]|5[1348]|6[1-9]|7[12]|8[1-8]|9\\d))\\d{7}",
          ,
          ,
          ,
          "2001234567",
          ,
          ,
          [10],
          [7, 8],
        ],
        [
          ,
          ,
          "657[12]\\d{6}|(?:1(?:2(?:2[1-9]|3[1-35-8]|4[13-9]|7[1-689]|8[1-578]|9[467])|3(?:1[1-79]|[2458][1-9]|3\\d|7[1-8]|9[1-5])|4(?:1[1-57-9]|[24-7][1-9]|3[1-8]|8[1-35-9]|9[2-689])|5(?:[56]\\d|88|9[1-79])|6(?:1[2-68]|[2-4][1-9]|5[1-3689]|6[1-57-9]|7[1-7]|8[67]|9[4-8])|7(?:[1-467][1-9]|5[13-9]|8[1-69]|9[17])|8(?:1\\d|2[13-689]|3[1-6]|4[124-6]|6[1246-9]|7[1-378]|9[12479])|9(?:1[346-9]|2[1-4]|3[2-46-8]|5[1348]|[69][1-9]|7[12]|8[1-8]))|2(?:2\\d|3[1-35-8]|4[13-9]|7[1-689]|8[1-578]|9[467])|3(?:1[1-79]|[2458][1-9]|3\\d|7[1-8]|9[1-5])|4(?:1[1-57-9]|[25-7][1-9]|3[1-8]|4\\d|8[1-35-9]|9[2-689])|5(?:[56]\\d|88|9[1-79])|6(?:1[2-68]|[2-4][1-9]|5[1-3689]|6[1-57-9]|7[1-7]|8[67]|9[4-8])|7(?:[1346][1-9]|[27]\\d|5[13-9]|8[1-69]|9[17])|8(?:1\\d|2[13-689]|3[1-6]|4[124-6]|6[1246-9]|7[0-378]|9[12479])|9(?:1[346-9]|2[1-4]|3[2-46-8]|5[1348]|6[1-9]|7[12]|8[1-8]|9\\d))\\d{7}",
          ,
          ,
          ,
          "12221234567",
          ,
          ,
          ,
          [7, 8],
        ],
        [, , "8(?:00|88)\\d{7}", , , , "8001234567", , , [10]],
        [, , "900\\d{7}", , , , "9001234567", , , [10]],
        [, , "300\\d{7}", , , , "3001234567", , , [10]],
        [, , "500\\d{7}", , , , "5001234567", , , [10]],
        [, , , , , , , , , [-1]],
        "MX",
        52,
        "0[09]",
        "01",
        ,
        ,
        "0(?:[12]|4[45])|1",
        ,
        "00",
        ,
        [
          [, "(\\d{5})", "$1", ["53"]],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2 $3", ["33|5[56]|81"], , , 1],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["[2-9]"], , , 1],
          [
            ,
            "(\\d)(\\d{2})(\\d{4})(\\d{4})",
            "$2 $3 $4",
            ["1(?:33|5[56]|81)"],
            ,
            ,
            1,
          ],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{4})", "$2 $3 $4", ["1"], , , 1],
        ],
        [
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2 $3", ["33|5[56]|81"], , , 1],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["[2-9]"], , , 1],
          [
            ,
            "(\\d)(\\d{2})(\\d{4})(\\d{4})",
            "$2 $3 $4",
            ["1(?:33|5[56]|81)"],
            ,
            ,
            1,
          ],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{4})", "$2 $3 $4", ["1"], , , 1],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MY: [
        ,
        [, , "1\\d{8,9}|(?:3\\d|[4-9])\\d{7}", , , , , , , [8, 9, 10], [6, 7]],
        [
          ,
          ,
          "(?:3(?:2[0-36-9]|3[0-368]|4[0-278]|5[0-24-8]|6[0-467]|7[1246-9]|8\\d|9[0-57])\\d|4(?:2[0-689]|[3-79]\\d|8[1-35689])|5(?:2[0-589]|[3468]\\d|5[0-489]|7[1-9]|9[23])|6(?:2[2-9]|3[1357-9]|[46]\\d|5[0-6]|7[0-35-9]|85|9[015-8])|7(?:[2579]\\d|3[03-68]|4[0-8]|6[5-9]|8[0-35-9])|8(?:[24][2-8]|3[2-5]|5[2-7]|6[2-589]|7[2-578]|[89][2-9])|9(?:0[57]|13|[25-7]\\d|[3489][0-8]))\\d{5}",
          ,
          ,
          ,
          "323856789",
          ,
          ,
          [8, 9],
          [6, 7],
        ],
        [
          ,
          ,
          "1(?:1888[689]|4400|8(?:47|8[27])[0-4])\\d{4}|1(?:0(?:[23568]\\d|4[0-6]|7[016-9]|9[0-8])|1(?:[1-5]\\d\\d|6(?:0[5-9]|[1-9]\\d)|7(?:[0-4]\\d|5[0-7]))|(?:[269]\\d|[37][1-9]|4[235-9])\\d|5(?:31|9\\d\\d)|8(?:1[23]|[236]\\d|4[06]|5(?:46|[7-9])|7[016-9]|8[01]|9[0-8]))\\d{5}",
          ,
          ,
          ,
          "123456789",
          ,
          ,
          [9, 10],
        ],
        [, , "1[378]00\\d{6}", , , , "1300123456", , , [10]],
        [, , "1600\\d{6}", , , , "1600123456", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "15(?:4(?:6[0-4]\\d|8(?:0[125]|[17]\\d|21|3[01]|4[01589]|5[014]|6[02]))|6(?:32[0-6]|78\\d))\\d{4}",
          ,
          ,
          ,
          "1546012345",
          ,
          ,
          [10],
        ],
        "MY",
        60,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{4})", "$1-$2 $3", ["[4-79]"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3,4})",
            "$1-$2 $3",
            [
              "1(?:[02469]|[378][1-9]|53)|8",
              "1(?:[02469]|[37][1-9]|53|8(?:[1-46-9]|5[7-9]))|8",
            ],
            "0$1",
          ],
          [, "(\\d)(\\d{4})(\\d{4})", "$1-$2 $3", ["3"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{2})(\\d{4})", "$1-$2-$3-$4", ["1(?:[367]|80)"]],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1-$2 $3", ["15"], "0$1"],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1-$2 $3", ["1"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      MZ: [
        ,
        [, , "(?:2|8\\d)\\d{7}", , , , , , , [8, 9]],
        [
          ,
          ,
          "2(?:[1346]\\d|5[0-2]|[78][12]|93)\\d{5}",
          ,
          ,
          ,
          "21123456",
          ,
          ,
          [8],
        ],
        [, , "8[2-79]\\d{7}", , , , "821234567", , , [9]],
        [, , "800\\d{6}", , , , "800123456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "MZ",
        258,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["2|8[2-79]"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NA: [
        ,
        [, , "[68]\\d{7,8}", , , , , , , [8, 9]],
        [
          ,
          ,
          "64426\\d{3}|6(?:1(?:2[2-7]|3[01378]|4[0-4])|254|32[0237]|4(?:27|41|5[25])|52[236-8]|626|7(?:2[2-4]|30))\\d{4,5}|6(?:1(?:(?:0\\d|2[0189]|3[24-69]|4[5-9])\\d|17|69|7[014])|2(?:17|5[0-36-8]|69|70)|3(?:17|2[14-689]|34|6[289]|7[01]|81)|4(?:17|2[0-2]|4[06]|5[0137]|69|7[01])|5(?:17|2[0459]|69|7[01])|6(?:17|25|38|42|69|7[01])|7(?:17|2[569]|3[13]|6[89]|7[01]))\\d{4}",
          ,
          ,
          ,
          "61221234",
        ],
        [, , "(?:60|8[1245])\\d{7}", , , , "811234567", , , [9]],
        [, , "80\\d{7}", , , , "800123456", , , [9]],
        [, , "8701\\d{5}", , , , "870123456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "8(?:3\\d\\d|86)\\d{5}", , , , "88612345"],
        "NA",
        264,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["88"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["6"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["87"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NC: [
        ,
        [, , "(?:050|[2-57-9]\\d\\d)\\d{3}", , , , , , , [6]],
        [, , "(?:2[03-9]|3[0-5]|4[1-7]|88)\\d{4}", , , , "201234"],
        [, , "(?:5[0-4]|[79]\\d|8[0-79])\\d{4}", , , , "751234"],
        [, , "050\\d{3}", , , , "050012"],
        [, , "36\\d{4}", , , , "366711"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "NC",
        687,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})", "$1", ["5[6-8]"]],
          [, "(\\d{2})(\\d{2})(\\d{2})", "$1.$2.$3", ["[02-57-9]"]],
        ],
        [[, "(\\d{2})(\\d{2})(\\d{2})", "$1.$2.$3", ["[02-57-9]"]]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NE: [
        ,
        [, , "[027-9]\\d{7}", , , , , , , [8]],
        [
          ,
          ,
          "2(?:0(?:20|3[1-8]|4[13-5]|5[14]|6[14578]|7[1-578])|1(?:4[145]|5[14]|6[14-68]|7[169]|88))\\d{4}",
          ,
          ,
          ,
          "20201234",
        ],
        [, , "(?:23|7[047]|[89]\\d)\\d{6}", , , , "93123456"],
        [, , "08\\d{6}", , , , "08123456"],
        [, , "09\\d{6}", , , , "09123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "NE",
        227,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["08"]],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[089]|2[013]|7[047]"],
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NF: [
        ,
        [, , "[13]\\d{5}", , , , , , , [6], [5]],
        [, , "(?:1(?:06|17|28|39)|3[0-2]\\d)\\d{3}", , , , "106609", , , , [5]],
        [, , "(?:14|3[58])\\d{4}", , , , "381234", , , , [5]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "NF",
        672,
        "00",
        ,
        ,
        ,
        "([0-258]\\d{4})$",
        "3$1",
        ,
        ,
        [
          [, "(\\d{2})(\\d{4})", "$1 $2", ["1[0-3]"]],
          [, "(\\d)(\\d{5})", "$1 $2", ["[13]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NG: [
        ,
        [
          ,
          ,
          "2[0-24-9]\\d{8}|[78]\\d{10,13}|[7-9]\\d{9}|[1-9]\\d{7}|[124-7]\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 10, 11, 12, 13, 14],
          [5, 6],
        ],
        [
          ,
          ,
          "20(?:[1259]\\d|3[013-9]|4[1-8]|6[024-689]|7[1-79]|8[2-9])\\d{6}|(?:(?:[1-356]\\d|4[02-8]|8[2-9])\\d|9(?:0[3-9]|[1-9]\\d))\\d{5}|7(?:0(?:[013-689]\\d|2[0-24-9])\\d{3,4}|[1-79]\\d{6})|(?:[12]\\d|4[147]|5[14579]|6[1578]|7[1-3578])\\d{5}",
          ,
          ,
          ,
          "18040123",
          ,
          ,
          [7, 8, 10],
          [5, 6],
        ],
        [
          ,
          ,
          "(?:702[0-24-9]|819[01])\\d{6}|(?:7(?:0[13-9]|[12]\\d)|8(?:0[1-9]|1[0-8])|9(?:0[1-9]|1[1-6]))\\d{7}",
          ,
          ,
          ,
          "8021234567",
          ,
          ,
          [10],
        ],
        [, , "800\\d{7,11}", , , , "80017591759", , , [10, 11, 12, 13, 14]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "NG",
        234,
        "009",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{3})", "$1 $2 $3", ["78"], "0$1"],
          [
            ,
            "(\\d)(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["[12]|9(?:0[3-9]|[1-9])"],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2,3})",
            "$1 $2 $3",
            ["[3-6]|7(?:0[0-689]|[1-79])|8[2-9]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[7-9]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["20[129]"], "0$1"],
          [, "(\\d{4})(\\d{2})(\\d{4})", "$1 $2 $3", ["2"], "0$1"],
          [, "(\\d{3})(\\d{4})(\\d{4,5})", "$1 $2 $3", ["[78]"], "0$1"],
          [, "(\\d{3})(\\d{5})(\\d{5,6})", "$1 $2 $3", ["[78]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "700\\d{7,11}", , , , "7001234567", , , [10, 11, 12, 13, 14]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NI: [
        ,
        [, , "(?:1800|[25-8]\\d{3})\\d{4}", , , , , , , [8]],
        [, , "2\\d{7}", , , , "21234567"],
        [
          ,
          ,
          "(?:5(?:5[0-7]|[78]\\d)|6(?:20|3[035]|4[045]|5[05]|77|8[1-9]|9[059])|(?:7[5-8]|8\\d)\\d)\\d{5}",
          ,
          ,
          ,
          "81234567",
        ],
        [, , "1800\\d{4}", , , , "18001234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "NI",
        505,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{4})(\\d{4})", "$1 $2", ["[125-8]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NL: [
        ,
        [
          ,
          ,
          "(?:[124-7]\\d\\d|3(?:[02-9]\\d|1[0-8]))\\d{6}|8\\d{6,9}|9\\d{6,10}|1\\d{4,5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 8, 9, 10, 11],
        ],
        [
          ,
          ,
          "(?:1(?:[035]\\d|1[13-578]|6[124-8]|7[24]|8[0-467])|2(?:[0346]\\d|2[2-46-9]|5[125]|9[479])|3(?:[03568]\\d|1[3-8]|2[01]|4[1-8])|4(?:[0356]\\d|1[1-368]|7[58]|8[15-8]|9[23579])|5(?:[0358]\\d|[19][1-9]|2[1-57-9]|4[13-8]|6[126]|7[0-3578])|7\\d\\d)\\d{6}",
          ,
          ,
          ,
          "101234567",
          ,
          ,
          [9],
        ],
        [, , "(?:6[1-58]|970\\d)\\d{7}", , , , "612345678", , , [9, 11]],
        [, , "800\\d{4,7}", , , , "8001234", , , [7, 8, 9, 10]],
        [, , "90[069]\\d{4,7}", , , , "9061234", , , [7, 8, 9, 10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:85|91)\\d{7}", , , , "851234567", , , [9]],
        "NL",
        31,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{4})", "$1", ["1[238]|[34]"]],
          [, "(\\d{2})(\\d{3,4})", "$1 $2", ["14"]],
          [, "(\\d{6})", "$1", ["1"]],
          [, "(\\d{3})(\\d{4,7})", "$1 $2", ["[89]0"], "0$1"],
          [, "(\\d{2})(\\d{7})", "$1 $2", ["66"], "0$1"],
          [, "(\\d)(\\d{8})", "$1 $2", ["6"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            ["1[16-8]|2[259]|3[124]|4[17-9]|5[124679]"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[1-578]|91"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{5})", "$1 $2 $3", ["9"], "0$1"],
        ],
        [
          [, "(\\d{3})(\\d{4,7})", "$1 $2", ["[89]0"], "0$1"],
          [, "(\\d{2})(\\d{7})", "$1 $2", ["66"], "0$1"],
          [, "(\\d)(\\d{8})", "$1 $2", ["6"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            ["1[16-8]|2[259]|3[124]|4[17-9]|5[124679]"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[1-578]|91"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{5})", "$1 $2 $3", ["9"], "0$1"],
        ],
        [, , "66\\d{7}", , , , "662345678", , , [9]],
        ,
        ,
        [
          ,
          ,
          "140(?:1[035]|2[0346]|3[03568]|4[0356]|5[0358]|8[458])|140(?:1[16-8]|2[259]|3[124]|4[17-9]|5[124679]|7)\\d",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6],
        ],
        [
          ,
          ,
          "140(?:1[035]|2[0346]|3[03568]|4[0356]|5[0358]|8[458])|(?:140(?:1[16-8]|2[259]|3[124]|4[17-9]|5[124679]|7)|8[478]\\d{6})\\d",
          ,
          ,
          ,
          "14020",
          ,
          ,
          [5, 6, 9],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NO: [
        ,
        [, , "(?:0|[2-9]\\d{3})\\d{4}", , , , , , , [5, 8]],
        [
          ,
          ,
          "(?:2[1-4]|3[1-3578]|5[1-35-7]|6[1-4679]|7[0-8])\\d{6}",
          ,
          ,
          ,
          "21234567",
          ,
          ,
          [8],
        ],
        [, , "(?:4[015-8]|9\\d)\\d{6}", , , , "40612345", , , [8]],
        [, , "80[01]\\d{5}", , , , "80012345", , , [8]],
        [, , "82[09]\\d{5}", , , , "82012345", , , [8]],
        [, , "810(?:0[0-6]|[2-8]\\d)\\d{3}", , , , "81021234", , , [8]],
        [, , "880\\d{5}", , , , "88012345", , , [8]],
        [, , "85[0-5]\\d{5}", , , , "85012345", , , [8]],
        "NO",
        47,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{2})(\\d{3})", "$1 $2 $3", ["8"]],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[2-79]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        1,
        "[02-689]|7[0-8]",
        [, , , , , , , , , [-1]],
        [, , "(?:0[2-9]|81(?:0(?:0[7-9]|1\\d)|5\\d\\d))\\d{3}", , , , "02000"],
        ,
        ,
        [, , "81[23]\\d{5}", , , , "81212345", , , [8]],
      ],
      NP: [
        ,
        [, , "(?:1\\d|9)\\d{9}|[1-9]\\d{7}", , , , , , , [8, 10, 11], [6, 7]],
        [
          ,
          ,
          "(?:1[0-6]\\d|99[02-6])\\d{5}|(?:2[13-79]|3[135-8]|4[146-9]|5[135-7]|6[13-9]|7[15-9]|8[1-46-9]|9[1-7])[2-6]\\d{5}",
          ,
          ,
          ,
          "14567890",
          ,
          ,
          [8],
          [6, 7],
        ],
        [
          ,
          ,
          "9(?:00|6[0-3]|7[024-6]|8[0-24-68])\\d{7}",
          ,
          ,
          ,
          "9841234567",
          ,
          ,
          [10],
        ],
        [, , "1(?:66001|800\\d\\d)\\d{5}", , , , "16600101234", , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "NP",
        977,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{7})", "$1-$2", ["1[2-6]"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{6})",
            "$1-$2",
            ["1[01]|[2-8]|9(?:[1-59]|[67][2-6])"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{7})", "$1-$2", ["9"]],
          [, "(\\d{4})(\\d{2})(\\d{5})", "$1-$2-$3", ["1"]],
        ],
        [
          [, "(\\d)(\\d{7})", "$1-$2", ["1[2-6]"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{6})",
            "$1-$2",
            ["1[01]|[2-8]|9(?:[1-59]|[67][2-6])"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{7})", "$1-$2", ["9"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NR: [
        ,
        [, , "(?:444|(?:55|8\\d)\\d|666)\\d{4}", , , , , , , [7]],
        [, , "444\\d{4}", , , , "4441234"],
        [, , "(?:55[3-9]|666|8\\d\\d)\\d{4}", , , , "5551234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "NR",
        674,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[4-68]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NU: [
        ,
        [, , "(?:[4-7]|888\\d)\\d{3}", , , , , , , [4, 7]],
        [, , "[47]\\d{3}", , , , "7012", , , [4]],
        [, , "(?:[56]|888[1-9])\\d{3}", , , , "8884012"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "NU",
        683,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["8"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      NZ: [
        ,
        [
          ,
          ,
          "[1289]\\d{9}|50\\d{5}(?:\\d{2,3})?|[27-9]\\d{7,8}|(?:[34]\\d|6[0-35-9])\\d{6}|8\\d{4,6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 8, 9, 10],
        ],
        [
          ,
          ,
          "24099\\d{3}|(?:3[2-79]|[49][2-9]|6[235-9]|7[2-57-9])\\d{6}",
          ,
          ,
          ,
          "32345678",
          ,
          ,
          [8],
          [7],
        ],
        [
          ,
          ,
          "2(?:[0-27-9]\\d|6)\\d{6,7}|2(?:1\\d|75)\\d{5}",
          ,
          ,
          ,
          "211234567",
          ,
          ,
          [8, 9, 10],
        ],
        [, , "508\\d{6,7}|80\\d{6,8}", , , , "800123456", , , [8, 9, 10]],
        [
          ,
          ,
          "(?:1[13-57-9]\\d{5}|50(?:0[08]|30|66|77|88))\\d{3}|90\\d{6,8}",
          ,
          ,
          ,
          "900123456",
          ,
          ,
          [7, 8, 9, 10],
        ],
        [, , , , , , , , , [-1]],
        [, , "70\\d{7}", , , , "701234567", , , [9]],
        [, , , , , , , , , [-1]],
        "NZ",
        64,
        "0(?:0|161)",
        "0",
        ,
        ,
        "0",
        ,
        "00",
        ,
        [
          [, "(\\d{2})(\\d{3,8})", "$1 $2", ["8[1-79]"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2,3})",
            "$1 $2 $3",
            ["50[036-8]|8|90", "50(?:[0367]|88)|8|90"],
            "0$1",
          ],
          [
            ,
            "(\\d)(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["24|[346]|7[2-57-9]|9[2-9]"],
            "0$1",
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["2(?:10|74)|[589]"],
            "0$1",
          ],
          [, "(\\d{2})(\\d{3,4})(\\d{4})", "$1 $2 $3", ["1|2[028]"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3,5})",
            "$1 $2 $3",
            ["2(?:[169]|7[0-35-9])|7"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "8(?:1[16-9]|22|3\\d|4[045]|5[459]|6[235-9]|7[0-3579]|90)\\d{2,7}",
          ,
          ,
          ,
          "83012378",
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      OM: [
        ,
        [
          ,
          ,
          "(?:1505|[279]\\d{3}|500)\\d{4}|800\\d{5,6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9],
        ],
        [, , "2[1-6]\\d{6}", , , , "23123456", , , [8]],
        [
          ,
          ,
          "(?:1505|90[1-9]\\d)\\d{4}|(?:7[126-9]|9[1-9])\\d{6}",
          ,
          ,
          ,
          "92123456",
          ,
          ,
          [8],
        ],
        [, , "8007\\d{4,5}|(?:500|800[05])\\d{4}", , , , "80071234"],
        [, , "900\\d{5}", , , , "90012345", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "OM",
        968,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4,6})", "$1 $2", ["[58]"]],
          [, "(\\d{2})(\\d{6})", "$1 $2", ["2"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[179]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PA: [
        ,
        [
          ,
          ,
          "(?:00800|8\\d{3})\\d{6}|[68]\\d{7}|[1-57-9]\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 10, 11],
        ],
        [
          ,
          ,
          "(?:1(?:0\\d|1[479]|2[37]|3[0137]|4[17]|5[05]|6[058]|7[0167]|8[2358]|9[1389])|2(?:[0235-79]\\d|1[0-7]|4[013-9]|8[02-9])|3(?:[089]\\d|1[0-7]|2[0-5]|33|4[0-79]|5[0-35]|6[068]|7[0-8])|4(?:00|3[0-579]|4\\d|7[0-57-9])|5(?:[01]\\d|2[0-7]|[56]0|79)|7(?:0[09]|2[0-26-8]|3[03]|4[04]|5[05-9]|6[0156]|7[0-24-9]|8[5-9]|90)|8(?:09|2[89]|3\\d|4[0-24-689]|5[014]|8[02])|9(?:0[5-9]|1[0135-8]|2[036-9]|3[35-79]|40|5[0457-9]|6[05-9]|7[04-9]|8[35-8]|9\\d))\\d{4}",
          ,
          ,
          ,
          "2001234",
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "(?:1[16]1|21[89]|6\\d{3}|8(?:1[01]|7[23]))\\d{4}",
          ,
          ,
          ,
          "61234567",
          ,
          ,
          [7, 8],
        ],
        [, , "800\\d{4,5}|(?:00800|800\\d)\\d{6}", , , , "8001234"],
        [
          ,
          ,
          "(?:8(?:22|55|60|7[78]|86)|9(?:00|81))\\d{4}",
          ,
          ,
          ,
          "8601234",
          ,
          ,
          [7],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "PA",
        507,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1-$2", ["[1-57-9]"]],
          [, "(\\d{4})(\\d{4})", "$1-$2", ["[68]"]],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PE: [
        ,
        [, , "(?:[14-8]|9\\d)\\d{7}", , , , , , , [8, 9], [6, 7]],
        [
          ,
          ,
          "(?:(?:4[34]|5[14])[0-8]\\d|7(?:173|3[0-8]\\d)|8(?:10[05689]|6(?:0[06-9]|1[6-9]|29)|7(?:0[569]|[56]0)))\\d{4}|(?:1[0-8]|4[12]|5[236]|6[1-7]|7[246]|8[2-4])\\d{6}",
          ,
          ,
          ,
          "11234567",
          ,
          ,
          [8],
          [6, 7],
        ],
        [, , "9\\d{8}", , , , "912345678", , , [9]],
        [, , "800\\d{5}", , , , "80012345", , , [8]],
        [, , "805\\d{5}", , , , "80512345", , , [8]],
        [, , "801\\d{5}", , , , "80112345", , , [8]],
        [, , "80[24]\\d{5}", , , , "80212345", , , [8]],
        [, , , , , , , , , [-1]],
        "PE",
        51,
        "00|19(?:1[124]|77|90)00",
        "0",
        " Anexo ",
        ,
        "0",
        ,
        "00",
        ,
        [
          [, "(\\d{3})(\\d{5})", "$1 $2", ["80"], "(0$1)"],
          [, "(\\d)(\\d{7})", "$1 $2", ["1"], "(0$1)"],
          [, "(\\d{2})(\\d{6})", "$1 $2", ["[4-8]"], "(0$1)"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["9"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PF: [
        ,
        [, , "4\\d{5}(?:\\d{2})?|8\\d{7,8}", , , , , , , [6, 8, 9]],
        [, , "4(?:0[4-689]|9[4-68])\\d{5}", , , , "40412345", , , [8]],
        [, , "8[7-9]\\d{6}", , , , "87123456", , , [8]],
        [, , "80[0-5]\\d{6}", , , , "800012345", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "499\\d{5}", , , , "49901234", , , [8]],
        "PF",
        689,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3", ["44"]],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["4|8[7-9]"]],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "44\\d{4}", , , , , , , [6]],
        [, , "44\\d{4}", , , , "440123", , , [6]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PG: [
        ,
        [
          ,
          ,
          "(?:180|[78]\\d{3})\\d{4}|(?:[2-589]\\d|64)\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8],
        ],
        [
          ,
          ,
          "(?:(?:3[0-2]|4[257]|5[34]|9[78])\\d|64[1-9]|85[02-46-9])\\d{4}",
          ,
          ,
          ,
          "3123456",
          ,
          ,
          [7],
        ],
        [, , "(?:7\\d|8[1-38])\\d{6}", , , , "70123456", , , [8]],
        [, , "180\\d{4}", , , , "1801234", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "2(?:0[0-57]|7[568])\\d{4}", , , , "2751234", , , [7]],
        "PG",
        675,
        "00|140[1-3]",
        ,
        ,
        ,
        ,
        ,
        "00",
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["18|[2-69]|85"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[78]"]],
        ],
        ,
        [, , "27[01]\\d{4}", , , , "2700123", , , [7]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PH: [
        ,
        [
          ,
          ,
          "(?:[2-7]|9\\d)\\d{8}|2\\d{5}|(?:1800|8)\\d{7,9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 8, 9, 10, 11, 12, 13],
          [4, 5, 7],
        ],
        [
          ,
          ,
          "(?:(?:2[3-8]|3[2-68]|4[2-9]|5[2-6]|6[2-58]|7[24578])\\d{3}|88(?:22\\d\\d|42))\\d{4}|(?:2|8[2-8]\\d\\d)\\d{5}",
          ,
          ,
          ,
          "232345678",
          ,
          ,
          [6, 8, 9, 10],
          [4, 5, 7],
        ],
        [
          ,
          ,
          "(?:8(?:1[37]|9[5-8])|9(?:0[5-9]|1[0-24-9]|[235-7]\\d|4[2-9]|8[135-9]|9[1-9]))\\d{7}",
          ,
          ,
          ,
          "9051234567",
          ,
          ,
          [10],
        ],
        [, , "1800\\d{7,9}", , , , "180012345678", , , [11, 12, 13]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "PH",
        63,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{5})", "$1 $2", ["2"], "(0$1)"],
          [
            ,
            "(\\d{4})(\\d{4,6})",
            "$1 $2",
            [
              "3(?:23|39|46)|4(?:2[3-6]|[35]9|4[26]|76)|544|88[245]|(?:52|64|86)2",
              "3(?:230|397|461)|4(?:2(?:35|[46]4|51)|396|4(?:22|63)|59[347]|76[15])|5(?:221|446)|642[23]|8(?:622|8(?:[24]2|5[13]))",
            ],
            "(0$1)",
          ],
          [
            ,
            "(\\d{5})(\\d{4})",
            "$1 $2",
            ["346|4(?:27|9[35])|883", "3469|4(?:279|9(?:30|56))|8834"],
            "(0$1)",
          ],
          [, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["2"], "(0$1)"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[3-7]|8[2-8]"], "(0$1)"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["[89]"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"]],
          [, "(\\d{4})(\\d{1,2})(\\d{3})(\\d{4})", "$1 $2 $3 $4", ["1"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PK: [
        ,
        [
          ,
          ,
          "122\\d{6}|[24-8]\\d{10,11}|9(?:[013-9]\\d{8,10}|2(?:[01]\\d\\d|2(?:[06-8]\\d|1[01]))\\d{7})|(?:[2-8]\\d{3}|92(?:[0-7]\\d|8[1-9]))\\d{6}|[24-9]\\d{8}|[89]\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10, 11, 12],
          [5, 6, 7],
        ],
        [
          ,
          ,
          "(?:(?:21|42)[2-9]|58[126])\\d{7}|(?:2[25]|4[0146-9]|5[1-35-7]|6[1-8]|7[14]|8[16]|91)[2-9]\\d{6,7}|(?:2(?:3[2358]|4[2-4]|9[2-8])|45[3479]|54[2-467]|60[468]|72[236]|8(?:2[2-689]|3[23578]|4[3478]|5[2356])|9(?:2[2-8]|3[27-9]|4[2-6]|6[3569]|9[25-8]))[2-9]\\d{5,6}",
          ,
          ,
          ,
          "2123456789",
          ,
          ,
          [9, 10],
          [5, 6, 7, 8],
        ],
        [
          ,
          ,
          "3(?:[0-247]\\d|3[0-79]|55|64)\\d{7}",
          ,
          ,
          ,
          "3012345678",
          ,
          ,
          [10],
        ],
        [, , "800\\d{5}(?:\\d{3})?", , , , "80012345", , , [8, 11]],
        [, , "900\\d{5}", , , , "90012345", , , [8]],
        [, , , , , , , , , [-1]],
        [, , "122\\d{6}", , , , "122044444", , , [9]],
        [, , , , , , , , , [-1]],
        "PK",
        92,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})(\\d{2,7})", "$1 $2 $3", ["[89]0"], "0$1"],
          [, "(\\d{4})(\\d{5})", "$1 $2", ["1"]],
          [
            ,
            "(\\d{3})(\\d{6,7})",
            "$1 $2",
            [
              "2(?:3[2358]|4[2-4]|9[2-8])|45[3479]|54[2-467]|60[468]|72[236]|8(?:2[2-689]|3[23578]|4[3478]|5[2356])|9(?:2[2-8]|3[27-9]|4[2-6]|6[3569]|9[25-8])",
              "9(?:2[3-8]|98)|(?:2(?:3[2358]|4[2-4]|9[2-8])|45[3479]|54[2-467]|60[468]|72[236]|8(?:2[2-689]|3[23578]|4[3478]|5[2356])|9(?:22|3[27-9]|4[2-6]|6[3569]|9[25-7]))[2-9]",
            ],
            "(0$1)",
          ],
          [
            ,
            "(\\d{2})(\\d{7,8})",
            "$1 $2",
            ["(?:2[125]|4[0-246-9]|5[1-35-7]|6[1-8]|7[14]|8[16]|91)[2-9]"],
            "(0$1)",
          ],
          [, "(\\d{5})(\\d{5})", "$1 $2", ["58"], "(0$1)"],
          [, "(\\d{3})(\\d{7})", "$1 $2", ["3"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3})(\\d{3})",
            "$1 $2 $3 $4",
            ["2[125]|4[0-246-9]|5[1-35-7]|6[1-8]|7[14]|8[16]|91"],
            "(0$1)",
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3})(\\d{3})",
            "$1 $2 $3 $4",
            ["[24-9]"],
            "(0$1)",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "(?:2(?:[125]|3[2358]|4[2-4]|9[2-8])|4(?:[0-246-9]|5[3479])|5(?:[1-35-7]|4[2-467])|6(?:0[468]|[1-8])|7(?:[14]|2[236])|8(?:[16]|2[2-689]|3[23578]|4[3478]|5[2356])|9(?:1|22|3[27-9]|4[2-6]|6[3569]|9[2-7]))111\\d{6}",
          ,
          ,
          ,
          "21111825888",
          ,
          ,
          [11, 12],
        ],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PL: [
        ,
        [
          ,
          ,
          "(?:6|8\\d\\d)\\d{7}|[1-9]\\d{6}(?:\\d{2})?|[26]\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10],
        ],
        [
          ,
          ,
          "47\\d{7}|(?:1[2-8]|2[2-69]|3[2-4]|4[1-468]|5[24-689]|6[1-3578]|7[14-7]|8[1-79]|9[145])(?:[02-9]\\d{6}|1(?:[0-8]\\d{5}|9\\d{3}(?:\\d{2})?))",
          ,
          ,
          ,
          "123456789",
          ,
          ,
          [7, 9],
        ],
        [
          ,
          ,
          "21(?:1[013-5]|2\\d)\\d{5}|(?:45|5[0137]|6[069]|7[2389]|88)\\d{7}",
          ,
          ,
          ,
          "512345678",
          ,
          ,
          [9],
        ],
        [, , "800\\d{6,7}", , , , "800123456", , , [9, 10]],
        [, , "70[01346-8]\\d{6}", , , , "701234567", , , [9]],
        [, , "801\\d{6}", , , , "801234567", , , [9]],
        [, , , , , , , , , [-1]],
        [, , "39\\d{7}", , , , "391234567", , , [9]],
        "PL",
        48,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{5})", "$1", ["19"]],
          [, "(\\d{3})(\\d{3})", "$1 $2", ["11|20|64"]],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{3})",
            "$1 $2 $3",
            [
              "(?:1[2-8]|2[2-69]|3[2-4]|4[1-468]|5[24-689]|6[1-3578]|7[14-7]|8[1-79]|9[145])1",
              "(?:1[2-8]|2[2-69]|3[2-4]|4[1-468]|5[24-689]|6[1-3578]|7[14-7]|8[1-79]|9[145])19",
            ],
          ],
          [, "(\\d{3})(\\d{2})(\\d{2,3})", "$1 $2 $3", ["64"]],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            ["21|39|45|5[0137]|6[0469]|7[02389]|8(?:0[14]|8)"],
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["1[2-8]|[2-7]|8[1-79]|9[145]"],
          ],
          [, "(\\d{3})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["8"]],
        ],
        ,
        [, , "64\\d{4,7}", , , , "641234567", , , [6, 7, 8, 9]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "804\\d{6}", , , , "804123456", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PM: [
        ,
        [, , "[45]\\d{5}|(?:708|80\\d)\\d{6}", , , , , , , [6, 9]],
        [, , "(?:4[1-35-7]|5[01])\\d{4}", , , , "430123", , , [6]],
        [, , "(?:4[02-4]|5[056]|708[45][0-5])\\d{4}", , , , "551234"],
        [, , "80[0-5]\\d{6}", , , , "800012345", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "PM",
        508,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3", ["[45]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["7"]],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PR: [
        ,
        [, , "(?:[589]\\d\\d|787)\\d{7}", , , , , , , [10], [7]],
        [, , "(?:787|939)[2-9]\\d{6}", , , , "7872345678", , , , [7]],
        [, , "(?:787|939)[2-9]\\d{6}", , , , "7872345678", , , , [7]],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002345678"],
        [, , "900[2-9]\\d{6}", , , , "9002345678"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "PR",
        1,
        "011",
        "1",
        ,
        ,
        "1",
        ,
        ,
        1,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "787|939",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PS: [
        ,
        [, , "[2489]2\\d{6}|(?:1\\d|5)\\d{8}", , , , , , , [8, 9, 10], [7]],
        [
          ,
          ,
          "(?:22[2-47-9]|42[45]|82[014-68]|92[3569])\\d{5}",
          ,
          ,
          ,
          "22234567",
          ,
          ,
          [8],
          [7],
        ],
        [, , "5[69]\\d{7}", , , , "599123456", , , [9]],
        [, , "1800\\d{6}", , , , "1800123456", , , [10]],
        [, , , , , , , , , [-1]],
        [, , "1700\\d{6}", , , , "1700123456", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "PS",
        970,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["[2489]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["5"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1 $2 $3", ["1"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PT: [
        ,
        [, , "1693\\d{5}|(?:[26-9]\\d|30)\\d{7}", , , , , , , [9]],
        [
          ,
          ,
          "2(?:[12]\\d|3[1-689]|4[1-59]|[57][1-9]|6[1-35689]|8[1-69]|9[1256])\\d{6}",
          ,
          ,
          ,
          "212345678",
        ],
        [
          ,
          ,
          "6(?:[06]92(?:30|9\\d)|[35]92(?:3[034]|9\\d))\\d{3}|(?:(?:16|6[0356])93|9(?:[1-36]\\d\\d|480))\\d{5}",
          ,
          ,
          ,
          "912345678",
        ],
        [, , "80[02]\\d{6}", , , , "800123456"],
        [
          ,
          ,
          "(?:6(?:0[178]|4[68])\\d|76(?:0[1-57]|1[2-47]|2[237]))\\d{5}",
          ,
          ,
          ,
          "760123456",
        ],
        [, , "80(?:8\\d|9[1579])\\d{5}", , , , "808123456"],
        [, , "884[0-4689]\\d{5}", , , , "884123456"],
        [, , "30\\d{7}", , , , "301234567"],
        "PT",
        351,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["2[12]"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["16|[236-9]"]],
        ],
        ,
        [, , "6(?:222\\d|8988)\\d{4}", , , , "622212345"],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "70(?:38[01]|596|(?:7\\d|8[17])\\d)\\d{4}", , , , "707123456"],
        ,
        ,
        [, , "600\\d{6}|6[06]923[34]\\d{3}", , , , "600110000"],
      ],
      PW: [
        ,
        [, , "(?:[24-8]\\d\\d|345|900)\\d{4}", , , , , , , [7]],
        [
          ,
          ,
          "(?:2(?:55|77)|345|488|5(?:35|44|87)|6(?:22|54|79)|7(?:33|47)|8(?:24|55|76)|900)\\d{4}",
          ,
          ,
          ,
          "2771234",
        ],
        [
          ,
          ,
          "(?:(?:46|83)[0-5]|6[2-4689]0)\\d{4}|(?:45|77|88)\\d{5}",
          ,
          ,
          ,
          "6201234",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "PW",
        680,
        "01[12]",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[2-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      PY: [
        ,
        [
          ,
          ,
          "59\\d{4,6}|9\\d{5,10}|(?:[2-46-8]\\d|5[0-8])\\d{4,7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 11],
          [5],
        ],
        [
          ,
          ,
          "(?:[26]1|3[289]|4[1246-8]|7[1-3]|8[1-36])\\d{5,7}|(?:2(?:2[4-68]|[4-68]\\d|7[15]|9[1-5])|3(?:18|3[167]|4[2357]|51|[67]\\d)|4(?:3[12]|5[13]|9[1-47])|5(?:[1-4]\\d|5[02-4])|6(?:3[1-3]|44|7[1-8])|7(?:4[0-4]|5\\d|6[1-578]|75|8[0-8])|858)\\d{5,6}",
          ,
          ,
          ,
          "212345678",
          ,
          ,
          [7, 8, 9],
          [5, 6],
        ],
        [
          ,
          ,
          "9(?:51|6[129]|7[1-6]|8[1-7]|9[1-5])\\d{6}",
          ,
          ,
          ,
          "961456789",
          ,
          ,
          [9],
        ],
        [, , "9800\\d{5,7}", , , , "98000123456", , , [9, 10, 11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "8700[0-4]\\d{4}", , , , "870012345", , , [9]],
        "PY",
        595,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3,6})", "$1 $2", ["[2-9]0"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{5})",
            "$1 $2",
            ["[26]1|3[289]|4[1246-8]|7[1-3]|8[1-36]"],
            "(0$1)",
          ],
          [
            ,
            "(\\d{3})(\\d{4,5})",
            "$1 $2",
            ["2[279]|3[13-5]|4[359]|5|6(?:[34]|7[1-46-8])|7[46-8]|85"],
            "(0$1)",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["2[14-68]|3[26-9]|4[1246-8]|6(?:1|75)|7[1-35]|8[1-36]"],
            "(0$1)",
          ],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["87"]],
          [, "(\\d{3})(\\d{6})", "$1 $2", ["9(?:[5-79]|8[1-7])"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[2-8]"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["9"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "[2-9]0\\d{4,7}", , , , "201234567", , , [6, 7, 8, 9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      QA: [
        ,
        [
          ,
          ,
          "800\\d{4}|(?:2|800)\\d{6}|(?:0080|[3-7])\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 11],
        ],
        [
          ,
          ,
          "4(?:1111|2022)\\d{3}|4(?:[04]\\d\\d|14[0-6]|999)\\d{4}",
          ,
          ,
          ,
          "44123456",
          ,
          ,
          [8],
        ],
        [, , "[35-7]\\d{7}", , , , "33123456", , , [8]],
        [
          ,
          ,
          "800\\d{4}|(?:0080[01]|800)\\d{6}",
          ,
          ,
          ,
          "8001234",
          ,
          ,
          [7, 9, 11],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "QA",
        974,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["2[16]|8"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[3-7]"]],
        ],
        ,
        [, , "2[16]\\d{5}", , , , "2123456", , , [7]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      RE: [
        ,
        [, , "(?:26|[689]\\d)\\d{7}", , , , , , , [9]],
        [, , "26(?:2\\d\\d|3(?:0\\d|1[0-6]))\\d{4}", , , , "262161234"],
        [
          ,
          ,
          "69(?:2\\d\\d|3(?:[06][0-6]|1[013]|2[0-2]|3[0-39]|4\\d|5[0-5]|7[0-37]|8[0-8]|9[0-479]))\\d{4}",
          ,
          ,
          ,
          "692123456",
        ],
        [, , "80\\d{7}", , , , "801234567"],
        [, , "89[1-37-9]\\d{6}", , , , "891123456"],
        [, , "8(?:1[019]|2[0156]|84|90)\\d{6}", , , , "810123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "9(?:399[0-3]|479[0-5]|76(?:2[27]|3[0-37]))\\d{4}",
          ,
          ,
          ,
          "939901234",
        ],
        "RE",
        262,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[2689]"],
            "0$1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        1,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      RO: [
        ,
        [, , "(?:[236-8]\\d|90)\\d{7}|[23]\\d{5}", , , , , , , [6, 9]],
        [
          ,
          ,
          "[23][13-6]\\d{7}|(?:2(?:19\\d|[3-6]\\d9)|31\\d\\d)\\d\\d",
          ,
          ,
          ,
          "211234567",
        ],
        [
          ,
          ,
          "7020\\d{5}|(?:6(?:2\\d|40)|7(?:0[013-9]|1[0-3]|[2-7]\\d|8[03-8]|9[0-39]))\\d{6}",
          ,
          ,
          ,
          "712034567",
          ,
          ,
          [9],
        ],
        [, , "800\\d{6}", , , , "800123456", , , [9]],
        [, , "90[0136]\\d{6}", , , , "900123456", , , [9]],
        [, , "801\\d{6}", , , , "801123456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "RO",
        40,
        "00",
        "0",
        " int ",
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})", "$1 $2", ["2[3-6]", "2[3-6]\\d9"], "0$1"],
          [, "(\\d{2})(\\d{4})", "$1 $2", ["219|31"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[23]1"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[236-9]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "(?:37\\d|80[578])\\d{6}", , , , "372123456", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      RS: [
        ,
        [
          ,
          ,
          "38[02-9]\\d{6,9}|6\\d{7,9}|90\\d{4,8}|38\\d{5,6}|(?:7\\d\\d|800)\\d{3,9}|(?:[12]\\d|3[0-79])\\d{5,10}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 11, 12],
          [4, 5],
        ],
        [
          ,
          ,
          "(?:11[1-9]\\d|(?:2[389]|39)(?:0[2-9]|[2-9]\\d))\\d{3,8}|(?:1[02-9]|2[0-24-7]|3[0-8])[2-9]\\d{4,9}",
          ,
          ,
          ,
          "10234567",
          ,
          ,
          [7, 8, 9, 10, 11, 12],
          [4, 5, 6],
        ],
        [, , "6(?:[0-689]|7\\d)\\d{6,7}", , , , "601234567", , , [8, 9, 10]],
        [, , "800\\d{3,9}", , , , "80012345"],
        [
          ,
          ,
          "(?:78\\d|90[0169])\\d{3,7}",
          ,
          ,
          ,
          "90012345",
          ,
          ,
          [6, 7, 8, 9, 10],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "RS",
        381,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3,9})", "$1 $2", ["(?:2[389]|39)0|[7-9]"], "0$1"],
          [, "(\\d{2})(\\d{5,10})", "$1 $2", ["[1-36]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "7[06]\\d{4,10}", , , , "700123456"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      RU: [
        ,
        [, , "8\\d{13}|[347-9]\\d{9}", , , , , , , [10, 14], [7]],
        [
          ,
          ,
          "(?:3(?:0[12]|4[1-35-79]|5[1-3]|65|8[1-58]|9[0145])|4(?:01|1[1356]|2[13467]|7[1-5]|8[1-7]|9[1-689])|8(?:1[1-8]|2[01]|3[13-6]|4[0-8]|5[15]|6[1-35-79]|7[1-37-9]))\\d{7}",
          ,
          ,
          ,
          "3011234567",
          ,
          ,
          [10],
          [7],
        ],
        [, , "9\\d{9}", , , , "9123456789", , , [10]],
        [, , "8(?:0[04]|108\\d{3})\\d{7}", , , , "8001234567"],
        [, , "80[39]\\d{7}", , , , "8091234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , "808\\d{7}", , , , "8081234567", , , [10]],
        [, , , , , , , , , [-1]],
        "RU",
        7,
        "810",
        "8",
        ,
        ,
        "8",
        ,
        "8~10",
        ,
        [
          [, "(\\d{3})(\\d{2})(\\d{2})", "$1-$2-$3", ["[0-79]"]],
          [
            ,
            "(\\d{4})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            [
              "7(?:1[0-8]|2[1-9])",
              "7(?:1(?:[0-356]2|4[29]|7|8[27])|2(?:1[23]|[2-9]2))",
              "7(?:1(?:[0-356]2|4[29]|7|8[27])|2(?:13[03-69]|62[013-9]))|72[1-57-9]2",
            ],
            "8 ($1)",
            ,
            1,
          ],
          [
            ,
            "(\\d{5})(\\d)(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            [
              "7(?:1[0-68]|2[1-9])",
              "7(?:1(?:[06][3-6]|[18]|2[35]|[3-5][3-5])|2(?:[13][3-5]|[24-689]|7[457]))",
              "7(?:1(?:0(?:[356]|4[023])|[18]|2(?:3[013-9]|5)|3[45]|43[013-79]|5(?:3[1-8]|4[1-7]|5)|6(?:3[0-35-9]|[4-6]))|2(?:1(?:3[178]|[45])|[24-689]|3[35]|7[457]))|7(?:14|23)4[0-8]|71(?:33|45)[1-79]",
            ],
            "8 ($1)",
            ,
            1,
          ],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["7"], "8 ($1)", , 1],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2-$3-$4",
            ["[349]|8(?:[02-7]|1[1-8])"],
            "8 ($1)",
            ,
            1,
          ],
          [
            ,
            "(\\d{4})(\\d{4})(\\d{3})(\\d{3})",
            "$1 $2 $3 $4",
            ["8"],
            "8 ($1)",
          ],
        ],
        [
          [
            ,
            "(\\d{4})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            [
              "7(?:1[0-8]|2[1-9])",
              "7(?:1(?:[0-356]2|4[29]|7|8[27])|2(?:1[23]|[2-9]2))",
              "7(?:1(?:[0-356]2|4[29]|7|8[27])|2(?:13[03-69]|62[013-9]))|72[1-57-9]2",
            ],
            "8 ($1)",
            ,
            1,
          ],
          [
            ,
            "(\\d{5})(\\d)(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            [
              "7(?:1[0-68]|2[1-9])",
              "7(?:1(?:[06][3-6]|[18]|2[35]|[3-5][3-5])|2(?:[13][3-5]|[24-689]|7[457]))",
              "7(?:1(?:0(?:[356]|4[023])|[18]|2(?:3[013-9]|5)|3[45]|43[013-79]|5(?:3[1-8]|4[1-7]|5)|6(?:3[0-35-9]|[4-6]))|2(?:1(?:3[178]|[45])|[24-689]|3[35]|7[457]))|7(?:14|23)4[0-8]|71(?:33|45)[1-79]",
            ],
            "8 ($1)",
            ,
            1,
          ],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["7"], "8 ($1)", , 1],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2-$3-$4",
            ["[349]|8(?:[02-7]|1[1-8])"],
            "8 ($1)",
            ,
            1,
          ],
          [
            ,
            "(\\d{4})(\\d{4})(\\d{3})(\\d{3})",
            "$1 $2 $3 $4",
            ["8"],
            "8 ($1)",
          ],
        ],
        [, , , , , , , , , [-1]],
        1,
        "3[04-689]|[489]",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      RW: [
        ,
        [, , "(?:06|[27]\\d\\d|[89]00)\\d{6}", , , , , , , [8, 9]],
        [, , "(?:06|2[23568]\\d)\\d{6}", , , , "250123456"],
        [, , "7[237-9]\\d{7}", , , , "720123456", , , [9]],
        [, , "800\\d{6}", , , , "800123456", , , [9]],
        [, , "900\\d{6}", , , , "900123456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "RW",
        250,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["0"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["2"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[7-9]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SA: [
        ,
        [, , "92\\d{7}|(?:[15]|8\\d)\\d{8}", , , , , , , [9, 10], [7]],
        [
          ,
          ,
          "1(?:1\\d|2[24-8]|3[35-8]|4[3-68]|6[2-5]|7[235-7])\\d{6}",
          ,
          ,
          ,
          "112345678",
          ,
          ,
          [9],
          [7],
        ],
        [
          ,
          ,
          "579[01]\\d{5}|5(?:[013-689]\\d|7[0-8])\\d{6}",
          ,
          ,
          ,
          "512345678",
          ,
          ,
          [9],
        ],
        [, , "800\\d{7}", , , , "8001234567", , , [10]],
        [, , "925\\d{6}", , , , "925012345", , , [9]],
        [, , "920\\d{6}", , , , "920012345", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "SA",
        966,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{5})", "$1 $2", ["9"]],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["5"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["81"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "811\\d{7}", , , , "8110123456", , , [10]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SB: [
        ,
        [, , "(?:[1-6]|[7-9]\\d\\d)\\d{4}", , , , , , , [5, 7]],
        [
          ,
          ,
          "(?:1[4-79]|[23]\\d|4[0-2]|5[03]|6[0-37])\\d{3}",
          ,
          ,
          ,
          "40123",
          ,
          ,
          [5],
        ],
        [
          ,
          ,
          "48\\d{3}|(?:(?:7[1-9]|8[4-9])\\d|9(?:1[2-9]|2[013-9]|3[0-2]|[46]\\d|5[0-46-9]|7[0-689]|8[0-79]|9[0-8]))\\d{4}",
          ,
          ,
          ,
          "7421234",
        ],
        [, , "1[38]\\d{3}", , , , "18123", , , [5]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "5[12]\\d{3}", , , , "51123", , , [5]],
        "SB",
        677,
        "0[01]",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{5})", "$1 $2", ["7|8[4-9]|9(?:[1-8]|9[0-8])"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SC: [
        ,
        [, , "800\\d{4}|(?:[249]\\d|64)\\d{5}", , , , , , , [7]],
        [, , "4[2-46]\\d{5}", , , , "4217123"],
        [, , "2[125-8]\\d{5}", , , , "2510123"],
        [, , "800[08]\\d{3}", , , , "8000000"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "971\\d{4}|(?:64|95)\\d{5}", , , , "6412345"],
        "SC",
        248,
        "010|0[0-2]",
        ,
        ,
        ,
        ,
        ,
        "00",
        ,
        [[, "(\\d)(\\d{3})(\\d{3})", "$1 $2 $3", ["[246]|9[57]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SD: [
        ,
        [, , "[19]\\d{8}", , , , , , , [9]],
        [, , "1(?:5\\d|8[35-7])\\d{6}", , , , "153123456"],
        [, , "(?:1[0-2]|9[0-3569])\\d{7}", , , , "911231234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "SD",
        249,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[19]"], "0$1"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SE: [
        ,
        [
          ,
          ,
          "(?:[26]\\d\\d|9)\\d{9}|[1-9]\\d{8}|[1-689]\\d{7}|[1-4689]\\d{6}|2\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 12],
        ],
        [
          ,
          ,
          "(?:(?:[12][136]|3[356]|4[0246]|6[03]|8\\d)\\d|90[1-9])\\d{4,6}|(?:1(?:2[0-35]|4[0-4]|5[0-25-9]|7[13-6]|[89]\\d)|2(?:2[0-7]|4[0136-8]|5[0138]|7[018]|8[01]|9[0-57])|3(?:0[0-4]|1\\d|2[0-25]|4[056]|7[0-2]|8[0-3]|9[023])|4(?:1[013-8]|3[0135]|5[14-79]|7[0-246-9]|8[0156]|9[0-689])|5(?:0[0-6]|[15][0-5]|2[0-68]|3[0-4]|4\\d|6[03-5]|7[013]|8[0-79]|9[01])|6(?:1[1-3]|2[0-4]|4[02-57]|5[0-37]|6[0-3]|7[0-2]|8[0247]|9[0-356])|9(?:1[0-68]|2\\d|3[02-5]|4[0-3]|5[0-4]|[68][01]|7[0135-8]))\\d{5,6}",
          ,
          ,
          ,
          "8123456",
          ,
          ,
          [7, 8, 9],
        ],
        [, , "7[02369]\\d{7}", , , , "701234567", , , [9]],
        [, , "20\\d{4,7}", , , , "20123456", , , [6, 7, 8, 9]],
        [
          ,
          ,
          "649\\d{6}|99[1-59]\\d{4}(?:\\d{3})?|9(?:00|39|44)[1-8]\\d{3,6}",
          ,
          ,
          ,
          "9001234567",
          ,
          ,
          [7, 8, 9, 10],
        ],
        [, , "77[0-7]\\d{6}", , , , "771234567", , , [9]],
        [, , "75[1-8]\\d{6}", , , , "751234567", , , [9]],
        [, , , , , , , , , [-1]],
        "SE",
        46,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2,3})(\\d{2})", "$1-$2 $3", ["20"], "0$1"],
          [, "(\\d{3})(\\d{4})", "$1-$2", ["9(?:00|39|44|9)"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})",
            "$1-$2 $3",
            ["[12][136]|3[356]|4[0246]|6[03]|90[1-9]"],
            "0$1",
          ],
          [, "(\\d)(\\d{2,3})(\\d{2})(\\d{2})", "$1-$2 $3 $4", ["8"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{2,3})(\\d{2})",
            "$1-$2 $3",
            [
              "1[2457]|2(?:[247-9]|5[0138])|3[0247-9]|4[1357-9]|5[0-35-9]|6(?:[125689]|4[02-57]|7[0-2])|9(?:[125-8]|3[02-5]|4[0-3])",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{3})(\\d{2,3})(\\d{3})",
            "$1-$2 $3",
            ["9(?:00|39|44)"],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{2,3})(\\d{2})(\\d{2})",
            "$1-$2 $3 $4",
            ["1[13689]|2[0136]|3[1356]|4[0246]|54|6[03]|90[1-9]"],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1-$2 $3 $4",
            ["10|7"],
            "0$1",
          ],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{2})", "$1-$2 $3 $4", ["8"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1-$2 $3 $4",
            [
              "[13-5]|2(?:[247-9]|5[0138])|6(?:[124-689]|7[0-2])|9(?:[125-8]|3[02-5]|4[0-3])",
            ],
            "0$1",
          ],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{3})", "$1-$2 $3 $4", ["9"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1-$2 $3 $4 $5",
            ["[26]"],
            "0$1",
          ],
        ],
        [
          [, "(\\d{2})(\\d{2,3})(\\d{2})", "$1 $2 $3", ["20"]],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["9(?:00|39|44|9)"]],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})",
            "$1 $2 $3",
            ["[12][136]|3[356]|4[0246]|6[03]|90[1-9]"],
          ],
          [, "(\\d)(\\d{2,3})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"]],
          [
            ,
            "(\\d{3})(\\d{2,3})(\\d{2})",
            "$1 $2 $3",
            [
              "1[2457]|2(?:[247-9]|5[0138])|3[0247-9]|4[1357-9]|5[0-35-9]|6(?:[125689]|4[02-57]|7[0-2])|9(?:[125-8]|3[02-5]|4[0-3])",
            ],
          ],
          [, "(\\d{3})(\\d{2,3})(\\d{3})", "$1 $2 $3", ["9(?:00|39|44)"]],
          [
            ,
            "(\\d{2})(\\d{2,3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["1[13689]|2[0136]|3[1356]|4[0246]|54|6[03]|90[1-9]"],
          ],
          [, "(\\d{2})(\\d{3})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["10|7"]],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{2})", "$1 $2 $3 $4", ["8"]],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            [
              "[13-5]|2(?:[247-9]|5[0138])|6(?:[124-689]|7[0-2])|9(?:[125-8]|3[02-5]|4[0-3])",
            ],
          ],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{3})", "$1 $2 $3 $4", ["9"]],
          [
            ,
            "(\\d{3})(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4 $5",
            ["[26]"],
          ],
        ],
        [, , "74[02-9]\\d{6}", , , , "740123456", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "10[1-8]\\d{6}", , , , "102345678", , , [9]],
        ,
        ,
        [, , "(?:25[245]|67[3-68])\\d{9}", , , , "254123456789", , , [12]],
      ],
      SG: [
        ,
        [
          ,
          ,
          "(?:(?:1\\d|8)\\d\\d|7000)\\d{7}|[3689]\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 10, 11],
        ],
        [
          ,
          ,
          "662[0-24-9]\\d{4}|6(?:[0-578]\\d|6[013-57-9]|9[0-35-9])\\d{5}",
          ,
          ,
          ,
          "61234567",
          ,
          ,
          [8],
        ],
        [
          ,
          ,
          "8(?:09|95)[0-2]\\d{4}|(?:8(?:0[1-8]|[1-8]\\d|9[0-4])|9[0-8]\\d)\\d{5}",
          ,
          ,
          ,
          "81234567",
          ,
          ,
          [8],
        ],
        [, , "(?:18|8)00\\d{7}", , , , "18001234567", , , [10, 11]],
        [, , "1900\\d{7}", , , , "19001234567", , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:3[12]\\d|666)\\d{5}", , , , "31234567", , , [8]],
        "SG",
        65,
        "0[0-3]\\d",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{4,5})",
            "$1",
            ["1[013-9]|77", "1(?:[013-8]|9(?:0[1-9]|[1-9]))|77"],
          ],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[369]|8(?:0[1-9]|[1-9])"]],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["8"]],
          [, "(\\d{4})(\\d{4})(\\d{3})", "$1 $2 $3", ["7"]],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"]],
        ],
        [
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[369]|8(?:0[1-9]|[1-9])"]],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["8"]],
          [, "(\\d{4})(\\d{4})(\\d{3})", "$1 $2 $3", ["7"]],
          [, "(\\d{4})(\\d{3})(\\d{4})", "$1 $2 $3", ["1"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "7000\\d{7}", , , , "70001234567", , , [11]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SH: [
        ,
        [, , "(?:[256]\\d|8)\\d{3}", , , , , , , [4, 5]],
        [, , "2(?:[0-57-9]\\d|6[4-9])\\d\\d", , , , "22158"],
        [, , "[56]\\d{4}", , , , "51234", , , [5]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "262\\d\\d", , , , "26212", , , [5]],
        "SH",
        290,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        1,
        "[256]",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SI: [
        ,
        [, , "[1-7]\\d{7}|8\\d{4,7}|90\\d{4,6}", , , , , , , [5, 6, 7, 8]],
        [, , "(?:[1-357][2-8]|4[24-8])\\d{6}", , , , "12345678", , , [8], [7]],
        [
          ,
          ,
          "65(?:[178]\\d|5[56]|6[01])\\d{4}|(?:[37][01]|4[0139]|51|6[489])\\d{6}",
          ,
          ,
          ,
          "31234567",
          ,
          ,
          [8],
        ],
        [, , "80\\d{4,6}", , , , "80123456", , , [6, 7, 8]],
        [, , "89[1-3]\\d{2,5}|90\\d{4,6}", , , , "90123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "(?:59\\d\\d|8(?:1(?:[67]\\d|8[0-589])|2(?:0\\d|2[0-37-9]|8[0-2489])|3[389]\\d))\\d{4}",
          ,
          ,
          ,
          "59012345",
          ,
          ,
          [8],
        ],
        "SI",
        386,
        "00|10(?:22|66|88|99)",
        "0",
        ,
        ,
        "0",
        ,
        "00",
        ,
        [
          [, "(\\d{2})(\\d{3,6})", "$1 $2", ["8[09]|9"], "0$1"],
          [, "(\\d{3})(\\d{5})", "$1 $2", ["59|8"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            ["[37][01]|4[0139]|51|6"],
            "0$1",
          ],
          [
            ,
            "(\\d)(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[1-57]"],
            "(0$1)",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SJ: [
        ,
        [, , "0\\d{4}|(?:[489]\\d|79)\\d{6}", , , , , , , [5, 8]],
        [, , "79\\d{6}", , , , "79123456", , , [8]],
        [, , "(?:4[015-8]|9\\d)\\d{6}", , , , "41234567", , , [8]],
        [, , "80[01]\\d{5}", , , , "80012345", , , [8]],
        [, , "82[09]\\d{5}", , , , "82012345", , , [8]],
        [, , "810(?:0[0-6]|[2-8]\\d)\\d{3}", , , , "81021234", , , [8]],
        [, , "880\\d{5}", , , , "88012345", , , [8]],
        [, , "85[0-5]\\d{5}", , , , "85012345", , , [8]],
        "SJ",
        47,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "79",
        [, , , , , , , , , [-1]],
        [, , "(?:0[2-9]|81(?:0(?:0[7-9]|1\\d)|5\\d\\d))\\d{3}", , , , "02000"],
        ,
        ,
        [, , "81[23]\\d{5}", , , , "81212345", , , [8]],
      ],
      SK: [
        ,
        [, , "[2-689]\\d{8}|[2-59]\\d{6}|[2-5]\\d{5}", , , , , , , [6, 7, 9]],
        [
          ,
          ,
          "(?:2(?:16|[2-9]\\d{3})|(?:(?:[3-5][1-8]\\d|819)\\d|601[1-5])\\d)\\d{4}|(?:2|[3-5][1-8])1[67]\\d{3}|[3-5][1-8]16\\d\\d",
          ,
          ,
          ,
          "221234567",
        ],
        [
          ,
          ,
          "909[1-9]\\d{5}|9(?:0[1-8]|1[0-24-9]|4[03-57-9]|5\\d)\\d{6}",
          ,
          ,
          ,
          "912123456",
          ,
          ,
          [9],
        ],
        [, , "800\\d{6}", , , , "800123456", , , [9]],
        [, , "9(?:00|[78]\\d)\\d{6}", , , , "900123456", , , [9]],
        [, , "8[5-9]\\d{7}", , , , "850123456", , , [9]],
        [, , , , , , , , , [-1]],
        [, , "6(?:02|5[0-4]|9[0-6])\\d{6}", , , , "690123456", , , [9]],
        "SK",
        421,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{2})(\\d{3,4})", "$1 $2 $3", ["21"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2,3})",
            "$1 $2 $3",
            ["[3-5][1-8]1", "[3-5][1-8]1[67]"],
            "0$1",
          ],
          [, "(\\d{4})(\\d{3})", "$1 $2", ["909", "9090"], "0$1"],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{2})", "$1/$2 $3 $4", ["2"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[689]"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1/$2 $3 $4",
            ["[3-5]"],
            "0$1",
          ],
        ],
        [
          [, "(\\d)(\\d{2})(\\d{3,4})", "$1 $2 $3", ["21"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2,3})",
            "$1 $2 $3",
            ["[3-5][1-8]1", "[3-5][1-8]1[67]"],
            "0$1",
          ],
          [, "(\\d)(\\d{3})(\\d{3})(\\d{2})", "$1/$2 $3 $4", ["2"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[689]"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1/$2 $3 $4",
            ["[3-5]"],
            "0$1",
          ],
        ],
        [, , "9090\\d{3}", , , , "9090123", , , [7]],
        ,
        ,
        [
          ,
          ,
          "9090\\d{3}|(?:602|8(?:00|[5-9]\\d)|9(?:00|[78]\\d))\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 9],
        ],
        [, , "96\\d{7}", , , , "961234567", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SL: [
        ,
        [, , "(?:[237-9]\\d|66)\\d{6}", , , , , , , [8], [6]],
        [, , "22[2-4][2-9]\\d{4}", , , , "22221234", , , , [6]],
        [, , "(?:25|3[0-5]|66|7[2-9]|8[08]|9[09])\\d{6}", , , , "25123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "SL",
        232,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{6})", "$1 $2", ["[236-9]"], "(0$1)"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SM: [
        ,
        [, , "(?:0549|[5-7]\\d)\\d{6}", , , , , , , [8, 10], [6]],
        [, , "0549(?:8[0157-9]|9\\d)\\d{4}", , , , "0549886377", , , [10], [6]],
        [, , "6[16]\\d{6}", , , , "66661212", , , [8]],
        [, , , , , , , , , [-1]],
        [, , "7[178]\\d{6}", , , , "71123456", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "5[158]\\d{6}", , , , "58001110", , , [8]],
        "SM",
        378,
        "00",
        ,
        ,
        ,
        "([89]\\d{5})$",
        "0549$1",
        ,
        ,
        [
          [, "(\\d{6})", "$1", ["[89]"]],
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[5-7]"]],
          [, "(\\d{4})(\\d{6})", "$1 $2", ["0"]],
        ],
        [
          [, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[5-7]"]],
          [, "(\\d{4})(\\d{6})", "$1 $2", ["0"]],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SN: [
        ,
        [, , "(?:[378]\\d|93)\\d{7}", , , , , , , [9]],
        [
          ,
          ,
          "3(?:0(?:1[0-2]|80)|282|3(?:8[1-9]|9[3-9])|611)\\d{5}",
          ,
          ,
          ,
          "301012345",
        ],
        [
          ,
          ,
          "7(?:(?:[06-8]\\d|21|90)\\d|5(?:01|[19]0|25|[38]3|[4-7]\\d))\\d{5}",
          ,
          ,
          ,
          "701234567",
        ],
        [, , "800\\d{6}", , , , "800123456"],
        [, , "88[4689]\\d{6}", , , , "884123456"],
        [, , "81[02468]\\d{6}", , , , "810123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "(?:3(?:392|9[01]\\d)\\d|93(?:3[13]0|929))\\d{4}",
          ,
          ,
          ,
          "933301234",
        ],
        "SN",
        221,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"]],
          [, "(\\d{2})(\\d{3})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[379]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SO: [
        ,
        [
          ,
          ,
          "[346-9]\\d{8}|[12679]\\d{7}|[1-5]\\d{6}|[1348]\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9],
        ],
        [
          ,
          ,
          "(?:1\\d|2[0-79]|3[0-46-8]|4[0-7]|5[57-9])\\d{5}|(?:[134]\\d|8[125])\\d{4}",
          ,
          ,
          ,
          "4012345",
          ,
          ,
          [6, 7],
        ],
        [
          ,
          ,
          "(?:(?:15|(?:3[59]|4[89]|6\\d|7[79]|8[08])\\d|9(?:0\\d|[2-9]))\\d|2(?:4\\d|8))\\d{5}|(?:[67]\\d\\d|904)\\d{5}",
          ,
          ,
          ,
          "71123456",
          ,
          ,
          [7, 8, 9],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "SO",
        252,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{4})", "$1 $2", ["8[125]"]],
          [, "(\\d{6})", "$1", ["[134]"]],
          [, "(\\d)(\\d{6})", "$1 $2", ["[15]|2[0-79]|3[0-46-8]|4[0-7]"]],
          [, "(\\d)(\\d{7})", "$1 $2", ["(?:2|90)4|[67]"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[348]|64|79|90"]],
          [, "(\\d{2})(\\d{5,7})", "$1 $2", ["1|28|6[0-35-9]|77|9[2-9]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SR: [
        ,
        [, , "(?:[2-5]|68|[78]\\d)\\d{5}", , , , , , , [6, 7]],
        [, , "(?:2[1-3]|3[0-7]|(?:4|68)\\d|5[2-58])\\d{4}", , , , "211234"],
        [, , "(?:7[124-7]|8[124-9])\\d{5}", , , , "7412345", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "56\\d{4}", , , , "561234", , , [6]],
        "SR",
        597,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{2})", "$1-$2-$3", ["56"]],
          [, "(\\d{3})(\\d{3})", "$1-$2", ["[2-5]"]],
          [, "(\\d{3})(\\d{4})", "$1-$2", ["[6-8]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SS: [
        ,
        [, , "[19]\\d{8}", , , , , , , [9]],
        [, , "1[89]\\d{7}", , , , "181234567"],
        [, , "(?:12|9[1257-9])\\d{7}", , , , "977123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "SS",
        211,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[19]"], "0$1"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ST: [
        ,
        [, , "(?:22|9\\d)\\d{5}", , , , , , , [7]],
        [, , "22\\d{5}", , , , "2221234"],
        [, , "900[5-9]\\d{3}|9(?:0[1-9]|[89]\\d)\\d{4}", , , , "9812345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "ST",
        239,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[29]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SV: [
        ,
        [
          ,
          ,
          "[267]\\d{7}|(?:80\\d|900)\\d{4}(?:\\d{4})?",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 11],
        ],
        [
          ,
          ,
          "2(?:79(?:0[0347-9]|[1-9]\\d)|89(?:0[024589]|[1-9]\\d))\\d{3}|2(?:[1-69]\\d|[78][0-8])\\d{5}",
          ,
          ,
          ,
          "21234567",
          ,
          ,
          [8],
        ],
        [, , "[67]\\d{7}", , , , "70123456", , , [8]],
        [, , "800\\d{8}|80[01]\\d{4}", , , , "8001234", , , [7, 11]],
        [, , "900\\d{4}(?:\\d{4})?", , , , "9001234", , , [7, 11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "SV",
        503,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[89]"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[267]"]],
          [, "(\\d{3})(\\d{4})(\\d{4})", "$1 $2 $3", ["[89]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SX: [
        ,
        [, , "7215\\d{6}|(?:[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "7215(?:4[2-8]|8[239]|9[056])\\d{4}",
          ,
          ,
          ,
          "7215425678",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "7215(?:1[02]|2\\d|5[034679]|8[014-8])\\d{4}",
          ,
          ,
          ,
          "7215205678",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002123456"],
        [, , "900[2-9]\\d{6}", , , , "9002123456"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "SX",
        1,
        "011",
        "1",
        ,
        ,
        "(5\\d{6})$|1",
        "721$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "721",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SY: [
        ,
        [, , "[1-39]\\d{8}|[1-5]\\d{7}", , , , , , , [8, 9], [6, 7]],
        [
          ,
          ,
          "21\\d{6,7}|(?:1(?:[14]\\d|[2356])|2[235]|3(?:[13]\\d|4)|4[134]|5[1-3])\\d{6}",
          ,
          ,
          ,
          "112345678",
          ,
          ,
          ,
          [6, 7],
        ],
        [, , "9[1-689]\\d{7}", , , , "944567890", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "SY",
        963,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[1-5]"], "0$1", , 1],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["9"], "0$1", , 1],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      SZ: [
        ,
        [, , "0800\\d{4}|(?:[237]\\d|900)\\d{6}", , , , , , , [8, 9]],
        [, , "[23][2-5]\\d{6}", , , , "22171234", , , [8]],
        [, , "7[6-9]\\d{6}", , , , "76123456", , , [8]],
        [, , "0800\\d{4}", , , , "08001234", , , [8]],
        [, , "900\\d{6}", , , , "900012345", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "70\\d{6}", , , , "70012345", , , [8]],
        "SZ",
        268,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[0237]"]],
          [, "(\\d{5})(\\d{4})", "$1 $2", ["9"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "0800\\d{4}", , , , , , , [8]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TA: [
        ,
        [, , "8\\d{3}", , , , , , , [4]],
        [, , "8\\d{3}", , , , "8999"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "TA",
        290,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "8",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TC: [
        ,
        [, , "(?:[58]\\d\\d|649|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "649(?:266|712|9(?:4\\d|50))\\d{4}",
          ,
          ,
          ,
          "6497121234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "649(?:2(?:3[129]|4[1-79])|3\\d\\d|4[34][1-3])\\d{4}",
          ,
          ,
          ,
          "6492311234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002345678"],
        [, , "900[2-9]\\d{6}", , , , "9002345678"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , "649(?:71[01]|966)\\d{4}", , , , "6497101234", , , , [7]],
        "TC",
        1,
        "011",
        "1",
        ,
        ,
        "([2-479]\\d{6})$|1",
        "649$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "649",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TD: [
        ,
        [, , "(?:22|[69]\\d|77)\\d{6}", , , , , , , [8]],
        [, , "22(?:[37-9]0|5[0-5]|6[89])\\d{4}", , , , "22501234"],
        [, , "(?:6[0235689]|77|9\\d)\\d{6}", , , , "63012345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "TD",
        235,
        "00|16",
        ,
        ,
        ,
        ,
        ,
        "00",
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[2679]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TG: [
        ,
        [, , "[279]\\d{7}", , , , , , , [8]],
        [, , "2(?:2[2-7]|3[23]|4[45]|55|6[67]|77)\\d{5}", , , , "22212345"],
        [, , "(?:7[019]|9[0-36-9])\\d{6}", , , , "90112345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "TG",
        228,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["[279]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TH: [
        ,
        [
          ,
          ,
          "(?:001800|[2-57]|[689]\\d)\\d{7}|1\\d{7,9}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10, 13],
        ],
        [
          ,
          ,
          "(?:1[0689]|2\\d|3[2-9]|4[2-5]|5[2-6]|7[3-7])\\d{6}",
          ,
          ,
          ,
          "21234567",
          ,
          ,
          [8],
        ],
        [
          ,
          ,
          "67(?:1[0-8]|2[4-7])\\d{5}|(?:14|6[1-6]|[89]\\d)\\d{7}",
          ,
          ,
          ,
          "812345678",
          ,
          ,
          [9],
        ],
        [, , "(?:001800\\d|1800)\\d{6}", , , , "1800123456", , , [10, 13]],
        [, , "1900\\d{6}", , , , "1900123456", , , [10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "6[08]\\d{7}", , , , "601234567", , , [9]],
        "TH",
        66,
        "00[1-9]",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{4})", "$1 $2 $3", ["2"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[13-9]"], "0$1"],
          [, "(\\d{4})(\\d{3})(\\d{3})", "$1 $2 $3", ["1"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TJ: [
        ,
        [, , "[0-57-9]\\d{8}", , , , , , , [9], [3, 5, 6, 7]],
        [
          ,
          ,
          "(?:3(?:1[3-5]|2[245]|3[12]|4[24-7]|5[25]|72)|4(?:46|74|87))\\d{6}",
          ,
          ,
          ,
          "372123456",
          ,
          ,
          ,
          [3, 5, 6, 7],
        ],
        [
          ,
          ,
          "(?:4(?:1[18]|4[02-479])|81[1-9])\\d{6}|(?:0[0-57-9]|1[017]|2[02]|[34]0|5[05]|7[0178]|8[078]|9\\d)\\d{7}",
          ,
          ,
          ,
          "917123456",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "TJ",
        992,
        "810",
        ,
        ,
        ,
        ,
        ,
        "8~10",
        ,
        [
          [, "(\\d{6})(\\d)(\\d{2})", "$1 $2 $3", ["331", "3317"]],
          [, "(\\d{3})(\\d{2})(\\d{4})", "$1 $2 $3", ["44[02-479]|[34]7"]],
          [, "(\\d{4})(\\d)(\\d{4})", "$1 $2 $3", ["3[1-5]"]],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[0-57-9]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TK: [
        ,
        [, , "[2-47]\\d{3,6}", , , , , , , [4, 5, 6, 7]],
        [, , "(?:2[2-4]|[34]\\d)\\d{2,5}", , , , "3101"],
        [, , "7[2-4]\\d{2,5}", , , , "7290"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "TK",
        690,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TL: [
        ,
        [, , "7\\d{7}|(?:[2-47]\\d|[89]0)\\d{5}", , , , , , , [7, 8]],
        [, , "(?:2[1-5]|3[1-9]|4[1-4])\\d{5}", , , , "2112345", , , [7]],
        [, , "7[2-8]\\d{6}", , , , "77212345", , , [8]],
        [, , "80\\d{5}", , , , "8012345", , , [7]],
        [, , "90\\d{5}", , , , "9012345", , , [7]],
        [, , , , , , , , , [-1]],
        [, , "70\\d{5}", , , , "7012345", , , [7]],
        [, , , , , , , , , [-1]],
        "TL",
        670,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[2-489]|70"]],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["7"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TM: [
        ,
        [, , "[1-6]\\d{7}", , , , , , , [8]],
        [
          ,
          ,
          "(?:1(?:2\\d|3[1-9])|2(?:22|4[0-35-8])|3(?:22|4[03-9])|4(?:22|3[128]|4\\d|6[15])|5(?:22|5[7-9]|6[014-689]))\\d{5}",
          ,
          ,
          ,
          "12345678",
        ],
        [, , "6\\d{7}", , , , "66123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "TM",
        993,
        "810",
        "8",
        ,
        ,
        "8",
        ,
        "8~10",
        ,
        [
          [
            ,
            "(\\d{2})(\\d{2})(\\d{2})(\\d{2})",
            "$1 $2-$3-$4",
            ["12"],
            "(8 $1)",
          ],
          [
            ,
            "(\\d{3})(\\d)(\\d{2})(\\d{2})",
            "$1 $2-$3-$4",
            ["[1-5]"],
            "(8 $1)",
          ],
          [, "(\\d{2})(\\d{6})", "$1 $2", ["6"], "8 $1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TN: [
        ,
        [, , "[2-57-9]\\d{7}", , , , , , , [8]],
        [, , "81200\\d{3}|(?:3[0-2]|7\\d)\\d{6}", , , , "30010123"],
        [
          ,
          ,
          "3(?:001|[12]40)\\d{4}|(?:(?:[259]\\d|4[0-8])\\d|3(?:1[1-35]|6[0-4]|91))\\d{5}",
          ,
          ,
          ,
          "20123456",
        ],
        [, , "8010\\d{4}", , , , "80101234"],
        [, , "88\\d{6}", , , , "88123456"],
        [, , "8[12]10\\d{4}", , , , "81101234"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "TN",
        216,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["[2-57-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TO: [
        ,
        [
          ,
          ,
          "(?:0800|(?:[5-8]\\d\\d|999)\\d)\\d{3}|[2-8]\\d{4}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 7],
        ],
        [
          ,
          ,
          "(?:2\\d|3[0-8]|4[0-4]|50|6[09]|7[0-24-69]|8[05])\\d{3}",
          ,
          ,
          ,
          "20123",
          ,
          ,
          [5],
        ],
        [
          ,
          ,
          "(?:5(?:4[0-5]|5[4-6])|6(?:[09]\\d|3[02]|8[15-9])|(?:7\\d|8[46-9])\\d|999)\\d{4}",
          ,
          ,
          ,
          "7715123",
          ,
          ,
          [7],
        ],
        [, , "0800\\d{3}", , , , "0800222", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "55[0-37-9]\\d{4}", , , , "5510123", , , [7]],
        "TO",
        676,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})", "$1-$2", ["[2-4]|50|6[09]|7[0-24-69]|8[05]"]],
          [, "(\\d{4})(\\d{3})", "$1 $2", ["0"]],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[5-9]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TR: [
        ,
        [
          ,
          ,
          "4\\d{6}|8\\d{11,12}|(?:[2-58]\\d\\d|900)\\d{7}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 10, 12, 13],
        ],
        [
          ,
          ,
          "(?:2(?:[13][26]|[28][2468]|[45][268]|[67][246])|3(?:[13][28]|[24-6][2468]|[78][02468]|92)|4(?:[16][246]|[23578][2468]|4[26]))\\d{7}",
          ,
          ,
          ,
          "2123456789",
          ,
          ,
          [10],
        ],
        [
          ,
          ,
          "561(?:011|61\\d)\\d{4}|5(?:0[15-7]|1[06]|24|[34]\\d|5[1-59]|9[46])\\d{7}",
          ,
          ,
          ,
          "5012345678",
          ,
          ,
          [10],
        ],
        [
          ,
          ,
          "8(?:00\\d{7}(?:\\d{2,3})?|11\\d{7})",
          ,
          ,
          ,
          "8001234567",
          ,
          ,
          [10, 12, 13],
        ],
        [, , "(?:8[89]8|900)\\d{7}", , , , "9001234567", , , [10]],
        [, , , , , , , , , [-1]],
        [, , "592(?:21[12]|461)\\d{4}", , , , "5922121234", , , [10]],
        [, , "850\\d{7}", , , , "8500123456", , , [10]],
        "TR",
        90,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d)(\\d{3})", "$1 $2 $3", ["444"], , , 1],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["512|8[01589]|90"],
            "0$1",
            ,
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["5(?:[0-59]|61)", "5(?:[0-59]|61[06])", "5(?:[0-59]|61[06]1)"],
            "0$1",
            ,
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[24][1-8]|3[1-9]"],
            "(0$1)",
            ,
            1,
          ],
          [, "(\\d{3})(\\d{3})(\\d{6,7})", "$1 $2 $3", ["80"], "0$1", , 1],
        ],
        [
          [
            ,
            "(\\d{3})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["512|8[01589]|90"],
            "0$1",
            ,
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["5(?:[0-59]|61)", "5(?:[0-59]|61[06])", "5(?:[0-59]|61[06]1)"],
            "0$1",
            ,
            1,
          ],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[24][1-8]|3[1-9]"],
            "(0$1)",
            ,
            1,
          ],
          [, "(\\d{3})(\\d{3})(\\d{6,7})", "$1 $2 $3", ["80"], "0$1", , 1],
        ],
        [, , "512\\d{7}", , , , "5123456789", , , [10]],
        ,
        ,
        [, , "(?:444|811\\d{3})\\d{4}", , , , , , , [7, 10]],
        [, , "444\\d{4}", , , , "4441444", , , [7]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TT: [
        ,
        [, , "(?:[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "868(?:2(?:01|1[5-9]|[23]\\d|4[0-2])|6(?:0[7-9]|1[02-8]|2[1-9]|[3-69]\\d|7[0-79])|82[124])\\d{4}",
          ,
          ,
          ,
          "8682211234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "868(?:(?:2[5-9]|3\\d)\\d|4(?:3[0-6]|[6-9]\\d)|6(?:20|78|8\\d)|7(?:0[1-9]|1[02-9]|[2-9]\\d))\\d{4}",
          ,
          ,
          ,
          "8682911234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002345678"],
        [, , "900[2-9]\\d{6}", , , , "9002345678"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "TT",
        1,
        "011",
        "1",
        ,
        ,
        "([2-46-8]\\d{6})$|1",
        "868$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "868",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "868619\\d{4}", , , , "8686191234", , , , [7]],
      ],
      TV: [
        ,
        [, , "(?:2|7\\d\\d|90)\\d{4}", , , , , , , [5, 6, 7]],
        [, , "2[02-9]\\d{3}", , , , "20123", , , [5]],
        [, , "(?:7[01]\\d|90)\\d{4}", , , , "901234", , , [6, 7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "TV",
        688,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3})", "$1 $2", ["2"]],
          [, "(\\d{2})(\\d{4})", "$1 $2", ["90"]],
          [, "(\\d{2})(\\d{5})", "$1 $2", ["7"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TW: [
        ,
        [
          ,
          ,
          "[2-689]\\d{8}|7\\d{9,10}|[2-8]\\d{7}|2\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 10, 11],
        ],
        [
          ,
          ,
          "(?:2[2-8]\\d|370|55[01]|7[1-9])\\d{6}|4(?:(?:0(?:0[1-9]|[2-48]\\d)|1[023]\\d)\\d{4,5}|(?:[239]\\d\\d|4(?:0[56]|12|49))\\d{5})|6(?:[01]\\d{7}|4(?:0[56]|12|24|4[09])\\d{4,5})|8(?:(?:2(?:3\\d|4[0-269]|[578]0|66)|36[24-9]|90\\d\\d)\\d{4}|4(?:0[56]|12|24|4[09])\\d{4,5})|(?:2(?:2(?:0\\d\\d|4(?:0[68]|[249]0|3[0-467]|5[0-25-9]|6[0235689]))|(?:3(?:[09]\\d|1[0-4])|(?:4\\d|5[0-49]|6[0-29]|7[0-5])\\d)\\d)|(?:(?:3[2-9]|5[2-8]|6[0-35-79]|8[7-9])\\d\\d|4(?:2(?:[089]\\d|7[1-9])|(?:3[0-4]|[78]\\d|9[01])\\d))\\d)\\d{3}",
          ,
          ,
          ,
          "221234567",
          ,
          ,
          [8, 9],
        ],
        [, , "(?:40001[0-2]|9[0-8]\\d{4})\\d{3}", , , , "912345678", , , [9]],
        [, , "80[0-79]\\d{6}|800\\d{5}", , , , "800123456", , , [8, 9]],
        [, , "20(?:[013-9]\\d\\d|2)\\d{4}", , , , "203123456", , , [7, 9]],
        [, , , , , , , , , [-1]],
        [, , "99\\d{7}", , , , "990123456", , , [9]],
        [
          ,
          ,
          "7010(?:[0-2679]\\d|3[0-7]|8[0-5])\\d{5}|70\\d{8}",
          ,
          ,
          ,
          "7012345678",
          ,
          ,
          [10, 11],
        ],
        "TW",
        886,
        "0(?:0[25-79]|19)",
        "0",
        "#",
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d)(\\d{4})", "$1 $2 $3", ["202"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[258]0"], "0$1"],
          [
            ,
            "(\\d)(\\d{3,4})(\\d{4})",
            "$1 $2 $3",
            [
              "[23568]|4(?:0[02-48]|[1-47-9])|7[1-9]",
              "[23568]|4(?:0[2-48]|[1-47-9])|(?:400|7)[1-9]",
            ],
            "0$1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[49]"], "0$1"],
          [, "(\\d{2})(\\d{4})(\\d{4,5})", "$1 $2 $3", ["7"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "50[0-46-9]\\d{6}", , , , "500123456", , , [9]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      TZ: [
        ,
        [, , "(?:[25-8]\\d|41|90)\\d{7}", , , , , , , [9]],
        [, , "2[2-8]\\d{7}", , , , "222345678"],
        [, , "77[2-9]\\d{6}|(?:6[125-9]|7[13-689])\\d{7}", , , , "621234567"],
        [, , "80[08]\\d{6}", , , , "800123456"],
        [, , "90\\d{7}", , , , "900123456"],
        [, , "8(?:40|6[01])\\d{6}", , , , "840123456"],
        [, , , , , , , , , [-1]],
        [, , "41\\d{7}", , , , "412345678"],
        "TZ",
        255,
        "00[056]",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{2})(\\d{4})", "$1 $2 $3", ["[89]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[24]"], "0$1"],
          [, "(\\d{2})(\\d{7})", "$1 $2", ["5"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[67]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "(?:8(?:[04]0|6[01])|90\\d)\\d{6}"],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      UA: [
        ,
        [, , "[89]\\d{9}|[3-9]\\d{8}", , , , , , , [9, 10], [5, 6, 7]],
        [
          ,
          ,
          "(?:3[1-8]|4[13-8]|5[1-7]|6[12459])\\d{7}",
          ,
          ,
          ,
          "311234567",
          ,
          ,
          [9],
          [5, 6, 7],
        ],
        [
          ,
          ,
          "(?:39|50|6[36-8]|7[1-3]|9[1-9])\\d{7}",
          ,
          ,
          ,
          "501234567",
          ,
          ,
          [9],
        ],
        [, , "800[1-8]\\d{5,6}", , , , "800123456"],
        [, , "900[239]\\d{5,6}", , , , "900212345"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "89[1-579]\\d{6}", , , , "891234567", , , [9]],
        "UA",
        380,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        "0~0",
        ,
        [
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3})",
            "$1 $2 $3",
            [
              "6[12][29]|(?:3[1-8]|4[136-8]|5[12457]|6[49])2|(?:56|65)[24]",
              "6[12][29]|(?:35|4[1378]|5[12457]|6[49])2|(?:56|65)[24]|(?:3[1-46-8]|46)2[013-9]",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{4})(\\d{5})",
            "$1 $2",
            [
              "3[1-8]|4(?:[1367]|[45][6-9]|8[4-6])|5(?:[1-5]|6[0135689]|7[4-6])|6(?:[12][3-7]|[459])",
              "3[1-8]|4(?:[1367]|[45][6-9]|8[4-6])|5(?:[1-5]|6(?:[015689]|3[02389])|7[4-6])|6(?:[12][3-7]|[459])",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{4})",
            "$1 $2 $3",
            ["[3-7]|89|9[1-9]"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3,4})", "$1 $2 $3", ["[89]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      UG: [
        ,
        [, , "800\\d{6}|(?:[29]0|[347]\\d)\\d{7}", , , , , , , [9], [5, 6, 7]],
        [
          ,
          ,
          "20(?:(?:240|30[67])\\d|6(?:00[0-2]|30[0-4]))\\d{3}|(?:20(?:[017]\\d|2[5-9]|3[1-4]|5[0-4]|6[15-9])|[34]\\d{3})\\d{5}",
          ,
          ,
          ,
          "312345678",
          ,
          ,
          ,
          [5, 6, 7],
        ],
        [
          ,
          ,
          "72(?:[48]0|6[01])\\d{5}|7(?:[015-8]\\d|20|36|4[0-4]|9[89])\\d{6}",
          ,
          ,
          ,
          "712345678",
        ],
        [, , "800[1-3]\\d{5}", , , , "800123456"],
        [, , "90[1-3]\\d{6}", , , , "901123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "UG",
        256,
        "00[057]",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{4})(\\d{5})", "$1 $2", ["202", "2024"], "0$1"],
          [, "(\\d{3})(\\d{6})", "$1 $2", ["[27-9]|4(?:6[45]|[7-9])"], "0$1"],
          [, "(\\d{2})(\\d{7})", "$1 $2", ["[34]"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      US: [
        ,
        [, , "[2-9]\\d{9}|3\\d{6}", , , , , , , [10], [7]],
        [
          ,
          ,
          "(?:5056(?:[0-35-9]\\d|4[468])|7302[0-3]\\d)\\d{4}|(?:472[24]|505[2-57-9]|7306|983[2-47-9])\\d{6}|(?:2(?:0[1-35-9]|1[02-9]|2[03-57-9]|3[149]|4[08]|5[1-46]|6[0279]|7[0269]|8[13])|3(?:0[1-57-9]|1[02-9]|2[013569]|3[0-24679]|4[167]|5[0-2]|6[01349]|8[056])|4(?:0[124-9]|1[02-579]|2[3-5]|3[0245]|4[023578]|58|6[349]|7[0589]|8[04])|5(?:0[1-47-9]|1[0235-8]|20|3[0149]|4[01]|5[179]|6[1-47]|7[0-5]|8[0256])|6(?:0[1-35-9]|1[024-9]|2[03689]|3[016]|4[0156]|5[01679]|6[0-279]|78|8[0-29])|7(?:0[1-46-8]|1[2-9]|2[04-8]|3[1247]|4[037]|5[47]|6[02359]|7[0-59]|8[156])|8(?:0[1-68]|1[02-8]|2[068]|3[0-2589]|4[03578]|5[046-9]|6[02-5]|7[028])|9(?:0[1346-9]|1[02-9]|2[0589]|3[0146-8]|4[01357-9]|5[12469]|7[0-389]|8[04-69]))[2-9]\\d{6}",
          ,
          ,
          ,
          "2015550123",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "(?:5056(?:[0-35-9]\\d|4[468])|7302[0-3]\\d)\\d{4}|(?:472[24]|505[2-57-9]|7306|983[2-47-9])\\d{6}|(?:2(?:0[1-35-9]|1[02-9]|2[03-57-9]|3[149]|4[08]|5[1-46]|6[0279]|7[0269]|8[13])|3(?:0[1-57-9]|1[02-9]|2[013569]|3[0-24679]|4[167]|5[0-2]|6[01349]|8[056])|4(?:0[124-9]|1[02-579]|2[3-5]|3[0245]|4[023578]|58|6[349]|7[0589]|8[04])|5(?:0[1-47-9]|1[0235-8]|20|3[0149]|4[01]|5[179]|6[1-47]|7[0-5]|8[0256])|6(?:0[1-35-9]|1[024-9]|2[03689]|3[016]|4[0156]|5[01679]|6[0-279]|78|8[0-29])|7(?:0[1-46-8]|1[2-9]|2[04-8]|3[1247]|4[037]|5[47]|6[02359]|7[0-59]|8[156])|8(?:0[1-68]|1[02-8]|2[068]|3[0-2589]|4[03578]|5[046-9]|6[02-5]|7[028])|9(?:0[1346-9]|1[02-9]|2[0589]|3[0146-8]|4[01357-9]|5[12469]|7[0-389]|8[04-69]))[2-9]\\d{6}",
          ,
          ,
          ,
          "2015550123",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002345678"],
        [, , "900[2-9]\\d{6}", , , , "9002345678"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "US",
        1,
        "011",
        "1",
        ,
        ,
        "1",
        ,
        ,
        1,
        [
          [, "(\\d{3})(\\d{4})", "$1-$2", ["310"], , , 1],
          [, "(\\d{3})(\\d{4})", "$1-$2", ["[24-9]|3(?:[02-9]|1[1-9])"]],
          [, "(\\d{3})(\\d{3})(\\d{4})", "($1) $2-$3", ["[2-9]"], , , 1],
        ],
        [
          [, "(\\d{3})(\\d{4})", "$1-$2", ["310"], , , 1],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1-$2-$3", ["[2-9]"]],
        ],
        [, , , , , , , , , [-1]],
        1,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      UY: [
        ,
        [
          ,
          ,
          "0004\\d{2,9}|[1249]\\d{7}|(?:[49]\\d|80)\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 11, 12, 13],
        ],
        [
          ,
          ,
          "(?:1(?:770|9(?:20|87))|(?:2\\d|4[2-7])\\d\\d)\\d{4}",
          ,
          ,
          ,
          "21231234",
          ,
          ,
          [8],
          [7],
        ],
        [, , "9[1-9]\\d{6}", , , , "94231234", , , [8]],
        [, , "0004\\d{2,9}|(?:405|80[05])\\d{4}", , , , "8001234"],
        [, , "90[0-8]\\d{4}", , , , "9001234", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "UY",
        598,
        "0(?:0|1[3-9]\\d)",
        "0",
        " int. ",
        ,
        "0",
        ,
        "00",
        ,
        [
          [, "(\\d{3})(\\d{3,4})", "$1 $2", ["0"]],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[49]0|8"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["9"], "0$1"],
          [, "(\\d{4})(\\d{4})", "$1 $2", ["[124]"]],
          [, "(\\d{3})(\\d{3})(\\d{2,4})", "$1 $2 $3", ["0"]],
          [, "(\\d{3})(\\d{3})(\\d{3})(\\d{2,4})", "$1 $2 $3 $4", ["0"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      UZ: [
        ,
        [, , "(?:20|33|[5-79]\\d|88)\\d{7}", , , , , , , [9]],
        [
          ,
          ,
          "(?:55\\d\\d|6(?:1(?:22|3[124]|4[1-4]|5[1-3578]|64)|2(?:22|3[0-57-9]|41)|5(?:22|3[3-7]|5[024-8])|6\\d\\d|7(?:[23]\\d|7[69])|9(?:22|4[1-8]|6[135]))|7(?:0(?:5[4-9]|6[0146]|7[124-6]|9[135-8])|(?:1[12]|[68]\\d)\\d|2(?:22|3[13-57-9]|4[1-3579]|5[14])|3(?:2\\d|3[1578]|4[1-35-7]|5[1-57]|61)|4(?:2\\d|3[1-579]|7[1-79])|5(?:22|5[1-9]|6[1457])|9(?:22|5[1-9])))\\d{5}",
          ,
          ,
          ,
          "669050123",
        ],
        [
          ,
          ,
          "(?:(?:[25]0|33|88|9[0-57-9])\\d{3}|6(?:1(?:2(?:2[01]|98)|35[0-4]|50\\d|61[23]|7(?:[01][017]|4\\d|55|9[5-9]))|2(?:(?:11|7\\d)\\d|2(?:[12]1|9[01379])|5(?:[126]\\d|3[0-4]))|5(?:19[01]|2(?:27|9[26])|(?:30|59|7\\d)\\d)|6(?:2(?:1[5-9]|2[0367]|38|41|52|60)|(?:3[79]|9[0-3])\\d|4(?:56|83)|7(?:[07]\\d|1[017]|3[07]|4[047]|5[057]|67|8[0178]|9[79]))|7(?:2(?:24|3[237]|4[5-9]|7[15-8])|5(?:7[12]|8[0589])|7(?:0\\d|[39][07])|9(?:0\\d|7[079]))|9(?:2(?:1[1267]|3[01]|5\\d|7[0-4])|(?:5[67]|7\\d)\\d|6(?:2[0-26]|8\\d)))|7(?:[07]\\d{3}|1(?:13[01]|6(?:0[47]|1[67]|66)|71[3-69]|98\\d)|2(?:2(?:2[79]|95)|3(?:2[5-9]|6[0-6])|57\\d|7(?:0\\d|1[17]|2[27]|3[37]|44|5[057]|66|88))|3(?:2(?:1[0-6]|21|3[469]|7[159])|(?:33|9[4-6])\\d|5(?:0[0-4]|5[579]|9\\d)|7(?:[0-3579]\\d|4[0467]|6[67]|8[078]))|4(?:2(?:29|5[0257]|6[0-7]|7[1-57])|5(?:1[0-4]|8\\d|9[5-9])|7(?:0\\d|1[024589]|2[0-27]|3[0137]|[46][07]|5[01]|7[5-9]|9[079])|9(?:7[015-9]|[89]\\d))|5(?:112|2(?:0\\d|2[29]|[49]4)|3[1568]\\d|52[6-9]|7(?:0[01578]|1[017]|[23]7|4[047]|[5-7]\\d|8[78]|9[079]))|9(?:22[128]|3(?:2[0-4]|7\\d)|57[02569]|7(?:2[05-9]|3[37]|4\\d|60|7[2579]|87|9[07]))))\\d{4}",
          ,
          ,
          ,
          "912345678",
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "UZ",
        998,
        "810",
        "8",
        ,
        ,
        "8",
        ,
        "8~10",
        ,
        [
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["[235-9]"],
            "8 $1",
          ],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      VA: [
        ,
        [
          ,
          ,
          "0\\d{5,10}|3[0-8]\\d{7,10}|55\\d{8}|8\\d{5}(?:\\d{2,4})?|(?:1\\d|39)\\d{7,8}",
          ,
          ,
          ,
          ,
          ,
          ,
          [6, 7, 8, 9, 10, 11, 12],
        ],
        [, , "06698\\d{1,6}", , , , "0669812345", , , [6, 7, 8, 9, 10, 11]],
        [, , "3[1-9]\\d{8}|3[2-9]\\d{7}", , , , "3123456789", , , [9, 10]],
        [, , "80(?:0\\d{3}|3)\\d{3}", , , , "800123456", , , [6, 9]],
        [
          ,
          ,
          "(?:0878\\d{3}|89(?:2\\d|3[04]|4(?:[0-4]|[5-9]\\d\\d)|5[0-4]))\\d\\d|(?:1(?:44|6[346])|89(?:38|5[5-9]|9))\\d{6}",
          ,
          ,
          ,
          "899123456",
          ,
          ,
          [6, 8, 9, 10],
        ],
        [, , "84(?:[08]\\d{3}|[17])\\d{3}", , , , "848123456", , , [6, 9]],
        [, , "1(?:78\\d|99)\\d{6}", , , , "1781234567", , , [9, 10]],
        [, , "55\\d{8}", , , , "5512345678", , , [10]],
        "VA",
        39,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "06698",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "3[2-8]\\d{9,10}", , , , "33101234501", , , [11, 12]],
      ],
      VC: [
        ,
        [, , "(?:[58]\\d\\d|784|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "784(?:266|3(?:6[6-9]|7\\d|8[0-6])|4(?:38|5[0-36-8]|8[0-8])|5(?:55|7[0-2]|93)|638|784)\\d{4}",
          ,
          ,
          ,
          "7842661234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "784(?:4(?:3[0-5]|5[45]|89|9[0-8])|5(?:2[6-9]|3[0-4])|720)\\d{4}",
          ,
          ,
          ,
          "7844301234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002345678"],
        [, , "900[2-9]\\d{6}", , , , "9002345678"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , "78451[0-2]\\d{4}", , , , "7845101234", , , , [7]],
        "VC",
        1,
        "011",
        "1",
        ,
        ,
        "([2-7]\\d{6})$|1",
        "784$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "784",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      VE: [
        ,
        [, , "[68]00\\d{7}|(?:[24]\\d|[59]0)\\d{8}", , , , , , , [10], [7]],
        [
          ,
          ,
          "(?:2(?:12|3[457-9]|[467]\\d|[58][1-9]|9[1-6])|[4-6]00)\\d{7}",
          ,
          ,
          ,
          "2121234567",
          ,
          ,
          ,
          [7],
        ],
        [, , "4(?:1[24-8]|2[46])\\d{7}", , , , "4121234567"],
        [, , "800\\d{7}", , , , "8001234567"],
        [, , "90[01]\\d{7}", , , , "9001234567"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "VE",
        58,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{7})", "$1-$2", ["[24-689]"], "0$1", "$CC $1"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "501\\d{7}", , , , "5010123456", , , , [7]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      VG: [
        ,
        [, , "(?:284|[58]\\d\\d|900)\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "284(?:229|4(?:22|9[45])|774|8(?:52|6[459]))\\d{4}",
          ,
          ,
          ,
          "2842291234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "284(?:245|3(?:0[0-3]|4[0-7]|68|9[34])|4(?:4[0-6]|68|9[69])|5(?:4[0-7]|68|9[69]))\\d{4}",
          ,
          ,
          ,
          "2843001234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002345678"],
        [, , "900[2-9]\\d{6}", , , , "9002345678"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "VG",
        1,
        "011",
        "1",
        ,
        ,
        "([2-578]\\d{6})$|1",
        "284$1",
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "284",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      VI: [
        ,
        [, , "[58]\\d{9}|(?:34|90)0\\d{7}", , , , , , , [10], [7]],
        [
          ,
          ,
          "340(?:2(?:0[0-368]|2[06-8]|4[49]|77)|3(?:32|44)|4(?:2[23]|44|7[34]|89)|5(?:1[34]|55)|6(?:2[56]|4[23]|77|9[023])|7(?:1[2-57-9]|2[57]|7\\d)|884|998)\\d{4}",
          ,
          ,
          ,
          "3406421234",
          ,
          ,
          ,
          [7],
        ],
        [
          ,
          ,
          "340(?:2(?:0[0-368]|2[06-8]|4[49]|77)|3(?:32|44)|4(?:2[23]|44|7[34]|89)|5(?:1[34]|55)|6(?:2[56]|4[23]|77|9[023])|7(?:1[2-57-9]|2[57]|7\\d)|884|998)\\d{4}",
          ,
          ,
          ,
          "3406421234",
          ,
          ,
          ,
          [7],
        ],
        [, , "8(?:00|33|44|55|66|77|88)[2-9]\\d{6}", , , , "8002345678"],
        [, , "900[2-9]\\d{6}", , , , "9002345678"],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-9]|33|44|66|77|88)[2-9]\\d{6}",
          ,
          ,
          ,
          "5002345678",
        ],
        [, , , , , , , , , [-1]],
        "VI",
        1,
        "011",
        "1",
        ,
        ,
        "([2-9]\\d{6})$|1",
        "340$1",
        ,
        1,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        "340",
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      VN: [
        ,
        [
          ,
          ,
          "[12]\\d{9}|[135-9]\\d{8}|[16]\\d{7}|[16-8]\\d{6}",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 10],
        ],
        [
          ,
          ,
          "2(?:0[3-9]|1[0-689]|2[0-25-9]|[38][2-9]|4[2-8]|5[124-9]|6[0-39]|7[0-7]|9[0-4679])\\d{7}",
          ,
          ,
          ,
          "2101234567",
          ,
          ,
          [10],
        ],
        [
          ,
          ,
          "(?:5(?:2[238]|59)|89[6-9]|99[013-9])\\d{6}|(?:3\\d|5[1689]|7[06-9]|8[1-8]|9[0-8])\\d{7}",
          ,
          ,
          ,
          "912345678",
          ,
          ,
          [9],
        ],
        [
          ,
          ,
          "1800\\d{4,6}|12(?:0[13]|28)\\d{4}",
          ,
          ,
          ,
          "1800123456",
          ,
          ,
          [8, 9, 10],
        ],
        [, , "1900\\d{4,6}", , , , "1900123456", , , [8, 9, 10]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "672\\d{6}", , , , "672012345", , , [9]],
        "VN",
        84,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{4})", "$1 $2", ["[17]99"], "0$1", , 1],
          [, "(\\d{2})(\\d{5})", "$1 $2", ["80"], "0$1", , 1],
          [, "(\\d{3})(\\d{4,5})", "$1 $2", ["69"], "0$1", , 1],
          [, "(\\d{4})(\\d{4,6})", "$1 $2", ["1"], , , 1],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["6"],
            "0$1",
            ,
            1,
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[357-9]"], "0$1", , 1],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2 $3", ["2[48]"], "0$1", , 1],
          [, "(\\d{3})(\\d{4})(\\d{3})", "$1 $2 $3", ["2"], "0$1", , 1],
        ],
        [
          [, "(\\d{2})(\\d{5})", "$1 $2", ["80"], "0$1", , 1],
          [, "(\\d{4})(\\d{4,6})", "$1 $2", ["1"], , , 1],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{2})(\\d{2})",
            "$1 $2 $3 $4",
            ["6"],
            "0$1",
            ,
            1,
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[357-9]"], "0$1", , 1],
          [, "(\\d{2})(\\d{4})(\\d{4})", "$1 $2 $3", ["2[48]"], "0$1", , 1],
          [, "(\\d{3})(\\d{4})(\\d{3})", "$1 $2 $3", ["2"], "0$1", , 1],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "[17]99\\d{4}|69\\d{5,6}", , , , , , , [7, 8]],
        [, , "(?:[17]99|80\\d)\\d{4}|69\\d{5,6}", , , , "1992000", , , [7, 8]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      VU: [
        ,
        [, , "[57-9]\\d{6}|(?:[238]\\d|48)\\d{3}", , , , , , , [5, 7]],
        [
          ,
          ,
          "(?:38[0-8]|48[4-9])\\d\\d|(?:2[02-9]|3[4-7]|88)\\d{3}",
          ,
          ,
          ,
          "22123",
          ,
          ,
          [5],
        ],
        [, , "(?:[58]\\d|7[013-7])\\d{5}", , , , "5912345", , , [7]],
        [, , "81[18]\\d\\d", , , , "81123", , , [5]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "9(?:0[1-9]|1[01])\\d{4}", , , , "9010123", , , [7]],
        "VU",
        678,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{4})", "$1 $2", ["[57-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "(?:3[03]|900\\d)\\d{3}", , , , "30123"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      WF: [
        ,
        [, , "(?:40|72)\\d{4}|8\\d{5}(?:\\d{3})?", , , , , , , [6, 9]],
        [, , "72\\d{4}", , , , "721234", , , [6]],
        [, , "(?:72|8[23])\\d{4}", , , , "821234", , , [6]],
        [, , "80[0-5]\\d{6}", , , , "800012345", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "WF",
        681,
        "00",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3", ["[478]"]],
          [, "(\\d{3})(\\d{2})(\\d{2})(\\d{2})", "$1 $2 $3 $4", ["8"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "[48]0\\d{4}", , , , "401234", , , [6]],
      ],
      WS: [
        ,
        [
          ,
          ,
          "(?:[2-6]|8\\d{5})\\d{4}|[78]\\d{6}|[68]\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 10],
        ],
        [, , "6[1-9]\\d{3}|(?:[2-5]|60)\\d{4}", , , , "22123", , , [5, 6]],
        [
          ,
          ,
          "(?:7[1-35-7]|8(?:[3-7]|9\\d{3}))\\d{5}",
          ,
          ,
          ,
          "7212345",
          ,
          ,
          [7, 10],
        ],
        [, , "800\\d{3}", , , , "800123", , , [6]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "WS",
        685,
        "0",
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{5})", "$1", ["[2-5]|6[1-9]"]],
          [, "(\\d{3})(\\d{3,7})", "$1 $2", ["[68]"]],
          [, "(\\d{2})(\\d{5})", "$1 $2", ["7"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      XK: [
        ,
        [
          ,
          ,
          "2\\d{7,8}|3\\d{7,11}|(?:4\\d\\d|[89]00)\\d{5}",
          ,
          ,
          ,
          ,
          ,
          ,
          [8, 9, 10, 11, 12],
        ],
        [
          ,
          ,
          "38\\d{6,10}|(?:2[89]|39)(?:0\\d{5,6}|[1-9]\\d{5})",
          ,
          ,
          ,
          "28012345",
        ],
        [, , "4[3-9]\\d{6}", , , , "43201234", , , [8]],
        [, , "800\\d{5}", , , , "80001234", , , [8]],
        [, , "900\\d{5}", , , , "90001234", , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "XK",
        383,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{5})", "$1 $2", ["[89]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{3})", "$1 $2 $3", ["[2-4]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["2|39"], "0$1"],
          [, "(\\d{2})(\\d{7,10})", "$1 $2", ["3"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      YE: [
        ,
        [, , "(?:1|7\\d)\\d{7}|[1-7]\\d{6}", , , , , , , [7, 8, 9], [6]],
        [
          ,
          ,
          "78[0-7]\\d{4}|17\\d{6}|(?:[12][2-68]|3[2358]|4[2-58]|5[2-6]|6[3-58]|7[24-6])\\d{5}",
          ,
          ,
          ,
          "1234567",
          ,
          ,
          [7, 8],
          [6],
        ],
        [, , "7[01378]\\d{7}", , , , "712345678", , , [9]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "YE",
        967,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d)(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["[1-6]|7(?:[24-6]|8[0-7])"],
            "0$1",
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["7"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      YT: [
        ,
        [, , "(?:80|9\\d)\\d{7}|(?:26|63)9\\d{6}", , , , , , , [9]],
        [, , "269(?:0[0-467]|15|5[0-4]|6\\d|[78]0)\\d{4}", , , , "269601234"],
        [
          ,
          ,
          "639(?:0[0-79]|1[019]|[267]\\d|3[09]|40|5[05-9]|9[04-79])\\d{4}",
          ,
          ,
          ,
          "639012345",
        ],
        [, , "80\\d{7}", , , , "801234567"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "9(?:(?:39|47)8[01]|769\\d)\\d{4}", , , , "939801234"],
        "YT",
        262,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        ,
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ZA: [
        ,
        [, , "[1-79]\\d{8}|8\\d{4,9}", , , , , , , [5, 6, 7, 8, 9, 10]],
        [
          ,
          ,
          "(?:2(?:0330|4302)|52087)0\\d{3}|(?:1[0-8]|2[1-378]|3[1-69]|4\\d|5[1346-8])\\d{7}",
          ,
          ,
          ,
          "101234567",
          ,
          ,
          [9],
        ],
        [
          ,
          ,
          "(?:1(?:3492[0-25]|4495[0235]|549(?:20|5[01]))|4[34]492[01])\\d{3}|8[1-4]\\d{3,7}|(?:2[27]|47|54)4950\\d{3}|(?:1(?:049[2-4]|9[12]\\d\\d)|(?:6\\d|7[0-46-9])\\d{3}|8(?:5\\d{3}|7(?:08[67]|158|28[5-9]|310)))\\d{4}|(?:1[6-8]|28|3[2-69]|4[025689]|5[36-8])4920\\d{3}|(?:12|[2-5]1)492\\d{4}",
          ,
          ,
          ,
          "711234567",
          ,
          ,
          [5, 6, 7, 8, 9],
        ],
        [, , "80\\d{7}", , , , "801234567", , , [9]],
        [, , "(?:86[2-9]|9[0-2]\\d)\\d{6}", , , , "862345678", , , [9]],
        [, , "860\\d{6}", , , , "860123456", , , [9]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "87(?:08[0-589]|15[0-79]|28[0-4]|31[1-9])\\d{4}|87(?:[02][0-79]|1[0-46-9]|3[02-9]|[4-9]\\d)\\d{5}",
          ,
          ,
          ,
          "871234567",
          ,
          ,
          [9],
        ],
        "ZA",
        27,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{3,4})", "$1 $2", ["8[1-4]"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{2,3})", "$1 $2 $3", ["8[1-4]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["860"], "0$1"],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["[1-9]"], "0$1"],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["8"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "861\\d{6,7}", , , , "861123456", , , [9, 10]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ZM: [
        ,
        [, , "800\\d{6}|(?:21|63|[79]\\d)\\d{7}", , , , , , , [9], [6]],
        [, , "21[1-8]\\d{6}", , , , "211234567", , , , [6]],
        [, , "(?:7[5-79]|9[5-8])\\d{7}", , , , "955123456"],
        [, , "800\\d{6}", , , , "800123456"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "63\\d{7}", , , , "630123456"],
        "ZM",
        260,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [, "(\\d{3})(\\d{3})", "$1 $2", ["[1-9]"]],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[28]"], "0$1"],
          [, "(\\d{2})(\\d{7})", "$1 $2", ["[79]"], "0$1"],
        ],
        [
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[28]"], "0$1"],
          [, "(\\d{2})(\\d{7})", "$1 $2", ["[79]"], "0$1"],
        ],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      ZW: [
        ,
        [
          ,
          ,
          "2(?:[0-57-9]\\d{6,8}|6[0-24-9]\\d{6,7})|[38]\\d{9}|[35-8]\\d{8}|[3-6]\\d{7}|[1-689]\\d{6}|[1-3569]\\d{5}|[1356]\\d{4}",
          ,
          ,
          ,
          ,
          ,
          ,
          [5, 6, 7, 8, 9, 10],
          [3, 4],
        ],
        [
          ,
          ,
          "(?:1(?:(?:3\\d|9)\\d|[4-8])|2(?:(?:(?:0(?:2[014]|5)|(?:2[0157]|31|84|9)\\d\\d|[56](?:[14]\\d\\d|20)|7(?:[089]|2[03]|[35]\\d\\d))\\d|4(?:2\\d\\d|8))\\d|1(?:2|[39]\\d{4}))|3(?:(?:123|(?:29\\d|92)\\d)\\d\\d|7(?:[19]|[56]\\d))|5(?:0|1[2-478]|26|[37]2|4(?:2\\d{3}|83)|5(?:25\\d\\d|[78])|[689]\\d)|6(?:(?:[16-8]21|28|52[013])\\d\\d|[39])|8(?:[1349]28|523)\\d\\d)\\d{3}|(?:4\\d\\d|9[2-9])\\d{4,5}|(?:(?:2(?:(?:(?:0|8[146])\\d|7[1-7])\\d|2(?:[278]\\d|92)|58(?:2\\d|3))|3(?:[26]|9\\d{3})|5(?:4\\d|5)\\d\\d)\\d|6(?:(?:(?:[0-246]|[78]\\d)\\d|37)\\d|5[2-8]))\\d\\d|(?:2(?:[569]\\d|8[2-57-9])|3(?:[013-59]\\d|8[37])|6[89]8)\\d{3}",
          ,
          ,
          ,
          "1312345",
          ,
          ,
          ,
          [3, 4],
        ],
        [, , "7(?:[178]\\d|3[1-9])\\d{6}", , , , "712345678", , , [9]],
        [, , "80(?:[01]\\d|20|8[0-8])\\d{3}", , , , "8001234", , , [7]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "86(?:1[12]|22|30|44|55|77|8[368])\\d{6}",
          ,
          ,
          ,
          "8686123456",
          ,
          ,
          [10],
        ],
        "ZW",
        263,
        "00",
        "0",
        ,
        ,
        "0",
        ,
        ,
        ,
        [
          [
            ,
            "(\\d{3})(\\d{3,5})",
            "$1 $2",
            [
              "2(?:0[45]|2[278]|[49]8)|3(?:[09]8|17)|6(?:[29]8|37|75)|[23][78]|(?:33|5[15]|6[68])[78]",
            ],
            "0$1",
          ],
          [, "(\\d)(\\d{3})(\\d{2,4})", "$1 $2 $3", ["[49]"], "0$1"],
          [, "(\\d{3})(\\d{4})", "$1 $2", ["80"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{7})",
            "$1 $2",
            [
              "24|8[13-59]|(?:2[05-79]|39|5[45]|6[15-8])2",
              "2(?:02[014]|4|[56]20|[79]2)|392|5(?:42|525)|6(?:[16-8]21|52[013])|8[13-59]",
            ],
            "(0$1)",
          ],
          [, "(\\d{2})(\\d{3})(\\d{4})", "$1 $2 $3", ["7"], "0$1"],
          [
            ,
            "(\\d{3})(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            [
              "2(?:1[39]|2[0157]|[378]|[56][14])|3(?:12|29)",
              "2(?:1[39]|2[0157]|[378]|[56][14])|3(?:123|29)",
            ],
            "0$1",
          ],
          [, "(\\d{4})(\\d{6})", "$1 $2", ["8"], "0$1"],
          [
            ,
            "(\\d{2})(\\d{3,5})",
            "$1 $2",
            [
              "1|2(?:0[0-36-9]|12|29|[56])|3(?:1[0-689]|[24-6])|5(?:[0236-9]|1[2-4])|6(?:[013-59]|7[0-46-9])|(?:33|55|6[68])[0-69]|(?:29|3[09]|62)[0-79]",
            ],
            "0$1",
          ],
          [
            ,
            "(\\d{2})(\\d{3})(\\d{3,4})",
            "$1 $2 $3",
            ["29[013-9]|39|54"],
            "0$1",
          ],
          [, "(\\d{4})(\\d{3,5})", "$1 $2", ["(?:25|54)8", "258|5483"], "0$1"],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      800: [
        ,
        [, , "(?:00|[1-9]\\d)\\d{6}", , , , , , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "(?:00|[1-9]\\d)\\d{6}", , , , "12345678"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "001",
        800,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        1,
        [[, "(\\d{4})(\\d{4})", "$1 $2", ["\\d"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      808: [
        ,
        [, , "[1-9]\\d{7}", , , , , , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "[1-9]\\d{7}", , , , "12345678"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "001",
        808,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        1,
        [[, "(\\d{4})(\\d{4})", "$1 $2", ["[1-9]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      870: [
        ,
        [, , "7\\d{11}|[35-7]\\d{8}", , , , , , , [9, 12]],
        [, , , , , , , , , [-1]],
        [, , "(?:[356]|774[45])\\d{8}|7[6-8]\\d{7}", , , , "301234567"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "001",
        870,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [[, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["[35-7]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      878: [
        ,
        [, , "10\\d{10}", , , , , , , [12]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "10\\d{10}", , , , "101234567890"],
        "001",
        878,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        1,
        [[, "(\\d{2})(\\d{5})(\\d{5})", "$1 $2 $3", ["1"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      881: [
        ,
        [, , "6\\d{9}|[0-36-9]\\d{8}", , , , , , , [9, 10]],
        [, , , , , , , , , [-1]],
        [, , "6\\d{9}|[0-36-9]\\d{8}", , , , "612345678"],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "001",
        881,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d)(\\d{3})(\\d{5})", "$1 $2 $3", ["[0-37-9]"]],
          [, "(\\d)(\\d{3})(\\d{5,6})", "$1 $2 $3", ["6"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      882: [
        ,
        [
          ,
          ,
          "[13]\\d{6}(?:\\d{2,5})?|[19]\\d{7}|(?:[25]\\d\\d|4)\\d{7}(?:\\d{2})?",
          ,
          ,
          ,
          ,
          ,
          ,
          [7, 8, 9, 10, 11, 12],
        ],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "342\\d{4}|(?:337|49)\\d{6}|(?:3(?:2|47|7\\d{3})|50\\d{3})\\d{7}",
          ,
          ,
          ,
          "3421234",
          ,
          ,
          [7, 8, 9, 10, 12],
        ],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "1(?:3(?:0[0347]|[13][0139]|2[035]|4[013568]|6[0459]|7[06]|8[15-8]|9[0689])\\d{4}|6\\d{5,10})|(?:345\\d|9[89])\\d{6}|(?:10|2(?:3|85\\d)|3(?:[15]|[69]\\d\\d)|4[15-8]|51)\\d{8}",
          ,
          ,
          ,
          "390123456789",
        ],
        "001",
        882,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        [
          [, "(\\d{2})(\\d{5})", "$1 $2", ["16|342"]],
          [, "(\\d{2})(\\d{6})", "$1 $2", ["49"]],
          [, "(\\d{2})(\\d{2})(\\d{4})", "$1 $2 $3", ["1[36]|9"]],
          [, "(\\d{2})(\\d{4})(\\d{3})", "$1 $2 $3", ["3[23]"]],
          [, "(\\d{2})(\\d{3,4})(\\d{4})", "$1 $2 $3", ["16"]],
          [
            ,
            "(\\d{2})(\\d{4})(\\d{4})",
            "$1 $2 $3",
            ["10|23|3(?:[15]|4[57])|4|51"],
          ],
          [, "(\\d{3})(\\d{4})(\\d{4})", "$1 $2 $3", ["34"]],
          [, "(\\d{2})(\\d{4,5})(\\d{5})", "$1 $2 $3", ["[1-35]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , "348[57]\\d{7}", , , , "34851234567", , , [11]],
      ],
      883: [
        ,
        [, , "(?:[1-4]\\d|51)\\d{6,10}", , , , , , , [8, 9, 10, 11, 12]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [
          ,
          ,
          "(?:2(?:00\\d\\d|10)|(?:370[1-9]|51\\d0)\\d)\\d{7}|51(?:00\\d{5}|[24-9]0\\d{4,7})|(?:1[0-79]|2[24-689]|3[02-689]|4[0-4])0\\d{5,9}",
          ,
          ,
          ,
          "510012345",
        ],
        "001",
        883,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        1,
        [
          [
            ,
            "(\\d{3})(\\d{3})(\\d{2,8})",
            "$1 $2 $3",
            ["[14]|2[24-689]|3[02-689]|51[24-9]"],
          ],
          [, "(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3", ["510"]],
          [, "(\\d{3})(\\d{3})(\\d{4})", "$1 $2 $3", ["21"]],
          [, "(\\d{4})(\\d{4})(\\d{4})", "$1 $2 $3", ["51[13]"]],
          [, "(\\d{3})(\\d{3})(\\d{3})(\\d{3})", "$1 $2 $3 $4", ["[235]"]],
        ],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      888: [
        ,
        [, , "\\d{11}", , , , , , , [11]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "001",
        888,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        1,
        [[, "(\\d{3})(\\d{3})(\\d{5})", "$1 $2 $3"]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , "\\d{11}", , , , "12345678901"],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
      979: [
        ,
        [, , "[1359]\\d{8}", , , , , , , [9], [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , "[1359]\\d{8}", , , , "123456789", , , , [8]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        "001",
        979,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        1,
        [[, "(\\d)(\\d{4})(\\d{4})", "$1 $2 $3", ["[1359]"]]],
        ,
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
        [, , , , , , , , , [-1]],
        ,
        ,
        [, , , , , , , , , [-1]],
      ],
    }; /*

 Copyright (C) 2010 The Libphonenumber Authors.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
  function J() {
    this.g = {};
  }
  J.h = void 0;
  J.g = function () {
    return J.h ? J.h : (J.h = new J());
  };
  var K = {
      0: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      "\uff10": "0",
      "\uff11": "1",
      "\uff12": "2",
      "\uff13": "3",
      "\uff14": "4",
      "\uff15": "5",
      "\uff16": "6",
      "\uff17": "7",
      "\uff18": "8",
      "\uff19": "9",
      "\u0660": "0",
      "\u0661": "1",
      "\u0662": "2",
      "\u0663": "3",
      "\u0664": "4",
      "\u0665": "5",
      "\u0666": "6",
      "\u0667": "7",
      "\u0668": "8",
      "\u0669": "9",
      "\u06f0": "0",
      "\u06f1": "1",
      "\u06f2": "2",
      "\u06f3": "3",
      "\u06f4": "4",
      "\u06f5": "5",
      "\u06f6": "6",
      "\u06f7": "7",
      "\u06f8": "8",
      "\u06f9": "9",
    },
    xa = {
      0: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      "+": "+",
      "*": "*",
      "#": "#",
    },
    ya = {
      0: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      "\uff10": "0",
      "\uff11": "1",
      "\uff12": "2",
      "\uff13": "3",
      "\uff14": "4",
      "\uff15": "5",
      "\uff16": "6",
      "\uff17": "7",
      "\uff18": "8",
      "\uff19": "9",
      "\u0660": "0",
      "\u0661": "1",
      "\u0662": "2",
      "\u0663": "3",
      "\u0664": "4",
      "\u0665": "5",
      "\u0666": "6",
      "\u0667": "7",
      "\u0668": "8",
      "\u0669": "9",
      "\u06f0": "0",
      "\u06f1": "1",
      "\u06f2": "2",
      "\u06f3": "3",
      "\u06f4": "4",
      "\u06f5": "5",
      "\u06f6": "6",
      "\u06f7": "7",
      "\u06f8": "8",
      "\u06f9": "9",
      A: "2",
      B: "2",
      C: "2",
      D: "3",
      E: "3",
      F: "3",
      G: "4",
      H: "4",
      I: "4",
      J: "5",
      K: "5",
      L: "5",
      M: "6",
      N: "6",
      O: "6",
      P: "7",
      Q: "7",
      R: "7",
      S: "7",
      T: "8",
      U: "8",
      V: "8",
      W: "9",
      X: "9",
      Y: "9",
      Z: "9",
    },
    za = RegExp("[+\uff0b]+"),
    L = RegExp("^[+\uff0b]+"),
    Aa = RegExp("([0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9])"),
    Ba = RegExp("[+\uff0b0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]"),
    Ca = /[\\\/] *x/,
    Da = RegExp("[^0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9A-Za-z#]+$"),
    Ea = /(?:.*?[A-Za-z]){3}.*/,
    Fa = RegExp(
      "^\\+([0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]|[\\-\\.\\(\\)]?)*[0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]([0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]|[\\-\\.\\(\\)]?)*$"
    ),
    Ga = RegExp(
      "^([A-Za-z0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]+((\\-)*[A-Za-z0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9])*\\.)*[A-Za-z]+((\\-)*[A-Za-z0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9])*\\.?$"
    );
  function M(a) {
    return "([0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]{1," + a + "})";
  }
  function Ha() {
    return (
      ";ext=" +
      M("20") +
      "|[ \u00a0\\t,]*(?:e?xt(?:ensi(?:o\u0301?|\u00f3))?n?|\uff45?\uff58\uff54\uff4e?|\u0434\u043e\u0431|anexo)[:\\.\uff0e]?[ \u00a0\\t,-]*" +
      (M("20") +
        "#?|[ \u00a0\\t,]*(?:[x\uff58#\uff03~\uff5e]|int|\uff49\uff4e\uff54)[:\\.\uff0e]?[ \u00a0\\t,-]*") +
      (M("9") + "#?|[- ]+") +
      (M("6") + "#|[ \u00a0\\t]*(?:,{2}|;)[:\\.\uff0e]?[ \u00a0\\t,-]*") +
      (M("15") + "#?|[ \u00a0\\t]*(?:,)+[:\\.\uff0e]?[ \u00a0\\t,-]*") +
      (M("9") + "#?")
    );
  }
  var Ia = new RegExp("(?:" + Ha() + ")$", "i"),
    Ja = new RegExp(
      "^[0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]{2}$|^[+\uff0b]*(?:[-x\u2010-\u2015\u2212\u30fc\uff0d-\uff0f \u00a0\u00ad\u200b\u2060\u3000()\uff08\uff09\uff3b\uff3d.\\[\\]/~\u2053\u223c\uff5e*]*[0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]){3,}[-x\u2010-\u2015\u2212\u30fc\uff0d-\uff0f \u00a0\u00ad\u200b\u2060\u3000()\uff08\uff09\uff3b\uff3d.\\[\\]/~\u2053\u223c\uff5e*A-Za-z0-9\uff10-\uff19\u0660-\u0669\u06f0-\u06f9]*(?:" +
        Ha() +
        ")?$",
      "i"
    ),
    Ka = /(\$\d)/,
    La = /^\(?\$1\)?$/;
  function Ma(a) {
    return 2 > a.length ? !1 : N(Ja, a);
  }
  function Na(a) {
    return N(Ea, a) ? O(a, ya) : O(a, K);
  }
  function Oa(a) {
    var b = Na(a.toString());
    C(a);
    a.g(b);
  }
  function Pa(a) {
    return null != a && (1 != w(a, 9) || -1 != t(a, 9)[0]);
  }
  function O(a, b) {
    for (var c = new B(), d, e = a.length, f = 0; f < e; ++f)
      (d = a.charAt(f)), (d = b[d.toUpperCase()]), null != d && c.g(d);
    return c.toString();
  }
  function Qa(a) {
    return 0 == a.length || La.test(a);
  }
  function P(a) {
    return null != a && isNaN(a) && a.toUpperCase() in wa;
  }
  function Ra(a, b, c) {
    if (0 == q(b, 2) && p(b, 5)) {
      var d = v(b, 5);
      if (0 < d.length) return d;
    }
    d = v(b, 1);
    var e = Q(b);
    if (0 == c) return Sa(d, 0, e, "");
    if (!(d in I)) return e;
    a = R(a, d, S(d));
    b =
      p(b, 3) && 0 != q(b, 3).length
        ? 3 == c
          ? ";ext=" + q(b, 3)
          : p(a, 13)
          ? q(a, 13) + v(b, 3)
          : " ext. " + v(b, 3)
        : "";
    a: {
      a = 0 == t(a, 20).length || 2 == c ? t(a, 19) : t(a, 20);
      for (var f, g = a.length, h = 0; h < g; ++h) {
        f = a[h];
        var l = w(f, 3);
        if (0 == l || 0 == e.search(q(f, 3, l - 1)))
          if (((l = new RegExp(q(f, 1))), N(l, e))) {
            a = f;
            break a;
          }
      }
      a = null;
    }
    null != a &&
      ((g = a),
      (a = v(g, 2)),
      (f = new RegExp(q(g, 1))),
      v(g, 5),
      (g = v(g, 4)),
      (e =
        2 == c && null != g && 0 < g.length
          ? e.replace(f, a.replace(Ka, g))
          : e.replace(f, a)),
      3 == c &&
        ((e = e.replace(
          RegExp(
            "^[-x\u2010-\u2015\u2212\u30fc\uff0d-\uff0f \u00a0\u00ad\u200b\u2060\u3000()\uff08\uff09\uff3b\uff3d.\\[\\]/~\u2053\u223c\uff5e]+"
          ),
          ""
        )),
        (e = e.replace(
          RegExp(
            "[-x\u2010-\u2015\u2212\u30fc\uff0d-\uff0f \u00a0\u00ad\u200b\u2060\u3000()\uff08\uff09\uff3b\uff3d.\\[\\]/~\u2053\u223c\uff5e]+",
            "g"
          ),
          "-"
        ))));
    return Sa(d, c, e, b);
  }
  function R(a, b, c) {
    return "001" == c ? T(a, "" + b) : T(a, c);
  }
  function Q(a) {
    if (!p(a, 2)) return "";
    var b = "" + q(a, 2);
    return p(a, 4) && q(a, 4) && 0 < v(a, 8)
      ? Array(v(a, 8) + 1).join("0") + b
      : b;
  }
  function Sa(a, b, c, d) {
    switch (b) {
      case 0:
        return "+" + a + c + d;
      case 1:
        return "+" + a + " " + c + d;
      case 3:
        return "tel:+" + a + "-" + c + d;
      default:
        return c + d;
    }
  }
  function U(a, b) {
    switch (b) {
      case 4:
        return q(a, 5);
      case 3:
        return q(a, 4);
      case 1:
        return q(a, 3);
      case 0:
      case 2:
        return q(a, 2);
      case 5:
        return q(a, 6);
      case 6:
        return q(a, 8);
      case 7:
        return q(a, 7);
      case 8:
        return q(a, 21);
      case 9:
        return q(a, 25);
      case 10:
        return q(a, 28);
      default:
        return q(a, 1);
    }
  }
  function Ta(a, b) {
    return V(a, q(b, 1))
      ? V(a, q(b, 5))
        ? 4
        : V(a, q(b, 4))
        ? 3
        : V(a, q(b, 6))
        ? 5
        : V(a, q(b, 8))
        ? 6
        : V(a, q(b, 7))
        ? 7
        : V(a, q(b, 21))
        ? 8
        : V(a, q(b, 25))
        ? 9
        : V(a, q(b, 28))
        ? 10
        : V(a, q(b, 2))
        ? q(b, 18) || V(a, q(b, 3))
          ? 2
          : 0
        : !q(b, 18) && V(a, q(b, 3))
        ? 1
        : -1
      : -1;
  }
  function T(a, b) {
    if (null == b) return null;
    b = b.toUpperCase();
    var c = a.g[b];
    if (null == c) {
      c = wa[b];
      if (null == c) return null;
      c = new A().g(G.m(), c);
      a.g[b] = c;
    }
    return c;
  }
  function V(a, b) {
    var c = a.length;
    return 0 < w(b, 9) && -1 == t(b, 9).indexOf(c) ? !1 : N(v(b, 2), a);
  }
  function Ua(a, b) {
    if (null == b) return null;
    var c = v(b, 1);
    c = I[c];
    if (null == c) a = null;
    else if (1 == c.length) a = c[0];
    else
      a: {
        b = Q(b);
        for (var d, e = c.length, f = 0; f < e; f++) {
          d = c[f];
          var g = T(a, d);
          if (p(g, 23)) {
            if (0 == b.search(q(g, 23))) {
              a = d;
              break a;
            }
          } else if (-1 != Ta(b, g)) {
            a = d;
            break a;
          }
        }
        a = null;
      }
    return a;
  }
  function S(a) {
    a = I[a];
    return null == a ? "ZZ" : a[0];
  }
  function Va(a, b) {
    a = T(a, b);
    if (null == a) throw Error("Invalid region code: " + b);
    return v(a, 10);
  }
  function W(a, b, c, d) {
    var e = U(c, d),
      f = 0 == w(e, 9) ? t(q(c, 1), 9) : t(e, 9);
    e = t(e, 10);
    if (2 == d)
      if (Pa(U(c, 0)))
        (a = U(c, 1)),
          Pa(a) &&
            ((f = f.concat(0 == w(a, 9) ? t(q(c, 1), 9) : t(a, 9))),
            f.sort(),
            0 == e.length
              ? (e = t(a, 10))
              : ((e = e.concat(t(a, 10))), e.sort()));
      else return W(a, b, c, 1);
    if (-1 == f[0]) return 5;
    b = b.length;
    if (-1 < e.indexOf(b)) return 4;
    c = f[0];
    return c == b
      ? 0
      : c > b
      ? 2
      : f[f.length - 1] < b
      ? 3
      : -1 < f.indexOf(b, 1)
      ? 0
      : 5;
  }
  function X(a, b, c) {
    var d = Q(b);
    b = v(b, 1);
    if (!(b in I)) return 1;
    b = R(a, b, S(b));
    return W(a, d, b, c);
  }
  function Wa(a, b) {
    a = a.toString();
    if (0 == a.length || "0" == a.charAt(0)) return 0;
    for (var c, d = a.length, e = 1; 3 >= e && e <= d; ++e)
      if (((c = parseInt(a.substring(0, e), 10)), c in I))
        return b.g(a.substring(e)), c;
    return 0;
  }
  function Xa(a, b, c, d, e, f) {
    if (0 == b.length) return 0;
    b = new B(b);
    var g;
    null != c && (g = q(c, 11));
    null == g && (g = "NonMatch");
    var h = b.toString();
    if (0 == h.length) g = 20;
    else if (L.test(h)) (h = h.replace(L, "")), C(b), b.g(Na(h)), (g = 1);
    else {
      h = new RegExp(g);
      Oa(b);
      g = b.toString();
      if (0 == g.search(h)) {
        h = g.match(h)[0].length;
        var l = g.substring(h).match(Aa);
        l && null != l[1] && 0 < l[1].length && "0" == O(l[1], K)
          ? (g = !1)
          : (C(b), b.g(g.substring(h)), (g = !0));
      } else g = !1;
      g = g ? 5 : 20;
    }
    e && r(f, 6, g);
    if (20 != g) {
      if (2 >= b.h.length) throw Error("Phone number too short after IDD");
      a = Wa(b, d);
      if (0 != a) return r(f, 1, a), a;
      throw Error("Invalid country calling code");
    }
    if (
      null != c &&
      ((g = v(c, 10)),
      (h = "" + g),
      (l = b.toString()),
      0 == l.lastIndexOf(h, 0) &&
        ((h = new B(l.substring(h.length))),
        (l = q(c, 1)),
        (l = new RegExp(v(l, 2))),
        Ya(h, c, null),
        (h = h.toString()),
        (!N(l, b.toString()) && N(l, h)) || 3 == W(a, b.toString(), c, -1)))
    )
      return d.g(h), e && r(f, 6, 10), r(f, 1, g), g;
    r(f, 1, 0);
    return 0;
  }
  function Ya(a, b, c) {
    var d = a.toString(),
      e = d.length,
      f = q(b, 15);
    if (0 != e && null != f && 0 != f.length) {
      var g = new RegExp("^(?:" + f + ")");
      if ((e = g.exec(d))) {
        f = new RegExp(v(q(b, 1), 2));
        var h = N(f, d),
          l = e.length - 1;
        b = q(b, 16);
        if (null == b || 0 == b.length || null == e[l] || 0 == e[l].length) {
          if (!h || N(f, d.substring(e[0].length)))
            null != c && 0 < l && null != e[l] && c.g(e[1]),
              a.set(d.substring(e[0].length));
        } else if (((d = d.replace(g, b)), !h || N(f, d)))
          null != c && 0 < l && c.g(e[1]), a.set(d);
      }
    }
  }
  function Y(a, b, c) {
    if (!P(c) && 0 < b.length && "+" != b.charAt(0))
      throw Error("Invalid country calling code");
    return Za(a, b, c, !0);
  }
  function Za(a, b, c, d) {
    if (null == b)
      throw Error("The string supplied did not seem to be a phone number");
    if (250 < b.length)
      throw Error("The string supplied is too long to be a phone number");
    var e = new B();
    var f = b.indexOf(";phone-context=");
    if (-1 === f) f = null;
    else if (((f += 15), f >= b.length)) f = "";
    else {
      var g = b.indexOf(";", f);
      f = -1 !== g ? b.substring(f, g) : b.substring(f);
    }
    var h = f;
    null == h
      ? (g = !0)
      : 0 === h.length
      ? (g = !1)
      : ((g = Fa.exec(h)), (h = Ga.exec(h)), (g = null !== g || null !== h));
    if (!g)
      throw Error("The string supplied did not seem to be a phone number");
    null != f
      ? ("+" === f.charAt(0) && e.g(f),
        (f = b.indexOf("tel:")),
        e.g(b.substring(0 <= f ? f + 4 : 0, b.indexOf(";phone-context="))))
      : ((f = e.g),
        (g = b ?? ""),
        (h = g.search(Ba)),
        0 <= h
          ? ((g = g.substring(h)),
            (g = g.replace(Da, "")),
            (h = g.search(Ca)),
            0 <= h && (g = g.substring(0, h)))
          : (g = ""),
        f.call(e, g));
    f = e.toString();
    g = f.indexOf(";isub=");
    0 < g && (C(e), e.g(f.substring(0, g)));
    if (!Ma(e.toString()))
      throw Error("The string supplied did not seem to be a phone number");
    f = e.toString();
    if (!(P(c) || (null != f && 0 < f.length && L.test(f))))
      throw Error("Invalid country calling code");
    f = new H();
    d && r(f, 5, b);
    a: {
      b = e.toString();
      g = b.search(Ia);
      if (0 <= g && Ma(b.substring(0, g))) {
        h = b.match(Ia);
        for (var l = h.length, D = 1; D < l; ++D)
          if (null != h[D] && 0 < h[D].length) {
            C(e);
            e.g(b.substring(0, g));
            b = h[D];
            break a;
          }
      }
      b = "";
    }
    0 < b.length && r(f, 3, b);
    g = T(a, c);
    b = new B();
    h = 0;
    l = e.toString();
    try {
      h = Xa(a, l, g, b, d, f);
    } catch (ba) {
      if ("Invalid country calling code" == ba.message && L.test(l)) {
        if (((l = l.replace(L, "")), (h = Xa(a, l, g, b, d, f)), 0 == h))
          throw ba;
      } else throw ba;
    }
    0 != h
      ? ((e = S(h)), e != c && (g = R(a, h, e)))
      : (Oa(e),
        b.g(e.toString()),
        null != c
          ? ((h = v(g, 10)), r(f, 1, h))
          : d && (delete f.h[6], f.g && delete f.g[6]));
    if (2 > b.h.length)
      throw Error("The string supplied is too short to be a phone number");
    null != g &&
      ((c = new B()),
      (e = new B(b.toString())),
      Ya(e, g, c),
      (a = W(a, e.toString(), g, -1)),
      2 != a &&
        4 != a &&
        5 != a &&
        ((b = e), d && 0 < c.toString().length && r(f, 7, c.toString())));
    d = b.toString();
    a = d.length;
    if (2 > a)
      throw Error("The string supplied is too short to be a phone number");
    if (17 < a)
      throw Error("The string supplied is too long to be a phone number");
    if (1 < d.length && "0" == d.charAt(0)) {
      r(f, 4, !0);
      for (a = 1; a < d.length - 1 && "0" == d.charAt(a); ) a++;
      1 != a && r(f, 8, a);
    }
    r(f, 2, parseInt(d, 10));
    return f;
  }
  function N(a, b) {
    return (a =
      "string" == typeof a ? b.match("^(?:" + a + ")$") : b.match(a)) &&
      a[0].length == b.length
      ? !0
      : !1;
  }
  function $a(a) {
    this.fa = RegExp("\u2008");
    this.ja = "";
    this.v = new B();
    this.da = "";
    this.s = new B();
    this.ba = new B();
    this.u = !0;
    this.ea = this.ca = this.la = !1;
    this.ga = J.g();
    this.$ = 0;
    this.h = new B();
    this.ha = !1;
    this.o = "";
    this.g = new B();
    this.j = [];
    this.ka = a;
    this.l = ab(this, this.ka);
  }
  var bb = new G();
  r(bb, 11, "NA");
  var cb = RegExp(
      "^[-x\u2010-\u2015\u2212\u30fc\uff0d-\uff0f \u00a0\u00ad\u200b\u2060\u3000()\uff08\uff09\uff3b\uff3d.\\[\\]/~\u2053\u223c\uff5e]*\\$1[-x\u2010-\u2015\u2212\u30fc\uff0d-\uff0f \u00a0\u00ad\u200b\u2060\u3000()\uff08\uff09\uff3b\uff3d.\\[\\]/~\u2053\u223c\uff5e]*(\\$\\d[-x\u2010-\u2015\u2212\u30fc\uff0d-\uff0f \u00a0\u00ad\u200b\u2060\u3000()\uff08\uff09\uff3b\uff3d.\\[\\]/~\u2053\u223c\uff5e]*)*$"
    ),
    db = /[- ]/;
  function ab(a, b) {
    var c = a.ga;
    b = P(b) ? Va(c, b) : 0;
    a = T(a.ga, S(b));
    return null != a ? a : bb;
  }
  function eb(a) {
    for (var b = a.j.length, c = 0; c < b; ++c) {
      var d = a.j[c],
        e = v(d, 1);
      if (a.da == e) return !1;
      var f = a;
      var g = d,
        h = v(g, 1);
      C(f.v);
      var l = f;
      g = v(g, 2);
      var D = "999999999999999".match(h)[0];
      D.length < l.g.h.length
        ? (l = "")
        : ((l = D.replace(new RegExp(h, "g"), g)),
          (l = l.replace(RegExp("9", "g"), "\u2008")));
      0 < l.length ? (f.v.g(l), (f = !0)) : (f = !1);
      if (f) return (a.da = e), (a.ha = db.test(q(d, 4))), (a.$ = 0), !0;
    }
    return (a.u = !1);
  }
  function fb(a, b) {
    for (var c = [], d = b.length - 3, e = a.j.length, f = 0; f < e; ++f) {
      var g = a.j[f];
      0 == w(g, 3)
        ? c.push(a.j[f])
        : ((g = q(g, 3, Math.min(d, w(g, 3) - 1))),
          0 == b.search(g) && c.push(a.j[f]));
    }
    a.j = c;
  }
  function gb(a, b) {
    a.s.g(b);
    var c = b;
    Aa.test(c) || (1 == a.s.h.length && za.test(c))
      ? ("+" == b ? ((c = b), a.ba.g(b)) : ((c = K[b]), a.ba.g(c), a.g.g(c)),
        (b = c))
      : ((a.u = !1), (a.la = !0));
    if (!a.u) {
      if (!a.la)
        if (hb(a)) {
          if (ib(a)) return jb(a);
        } else if (
          (0 < a.o.length &&
            ((b = a.g.toString()),
            C(a.g),
            a.g.g(a.o),
            a.g.g(b),
            (b = a.h.toString()),
            (c = b.lastIndexOf(a.o)),
            C(a.h),
            a.h.g(b.substring(0, c))),
          a.o != kb(a))
        )
          return a.h.g(" "), jb(a);
      return a.s.toString();
    }
    switch (a.ba.h.length) {
      case 0:
      case 1:
      case 2:
        return a.s.toString();
      case 3:
        if (hb(a)) a.ea = !0;
        else return (a.o = kb(a)), lb(a);
      default:
        if (a.ea) return ib(a) && (a.ea = !1), a.h.toString() + a.g.toString();
        if (0 < a.j.length) {
          b = mb(a, b);
          c = nb(a);
          if (0 < c.length) return c;
          fb(a, a.g.toString());
          return eb(a) ? ob(a) : a.u ? Z(a, b) : a.s.toString();
        }
        return lb(a);
    }
  }
  function jb(a) {
    a.u = !0;
    a.ea = !1;
    a.j = [];
    a.$ = 0;
    C(a.v);
    a.da = "";
    return lb(a);
  }
  function nb(a) {
    for (var b = a.g.toString(), c = a.j.length, d = 0; d < c; ++d) {
      var e = a.j[d],
        f = v(e, 1);
      if (
        new RegExp("^(?:" + f + ")$").test(b) &&
        ((a.ha = db.test(q(e, 4))),
        (e = b.replace(new RegExp(f, "g"), q(e, 2))),
        (e = Z(a, e)),
        O(e, xa) == a.ba)
      )
        return e;
    }
    return "";
  }
  function Z(a, b) {
    var c = a.h.h.length;
    return a.ha && 0 < c && " " != a.h.toString().charAt(c - 1)
      ? a.h + " " + b
      : a.h + b;
  }
  function lb(a) {
    var b = a.g.toString();
    if (3 <= b.length) {
      for (
        var c =
            a.ca && 0 == a.o.length && 0 < w(a.l, 20) ? t(a.l, 20) : t(a.l, 19),
          d = c.length,
          e = 0;
        e < d;
        ++e
      ) {
        var f = c[e];
        (0 < a.o.length && Qa(v(f, 4)) && !q(f, 6) && !p(f, 5)) ||
          ((0 != a.o.length || a.ca || Qa(v(f, 4)) || q(f, 6)) &&
            cb.test(v(f, 2)) &&
            a.j.push(f));
      }
      fb(a, b);
      b = nb(a);
      return 0 < b.length ? b : eb(a) ? ob(a) : a.s.toString();
    }
    return Z(a, b);
  }
  function ob(a) {
    var b = a.g.toString(),
      c = b.length;
    if (0 < c) {
      for (var d = "", e = 0; e < c; e++) d = mb(a, b.charAt(e));
      return a.u ? Z(a, d) : a.s.toString();
    }
    return a.h.toString();
  }
  function kb(a) {
    var b = a.g.toString(),
      c = 0;
    if (1 != q(a.l, 10)) var d = !1;
    else
      (d = a.g.toString()),
        (d = "1" == d.charAt(0) && "0" != d.charAt(1) && "1" != d.charAt(1));
    d
      ? ((c = 1), a.h.g("1").g(" "), (a.ca = !0))
      : p(a.l, 15) &&
        ((d = new RegExp("^(?:" + q(a.l, 15) + ")")),
        (d = b.match(d)),
        null != d &&
          null != d[0] &&
          0 < d[0].length &&
          ((a.ca = !0), (c = d[0].length), a.h.g(b.substring(0, c))));
    C(a.g);
    a.g.g(b.substring(c));
    return b.substring(0, c);
  }
  function hb(a) {
    var b = a.ba.toString(),
      c = new RegExp("^(?:\\+|" + q(a.l, 11) + ")");
    c = b.match(c);
    return null != c && null != c[0] && 0 < c[0].length
      ? ((a.ca = !0),
        (c = c[0].length),
        C(a.g),
        a.g.g(b.substring(c)),
        C(a.h),
        a.h.g(b.substring(0, c)),
        "+" != b.charAt(0) && a.h.g(" "),
        !0)
      : !1;
  }
  function ib(a) {
    if (0 == a.g.h.length) return !1;
    var b = new B(),
      c = Wa(a.g, b);
    if (0 == c) return !1;
    C(a.g);
    a.g.g(b.toString());
    b = S(c);
    "001" == b ? (a.l = T(a.ga, "" + c)) : b != a.ka && (a.l = ab(a, b));
    a.h.g("" + c).g(" ");
    a.o = "";
    return !0;
  }
  function mb(a, b) {
    var c = a.v.toString();
    if (0 <= c.substring(a.$).search(a.fa)) {
      var d = c.search(a.fa);
      b = c.replace(a.fa, b);
      C(a.v);
      a.v.g(b);
      a.$ = d;
      return b.substring(0, a.$ + 1);
    }
    1 == a.j.length && (a.u = !1);
    a.da = "";
    return a.s.toString();
  }
  k("intlTelInputUtils", {});
  k("intlTelInputUtils.formatNumberAsYouType", (a, b) => {
    try {
      const c = a.replace(/[^+0-9]/g, ""),
        d = new $a(b);
      let e = "";
      for (let f = 0; f < c.length; f++)
        (b = d), (b.ja = gb(b, c.charAt(f))), (e = b.ja);
      return e;
    } catch (c) {
      return a;
    }
  });
  k("intlTelInputUtils.formatNumber", (a, b, c) => {
    try {
      const e = J.g(),
        f = Y(e, a, b);
      var d = X(e, f, -1);
      return 0 == d || 4 == d ? Ra(e, f, "undefined" === typeof c ? 0 : c) : a;
    } catch (e) {
      return a;
    }
  });
  k("intlTelInputUtils.getExampleNumber", (a, b, c) => {
    try {
      const h = J.g();
      a: {
        var d = h;
        if (P(a)) {
          var e = U(T(d, a), c);
          try {
            if (p(e, 6)) {
              var f = q(e, 6);
              var g = Za(d, f, a, !1);
              break a;
            }
          } catch (l) {}
        }
        g = null;
      }
      return Ra(h, g, b ? 2 : 1);
    } catch (h) {
      return "";
    }
  });
  k("intlTelInputUtils.getExtension", (a, b) => {
    try {
      return q(Y(J.g(), a, b), 3);
    } catch (c) {
      return "";
    }
  });
  k("intlTelInputUtils.getNumberType", (a, b) => {
    try {
      const h = J.g();
      var c = Y(h, a, b);
      a = h;
      var d = Ua(a, c),
        e = R(a, v(c, 1), d);
      if (null == e) var f = -1;
      else {
        var g = Q(c);
        f = Ta(g, e);
      }
      return f;
    } catch (h) {
      return -99;
    }
  });
  k("intlTelInputUtils.getValidationError", (a, b) => {
    try {
      const c = J.g(),
        d = Y(c, a, b);
      return X(c, d, -1);
    } catch (c) {
      return "Invalid country calling code" === c.message
        ? 1
        : 3 >= a.length ||
          "Phone number too short after IDD" === c.message ||
          "The string supplied is too short to be a phone number" === c.message
        ? 2
        : "The string supplied is too long to be a phone number" === c.message
        ? 3
        : -99;
    }
  });
  k("intlTelInputUtils.isValidNumber", (a, b) => {
    try {
      const l = J.g();
      var c = Y(l, a, b);
      a = l;
      var d = Ua(a, c);
      var e = v(c, 1),
        f = R(a, e, d);
      if (null == f || ("001" != d && e != Va(a, d))) var g = !1;
      else {
        var h = Q(c);
        g = -1 != Ta(h, f);
      }
      return g;
    } catch (l) {
      return !1;
    }
  });
  k("intlTelInputUtils.isPossibleNumber", (a, b, c) => {
    try {
      const d = J.g(),
        e = Y(d, a, b);
      return c ? 0 === X(d, e, 1) : 0 === X(d, e, -1);
    } catch (d) {
      return !1;
    }
  });
  k("intlTelInputUtils.numberFormat", {
    E164: 0,
    INTERNATIONAL: 1,
    NATIONAL: 2,
    RFC3966: 3,
  });
  k("intlTelInputUtils.numberType", {
    FIXED_LINE: 0,
    MOBILE: 1,
    FIXED_LINE_OR_MOBILE: 2,
    TOLL_FREE: 3,
    PREMIUM_RATE: 4,
    SHARED_COST: 5,
    VOIP: 6,
    PERSONAL_NUMBER: 7,
    PAGER: 8,
    UAN: 9,
    VOICEMAIL: 10,
    UNKNOWN: -1,
  });
  k("intlTelInputUtils.validationError", {
    IS_POSSIBLE: 0,
    INVALID_COUNTRY_CODE: 1,
    TOO_SHORT: 2,
    TOO_LONG: 3,
    IS_POSSIBLE_LOCAL_ONLY: 4,
    INVALID_LENGTH: 5,
  });
})();
