import vars from "../_vars";
import { disableScroll } from "../functions/disable-scroll";
import { enableScroll } from "../functions/enable-scroll";
import {
  removeClassInArray,
  addClassInArray,
  addCustomClass,
  removeCustomClass,
  fadeOut,
  fadeIn,
} from "../functions/customFunctions";

export function modalClickHandler(
  attribute,
  activeClass,
  overlayClass = activeClass
) {
  const curentModal = overlay.querySelector(`[data-popup="${attribute}"]`);
  removeClassInArray(modals, activeClass);
  addCustomClass(overlay, overlayClass);
  addCustomClass(curentModal, activeClass);
  fadeIn(curentModal, 200);
  disableScroll();

  innerButton = overlay.querySelector(
    `${"[data-popup]"}.${activeClass} .close`
  );
}

const {
  overlay,
  activeClass,
  mobileMenu,
  modals,
  modalsButton,
  activeMode,
  innerButtonModal,
  burger,
} = vars;
let innerButton;
const commonFunction = function () {
  removeCustomClass(overlay, activeMode);
  removeCustomClass(overlay, activeClass);
  removeClassInArray(modals, activeClass);

  modals.forEach((modal) => fadeOut(modal, 300));
  enableScroll();
};

function findAttribute(element, attributeName) {
  let target = element;
  while (target && target !== document) {
    if (target.hasAttribute(attributeName)) {
      return target.getAttribute(attributeName);
    }
    target = target.parentNode;
  }
  return null;
}

function buttonClickHandler(e, buttonAttribute, activeClass) {
  e.preventDefault();
  const currentModalId = findAttribute(e.target, buttonAttribute);
  if (!currentModalId) {
    return;
  }

  const currentModal = overlay.querySelector(
    `[data-popup="${currentModalId}"]`
  );

  mobileMenu && removeCustomClass(mobileMenu, activeClass);
  burger && removeClassInArray(burger, activeClass);

  removeClassInArray(modals, activeClass);
  addCustomClass(overlay, activeClass);
  addCustomClass(overlay, activeMode);
  addCustomClass(currentModal, activeClass);
  fadeIn(currentModal, 200, "flex");

  disableScroll();
  innerButton = overlay.querySelector(
    `${"[data-popup]"}.${activeClass} .close`
  );
}

function overlayClickHandler(e, activeClass) {
  if (e.target === overlay || e.target === innerButton) commonFunction();
}

function modalInit(buttonsArray, buttonAttribute, activeClass) {
  buttonsArray.map(function (btn) {
    btn.addEventListener("click", (e) => {
      buttonClickHandler(e, buttonAttribute, activeClass);

      if (btn.getAttribute("data-btn-modal") === "consultation") {
        const parent = btn.closest('.villas-card.sales-card');
        const textCard = parent.querySelector(".sales-card__title").textContent;
        const currentModal = overlay.querySelector(
          `[data-popup="consultation"]`
        );
        const form = currentModal.querySelector('.form-validation');
        
        
        if(textCard){
          const hiddenInput = document.createElement('input');
          hiddenInput.type = 'hidden';
          hiddenInput.name = 'offer';
          hiddenInput.value = parent.querySelector('.sales-card__title').innerText.replace(/[\r\n]+/g, ' ');
          form.appendChild(hiddenInput);
        }

        if (textCard && currentModal) {
          currentModal.querySelector(".modal__title-name").textContent =
            textCard;
        }
      }
    });
  });
}

overlay &&
  overlay.addEventListener("click", function (e) {
    overlayClickHandler(e, activeClass);
  });

modalInit(modalsButton, "data-btn-modal", activeClass);

innerButtonModal &&
  innerButtonModal.forEach(function (btn) {
    btn.addEventListener("click", function (e) {
      enableScroll();
      e.preventDefault();

      const prevId = findAttribute(this.closest("[data-popup]"), "data-popup");
      if (!prevId) {
        return;
      }

      const currentModalId = this.getAttribute("data-btn-inner");
      const currentModal = overlay.querySelector(
        `[data-popup="${currentModalId}"]`
      );
      removeClassInArray(modals, activeClass);
      addCustomClass(overlay, activeClass);
      fadeOut(document.querySelector(`[data-popup="${prevId}"]`), 0);
      fadeIn(currentModal, 200);
      addCustomClass(currentModal, activeClass);
      disableScroll();
      innerButton = overlay.querySelector(
        `${"[data-popup]"}.${activeClass} .close`
      );
    });
  });

  const estateModal = 'estate';
  const catalogModal = 'catalog';
  
  let openFlag = false;
  let lastModal = null;
  let modalShowCount = sessionStorage.getItem('modalShowCount') ? parseInt(sessionStorage.getItem('modalShowCount')) : 0;
  const maxModalShows = 2;
  
  function getDelay(attribute) {
    const overlayElement = document.querySelector('.overlay');
    return overlayElement ? parseInt(overlayElement.getAttribute(attribute), 10) : null;
  }
  
  function showRandomModal() {
    if (modalShowCount >= maxModalShows) {
      return;
    }
  
    let nextModal;
    if (lastModal === null) {
    
      const modals = [];
      if (document.querySelector(`[data-popup="${estateModal}"]`)) {
        modals.push(estateModal);
      }
      if (document.querySelector(`[data-popup="${catalogModal}"]`)) {
        modals.push(catalogModal);
      }
      if (modals.length === 0) {
        return;
      }
      const randomIndex = Math.floor(Math.random() * modals.length);
      nextModal = modals[randomIndex];
    } else {
  
      nextModal = lastModal === estateModal ? catalogModal : estateModal;
      if (!document.querySelector(`[data-popup="${nextModal}"]`)) {
        return;
      }
    }
    lastModal = nextModal;
    modalShowCount++;
    sessionStorage.setItem('modalShowCount', modalShowCount);
    document.querySelectorAll('.modal').forEach((e) => {
      e.classList.contains('active') ? fadeOut(e, 0) : '';
    });
    modalClickHandler(nextModal);
    openFlag = true;
  }
  
  function handleClose() {
    openFlag = false;
    commonFunction();
    if (modalShowCount < maxModalShows) {
      const subsequentDelay = getDelay('data-second-delay') || 3000;
      setTimeout(showRandomModal, subsequentDelay);
    }
  }
 
  const initialDelay = getDelay('data-initial-delay') || 5000;
  setTimeout(showRandomModal, initialDelay);

  if (overlay) {
    overlay.addEventListener('click', (event) => {
      if (event.target.classList.contains('overlay')) {
        if (openFlag) {
          handleClose();
        }
      }
    });
  }
  
const estateCloseButton = document.querySelector('[data-popup="estate"] .close');
if (estateCloseButton) {
  estateCloseButton.addEventListener('click', () => {
    if (openFlag) {
      handleClose();
    }
  });
}

const catalogCloseButton = document.querySelector('[data-popup="catalog"] .close');
if (catalogCloseButton) {
  catalogCloseButton.addEventListener('click', () => {
    if (openFlag) {
      handleClose();
    }
  });
}